/**
 * File to help list out event types
 */
const eventTypes = {
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  USER_ACTIVITY: 'User Activity',
  CONTENT_SESSION: 'Content Session',
  CONTENT_LIKED: 'Content Liked',
  CONTENT_UNLIKE: 'Content UnLiked',
  COMMENT_LIKED: 'Comment Liked',
  COMMENT_UNLIKED: 'Comment UnLiked',
  CONTENT_CREATED: 'Content Created',
  CONTENT_UPDATED: 'Content Updated',
  CONTENT_SHARED: 'Content Shared',
  CONTENT_SAVED_OFFLINE: 'Content For Offline Saved',
  NEW_FEED_DOWNLOAD: 'New Feed',
  FOLDER_OPENED: 'Folder Opened',
  BANNER_OPENED: 'Banner Opened',
  CALENDAR_DAY_OPENED: 'Calendar Day Opened',
  CONTENT_OPENED: 'Content Opened',
  CONTENT_DELETED: 'Content Deleted',
  ALERT_OPENED: 'Alert Opened',
  COMMENT_CREATED: 'Comment Created',
  COMMENT_UPDATED: 'Comment Updated',
  COMMENT_REMOVED: 'Comment Removed',
  LOAD_MORE_COMMENTS: 'Load More Comments',
  EVENT_OPENED: 'Event Opened',
  DIRECTORY_ENTRY_OPENED: 'Directory Entry Opened',
  PUSH_NOTIFICATION_TAPPED: 'Push Notification Tapped',
  SOCIAL_SESSION: 'Social Session',
  ERROR: 'ERRORS',
  SEARCH_INITIATED: 'Search Initiated',
  SEARCH_ITEM_CLICKED: 'Search Item Clicked',
  VIDEO_PLAYED: 'Video Played',
  VIDEO_PAUSED: 'Video Paused',
  VIDEO_ENDED: 'Video Ended',
  AUDIO_PLAYED: 'Audio Played',
  AUDIO_PAUSED: 'Audio Paused',
  AUDIO_ENDED: 'Audio Ended',
  NEWSFEED_VIEW: 'Newsfeed View',
  VIDEO_SESSION: 'Video Session',
  AUDIO_SESSION: 'Audio Session',
  SEE_ALL_FOLDERS: 'See All Folders',
  LOAD_MORE_FOLDERS: 'Load More Folders',
  SORT_FOLDERS: 'Sort Folders',
  SEE_ALL_CONTENTS: 'See All Contents',
  LOAD_MORE_CONTENTS: 'Load More Contents',
  SORT_CONTENTS: 'Sort Contents',
  ADDRESS_OPENED: 'Address Opened',
  USER_NAME_UPDATE: 'User Name Update',
  MY_RECENT_CONTENT_WIDGET: 'My Recent Content Widget',
  MY_RECENT_CONTENT_WIDGET_DOT_CLICKED: 'My Recent Content Widget Dot Clicked',
  QUICK_LINK_CLICKED: 'Quick Link Clicked',
  APP_INTEGRATION_OPEN: 'App Integration Open',
  MY_DIRECTORY_WIDGET: 'My Directory Widget',
  SEE_ALL_CALENDAR: 'See All in Calendar',
  UPCOMING_EVENT_CLICKED: 'Upcoming Event Clicked',
  UPCOMING_LINK_CLICKED: 'Upcoming Link Clicked',
  CONTENT_PREVIEW_NEXT: 'Content Preview Next',
  CONTENT_PREVIEW_PREVIOUS: 'Content Preview Previous',
  PROFILE_WIDGET_EDIT: 'Profile Widget Edit',
  PROFILE_WIDGET_VIEW_PROFILE: 'Profile Widget View Profile',
  PROFILE_PHOTO_UPLOAD: 'Profile photo uploaded',
  PROFILE_PHOTO_DELETE: 'Profile photo removed'
}

export default eventTypes
