import { gql } from '@apollo/client'

const GET_USERS_FOR_CONTENT = gql`
  query GetUsersForContent($user: getUsersForContentInput!) {
    getUsersForContent(req: $user) {
      id: _id
      name
    }
  }
`

export {
  GET_USERS_FOR_CONTENT
}
