// @flow
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState, useCallback } from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import styled from 'styled-components'
import CommentWithReplies from 'michelangelo/dist/SharedComponents/ListItem/CommentWithReplies'
import CommentInput from 'michelangelo/dist/SharedComponents/Inputs/CommentInput'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'
import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import color from 'michelangelo/dist/Components/styles/color'
import InfoModal from 'michelangelo/dist/SharedComponents/InfoModal'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../../i18n/messages'

import { readFromCache } from '../../apollo/cacheHelper'
import { isAccountAdmin, isAppProfileAdmin } from '../../Helpers/UserInfoUtil'
import ConfirmModal from '../../Components/modals/ConfirmModal'

import GET_ME_QUERY from '../../apollo/queries/getMe'
import {
  CREATE_COMMENT_MUTATION,
  DELETE_COMMENT_MUTATION,
  GET_COMMENT_BY_ID,
  GET_COMMENTS_AND_REPLY_QUERY,
  GET_REPLIES,
  UPDATE_COMMENT_MUTATION
} from '../../apollo/queries/comments'
import { trackCommentEvent } from '../../Helpers/segmentHelper'
import { contentLocations } from '../../Helpers/contentHelper'
import SEND_EMAIL_QUERY from '../../apollo/queries/sendEmail'
import { checkIsReadAcknowledgement } from '../../Helpers/ReadAcknowledgement'
import OverlayPage from '../../Components/OverlayPage'
import { getLanguageTagFromLocale, shouldShowTranslate } from '../../Helpers/TranslationHelper'
import { ADD_COMMENT_LIKE_QUERY } from '../../apollo/queries/addLike'
import REMOVE_LIKE_QUERY from '../../apollo/queries/removeLike'
import { findIndex, includes, replace, sortBy, debounce, cloneDeep } from 'lodash'
import { useLocation } from 'react-router-dom'

import Tribute from 'tributejs'
import { getUsersForContent } from '../../apollo/queries/getUsers/query'

const CommentInputContainer = styled.div`
  margin-bottom: 1px;
  ${(props) => props.isMobilePreview && `
    width: 100%;
    box-shadow: 0px 0px 7px rgba(39, 43, 52, 0.08);
    z-index: 4;
  `}
`
const CommentContainer = styled.div`
  background-color: ${color.white};
`
const LoadMoreContainer = styled.div`
  min-height: 32px;
`
const CommentListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`
const Container = styled.div`
  background-color: ${color.white};

  ${(props) => props.isMobilePreview && `
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
  `}
`

type CommentsType = {
  content: Object,
  isContentPreview?: boolean,
  client?: Object,
  testID?: string,
  isMobilePreview?: boolean
} & InjectIntlProvidedProps

const Comments: any = forwardRef((props: CommentsType, ref) => {
  const { content, isContentPreview, isMobilePreview, intl: { formatMessage, locale }, client, testID } = props
  const contentSubLocation = isContentPreview ? 'CONTENT_DETAILS' : 'CONTENT_CARD'
  const lang = getLanguageTagFromLocale(locale)
  const [createLike] = useMutation(ADD_COMMENT_LIKE_QUERY)
  const [removeLike] = useMutation(REMOVE_LIKE_QUERY)

  const [mentions, setMentions] = useState([])
  const [tribute, setTribute] = useState(null)
  const [defaultFontSize] = useState(16)

  const maxReplies = 2
  const limit = isMobilePreview ? 10 : 3
  const order = 'DESC'
  const repliesOrder = 'ASC'
  const [offset, setOffset] = useState(0)
  const [initiatedCommentDelete, setInitiatedCommentDelete] = useState(false)
  const [commentsArray, setCommentsArray] = useState([])
  const [activeParent, setActiveParent] = useState(null)
  const [activeFirstParent, setActiveFirstParent] = useState(null)
  const [getNewData, setGetNewData] = useState(false)
  const [getFirstComments, setGetFirstComments] = useState(true)
  const [pageChanged, setPageChanged] = useState(false)

  const { activeCfp } = readFromCache(useApolloClient(), ['activeCfp'])
  const [showModal, setShowModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [MAX_MENTIONS] = useState(10)
  const [showOverlay, setShowOverlay] = useState(false)

  const [selectedComment, setSelectedComment] = useState(null)

  const [domNode, setDomNode] = useState(null)
  const textInputRepliesRef = useCallback(node => {
    setDomNode(node)
  }, [])

  const textInputRef = useRef(null)
  const replyTextInputRef = useRef(null)

  const { loading, data: { getMeRequest: me } } = useQuery(GET_ME_QUERY)

  const account = me && me.memberships && me.memberships[0] ? me.memberships[0].account : { commentThreadingEnabled: false }

  const isAppProfileAdminUser = isAppProfileAdmin() && includes(activeCfp.admins, me._id)

  const [getComments, { data: getCommentsData, loading: getCommentsLoading }] = useLazyQuery(GET_COMMENTS_AND_REPLY_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  })
  const [getRepliesLazy, { data: getRepliesData, loading: getRepliesLoading }] = useLazyQuery(GET_REPLIES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  })
  const getReplies = async (parentId, firstParentId, limit, offset) => {
    setGetNewData(true)
    setActiveParent(parentId)
    setActiveFirstParent(firstParentId)
    await getRepliesLazy({
      variables: {
        comment: { account: content.account, discussionId: content._id, parentId, limit, offset, order: repliesOrder }
      }
    })
  }
  const [getCommentById, { data: getCommentByIdData, loading: getCommentByIdLoading }] = useLazyQuery(GET_COMMENT_BY_ID, {
    fetchPolicy: 'network-only'
  })

  const [createComment] = useMutation(CREATE_COMMENT_MUTATION)
  const [updateComment] = useMutation(UPDATE_COMMENT_MUTATION)
  const [deleteComment] = useMutation(DELETE_COMMENT_MUTATION)
  const [sendEmail] = useMutation(SEND_EMAIL_QUERY)

  const modalRef = useRef(null)

  const primaryColor = activeCfp.primaryColor
  const location = useLocation()

  useEffect(() => {
    if (tribute && domNode) {
      tribute.attach(domNode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domNode, tribute])

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    setInputFocus () {
      if (textInputRef.current) {
        textInputRef.current.focus()
      }
    }
  }))

  useEffect(() => {
    if (account.mentionsEnabled && content.mentionsEnabled) {
      const tribute = new Tribute({
        trigger: '@',
        values: debouncedRemoteSearch,
        selectTemplate: function (item) {
          if (item && item.original) {
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')
            // $FlowFixMe[incompatible-type]
            context.font = `400 ${defaultFontSize}px sans-serif`
            const { width } = context.measureText(`@${item.original.name}`)

            return `<input class="mentions-highlighted" style="width:${width - 1}px;" userId="${item.original.id}" value="@${item.original.name}" readonly />&nbsp;`
          }
        },
        menuItemTemplate: function (item) {
          const { name } = item.original
          let initials = ''

          if (name) {
            name.split(' ').forEach(s => { initials += s[0] })

            return `
            <div class='mentions-dropdown__initials'>
              ${initials}
            </div>
            <p class='mentions-dropdown__name'>
              ${name}
            </p>
            `
          }
        },
        selectClass: 'mentions-dropdown__highlighted',
        containerClass: 'mentions-dropdown__container',
        itemClass: 'mentions-dropdown__item',
        lookup: 'name',
        fillAttr: 'name',
        replaceTextSuffix: '',
        requireLeadingSpace: true,
        allowSpaces: true
      })

      tribute.attach(textInputRef.current)
      setTribute(tribute)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const remoteSearch = async (text, cb) => {
    const users = await getUsersForContent(client, content._id, content.account, text)

    try {
      if (users && users.data && users.data.getUsersForContent) {
        const mentionedUsers = JSON.parse(sessionStorage.getItem(`mentions-${content._id}`) || '')
        const filteredUsers = users.data.getUsersForContent.filter(users => !mentionedUsers.some(mentioned => users.id === mentioned))

        cb(filteredUsers)
      }
    } catch (e) {
      console.log(e)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRemoteSearch = useCallback(debounce(remoteSearch, 250), [])

  useEffect(() => {
    sessionStorage.setItem(`mentions-${content._id}`, JSON.stringify(mentions))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentions])

  const handleInput = (event) => {
    if (account.mentionsEnabled && content.mentionsEnabled) {
      const input = event.target
      const inputs = input.querySelectorAll('.mentions-highlighted')

      if (!input.hasAttribute('tribute-event-listener')) {
        input.addEventListener('tribute-active-true', function (e) {
          input.setAttribute('tribute-event-listener', true)
          const usersMentioned = JSON.parse(sessionStorage.getItem(`mentions-${content._id}`) || '')
          if (usersMentioned.length >= MAX_MENTIONS && tribute) {
            setShowInfoModal(true)
            tribute.isActive = false
          }
        })
      }

      if (!inputs.length) {
        setMentions([])
      }

      setMentions([])
      if (!inputs.length && !input.textContent.length && tribute) { // CTRL + A + Backspace
        tribute.hideMenu()
      }

      inputs.forEach(el => {
        // const previousSibling = el.previousSibling
        const nextSibling = el.nextSibling

        if (nextSibling && nextSibling.nodeType === 1) {
          el.insertAdjacentHTML('afterend', '&nbsp;')
        }

        const id = el.getAttribute('userId')
        setMentions((m) => [...m, id])
      })
    }
  }

  useEffect(() => {
    if (location) {
      setOffset(0)
      setPageChanged(true)
      setGetFirstComments(true)
    }
  }, [location])

  useEffect(() => {
    if (showInfoModal) {
      document.addEventListener('click', handleOutsideClick, false)
    } else {
      document.removeEventListener('click', handleOutsideClick, false)
    }
  }, [showInfoModal])

  const handleOutsideClick = (e: Event) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setShowInfoModal(false)
    }
  }

  useEffect(() => {
    if (getNewData && !getCommentsLoading && getCommentsData && getCommentsData.getCommentsAndReplies) {
      // used Set to have unique object values
      if (pageChanged) {
        setCommentsArray(getCommentsData.getCommentsAndReplies)
        setPageChanged(false)
      } else setCommentsArray([...new Set([...commentsArray, ...getCommentsData.getCommentsAndReplies])])
      setGetNewData(false)
    }
    // eslint-disable-next-line
  }, [getCommentsLoading, getCommentsData])

  useEffect(() => {
    if (getNewData && !getRepliesLoading && getRepliesData && getRepliesData.getReplies) {
      const comments = cloneDeep(commentsArray)
      if (activeFirstParent && activeParent) { // third level comment
        const firstParentIndex = findIndex(comments, ['_id', activeFirstParent])
        const parentIndex = findIndex(comments[firstParentIndex].children, ['_id', activeParent])
        if (repliesOrder === 'DESC') { comments[firstParentIndex].children[parentIndex].children = [...new Set([...comments[firstParentIndex].children[parentIndex].children || [], ...getRepliesData.getReplies])] }
        if (repliesOrder === 'ASC') { comments[firstParentIndex].children[parentIndex].children = [...new Set([...getRepliesData.getReplies, ...comments[firstParentIndex].children[parentIndex].children || []])] }
      } else if (activeParent) { // second level comment
        const firstParentIndex = findIndex(comments, ['_id', activeParent])
        comments[firstParentIndex].children = [...getRepliesData.getReplies, ...comments[firstParentIndex].children]
        comments[firstParentIndex].children = sortBy(comments[firstParentIndex].children, 'created')
        if (repliesOrder === 'DESC') comments[firstParentIndex].children.reverse()
      }
      setCommentsArray(comments)
      setGetNewData(false)
    }
    // eslint-disable-next-line
  }, [getRepliesLoading, getRepliesData])

  useEffect(() => {
    if (!getCommentByIdLoading && getCommentByIdData) {
      const commentId = getCommentByIdData.getCommentById._id
      const parentComment = getCommentByIdData.getCommentById.parentComment
      const firstParent = getCommentByIdData.getCommentById.firstParent
      let comment
      const comments = cloneDeep(commentsArray)
      if (!parentComment) { // 0 level comment
        comment = commentsArray.find((comment) => {
          return comment._id === commentId
        })
        if (comment) {
          const commentIndex = findIndex(commentsArray, ['_id', commentId])
          comments[commentIndex] = { ...comment, ...getCommentByIdData.getCommentById, loading: false }
          setCommentsArray(comments)
        }
      } else if (!firstParent) { // first level comment
        const parentIndex = findIndex(commentsArray, ['_id', parentComment])
        const childIndex = findIndex(commentsArray[parentIndex].children, ['_id', commentId])
        const comment = commentsArray[parentIndex].children[childIndex]
        comments[parentIndex].children[childIndex] = { ...comment, ...getCommentByIdData.getCommentById, loading: false }
        setCommentsArray(comments)
      } else { // second level comment
        const firstParentIndex = findIndex(commentsArray, ['_id', firstParent])
        const parentIndex = findIndex(commentsArray[firstParentIndex].children, ['_id', parentComment])
        const childIndex = findIndex(commentsArray[firstParentIndex].children[parentIndex].children, ['_id', commentId])
        const comment = commentsArray[firstParentIndex].children[parentIndex].children[childIndex]
        commentsArray[firstParentIndex].children[parentIndex].children[childIndex] = { ...comment, ...getCommentByIdData.getCommentById, loading: false }
        setCommentsArray(comments)
      }
    }
    // eslint-disable-next-line
  }, [getCommentByIdLoading, getCommentByIdData])

  useEffect(() => {
    function getMainComments () {
      setGetNewData(true)
      getComments({
        variables: {
          comment: { account: content.account, discussionId: content._id, commentsEnabled: content.commentsEnabled, totalComments: content.totalComments, limit, offset, maxReplies, order, repliesOrder }
        }
      })
      setGetFirstComments(false)
    }
    if (getFirstComments) { getMainComments() }
    // eslint-disable-next-line
  }, [getFirstComments])

  const checkIsRead = async () => {
    setShowOverlay(true)
    const isRead = await checkIsReadAcknowledgement(client, content, formatMessage, me, sendEmail)
    setShowOverlay(false)
    return isRead
  }

  const showDeleteModal = async (commentId) => {
    const isRead = await checkIsRead()
    // if was cancelled
    if (!isRead) return
    setSelectedComment(commentId)
    setShowModal(true)
  }

  const onDeleteComment = async (commentId) => {
    try {
      const isRead = await checkIsRead()
      // if was cancelled
      if (!isRead) return

      setInitiatedCommentDelete(true)
      const result = await deleteComment({
        variables: { comment: commentId, content: content._id }
      })
      const deletedComment = result?.data?.commentDelete
      trackCommentEvent('COMMENT_REMOVED', { ...content, contentLocation: contentLocations.NEWSFEED, contentSubLocation }, { commentId })
      await removeCommentFromArray(deletedComment)
      setShowModal(false)
    } catch (error) {
      console.error('error onDeleteComment ', error)
    } finally {
      setInitiatedCommentDelete(false)
    }
  }
  const removeCommentFromArray = async (comment) => {
    if (!comment) return
    const firstParent = comment.firstParent || null
    const parentComment = comment.parentComment || null
    const comments = cloneDeep(commentsArray)
    if (firstParent && parentComment) { // third level comment
      const firstParentIndex = findIndex(comments, ['_id', firstParent])
      const parentIndex = findIndex(comments[firstParentIndex].children, ['_id', parentComment])
      comments[firstParentIndex].children[parentIndex].children = comments[firstParentIndex].children[parentIndex].children.filter((item) => {
        return item._id !== comment._id
      })
      const childrenComments = comments[firstParentIndex].children[parentIndex].childrenComments || []
      comments[firstParentIndex].children[parentIndex].childrenComments = childrenComments.filter((item) => {
        return item !== comment._id
      })
      setCommentsArray(comments)
    } else if (parentComment) { // second level comment
      const firstParentIndex = findIndex(comments, ['_id', parentComment])
      comments[firstParentIndex].children = comments[firstParentIndex].children.filter((item) => {
        return item._id !== comment._id
      })
      comments[firstParentIndex].childrenComments = comments[firstParentIndex].childrenComments.filter((item) => {
        return item !== comment._id
      })
      setCommentsArray(comments)
    } else if (!firstParent && !parentComment) { // first level comment
      const newComments = comments.filter((item) => {
        return item._id !== comment._id
      })
      setCommentsArray(newComments)
      setOffset(offset - 1)
    }
  }

  const onUpdateComment = async (text, commentId) => {
    try {
      const isRead = await checkIsRead()
      // if was cancelled
      if (!isRead) return
      if (textInputRef && textInputRef.current) textInputRef.current.innerHTML = ''

      const div = returnAtMentions(text)

      const usersMentioned = JSON.parse(sessionStorage.getItem(`mentions-${content._id}`) || '')
      await updateComment({
        variables: { comment: { commentId, newCommentString: div.textContent, usersMentioned } }
      })
      trackCommentEvent('COMMENT_UPDATED', { ...content, contentLocation: contentLocations.NEWSFEED, contentSubLocation }, { commentId })
    } catch (error) {
      console.error('error onUpdateComment ', error)
    }
  }

  const returnAtMentions = (text) => {
    const div = document.createElement('div')
    div.innerHTML = text
    const inputs = div.querySelectorAll('input')

    inputs.forEach(i => {
      const id = i.getAttribute('userId')

      if (id) {
        const span = document.createElement('span')
        span.textContent = `@${id}`

        div.replaceChild(span, i)
      }
    })

    return div
  }

  const onPostComment = async (commentText, parentComment, firstParent, isReply) => {
    try {
      const isRead = await checkIsRead()
      // if was cancelled
      if (!isRead) return

      if (!commentText) return

      const div = returnAtMentions(commentText)

      const usersMentioned = JSON.parse(sessionStorage.getItem(`mentions-${content._id}`) || '')

      let commentToCreate = {
        account: content.account,
        comment: div.textContent,
        discussionId: content._id,
        discussionType: 'CONTENT',
        owner: { id: me._id, name: me.name, email: me.email, __typename: 'User' },
        usersMentioned
      }

      if (parentComment) commentToCreate = { ...commentToCreate, parentComment }
      if (firstParent) commentToCreate = { ...commentToCreate, firstParent }
      const result = await createComment({
        variables: { comment: { ...commentToCreate, owner: commentToCreate.owner.id } }
      })

      if (textInputRef && textInputRef.current) textInputRef.current.innerHTML = ''
      sessionStorage.setItem(`mentions-${content._id}`, JSON.stringify([]))

      const createdComment = result && result.data && result.data.commentCreation ? result.data.commentCreation : null

      trackCommentEvent('COMMENT_CREATED', { ...content, contentLocation: contentLocations.NEWSFEED, contentSubLocation }, { commentId: createdComment ? createdComment._id : null, parentComment })
      await addCommentToArray(createdComment)
    } catch (error) {
      console.error('error onPostComment', error)
    }
  }

  const addCommentToArray = async (comment) => {
    if (!comment) return
    const firstParent = comment.firstParent
    const parentComment = comment.parentComment
    const comments = cloneDeep(commentsArray)
    if (firstParent && parentComment) { // third level comment
      const firstParentIndex = findIndex(comments, ['_id', firstParent])
      const parentIndex = findIndex(comments[firstParentIndex].children, ['_id', parentComment])
      if (repliesOrder === 'DESC') {
        comments[firstParentIndex].children[parentIndex].children = [{ ...comment, recentlyAdded: true }, ...comments[firstParentIndex].children[parentIndex].children || []]
        comments[firstParentIndex].children[parentIndex].childrenComments = [comment._id, ...comments[firstParentIndex].children[parentIndex].childrenComments || []]
      } else {
        comments[firstParentIndex].children[parentIndex].children = [...comments[firstParentIndex].children[parentIndex].children || [], { ...comment, recentlyAdded: true }]
        comments[firstParentIndex].children[parentIndex].childrenComments = [...comments[firstParentIndex].children[parentIndex].childrenComments || [], comment._id]
      }
      setCommentsArray(comments)
    } else if (parentComment) { // second level comment
      const firstParentIndex = findIndex(comments, ['_id', parentComment])
      if (repliesOrder === 'DESC') {
        comments[firstParentIndex].children = [{ ...comment, recentlyAdded: true }, ...comments[firstParentIndex].children || []]
        comments[firstParentIndex].childrenComments = [comment._id, ...comments[firstParentIndex].childrenComments || []]
      } else {
        comments[firstParentIndex].children = [...comments[firstParentIndex].children || [], { ...comment, recentlyAdded: true }]
        comments[firstParentIndex].childrenComments = [...comments[firstParentIndex].childrenComments || [], comment._id]
      }
      setCommentsArray(comments)
    } else if (!firstParent && !parentComment) { // first level comment
      if (order === 'DESC') setCommentsArray([comment, ...comments])
      else setCommentsArray([...comments, comment])
      setOffset(offset + 1)
    }
  }

  const loadMore = async () => {
    const isRead = await checkIsRead()
    // if was cancelled
    if (!isRead) return
    if (content.totalFirstComments > limit + offset) {
      setOffset(limit + offset)
      setGetFirstComments(true)
      trackCommentEvent('LOAD_MORE_COMMENTS', { ...content, contentLocation: contentLocations.NEWSFEED, contentSubLocation }, { totalLoadedComments: limit + offset })
    }
  }

  const translateProps = (comment) => {
    const props = {
      showTranslateButton: shouldShowTranslate(lang),
      translateButtonTitle: formatMessage(messages.seeTranslation),
      translatedButtonTitle: formatMessage(messages.seeOriginal),
      renderTranslate: () => {
        if (!comment.translatedComment) comment.loading = true
        getCommentById({ variables: { comment: { commentId: comment._id, lang } } })
      }
    }

    return props
  }

  const onCommentLikeClick = async (comment) => {
    const isRead = await checkIsRead()
    // if was cancelled
    if (!isRead) return

    const likeRequest = {
      variables: {
        like: {
          account: me.memberships[0].account._id,
          item: comment._id,
          itemType: 'COMMENT',
          owner: me._id,
          created: content.postDate
        }
      }
    }
    if (comment.likedByUser) {
      const result = await removeLike(likeRequest)
      const unlikedComment = result && result.data && result.data.likeDelete ? result.data.likeDelete : null
      await updateCommentInArray(unlikedComment, comment.parentComment, comment.firstParent)
      trackCommentEvent('COMMENT_UNLIKED', { ...content, contentLocation: contentLocations.NEWSFEED, contentSubLocation }, { commentId: comment._id, comment: comment.comment, parentComment: comment.parentComment })
    } else {
      const result = await createLike(likeRequest)
      const likedComment = result && result.data && result.data.commentLikeCreation ? result.data.commentLikeCreation : null
      await updateCommentInArray(likedComment, comment.parentComment, comment.firstParent)
      trackCommentEvent('COMMENT_LIKED', { ...content, contentLocation: contentLocations.NEWSFEED, contentSubLocation }, { commentId: comment._id, comment: comment.comment, parentComment: comment.parentComment })
    }
  }

  const updateCommentInArray = async (comment, parentComment, firstParent) => {
    if (comment) {
      const comments = cloneDeep(commentsArray)
      if (firstParent && parentComment) { // third level comment
        const firstParentIndex = findIndex(comments, ['_id', firstParent])
        const parentIndex = findIndex(comments[firstParentIndex].children, ['_id', parentComment])
        const commentIndex = findIndex(comments[firstParentIndex].children[parentIndex].children, ['_id', comment._id])
        comments[firstParentIndex].children[parentIndex].children[commentIndex] = { ...comments[firstParentIndex].children[parentIndex].children[commentIndex], likedByUser: comment.likedByUser, totalLikes: comment.totalLikes }
      } else if (parentComment) { // second level comment
        const firstParentIndex = findIndex(comments, ['_id', parentComment])
        const commentIndex = findIndex(comments[firstParentIndex].children, ['_id', comment._id])
        comments[firstParentIndex].children[commentIndex] = { ...comments[firstParentIndex].children[commentIndex], likedByUser: comment.likedByUser, totalLikes: comment.totalLikes }
      } else if (!firstParent && !parentComment) { // first level comment
        const commentIndex = findIndex(comments, ['_id', comment._id])
        comments[commentIndex] = { ...comments[commentIndex], likedByUser: comment.likedByUser, totalLikes: comment.totalLikes }
      }
      setCommentsArray(comments)
    }
  }

  const handleSeeMoreClick = (comment) => {
    let limit; let offset = 0
    if (repliesOrder === 'DESC') offset = comment.children.length
    else if (repliesOrder === 'ASC') {
      limit = comment.childrenComments.length - comment.children.length
      offset = comment.offset ? comment.offset : offset
    }
    return getReplies(comment._id, comment.parentComment, limit, offset)
  }

  const prepareCommentReplies = (items) => {
    return items.map(item => {
      let loadMoreRepliesNr = 0
      let seeMoreText = ''
      if (item.childrenComments && item.children) {
        loadMoreRepliesNr = item.childrenComments.length - item.children.length
        seeMoreText = getLoadMoreText(loadMoreRepliesNr)
        item.offset = item.children.filter(item => !item.recentlyAdded).length
        if (repliesOrder === 'ASC') item.offset = item.children.filter(item => !item.recentlyAdded).length
        else item.offset = item.children.length
      }
      return {
        parentId: item.parentComment,
        commentObject: item,
        hasBorder: true,
        getDateFromNow: () => (moment(item.created).fromNow()),
        renderTranslate: () => {
          if (!item.translatedComment) item.loading = true
          getCommentById({ variables: { comment: { commentId: item._id, lang } } })
        },
        allowReply: !!account.commentThreadingEnabled,
        isLiked: item.likedByUser || false,
        isAuthor: item.owner && item.owner.id && content.owner === item.owner.id,
        isOwnerComment: item.owner.id === me._id,
        isAdmin: isAccountAdmin() || isAppProfileAdminUser,
        onUpdate: onUpdateComment,
        onDelete: showDeleteModal,
        totalReactions: item.totalLikes || 0,
        seeMoreText,
        onSeeMoreClick: () => handleSeeMoreClick(item),
        onLikeClick: onCommentLikeClick,
        border: true,
        children: item.children
          ? item.children.map(reply => {
            return {
              parentId: reply.parentComment,
              commentObject: reply,
              hasBorder: true,
              getDateFromNow: () => (moment(reply.created).fromNow()),
              renderTranslate: () => {
                if (!reply.translatedComment) reply.loading = true
                getCommentById({ variables: { comment: { commentId: reply._id, lang } } })
              },
              allowReply: false,
              isLiked: reply.likedByUser || false,
              isAuthor: reply.owner && reply.owner.id && content.owner === reply.owner.id,
              isOwnerComment: reply.owner.id === me._id,
              isAdmin: isAccountAdmin() || isAppProfileAdminUser,
              onUpdate: onUpdateComment,
              onDelete: showDeleteModal,
              totalReactions: reply.totalLikes || 0,
              onLikeClick: onCommentLikeClick,
              border: true
            }
          })
          : []
      }
    })
  }
  const getLoadMoreText = (numberOfReplies) => {
    const localMessage = numberOfReplies > 1 ? formatMessage(messages.loadXMoreComments) : formatMessage(messages.loadOneMoreComment)
    return numberOfReplies > 0 ? replace(localMessage, '#NUMBER#', numberOfReplies) : ''
  }

  const formatInputMentions = (caption, comment) => {
    const matches = caption.match(/@[a-z\d]{24}/g) || []
    let newCaption = caption
    newCaption = unescapeHtml(newCaption)
    if (matches.length) {
      for (const match of matches) {
        const userId = match.substr(1)

        if (userId) {
          const user = comment.mentions.find(u => u.id === userId)

          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')
          // $FlowFixMe[incompatible-type]
          context.font = `bold ${defaultFontSize}px sans-serif`

          if (user) {
            const { width } = context.measureText(user.name)

            newCaption = newCaption.replace(match, `<input class="mentions-highlighted__bold" style="width:${width}px" userId="${user.id}" value="${user.name}" readonly />`)
          } else {
            const { width } = context.measureText('Deleted user')

            newCaption = newCaption.replace(match, `<input class="mentions-highlighted__bold" style="width:${width}px" userId="deleted" value="Deleted user" readonly />`)
          }
        }
      }
    }
    return newCaption
  }

  const findCommentChildren = ({ children }) => {
    for (const child of children) {
      if (child?.children?.length) {
        findCommentChildren(child)
      }

      const { comment } = child
      child.comment = formatInputMentions(comment, child)
      if (child.translatedComment) {
        const { translatedComment } = child
        child.translatedComment = formatInputMentions(translatedComment, child)
      }
    }
  }

  const unescapeHtml = (safe) => {
    return safe
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'")
  }

  const listComments = () => {
    const comments = cloneDeep(commentsArray)
    return comments.map(c => {
      const comment = Object.assign({}, c)
      const isOwner = comment.owner.id === me._id
      const isAdmin = isAccountAdmin() || isAppProfileAdminUser
      const commentReplies = prepareCommentReplies(comment.children || [])
      let loadMoreRepliesNr = 0
      let seeMoreText = ''
      if (comment.childrenComments && comment.children) {
        loadMoreRepliesNr = comment.childrenComments.length - comment.children.length
        seeMoreText = getLoadMoreText(loadMoreRepliesNr)
        if (repliesOrder === 'ASC') comment.offset = comment.children.filter(item => !item.recentlyAdded).length
        else comment.offset = comment.children.length
      }

      if (comment?.children?.length) {
        findCommentChildren(comment)
      }

      comment.comment = formatInputMentions(comment.comment, comment)
      if (comment.translatedComment) {
        comment.translatedComment = formatInputMentions(comment.translatedComment, comment)
      }
      return (
        <CommentContainer data-cy={`${comment._id}_comment_container`} key={comment._id}>
          <CommentWithReplies
            testID={isContentPreview ? `${comment._id}_comment_preview` : `${comment._id}_comment`}
            hasBorder
            isOwnerComment={isOwner}
            isAdmin={isAdmin}
            commentObject={comment}
            getDateFromNow={() => (moment(comment.created).fromNow())}
            appBrandColor={primaryColor}
            onDelete={showDeleteModal}
            onUpdate={onUpdateComment}
            {...translateProps(comment)}
            isLiked={comment.likedByUser}
            totalReactions={comment.totalLikes}
            allowReply={!!account.commentThreadingEnabled}
            allowLike={!!account.commentThreadingEnabled}
            isAuthor={comment.owner && comment.owner.id && content.owner === comment.owner.id}
            onLikeClick={onCommentLikeClick}
            onReplyPost={onPostComment}
            currentUserName={me.name}
            currentUserPhoto={me.profilePhoto}
            seeMoreText={seeMoreText}
            onSeeMoreClick={() => handleSeeMoreClick(comment)}
            replyPlaceholder={formatMessage(messages.replyPlaceholder)}
            postButtonTitle={formatMessage(messages.post)}
            likeText={formatMessage(messages.like)}
            replyText={formatMessage(messages.reply)}
            editText={formatMessage(messages.edit)}
            deleteText={formatMessage(messages.delete)}
            itemsOrder={repliesOrder}
            tributeRef={textInputRepliesRef}
            tributeRefNode={domNode}
            tributeInputRef={replyTextInputRef}
            authorText={formatMessage(messages.author)}
            handleInput={handleInput}
            tribute={tribute}
          >
            {commentReplies}
          </CommentWithReplies>
        </CommentContainer>
      )
    })
  }

  const loadMoreDisabled = limit + offset >= content.totalFirstComments

  const getCommentsList = () => {
    const commentsList =
      <>
        {listComments()}
        <LoadMoreContainer>
          {!loadMoreDisabled && <Button testID={`${testID}_loadMoreComments`} ariaLabel={formatMessage(messages.loadMoreComments)} title={formatMessage(messages.loadMoreComments)} onClick={loadMore} themeColor={primaryColor} disabled={false} />}
        </LoadMoreContainer>
      </>

    if (isMobilePreview) { return <CommentListContainer>{commentsList}</CommentListContainer> }

    return commentsList
  }

  if (loading) return null

  const portalContainer = document.getElementById('modal-root')

  return (
    <Container isMobilePreview={isMobilePreview}>
      { /* $FlowFixMe[incompatible-type] */ }
      {portalContainer && ReactDOM.createPortal(<div ref={modalRef}><InfoModal cancelAction={() => setShowInfoModal(false)} containerClass="modal-container__mentions" body={replace(formatMessage(messages.maxMentions), '#NUMBER#', MAX_MENTIONS)} show={showInfoModal} cancelText="Okay" /></div>, portalContainer)}

      <OverlayPage visible={showOverlay} />
      {/* If isContentPreview display CommentInput in the end. Otherwise, CommentInput must be the first element displayed */}
      {(!isContentPreview && !isMobilePreview) &&
        <CommentInputContainer>
          <CommentInput
            thumbnail={me.profilePhoto && me.profilePhoto._32px}
            onChange={handleInput}
            testID={testID}
            placeholder={formatMessage(messages.writeAComment)}
            ref={textInputRef}
            userName={me.name}
            buttonTitle={formatMessage(messages.post)}
            appBrandColor={primaryColor} onPost={onPostComment}
          />
        </CommentInputContainer>
      }
      {getCommentsList()}
      {
        (isContentPreview || isMobilePreview) &&
        <CommentInputContainer isMobilePreview={isMobilePreview}>
          <CommentInput
            thumbnail={me.profilePhoto && me.profilePhoto._64px}
            testID={testID}
            onChange={handleInput}
            placeholder={formatMessage(messages.writeAComment)}
            ref={textInputRef}
            userName={me.name}
            buttonTitle={formatMessage(messages.post)}
            appBrandColor={primaryColor}
            onPost={onPostComment}
          />
        </CommentInputContainer>
      }
      <ConfirmModal disableSubmitButton={initiatedCommentDelete} testID={testID} show={showModal} onCancel={() => setShowModal(false)} onConfirm={onDeleteComment} comment={selectedComment} />
    </Container>
  )
})
Comments.displayName = 'Comments'
LoadMoreContainer.displayName = 'LoadMoreContainer'
export default injectIntl(Comments, { forwardRef: true })
