// @flow

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PopularList from 'michelangelo/dist/Components/PopularList'
import { readFromCache } from '../apollo/cacheHelper'
import { useQuery, useApolloClient } from '@apollo/client'
import getMostPopularQuery from '../apollo/queries/getMostPopular'
import GET_ME_QUERY from '../apollo/queries/getMe'

const BannerWrapper = styled.div`
  display: block;
`

type ExplorerBannerType = {
  itemClick: Function,
}
function ExplorerBanner (props: ExplorerBannerType) {
  const { itemClick } = props
  const { activeCfp } = readFromCache(useApolloClient(), ['activeCfp'])
  const [mostPopular, setMostPopular] = useState([])
  const primaryColor = activeCfp.primaryColor
  const { data: { getMeRequest: me } } = useQuery(GET_ME_QUERY)
  const displayContentAuthorEnabled = me.memberships[0].account.displayContentAuthorEnabled
  const { data } = useQuery(getMostPopularQuery(displayContentAuthorEnabled), {
    variables: {
      cfpId: activeCfp._id,
      displayContentAuthorEnabled
    }
  })
  useEffect(() => {
    if (data) {
      setMostPopular(data.getMostPopular)
    }
  }, [data])

  return (
    <BannerWrapper>
      <PopularList data={mostPopular} onItemClick={itemClick} scrollBarColor={primaryColor} showsHorizontalScrollIndicator={true}></PopularList>
    </BannerWrapper>
  )
}

export default ExplorerBanner
