// @flow

import React, { useState, useEffect, memo } from 'react'
import ContentCard from './ContentCard'
import Event from './Event'
import EmptyNewsFeed from './EmptyNewsFeed'
import styled from 'styled-components'
import { readFromCache } from '../../apollo/cacheHelper'
import { useApolloClient } from '@apollo/client'
import type { ContentType } from './ContentCard'
import type { InjectIntlProvidedProps } from 'react-intl'
import { isEqual } from 'lodash'
import { contentLocations } from '../../Helpers/contentHelper'
import { useIntl } from 'react-intl'
import { messages } from '../../i18n/messages'

type LatestPostsProps = {
  me: Object,
  contents: Array<ContentType>,
  refetch: Function,
  loading: boolean
} & InjectIntlProvidedProps

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`

// added for testing purposes
List.displayName = 'List'
ListItem.displayName = 'ListItem'

function NewsFeedLatestPosts (props: LatestPostsProps) {
  const { me, contents, refetch, loading, onContentPreviewClick } = props
  const [selectedContent, setSelectedContent] = useState(null)
  const [listItems, setListItems] = useState([])
  const intl = useIntl()

  const client = useApolloClient()

  const { activeCfp } = readFromCache(client, ['activeCfp'])
  const { darkTheme } = readFromCache(client, ['darkTheme'])
  const primaryColor = activeCfp.primaryColor
  const likesEnabled = me.memberships[0].account.likesEnabled
  const commentsEnabled = me.memberships[0].account.commentsEnabled
  const noPosts = <EmptyNewsFeed icon="newsFeed" refetch={refetch} title={intl.formatMessage(messages.noPost)} description={intl.formatMessage(messages.noPostDescription)} />
  const contentLocation = contentLocations.NEWSFEED

  useEffect(() => {
    if (!contents.length) return
    let updatedSelectedContent
    const _listItems = contents.map((content, index) => {
      // cache off a reference to the selectedContent from the most up-to-date query
      if (selectedContent && selectedContent._id === content._id) updatedSelectedContent = content
      // content position for analytics
      const contentListPosition = index + 1
      return (
        <ListItem key={content._id}>
          {content.calendar
            ? <MemoizedEvent event={content} />
            : <MemoizedContentCard
              content={{ ...content, contentListPosition }}
              isDarkTheme={darkTheme}
              me={me}
              appBrandColor={primaryColor}
              likesEnabled={likesEnabled}
              commentsEnabled={commentsEnabled}
              onContentPreviewClick={onContentPreviewClick}
              client={client}
              contentLocation={contentLocation}
            />
          }
        </ListItem>
      )
    })
    setListItems(_listItems)

    // HACK - This find is needed so ContentPreview -> ContentCard flow knows about the most
    // up to date content.  To fix this, ContentCard.js should useQuery() with a query responsible
    // for returning a specific piece of content.  This way, Apollo will notify ContentPreview -> ContentCard
    // about content changes.
    if (updatedSelectedContent) setSelectedContent({ ...updatedSelectedContent })
    // eslint-disable-next-line
  }, [contents])

  const list = <List>{listItems}</List>

  return (
    <div>
      {contents.length > 0 ? list : loading ? null : noPosts}
    </div>)
}

export default NewsFeedLatestPosts

const MemoizedContentCard = memo(ContentCard, (prevPros, nextProps) => {
  return isEqual(prevPros.content, nextProps.content)
})
const MemoizedEvent = memo(Event, (prevPros, nextProps) => {
  return isEqual(prevPros.event, nextProps.event)
})
