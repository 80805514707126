// @flow
import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop
} from 'react-image-crop'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'
import color from 'michelangelo/dist/Components/styles/color'
import { useIntl } from 'react-intl'
import { messages } from '../../i18n/messages'
import 'react-image-crop/dist/ReactCrop.css'
import { useDebounceEffect } from '../../utils/hooks'
import Avatar from 'michelangelo/dist/SharedComponents/DataDisplay/Avatar/Avatar'
import { getMeFromCache, readFromCache } from '../../apollo/cacheHelper'
import { useApolloClient, useMutation } from '@apollo/client'
import {
  DELETE_USER_PROFILE_PHOTO_MUTATION,
  UPDATE_USER_PROFILE_PHOTO_MUTATION
} from '../../apollo/queries/UpdateUserData/updateUserData'
import Row from 'michelangelo/dist/WebComponents/Layout/Row'
import Column from 'michelangelo/dist/WebComponents/Layout/Column'
import { getProfilePhoto } from '../../Helpers/UserInfoUtil'
import { AppContext } from '../../contexts/app'
import customConfirm from '../../utils/confirm'
import { trackProfilePhotoEvent } from '../../Helpers/segmentHelper'

export const ModalBlock = styled.div`
  align-items: center;
  bottom: 0;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 0.4rem;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  opacity: 1;
  z-index: 400;
`

export const ModalOverlay = styled.a`
  background: rgba(247, 248, 249, 0.75);
  bottom: 0;
  cursor: default;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

export const ModalClose = styled.div`
  float: right !important;
  text-decoration: none !important;
  justify-content: space-between;
  cursor: pointer;
  font-size: 1rem;
  max-width: 30px;
`

export const ModalContainer = styled.div`
  background: #ffffff;
  border-radius: 0.9rem;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  max-width: 1024px;
  padding: 0 0.8rem;
  width: 100%;
  animation: slide-down 0.2s ease 1;
  z-index: 1;
  box-shadow: 0 0.2rem 0.5rem rgba(48, 55, 66, 0.3);
`

export const ModalBody = styled.div`
  overflow-y: auto;
  padding: 10px 10px;
  position: relative;
`
const ControlsContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  padding: 20px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: flex-start;
  @media (max-width: 1024px) {
    margin-right: 16px;
    margin-left: 16px;
    padding: 0px;
  }
  @media (max-width: 768px) {
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 10px;
  }
`
export const CropControls = styled.div`
  display: flex;
  overflow-y: auto;
  padding: 30px 10px;
  position: relative;
  margin-bottom: 10px;
`

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #303742;
  padding: 20px 5px 10px 5px;
`

export const ModalTitle = styled.span`
  font-size: 30px;
  font-weight: 500;
`

export const ModalDescription = styled.div`
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
`

export const ModalFooter = styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 15px;
  justify-content: flex-end;
`

const TO_RADIANS = Math.PI / 180

type ProfilePhotoUploadProps = {
  active: ?boolean,
  hideModal: Function,
  onError?: Function
}

const ProfilePhotoUploadModal = (props: ProfilePhotoUploadProps) => {
  const { active, hideModal, onError } = props
  const client = useApolloClient()
  const { reloadMe, setShowMessageAlert, setSuccessMessage } = useContext(AppContext)
  const intl = useIntl()
  const [uploadProfilePhoto] = useMutation(UPDATE_USER_PROFILE_PHOTO_MUTATION)
  const [deleteProfilePhoto] = useMutation(DELETE_USER_PROFILE_PHOTO_MUTATION)
  const { activeCfp } = readFromCache(client)
  const darkTheme = readFromCache(client, ['darkTheme'])
  const primaryColor = activeCfp.primaryColor
  const { getMeRequest } = getMeFromCache(client) || { getMeRequest: {} }
  // get originalProfilePhoto key from getMeRequest
  const { name, profilePhoto } = getMeRequest
  // pass originalProfilePhoto to next useState to edit the original photo
  const [imgSrc, setImgSrc] = useState('')
  const [validFile, setValidFile] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [canDelete, setCanDelete] = useState(!!(profilePhoto && profilePhoto._32px))
  const [profileImage, setProfileImage] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null)
  const imgRef = useRef<HTMLImageElement | null>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const title = intl.formatMessage(messages.updateProfilePhoto)

  const handlePhotoUpload = async () => {
    try {
      setLoading(true)
      setIsDisabled(true)
      const profileImageParts = profileImage.split(',')
      const base64String = profileImageParts[1]
      const mimeType = profileImageParts[0].substring(
        profileImageParts[0].indexOf(':') + 1,
        profileImageParts[0].lastIndexOf(';')
      )
      const img = await b64toBlob(base64String, mimeType)
      await uploadProfilePhoto({
        variables: {
          file: img
        }
      })

      console.log('Yaaaaaaaay')
      setLoading(false)
      resetModal()
      setSuccessMessage(intl.formatMessage(messages.success))
      setShowMessageAlert(true)
      trackProfilePhotoEvent()
      reloadMe()
    } catch (e) {
      setLoading(false)
      setSuccessMessage(null)
      setShowMessageAlert(false)
      setIsDisabled(false)
      if (onError) onError()
    }
  }

  const handleDeleteProfilePhoto = async () => {
    try {
      if (imgSrc) {
        resetModal()
        return
      }
      await customConfirm({
        testID: 'delete_profile_photo',
        confirmBody: intl.formatMessage(messages.deleteProfilePhoto),
        cancelText: intl.formatMessage(messages.cancel),
        agreeText: intl.formatMessage(messages.agree)
      })
      setLoadingDelete(true)
      setIsDisabled(true)
      await deleteProfilePhoto()
      resetModal()
      setSuccessMessage(intl.formatMessage(messages.success))
      setShowMessageAlert(true)
      trackProfilePhotoEvent(false)
      reloadMe()
    } catch (e) {
      setLoadingDelete(false)
      setSuccessMessage(null)
      setShowMessageAlert(false)
    }
  }
  const resetModal = () => {
    const imageEl = document.getElementById('profilePhotoInput')
    // $FlowFixMe
    if (imageEl) imageEl.value = ''
    setImgSrc('')
    setLoadingDelete(false)
    const url = getProfilePhoto(profilePhoto, 1)
    setProfileImage(url)
    setCompletedCrop()
    setCrop()
    setIsDisabled(true)
    setCanDelete(!!url)
    setValidFile(true)
  }

  useEffect(() => {
    const url = getProfilePhoto(profilePhoto, 1)
    setProfileImage(url)
  }, [profilePhoto])

  const b64toBlob = async (b64Data, contentType = 'image/jpeg', sliceSize = 512) => {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  // This is to demonstrate how to make and center a % aspect crop
  // which is a bit trickier so we use some helper functions.
  const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    )
  }

  const canvasPreview = async (image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop, scale = 1, rotate = 0) => {
    const ctx = canvas.getContext('2d')

    if (!ctx) {
      throw new Error('No 2d context')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    if (image.naturalHeight >= image.naturalWidth) {
      // hey
    }
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY

    const rotateRads = rotate * TO_RADIANS
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    ctx.rotate(rotateRads)
    // 2) Scale the image
    ctx.scale(scale, scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight)

    ctx.restore()
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        await canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop
        )
        const url = previewCanvasRef && previewCanvasRef.current ? previewCanvasRef.current.toDataURL('image/jpeg') : ''
        setProfileImage(url)
      }
    },
    100,
    [completedCrop]
  )
  const onSelectFile = (e: SyntheticInputEvent<HTMLInputElement>) => {
    setValidFile(true)
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        const src = reader && reader.result ? reader.result.toString() : ''

        const profileImageParts = src.split(',')
        const mimeType = profileImageParts[0].substring(
          profileImageParts[0].indexOf(':') + 1,
          profileImageParts[0].lastIndexOf(';')
        )

        if (!mimeType.includes('image/')) {
          setValidFile(false)
          setIsDisabled(true)
        } else {
          setImgSrc(src)
          setCanDelete(true)
          setIsDisabled(false)
        }
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onImageLoad = (e: SyntheticInputEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, 1))
    setIsDisabled(false)
  }

  const onCloseModal = () => {
    hideModal(false)
    resetModal()
  }
  return (
    <>
      {active && (
        <ModalBlock>
          <ModalOverlay onClick={onCloseModal} />
          <ModalContainer>
            <ModalHeader>
              <ModalTitle>{title}</ModalTitle>
              <ModalClose><Button height={30} width={30} ariaLabel='Close' icon='x' onClick={onCloseModal} themeColor={color.success} /></ModalClose>
            </ModalHeader>
            <ModalDescription>
              {intl.formatMessage(messages.updateProfilePhotoDescription) + '.'}
            </ModalDescription>
            <ModalBody>
              <Row classNames={['is-marginless', 'is-paddingless', 'is-multiline']}>
                <Column classNames={['is-paddingless', 'is-4-desktop', 'is-12-tablet', 'is-12-mobile']}>
                  <ControlsContainer>
                    <Avatar canEdit={loadingDelete ? false : canDelete} editText={intl.formatMessage(messages.delete)} textTransform='uppercase' darkTheme={darkTheme} customCircleColor={primaryColor} userName={name} size={160} fontSize='h2' onEditClick={handleDeleteProfilePhoto} thumbnail={profileImage} />
                    <CropControls>
                      <input type="file" id='profilePhotoInput' accept="image/*" onChange={onSelectFile} />
                    </CropControls>
                    { !validFile && (
                        <p style={{ color: 'red' }}>File must be an image</p>
                    )}
                  </ControlsContainer>
                </Column>
                <Column classNames={['is-paddingless', 'is-8-desktop', 'is-12-tablet', 'is-12-mobile']}>
                  {Boolean(imgSrc) && (
                    <ReactCrop
                      crop={crop}
                      keepSelection
                      onChange={(_, percentCrop) => setCrop(percentCrop)}
                      onComplete={(c) => setCompletedCrop(c)}
                      aspect={1}
                      minHeight={192}
                      minWidth={192}
                      style={{
                        display: 'table',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: 'auto',
                        maxWidth: '60%'
                      }}
                    >
                      <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
                    </ReactCrop>
                  )}
                </Column>
              </Row>
              <div style={{ display: 'none' }}>
                {Boolean(completedCrop) && (
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: '1px solid black',
                      objectFit: 'contain',
                      width: completedCrop.width,
                      height: completedCrop.height
                    }}
                  />
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button icon={!loading ? null : 'spinner'} fill onClick={handlePhotoUpload} ariaLabel={intl.formatMessage(messages.save)} title={intl.formatMessage(messages.save)} themeColor={isDisabled ? color.disabled : color.info} disabled={isDisabled} />
            </ModalFooter>
          </ModalContainer>
        </ModalBlock>
      )}
    </>
  )
}
export default ProfilePhotoUploadModal
