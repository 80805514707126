// @flow
import { gql } from '@apollo/client'

const REMOVE_LIKE_QUERY = gql`
  mutation likeDelete($like: likeInput!) {
    likeDelete(req: $like)
    {
      _id
      totalLikes
      likedByUser
    }
  }`

export default REMOVE_LIKE_QUERY
