export const statuses = {
  PENDING: 'PENDING',
  OPTED_IN: 'OPTED-IN',
  OPTED_OUT: 'OPTED-OUT',
  PENDING_RESPONSE: 'PENDING RESPONSE',
  PENDING_INVITE: 'PENDING INVITE'
}

export const statusStrings = {
  [statuses.PENDING]: 'Pending',
  [statuses.OPTED_IN]: 'Opted-In',
  [statuses.OPTED_OUT]: 'Opted-Out',
  [statuses.PENDING_RESPONSE]: 'Pending Response',
  [statuses.PENDING_INVITE]: 'Pending Invite'
}
