// @flow
import React, { useEffect, useState } from 'react'
import './App.css'
import { ApolloProvider, useQuery, useApolloClient, useLazyQuery } from '@apollo/client'
import { useSetupClient } from './apollo/client'
import GET_ME_QUERY from './apollo/queries/getMe'
import styled from 'styled-components'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation
} from 'react-router-dom'
import NewsFeed from './NewsFeed'
import translations from './i18n/locales'
import { IntlProvider } from 'react-intl'
import AlertsPage from './Alerts'
import ExploreNew from './Explore/ExploreNew'
import Explore from './Explore'
import CalendarPage from './Calendar'
import MyProfilePage from './MyProfile'
import { readFromCache, writeToCache } from './apollo/cacheHelper'
import { isEmpty } from 'lodash'
import Directory from './Directory'
import DirectoryEntry from './DirectoryEntry'
import AppNavigator from './Components/AppNavigator/AppNavigator'
import LoadingScreen from './Components/LoadingScreen'
import RefreshPage from './Components/LoadingScreen/refresh'
import { segmentIdentifyEvent } from './Helpers/segmentHelper'
import { LogoutPopup } from './IdleTimer/logoutPrompt'
import Icon from 'michelangelo/dist/SharedComponents/DataDisplay/Icons/IconComponent'
import color from 'michelangelo/dist/Components/styles/color'
import HashtagFeed from './HashtagFeed'
import NewContentPreviewWeb from './Components/ContentPreview/NewContentPreviewWeb'
import ContentPreview from './Components/ContentPreview'
import GET_GRID_BY_ACCOUNT_ID_QUERY from './apollo/queries/getGridByAccountId'
import { AppContext } from './contexts/app'
import Notification from 'michelangelo/dist/Components/Notification'
import moment from 'moment'
import 'moment/min/locales'
import ContactDetails from './DirectoryEntry/ContactDetails'

const AppView = styled.div`
  background: #eef2f6;
`

AppView.displayName = 'AppView'

const UserProfileError = styled.div`
  background: #eef2f6;
  padding-top: 10%;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
`
UserProfileError.displayName = 'UserProfileError'

const { Provider } = AppContext

type AppProfilePropTypes = { me: any, grid: boolean }
function App ({ me, grid: gridEnabled }: AppProfilePropTypes) {
  const client = useApolloClient()
  const location = useLocation()

  const [fullDisplay, setFullDisplay] = useState(false)

  useEffect(() => {
    if (location?.pathname.includes('-preview')) {
      setFullDisplay(true)
    } else {
      setFullDisplay(false)
    }
  }, [location])

  const { activeCfp } = readFromCache(client, ['activeCfp'])
  const cfpIdFromPath = window?.location?.pathname.split('/')[1]

  const memberships = me.memberships[0]

  const pathCfp = cfpIdFromPath && memberships.contentFeedProfiles.find(cfp => cfp._id === cfpIdFromPath)

  const defaultRoute = me && memberships.account.defaultHomeView === 'EXPLORE' ? 'explore' : 'newsfeed'
  const defaultCfp = memberships.contentFeedProfiles[0]

  let cfpToRedirect = pathCfp || activeCfp || defaultCfp

  if (isEmpty(activeCfp) || !memberships.contentFeedProfiles.find(cfp => cfp._id === activeCfp)) {
    // const cfpId = window.location.pathname.split('/')[1] || me.memberships[0].contentFeedProfiles[0]._id
    // const activeCfp = me.memberships[0].contentFeedProfiles.find(cfp => cfp._id === cfpId)

    const currentCfp = pathCfp || defaultCfp
    cfpToRedirect = currentCfp

    writeToCache(client, { activeCfp: currentCfp })
    segmentIdentifyEvent(me)
  }
  const background = location.state ? location.state.background : location
  return (
    <>
      <AppNavigator me={me} activeCfp={activeCfp} showFullDisplay={fullDisplay}>
        <Routes>
          <Route path='/' element={<Navigate to={`/${cfpToRedirect._id}/${defaultRoute}`}/>} />
          <Route path='/:cfpid' element={<Navigate to={`/${cfpToRedirect._id}/${defaultRoute}`}/>} />
          <Route exact path='/:cfpId/newsfeed' element={<NewsFeed me={me} />} />
          <Route exact path='/:cfpId/hashtags/:hashtag' element={<HashtagFeed me={me} />} />
          <Route exact path='/:cfpId/explore/folders/:folderId?' element={<Explore me={me} />} />
          <Route exact path='/:cfpId/explore' element={gridEnabled ? <ExploreNew me={me} grid={gridEnabled} /> : <Explore me={me} />} />
          <Route exact path='/:cfpId/directory' element={<Directory me={me} />} />
          <Route path='/:cfpId/directory/search' element={<Directory me={me} />} >
            <Route path={'entry/:entryId'} element={ <ContactDetails linkText="Contacts" appBrandColor={activeCfp.primaryColor} /> } />
          </Route>
          <Route path='/:cfpId/directory/:directoryId' element={<DirectoryEntry me={me} />} >
            <Route path={'entry/:entryId'} element={ <ContactDetails linkText="Contacts" appBrandColor={activeCfp.primaryColor} /> } />
          </Route>
          <Route exact path='/:cfpId/calendar' element={<CalendarPage me={me} />} />
          <Route exact path='/:cfpId/alerts' element={<AlertsPage me={me} />} />
          <Route exact path='/:userId/my-profile' element={<MyProfilePage me={me} />} />
          {background && <Route path={`${background?.pathname}/contentPreview`} element={<ContentPreview me={me}/>} />}
          {background && <Route exact path='/:cfpId/explore-preview/:contentId' element={<NewContentPreviewWeb me={me}/>} />}
          {background && <Route exac path='/:cfpId/newsfeed-preview/:contentId' element={<NewContentPreviewWeb me={me}/>} />}
        </Routes>
      </AppNavigator>
    </>
  )
}

function GetMeApp () {
  const [me, setMe] = useState({})
  const [gridEnabled, setGridEnabled] = useState(false)
  const [retry, setRetry] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [showMessageAlert, setShowMessageAlert] = useState(false)
  const [fromUpdatePhoto, setFromUpdatePhoto] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [getMeLazy, { data: lazyData, loading: lazyLoading }] = useLazyQuery(GET_ME_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })
  // getMe query hook
  const { loading, data } = useQuery(GET_ME_QUERY, {
    variables: {},
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })
  const { data: gridData, loading: gridLoading } = useQuery(GET_GRID_BY_ACCOUNT_ID_QUERY, {
    variables: { accountId: '' },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (!loading && data && data.getMeRequest && !fromUpdatePhoto) {
      setMe(data)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data])
  /*  Check if grid flag is enabled to render the new UI */
  useEffect(() => {
    if (!gridLoading && gridData && gridData.getGridByAccountId) {
      setGridEnabled(gridData.getGridByAccountId.enabled)
    }
  }, [gridData, gridLoading])
  useEffect(() => {
    if (!lazyLoading && lazyData && lazyData.getMeRequest && fromUpdatePhoto) {
      const check = lazyData.getMeRequest?.profilePhoto?._32px === me.getMeRequest?.profilePhoto?._32px
      if (check) {
        setRetry(retry + 1)
      }
      if (!check || retry >= 3) {
        if (retry !== 0) {
          setRetry(0)
        }
        setMe(lazyData)
        setIsLoading(false)
        setShowMessageAlert(false)
        setFromUpdatePhoto(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyData, lazyLoading, fromUpdatePhoto])

  useEffect(() => {
    if (retry > 0 && retry <= 3) {
      setShowMessageAlert(false)
      setIsLoading(true)
      getMeLazy({ variables: {} })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retry])

  const reloadGetMe = () => {
    setIsLoading(true)
    setFromUpdatePhoto(true)
    getMeLazy({ variables: {} })
  }

  if (isLoading || gridLoading) {
    return <>
      <LoadingScreen />
      {showMessageAlert && <Notification show message={successMessage} autoClose={5} type='success' /> }
    </>
  }

  const userHasGroup = me.getMeRequest.memberships[0].contentFeedProfiles.length !== 0

  if (!me.getMeRequest) return <RefreshPage />
  return (
    <Router>
      <AppView className="App">
        { !userHasGroup
          ? <UserProfileError>
            <Icon iconName="warningCircle" size={56} color={color.grey300} />
            <label style={{ marginTop: '20px' }}>
                User Profile Error.
            </label>
            <label>
                Please contact your system administrator.
            </label>
          </UserProfileError>
          : <Provider value={{
            reloadMe: reloadGetMe,
            setShowMessageAlert,
            setSuccessMessage
          }}>
            <App me={me.getMeRequest} grid={gridEnabled}></App>
            {showMessageAlert && <Notification show message={successMessage} autoClose={5} type='success' /> }
          </Provider>
        }
        <LogoutPopup />
      </AppView>
    </Router>
  )
}

function BootstrapApollo () {
  const locale = navigator.language || 'en'
  moment.locale(locale)
  const messages = translations(locale)
  const apolloClient = useSetupClient()
  if (!apolloClient) {
    return <IntlProvider locale={locale} key={locale} messages={messages}><LoadingScreen /></IntlProvider>
  } else {
    return (
      <IntlProvider locale={locale} key={locale} messages={messages}>
        <ApolloProvider client={apolloClient}>
          <GetMeApp></GetMeApp>
        </ApolloProvider>
      </IntlProvider>
    )
  }
}
export default BootstrapApollo
