// @flow

import React, { useEffect, useState } from 'react'
import getNewsFeedQuery from '../apollo/queries/getNewsFeed'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import styled, { ReactComponentStyled } from 'styled-components'
import NewsFeedLatestPosts from './Components/NewsFeedLatestPosts'
import Carousel from '../Components/Carousel'
import { readFromCache } from '../apollo/cacheHelper'
import Column from 'michelangelo/dist/WebComponents/Layout/Column'
import color from 'michelangelo/dist/Components/styles/color'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import {
  cookieTimer,
  onLoad,
  trackBannerOpened,
  trackContentOpened
} from '../Helpers/segmentHelper'
import { isEmpty } from 'lodash'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../i18n/messages'
import { contentLocations } from '../Helpers/contentHelper'
import { checkIsReadAcknowledgement } from '../Helpers/ReadAcknowledgement'
import SEND_EMAIL_QUERY from '../apollo/queries/sendEmail'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import OverlayPage from '../Components/OverlayPage'
import Icon from 'michelangelo/dist/SharedComponents/DataDisplay/Icons/IconComponent'
import GridLayout from './Components/GridLayout'
import { goToOldContentPreview, goToContentPreview } from '../Helpers/contentPreviewHelper'
import GET_GRID_QUERY from '../apollo/queries/getGridByAccountId'
import useDimensions from 'michelangelo/dist/Components/helpers/WebDimension'

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  justify-content: flex-start;
  @media (max-width: 767px) {
    display: none;
  }
`
const CarouselContainer = styled.div`
  margin-bottom: 10px;
`
const Container = styled.div`
  max-width: 564px;
  min-width: 344px;
  width: auto;
  margin: auto;
`
const ParentCard = styled.div`
  display: flex;
  flex-direction: column;
`

const NewsFeedContainer = styled.div`
  padding: 16px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 16px 8px;
  }
`

const EndPageContainer = styled.div`
  margin-top: 20px;
`

const LoadaingOrNoCOntentContainer: ReactComponentStyled<{}> = styled.div`
  padding-bottom: 24px;
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
`

// added for testing purposes
NewsFeedContainer.displayName = 'NewsFeedContainer'
TitleContainer.displayName = 'TitleContainer'
EndPageContainer.displayName = 'EndPageContainer'

type NewsFeedProps = {
  me: Object
} & InjectIntlProvidedProps

function NewsFeed ({ me, intl: { formatMessage } }: NewsFeedProps) {
  const client = useApolloClient()
  const { cfpId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [gridEnabled, setGridEnabled] = useState(false)

  const [showOverlay, setShowOverlay] = useState(false)
  const [sendEmail] = useMutation(SEND_EMAIL_QUERY)
  const [reachedNewsfeedEnd, setReachedNewsfeedEnd] = useState(false)

  const contentLocation = contentLocations.NEWSFEED

  const [containerRef, { width }] = useDimensions()

  // get the current cfp from cache
  const { activeCfp } = readFromCache(client, ['activeCfp'])
  const accountId = me.memberships[0].account._id

  const { data: gridData } = useQuery(GET_GRID_QUERY, {
    variables: { accountId },
    fetchPolicy: 'cache-and-network'
  })

  /**  Check if grid flag is enabled to render the new UI */
  useEffect(() => {
    if (gridData && gridData.getGridByAccountId) {
      setGridEnabled(gridData.getGridByAccountId.enabled)
    }
  }, [gridData])
  const displayContentAuthorEnabled = me.memberships[0].account.displayContentAuthorEnabled

  // query the newsfeed of the current cfp
  const { loading, data, fetchMore, refetch } = useQuery(getNewsFeedQuery(displayContentAuthorEnabled), {
    variables: {
      cfpId: cfpId.toString(),
      offset: 0,
      limit: 10
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  let endpageLoadOrNoContent = null

  if (loading) {
    endpageLoadOrNoContent = <Icon position='relative' color={color.grey400} spin={true} iconName='spinner' size={32} />
  }

  const contents = data && data.getNewsFeed ? data.getNewsFeed : []

  if (reachedNewsfeedEnd && contents.length > 0) {
    endpageLoadOrNoContent = <Text text={formatMessage(messages.noContentToShow)} fontWeight="bold" textSize="th" fontColor={color.lightGrey}/>
  }
  const endPageDiv = (
    <EndPageContainer>
      <LoadaingOrNoCOntentContainer>
        {endpageLoadOrNoContent}
      </LoadaingOrNoCOntentContainer>
    </EndPageContainer>
  )

  // every time location is changed, set reachedNewsfeedEnd to false
  useEffect(() => {
    setReachedNewsfeedEnd(false)
  }, [location])

  useEffect(() => {
    onLoad(client, me, 'News Feed View')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      cookieTimer()
    }, 10000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onLoadMore = () => {
    const offset = contents.length
    if (reachedNewsfeedEnd) return
    return fetchMore({
      variables: {
        offset
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        if (isEmpty(fetchMoreResult.getNewsFeed) || !fetchMoreResult.getNewsFeed) setReachedNewsfeedEnd(true)
        const prevNewsFeedItems = prev.getNewsFeed || []
        return Object.assign({}, prev, {
          getNewsFeed: [...prevNewsFeedItems, ...fetchMoreResult.getNewsFeed]
        })
      }
    })
  }

  const checkIsRead = async (content) => {
    setShowOverlay(true)
    const isRead = await checkIsReadAcknowledgement(client, content, formatMessage, me, sendEmail)
    setShowOverlay(false)
    return isRead
  }

  const onContentPreviewClick = (content) => {
    const contentFilters = { type: 'desc', field: 'modified' }
    const folderFilters = { type: 'asc', field: 'title' }

    if (gridEnabled && !width) {
      goToContentPreview(navigate, { cfpId: activeCfp._id, content, contents, location, contentLocation }, { contentFilters, folderFilters })
    } else if (gridEnabled && width >= 769) {
      goToContentPreview(navigate, { cfpId: activeCfp._id, content, contents, location, contentLocation }, { contentFilters, folderFilters })
    } else {
      goToOldContentPreview(navigate, client, { content, location, contentLocation })
    }
  }

  const handleCarouselClick = async (content) => {
    const isRead = await checkIsRead(content)
    // if was cancelled
    if (!isRead) return
    await trackContentOpened({ ...content, contentLocation: contentLocations.CAROUSEL_NEWSFEED }, client)
    await trackBannerOpened(content, contentLocation)
    if (content.contentType === 'CONTENT_WEB' && !content.likesEnabled && !content.commentsEnabled) {
      return window.open(content.webUrl, '_target')
    }
    onContentPreviewClick(content)
  }

  useEffect(() => {
    const onScrollCallback = (e: Object) => {
      if (loading) return
      const documentElement = e.target.documentElement
      if (documentElement.scrollTop + documentElement.clientHeight >= documentElement.scrollHeight) {
        onLoadMore()
      }
    }
    if (window) {
      window.addEventListener('scroll', onScrollCallback)
    }
    return () => {
      if (window) {
        window.removeEventListener('scroll', onScrollCallback)
      }
    }
  }, [loading, onLoadMore])

  return (
    <NewsFeedContainer ref={containerRef} data-cy="newsFeedContainer">
      <GridLayout content={[]} me={me}>
        <OverlayPage visible={showOverlay}/>
        <Column classNames={['is-paddingless', 'news-feed-mid-column']}>
          <Container>
            <CarouselContainer>
              <Carousel onCarouselClick={handleCarouselClick} activeCfp={activeCfp} aspectRatio={4} displayContentAuthorEnabled={displayContentAuthorEnabled}/>
            </CarouselContainer>
            <TitleContainer>
              <Text text={formatMessage(messages.latestPost)} fontWeight="bold" textSize="th" fontColor={color.lightGrey} textTransform="uppercase"/>
            </TitleContainer>
            <ParentCard>
              <NewsFeedLatestPosts me={me} contents={contents} onContentPreviewClick={onContentPreviewClick} refetch={refetch} loading={loading}/>
            </ParentCard>
            { endPageDiv }
          </Container>
        </Column>
      </GridLayout>
    </NewsFeedContainer>
  )
}

export default injectIntl(NewsFeed)
