// @flow

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import TabItem from './TabItem'
import InfoItem from './InfoItem'
import Icon from 'michelangelo/dist/Components/Icon'
import color from 'michelangelo/dist/Components/styles/color'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'

const TabsUl = styled.ul`
  display: flex;
  justify-content: space-evenly;
`
TabsUl.displayName = 'TabsUl'

const TabContents = styled.div`
  padding-top: 36px;
  @media screen and (max-width: 768px) {
    padding: 16px;
  }
`
TabContents.displayName = 'TabContents'

const TabContent = styled.div`
  display: ${props => props.active ? 'flex' : 'none'};
  flex-direction: column;
`
TabContent.displayName = 'TabContent'

const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  background: ${color.white};
  padding: 70px 0px;
`
Empty.displayName = 'Empty'

const IconContainer = styled.div`
  margin: 16px 0px;
`
IconContainer.displayName = 'IconContainer'

const TitleContainer = styled.div`
  margin: 8px 0px;
`
TitleContainer.displayName = 'TitleContainer'

type TabContentItem = {
  icon?: string,
  label: string,
  value: string,
  type: string,
  onClick?: Function
}

type TabItemProps = {
  icon: string,
  label: string,
  tag?: string,
  contents: Array<TabContentItem>,
  onClick?: Function
}

type TabsProps = {
  items: Array<TabItemProps>,
  appBrandColor: string,
  emptyInfoText?: string
}
function Tabs (props: TabsProps) {
  const { items, appBrandColor, emptyInfoText } = props
  const [activeItem, setActiveItem] = useState(null)

  useEffect(() => {
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].contents.length !== 0) {
          setActiveItem(items[i])
          break
        }
      }
    }
  }, [items])

  const handleTabItemClickEvent = (tabItem) => {
    setActiveItem(tabItem)
    if (tabItem.onClick) tabItem.onClick(tabItem)
  }

  return (
    <div>
      <TabsUl>
        {
          items.map((tabItem, i) => (
            <TabItem item={tabItem} appBrandColor={appBrandColor} onClick={() => { handleTabItemClickEvent(tabItem) }} active={tabItem === activeItem} key={i}/>
          ))
        }
      </TabsUl>
      <TabContents>
        {
          items.map((tabItem, i) => {
            return (
              <TabContent key={i} active={tabItem === activeItem}>
                {
                  tabItem.contents.map((item, j) => {
                    return <InfoItem appBrandColor={appBrandColor} label={item.label} value={item.value} type={item.type} icon={item.icon} onClick={item.onClick} key={j}/>
                  })
                }
                {
                  tabItem.contents.length === 0
                    ? <Empty>
                      <IconContainer>
                        <Icon color={color.lightGrey} iconName='info' size="3x"/>
                      </IconContainer>
                      <TitleContainer>
                        <Text text={emptyInfoText || ''} fontWeight="bold" textSize="h5" fontColor={color.darkGray}/>
                      </TitleContainer>
                    </Empty>
                    : null
                }
              </TabContent>
            )
          })
        }
      </TabContents>
    </div>
  )
}

export default Tabs
