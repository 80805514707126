// @flow

import Row from 'michelangelo/dist/WebComponents/Layout/Row'
import React, { useEffect } from 'react'
import RecentFoldersWidgetComponent from '../../Components/Widgets/RecentFolders'
import DirectoryComponent from '../../Components/Widgets/Directory'
import AppsWidget from '../../Components/Widgets/AppsWidget'
import ProfileWidget from '../../Components/Widgets/ProfileWidget'
import RecentContentWidgetComponent from '../../Components/Widgets/RecentContent'
import QuickLinksWidgetComponent from '../../Components/Widgets/QuickLinks'
import CalendarWidgetComponent from '../../Components/Widgets/Calendar'
import UpcomingEventsWidgetComponent from '../../Components/Widgets/UpcomingEvents'

type Widget = {
    _id: string,
    title: string,
    type: string,
    enabled: boolean,
    widgetParams?: any,
    position: number[]
}

type GridContainerType = {
    widgets: Widget[],
    me: Object,
    content?: any
}
const widgetComponents = {
  MY_PROFILE: ProfileWidget,
  APPS: AppsWidget,
  MY_RECENT_CONTENT: RecentContentWidgetComponent,
  MY_RECENT_FOLDERS: RecentFoldersWidgetComponent,
  QUICK_LINKS: QuickLinksWidgetComponent,
  DIRECTORY: DirectoryComponent,
  CALENDAR: CalendarWidgetComponent,
  UPCOMING_EVENTS: UpcomingEventsWidgetComponent
}

const GridContainer = ({ widgets, me }: GridContainerType) => {
  useEffect(() => {
  }, [])
  const checkEnabledInProps = (me, widget, index) => {
    switch (widget.type) {
      case 'DIRECTORY': {
        return me.memberships[0].account.directoryEnabled
      }
      default: {
        return true
      }
    }
  }

  return (
    <>
      {widgets.map((widget, index) => {
        const newWidget = React.createElement(widgetComponents[widget.type], { me, widget })
        const enableBorder = checkEnabledInProps(me, widget, index)
        return widgetComponents[widget.type]
          ? <Row key={index} classNames={['is-flex', 'is-centered', 'is-marginless']}>
            <div style={{ marginTop: 18, paddingTop: 18, borderTop: enableBorder ? '1px solid #A3AEBD' : '', width: '100%' }}>
              {newWidget}
            </div>
          </Row>
          : <h3>{widget.title}</h3>
      })}
    </>
  )
}

export default GridContainer
