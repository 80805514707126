// @flow

import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import color from 'michelangelo/dist/Components/styles/color'
import { trackAudioEvent, trackContentSession, trackContentOpened, cookieClear } from '../../Helpers/segmentHelper'
import { v4 as uuidv4 } from 'uuid'
import { useMutation } from '@apollo/client'
import SEND_EMAIL_QUERY from '../../apollo/queries/sendEmail'
import { checkIsReadAcknowledgement } from '../../Helpers/ReadAcknowledgement'
import OverlayPage from '../OverlayPage'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(26, 39, 50, 0.9), rgba(26, 39, 50, 0.9)), url(${(props) => props.thumbnail});
  background-size: cover;
  background-position: center;
  padding: 34px 0;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`

const Thumbnail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 246px;
  height: 246px;
  background-color: ${color.grey900};
  background-image: url(${(props) => props.thumbnail || '/images/volume-up.svg'});
  background-size: ${(props) => props.thumbnail ? 'contain' : 'auto'}; // switched cover to contain to fit the thumbnail image
  background-position: center;
  background-repeat: no-repeat;
`

const AudioButton = styled.button`
  cursor: pointer;
  width: 52px;
  height: 52px;
  background-color: transparent;
  background-size: 52px;
  border: none;
`

const Audio = styled.audio`
  display: none;
`

const TimeContainer = styled.div`
  display: flex;
  margin-top: 50px;
  margin-bottom: 20px;
`

const CurrentTime = styled.span`
  color: #ffffff;
`

const Duration = styled.span`
  color: #b5b5b5;
`

const Progress = styled.progress`
  width: 50%;
  appearance: none;
  height: 8px;
  color: #ffffff;
  border-radius: 8px;
  background-color: #9B1921;
  border: 0;
  margin-bottom: 30px;
  ::-moz-progress-bar {
    background: #ffffff !important;
    border-radius: 8px;
  }
  ::-webkit-progress-value {
    background: #ffffff;
    border-radius: 8px;
  }
  ::-webkit-progress-bar {
    background: #9B1921;
    border-radius: 8px;
  }
`

// added for testing purpose
Container.displayName = 'Container'
Thumbnail.displayName = 'Thumbnail'
AudioButton.displayName = 'AudioButton'
Audio.displayName = 'Audio'
TimeContainer.displayName = 'TimeContainer'
CurrentTime.displayName = 'CurrentTime'
Duration.displayName = 'Duration'
Progress.displayName = 'Progress'

type AudioPlayerProps = {
  src: string,
  thumbnail?: string,
  onPlay?: Function,
  onPause?: Function,
  autoPlay?: boolean,
  content?: Object,
  client?: Object,
  me?: Object,
  formatMessage?: Function,
  contentLocation: String
}

const AudioPlayer = (props: AudioPlayerProps) => {
  const { src, onPlay, onPause, thumbnail, autoPlay, content, client, me, formatMessage, contentLocation } = props
  const audioRef = useRef()
  const playerContainerRef = useRef()
  const [audio, setAudio] = useState()
  const [status, setStatus] = useState('stopped')
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [sessionId, setsessionId] = useState(null)
  const [showOverlay, setShowOverlay] = useState(false)
  const [sendEmail] = useMutation(SEND_EMAIL_QUERY)

  const checkIsRead = async () => {
    setShowOverlay(true)
    const isRead = await checkIsReadAcknowledgement(client, content, formatMessage, me, sendEmail)
    setShowOverlay(false)
    return isRead
  }

  // pause video on scroll
  useEffect(() => {
    if (!audio) return
    const callback = (entries) => {
      let prevRatio = 0.0
      entries.forEach(entry => {
        if (entry.intersectionRatio !== prevRatio) {
          audio.pause()
        }
        prevRatio = entry.intersectionRatio
      })
    }
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: [0.0, 0.5]
    }
    const observer = new IntersectionObserver(callback, options)
    const target = playerContainerRef.current
    if (target) observer.observe(target)
  }, [audio, playerContainerRef])

  useEffect(() => {
    if (audioRef.current) {
      setAudio(audioRef.current)
    }
  }, [audioRef])

  useEffect(() => {
    if (!audio) return

    const handleOnPlayEvent = () => {
      const audioSessionId = sessionId || uuidv4()
      setsessionId(audioSessionId)
      setStatus('playing')
      trackContentOpened({ ...content, contentLocation }, client)
      trackAudioEvent('AUDIO_PLAYED', { ...content, audioSessionId, contentLocation }, client)
      if (onPlay) onPlay()
    }
    const handleOnPauseEvent = () => {
      setStatus('paused')
      trackContentSession({ ...content, contentLocation }, client)
      trackAudioEvent('AUDIO_PAUSED', { ...content, sessionId, contentLocation }, client)
      cookieClear()
      setsessionId(null)
      if (onPause) onPause()
    }
    const handleOnEndedEvent = () => {
      trackContentSession({ ...content, contentLocation }, client)
      trackAudioEvent('AUDIO_ENDED', { ...content, sessionId, contentLocation }, client)
      cookieClear()
      setStatus('ended')
      setsessionId(null)
    }
    const handleOnTimeUpdateEvent = () => {
      setCurrentTime(audio.currentTime)
    }
    const handleOnLoadedMetaDataEvent = () => {
      setDuration(audio.duration)
      setCurrentTime(audio.currentTime)
      if (autoPlay) audio.play()
    }

    audio.addEventListener('play', handleOnPlayEvent)
    audio.addEventListener('pause', handleOnPauseEvent)
    audio.addEventListener('ended', handleOnEndedEvent)
    audio.addEventListener('timeupdate', handleOnTimeUpdateEvent)
    audio.addEventListener('loadedmetadata', handleOnLoadedMetaDataEvent)

    return () => {
      audio.removeEventListener('play', handleOnPlayEvent)
      audio.removeEventListener('pause', handleOnPauseEvent)
      audio.removeEventListener('ended', handleOnEndedEvent)
      audio.removeEventListener('timeupdate', handleOnTimeUpdateEvent)
      audio.removeEventListener('loadedmetadata', handleOnLoadedMetaDataEvent)
    }
  }, [audio, onPlay, onPause, autoPlay, sessionId, content, client, contentLocation])

  const handlePlayButtonClick = async () => {
    const isRead = await checkIsRead()
    // if was cancelled
    if (!isRead) return

    if (audio) audio.play()
  }

  const handlePauseButtonClick = () => {
    if (audio) audio.pause()
  }

  const formatSeconds = (seconds: number) => {
    const h = Math.floor(seconds / 3600)
    const m = Math.floor(seconds / 60) % 60
    const s = Math.floor(seconds % 60)
    return [h, m, s]
      .map(v => v < 10 ? '0' + v : v)
      .filter((v, i) => v !== '00' || i > 0)
      .join(':')
  }

  const getCurrentTime = () => {
    return formatSeconds(currentTime)
  }

  const getDuration = () => {
    return formatSeconds(duration)
  }

  const getProgressValue = (e) => {
    const bounding = e.target.getBoundingClientRect()
    const x = e.pageX - bounding.left
    const max = bounding.width
    return Math.round(x / max * duration)
  }

  const handleProgressOnClickEvent = (e) => {
    if (audio) audio.currentTime = getProgressValue(e)
  }

  return (
    <Container thumbnail={thumbnail} ref={playerContainerRef}>
      <OverlayPage visible={showOverlay}/>
      <Audio ref={audioRef} src={src} controls preload="metadata"/>
      <Thumbnail id="thumbnail" thumbnail={thumbnail}/>
      <TimeContainer id="time-container">
        <CurrentTime>{ getCurrentTime() }/</CurrentTime><Duration>{ getDuration() }</Duration>
      </TimeContainer>
      <Progress id="progress-bar" max={duration} value={currentTime} onClick={handleProgressOnClickEvent}/>
      { status === 'ended' || status === 'paused' || status === 'stopped'
        ? <AudioButton id="play-button" onClick={handlePlayButtonClick}/>
        : <AudioButton id="pause-button" onClick={handlePauseButtonClick}/>
      }
    </Container>
  )
}

export default AudioPlayer
