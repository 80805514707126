// @flow
import { readFromCache } from '../apollo/cacheHelper'
import { ApolloClient } from '@apollo/client'

let apolloClient = {}
export const shouldShowTranslate = (lang: string, me: Object): boolean => {
  const currentCfpGoogleTranslationEnabled = getCurrentCfpGoogleTranslationEnabled()
  // If the device language is not in english or cfp has not enabled translations
  if (!lang || !currentCfpGoogleTranslationEnabled) return false
  lang = lang.toLowerCase()
  if (lang.indexOf('en') === -1) {
    return true
  }
  return false
}
export const getLanguageTagFromLocale = (lang: string): string => {
  if (!lang) return ''
  return lang.split('-')[0].toLowerCase()
}

export const setApolloClientForTranslations = (createdClient: ApolloClient) => {
  apolloClient = createdClient
}

const getCurrentCfpGoogleTranslationEnabled = (): boolean => {
  const { activeCfp } = readFromCache(apolloClient, ['activeCfp'])
  return activeCfp.googleTranslationEnabled ? activeCfp.googleTranslationEnabled : false
}
