// @flow

import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  refresh: {
    id: 'refresh',
    defaultMessage:
      'If you are seeing this page and you are logged in, please click the button below.'
  },
  addToCalendar: {
    id: 'addToCalendar',
    defaultMessage: 'Add to calendar'
  },
  sendOptIn: {
    id: 'sendOptIn',
    defaultMessage: 'Send opt-in'
  },
  latestPost: {
    id: 'newsFeed.latestPost',
    defaultMessage: 'Latest Posts'
  },
  smsOptIn: {
    id: 'smsOptIn',
    defaultMessage: 'SMS Opt-in'
  },
  sendTo: {
    id: 'sendTo',
    defaultMessage: 'Send to: '
  },
  smsTerms: {
    id: 'smsTerms',
    defaultMessage: 'By registering, you agree to receive text messages from us. Message frequency may vary. Standard SMS & Data rates may apply. Please confirm that you consent to the '
  },
  termsAndPrivacyPolicy: {
    id: 'termsAndPrivacyPolicy',
    defaultMessage: 'Terms and Privacy Policy'
  },
  smsOptInCancel: {
    id: 'smsOptInCancel',
    defaultMessage: 'CANCEL'
  },
  smsOptInSend: {
    id: 'smsOptInSend',
    defaultMessage: 'SEND'
  },
  like: {
    id: 'contentCard.like',
    defaultMessage: 'Like'
  },
  likes: {
    id: 'app.likes',
    defaultMessage: 'Likes'
  },
  numberOfLikes: {
    id: 'contentCard.numberOfLikes',
    defaultMessage: '#NUMBER# Likes'
  },
  maxMentions: {
    id: 'mentions.maxNumberOfMentions',
    defaultMessage: 'You can only enter a maximum of #NUMBER# @ user mentions.'
  },
  numberOfLike: {
    id: 'contentCard.numberOfLike',
    defaultMessage: '#NUMBER# Like'
  },
  comment: {
    id: 'contentCard.comment',
    defaultMessage: 'Comment'
  },
  comments: {
    id: 'app.comments',
    defaultMessage: 'Comments'
  },
  numberOfComments: {
    id: 'contentCard.numberOfComments',
    defaultMessage: '#NUMBER# Comments'
  },
  numberOfComment: {
    id: 'contentCard.numberOfComment',
    defaultMessage: '#NUMBER# Comment'
  },
  share: {
    id: 'contentCard.share',
    defaultMessage: 'Share'
  },
  shares: {
    id: 'contentCard.shares',
    defaultMessage: 'Shares'
  },
  numberOfShares: {
    id: 'contentCard.numberOfShares',
    defaultMessage: '#NUMBER# Shares'
  },
  numberOfShare: {
    id: 'contentCard.numberOfShare',
    defaultMessage: '#NUMBER# Share'
  },
  save: {
    id: 'contentCard.save',
    defaultMessage: 'Save'
  },
  saving: {
    id: 'contentCard.saving',
    defaultMessage: 'Saving'
  },
  remove: {
    id: 'contentCard.remove',
    defaultMessage: 'Remove'
  },
  removing: {
    id: 'contentCard.removing',
    defaultMessage: 'Removing'
  },
  more: {
    id: 'contentCard.more',
    defaultMessage: 'more'
  },
  loadMoreComments: {
    id: 'contentCard.comments.loadMore',
    defaultMessage: 'Load More'
  },
  at: {
    id: 'account.at',
    defaultMessage: 'at'
  },
  alerts: {
    id: 'alerts.alerts',
    defaultMessage: 'alerts'
  },
  explore: {
    id: 'explore',
    defaultMessage: 'Explore'
  },
  newsFeed: {
    id: 'newsFeed',
    defaultMessage: 'News Feed'
  },
  directory: {
    id: 'directory',
    defaultMessage: 'Directory'
  },
  calendar: {
    id: 'calendar',
    defaultMessage: 'Calendar'
  },
  folders: {
    id: 'folders',
    defaultMessage: 'Folders'
  },
  content: {
    id: 'content',
    defaultMessage: 'Content'
  },
  mostPopular: {
    id: 'mostPopular',
    defaultMessage: 'Most Popular'
  },
  name: {
    id: 'name',
    defaultMessage: 'Name'
  },
  priority: {
    id: 'priority',
    defaultMessage: 'priority'
  },
  lastModified: {
    id: 'lastModified',
    defaultMessage: 'Last Modified'
  },
  loadMore: {
    id: 'loadMore',
    defaultMessage: 'Load More'
  },
  loading: {
    id: 'loading',
    defaultMessage: 'Loading'
  },
  seeAll: {
    id: 'seeAll',
    defaultMessage: 'See all'
  },
  empty: {
    id: 'empty',
    defaultMessage: 'Empty'
  },
  allCalendars: {
    id: 'allCalendars',
    defaultMessage: 'All Calendars'
  },
  emptyCalendars: {
    id: 'emptyCalendars',
    defaultMessage: 'No calendars to show'
  },
  about: {
    id: 'about',
    defaultMessage: 'About'
  },
  timeMember: {
    id: 'timeMember',
    defaultMessage: 'member'
  },
  yourInteractions: {
    id: 'yourInteractions',
    defaultMessage: 'Your Interactions'
  },
  yourGroups: {
    id: 'yourGroups',
    defaultMessage: 'Your Groups'
  },
  contacts: {
    id: 'contacts',
    defaultMessage: 'Contacts'
  },
  search: {
    id: 'search',
    defaultMessage: 'Search'
  },
  searchAllUsers: {
    id: 'searchAllUsers',
    defaultMessage: 'Search All Users'
  },
  nothing: {
    id: 'nothing',
    defaultMessage: 'Nothing here at the moment...'
  },
  chooseDirectory: {
    id: 'chooseDirectory',
    defaultMessage: 'Choose a Directory folder to see the contacts'
  },
  acknowledge: {
    id: 'read.acknowledge',
    defaultMessage:
      'I acknowledge that by clicking below, I have received the attached content.'
  },
  noResults: {
    id: 'noResults',
    defaultMessage: 'There are no results found'
  },
  smsAlerts: {
    id: 'smsAlerts',
    defaultMessage: 'SMS Alerts'
  },
  editYourName: {
    id: 'user.editYourName',
    defaultMessage: 'You can edit your name'
  },
  improperNameFormat: {
    id: 'user.improperNameFormat',
    defaultMessage:
      'Please enter only your first and last name i.e. "John Smith"'
  },
  notEditableEmail: {
    id: 'user.notEditableEmail',
    defaultMessage: "You can't edit your email"
  },
  editYourEmail: {
    id: 'user.editYourEmail',
    defaultMessage: 'You can edit your email'
  },
  notEditableAccountId: {
    id: 'user.notEditableAccountId',
    defaultMessage: "You can't edit your account id"
  },
  editYourAccountId: {
    id: 'user.editYourAccountId',
    defaultMessage: 'You can edit your account id'
  },
  smsSubscriptionStatus: {
    id: 'profile.smsSubscriptionStatus',
    defaultMessage: 'SMS subscription status'
  },
  smsStatusUpdateDate: {
    id: 'profile.smsStatusUpdateDate',
    defaultMessage: 'SMS status last updated on'
  },
  mobileNumber: {
    id: 'profile.mobileNumber',
    defaultMessage: 'Mobile number'
  },
  role: {
    id: 'profile.role',
    defaultMessage: 'Role'
  },
  emailAddress: {
    id: 'profile.emailAddress',
    defaultMessage: 'Email address'
  },
  success: {
    id: 'success',
    defaultMessage: 'Success'
  },
  tryAgain: {
    id: 'tryAgain',
    defaultMessage: 'Error, please try again.'
  },
  nothingChanged: {
    id: 'nothingChanged',
    defaultMessage: 'Nothing has changed, please edit value to save.'
  },
  openDocument: {
    id: 'openDocument',
    defaultMessage: 'Open Document'
  },
  post: {
    id: 'comment.post',
    defaultMessage: 'post'
  },
  writeAComment: {
    id: 'comment.writeAComment',
    defaultMessage: 'Write a comment'
  },
  myProfile: {
    id: 'app.myProfile',
    defaultMessage: 'My Profile'
  },
  changeEmail: {
    id: 'app.changeEmail',
    defaultMessage: 'Change Email'
  },
  changePassword: {
    id: 'app.changePassword',
    defaultMessage: 'Change Password'
  },
  logout: {
    id: 'app.logout',
    defaultMessage: 'Logout'
  },
  gotToAlerts: {
    id: 'alerts.gotToAlerts',
    defaultMessage: 'Go to Alerts'
  },
  appProfiles: {
    id: 'app.appProfiles',
    defaultMessage: 'App Profiles'
  },
  all: {
    id: 'searchBar.all',
    defaultMessage: 'All'
  },
  folder: {
    id: 'searchBar.folder',
    defaultMessage: 'Folder'
  },
  event: {
    id: 'searchBar.event',
    defaultMessage: 'Event'
  },
  searchHere: {
    id: 'searchBar.searchHere',
    defaultMessage: 'Search here'
  },
  appManagement: {
    id: 'nav.appManagement',
    defaultMessage: 'App Management'
  },
  contentManagement: {
    id: 'nav.contentManagement',
    defaultMessage: 'Content Management'
  },
  analytics: {
    id: 'nav.analytics',
    defaultMessage: 'Analytics'
  },
  accountAdmin: {
    id: 'role.accountAdmin',
    defaultMessage: 'Account Admin'
  },
  appProfileAdmin: {
    id: 'role.appProfileAdmin',
    defaultMessage: 'App Profile Admin'
  },
  contentPublisher: {
    id: 'role.contentPublisher',
    defaultMessage: 'Content Publisher'
  },
  user: {
    id: 'role.user',
    defaultMessage: 'User'
  },
  hris: {
    id: 'app.hris',
    defaultMessage: 'HRIS'
  },
  myApps: {
    id: 'app.myApps',
    defaultMessage: 'My Apps'
  },
  noAppIntegrations: {
    id: 'app.NoAppIntegrations',
    defaultMessage: 'No apps integrated.'
  },
  stayLoggedIn: {
    id: 'app.stayLoggedIn',
    defaultMessage: 'Stay Logged In'
  },
  sessionTimout: {
    id: 'app.sessionTimout',
    defaultMessage: 'Session Timeout'
  },
  expiredSession: {
    id: 'app.expiredSession',
    defaultMessage: 'Your online session will expire in'
  },
  expireDescription: {
    id: 'app.expireDescription',
    defaultMessage:
      'Please move your mouse or press a key on the keyboard to keep session active.'
  },
  noContentToShow: {
    id: 'noContentToShow',
    defaultMessage: 'No more content to show'
  },
  totalTimeMember: {
    id: 'profile.totalTimeMember',
    defaultMessage: 'Total member time'
  },
  totalCommentsMade: {
    id: 'profile.totalCommentsMade',
    defaultMessage: 'Total comments made'
  },
  totalLikedContent: {
    id: 'profile.totalLikedContent',
    defaultMessage: 'Total content liked'
  },
  seeTranslation: {
    id: 'seeTranslation',
    defaultMessage: 'See Translation'
  },
  seeOriginal: {
    id: 'seeOriginal',
    defaultMessage: 'See Original'
  },
  deleteComment: {
    id: 'comment.delete',
    defaultMessage: 'Delete Comment?'
  },
  deleteCommentText: {
    id: 'comment.deleteText',
    defaultMessage: 'Are you sure you want to delete the comment?'
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel'
  },
  agree: {
    id: 'agree',
    defaultMessage: 'Agree'
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Delete'
  },
  edit: {
    id: 'edit',
    defaultMessage: 'Edit'
  },
  cancelSaveName: {
    id: 'profile.cancelSaveName',
    defaultMessage: 'Cancel'
  },
  cancelMobileNumber: {
    id: 'profile.cancelMobileNumber',
    defaultMessage: 'Cancel'
  },
  saveNameValidation: {
    id: 'profile.saveNameValidation',
    defaultMessage: 'Name cannot be blank'
  },
  e164Validation: {
    id: 'profile.e164Validation',
    defaultMessage: 'Phone Number format invalid, must be 10 digits'
  },
  mobileNumberValidation: {
    id: 'profile.mobileNumberValidation',
    defaultMessage: 'Mobile number cannot be blank'
  },
  saveName: {
    id: 'profile.saveName',
    defaultMessage: 'Save'
  },
  saveMobileNumber: {
    id: 'profile.saveMobileNumber',
    defaultMessage: 'Save'
  },
  firstNameInput: {
    id: 'profile.firstNameInput',
    defaultMessage: 'First name:'
  },
  lastNameInput: {
    id: 'profile.lastNameInput',
    defaultMessage: 'Last name:'
  },
  reload: {
    id: 'reload',
    defaultMessage: 'Reload'
  },
  noPost: {
    id: 'app.noPost',
    defaultMessage: 'No post to show'
  },
  noPostDescription: {
    id: 'app.noPost.description',
    defaultMessage: 'There is no post to show right now'
  },
  replyPlaceholder: {
    id: 'comment.replyPlaceholder',
    defaultMessage: 'Reply to'
  },
  reply: {
    id: 'comment.reply',
    defaultMessage: 'Reply'
  },
  author: {
    id: 'comment.author',
    defaultMessage: 'Author'
  },
  loadXMoreComments: {
    id: 'comment.loadXMore',
    defaultMessage: 'load #NUMBER# more comments'
  },
  loadOneMoreComment: {
    id: 'comment.loadOneMore',
    defaultMessage: 'load #NUMBER# more comment'
  },
  folderWidgetEmptyResultsLabel: {
    id: 'folderWidget.emptyResultsLabel',
    defaultMessage: 'No recent folders viewed'
  },
  folderWidgetHeaderTitle: {
    id: 'folderWidget.headerTitle',
    defaultMessage: 'My Recent Folders'
  },
  folderWidgetFooterLabel: {
    id: 'folderWidget.footerLabel',
    defaultMessage: 'See All Folders'
  },
  recentContentsWidgetHeaderLabel: {
    id: 'recentContentsWidget.headerTitle',
    defaultMessage: 'My Recent Content'
  },
  recentContentsWidgetEmptyResultsLabel: {
    id: 'recentContentsWidget.noRecentContentsLabel',
    defaultMessage: 'No recent content viewed'
  },
  upcomingEventsWidgetHeaderLabel: {
    id: 'upcomingEventsWidget.headerTitle',
    defaultMessage: 'Upcoming Events'
  },
  upcomingEventsWidgetEmptyResultsLabel: {
    id: 'upcomingEventsWidget.noUpcomingEventsLabel',
    defaultMessage: 'No upcoming events.'
  },
  upcomingEventsWidgetFooterLabel: {
    id: 'upcomingEvents.footerLabel',
    defaultMessage: 'See All in Calendar'
  },
  quickLinksWidgetHeaderLabel: {
    id: 'quickLinkWidget.headerTitle',
    defaultMessage: 'Quick Links'
  },
  quickLinksWidgetEmptyResultsLabel: {
    id: 'quickLinkWidget.noQuickLinksLabel',
    defaultMessage: 'No Quick Links added'
  },
  seeInDirectoryLabel: {
    id: 'directoryWidget.directoryFooter',
    defaultMessage: 'See in Directory'
  },
  noDirectoryLabel: {
    id: 'directoryWidget.directoryItemLabel',
    defaultMessage: 'No directories created'
  },
  viewProfile: {
    id: 'profileWidget.viewProfile',
    defaultMessage: 'View profile'
  },
  editImage: {
    id: 'profileWidget.edit',
    defaultMessage: 'Edit'
  },
  updateProfilePicture: {
    id: 'app.updateProfilePicture',
    defaultMessage: 'Update profile picture'
  },
  updateProfilePhoto: {
    id: 'app.updateProfilePhoto',
    defaultMessage: 'Update Profile Photo'
  },
  updateProfilePhotoDescription: {
    id: 'app.updateProfilePhotoDescription',
    defaultMessage: 'User Profile Photo needs to be at a minimum resolution of 512x512 pixels'
  },
  cropPhoto: {
    id: 'app.cropPhoto',
    defaultMessage: 'Crop photo'
  },
  sortFolderBy: {
    id: 'explore.sortFolderBy',
    defaultMessage: 'Sort Folders By'
  },
  folderNameAscending: {
    id: 'explore.folderNameAscending',
    defaultMessage: 'Folder Name Ascending'
  },
  folderNameDescending: {
    id: 'explore.folderNameDescending',
    defaultMessage: 'Folder Name Descending'
  },
  folderDefault: {
    id: 'explore.folderDefault',
    defaultMessage: 'Default Sort'
  },
  contentTitle: {
    id: 'explore.contentTitle',
    defaultMessage: 'Content Title'
  },
  contentDate: {
    id: 'explore.contentDate',
    defaultMessage: 'Content Date'
  },
  contentPriority: {
    id: 'explore.contentPriority',
    defaultMessage: 'Reset Sort'
  },
  next: {
    id: 'app.next',
    defaultMessage: 'Next'
  },
  previous: {
    id: 'app.previous',
    defaultMessage: 'Previous'
  },
  logoPlaceholder: {
    id: 'app.logoPlaceholder',
    defaultMessage: 'Your Logo Here'
  },
  deleteProfilePhoto: {
    id: 'app.deleteProfilePhoto',
    defaultMessage: 'Are you sure you want to delete your profile photo?'
  },
  linkSuccess: {
    id: 'explore.linkSuccess',
    defaultMessage: 'Link Copied Successfully'
  },
  contentDownloaded: {
    id: 'explore.contentDownloaded',
    defaultMessage: 'Content Downloaded'
  },
  phone: {
    id: 'directory.phone',
    defaultMessage: 'Phone'
  },
  email: {
    id: 'directory.email',
    defaultMessage: 'Email'
  },
  supportSite: {
    id: 'app.supportSite',
    defaultMessage: 'Support Site'
  }
})
