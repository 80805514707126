// @flow

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useDimensions from 'michelangelo/dist/Components/helpers/WebDimension'
import { useApolloClient, useQuery } from '@apollo/client'
import { readFromCache, writeToCache } from '../../apollo/cacheHelper'
import GET_CONTENT_BY_ID_QUERY from '../../apollo/queries/getContentById'
import ContentPreviewMobile from './ContentPreviewMobile'
import ContentPreviewWeb from './ContentPreviewWeb'
import { useLocation, useNavigate } from 'react-router-dom'
import { cookieClear, trackContentSession } from '../../Helpers/segmentHelper'
import { getLanguageTagFromLocale, shouldShowTranslate } from '../../Helpers/TranslationHelper'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'

const ContentPreviewContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
`

// added for testing purposes
ContentPreviewContainer.displayName = 'ContentPreviewContainer'

type ContentPreviewProps = {
  me: Object,
} & InjectIntlProvidedProps

function ContentPreview ({ me, intl: { locale } }: ContentPreviewProps) {
  const { state: { contentId, contentLocation } } = useLocation()
  const displayContentAuthorEnabled = me.memberships[0].account.displayContentAuthorEnabled
  const lang = getLanguageTagFromLocale(locale)
  const shouldTranslate = shouldShowTranslate(lang)
  const { data, loading } = useQuery(GET_CONTENT_BY_ID_QUERY(displayContentAuthorEnabled, shouldTranslate), {
    variables: {
      contentId,
      lang
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })
  const content: any = data && !loading ? data.getContentById : null
  const [maxImageDimensions, setMaxImageDimensions] = useState({ width: 500, height: 500 })
  const [isFullPreview, setIsFullPreview] = useState(true)

  const client = useApolloClient()
  const navigate = useNavigate()

  const { activeCfp, darkTheme } = readFromCache(client, ['activeCfp', 'darkTheme'])
  const primaryColor = activeCfp.primaryColor
  const likesEnabled = me.memberships[0].account.likesEnabled
  const commentsEnabled = me.memberships[0].account.commentsEnabled

  const [containerRef, { width, height }] = useDimensions()

  const verticalPadding = 84
  const horizontalPadding = 464

  if (document.documentElement) document.documentElement.classList.add('modal-open')

  /* eslint-disable */
  useEffect(() => {
    return () => {
      if (document.documentElement) document.documentElement.classList.remove('modal-open')
      writeToCache(client, { isPreviewOpen: false })
    }
  }, [])
  /* eslint-enable */

  /* eslint-disable */
  useEffect(() => {
    const imageMaxWidth = width - horizontalPadding > 500 ? width - horizontalPadding : 500
    const imageMaxHeight = height - verticalPadding > 500 ? height - verticalPadding : 500

    setMaxImageDimensions({ width: imageMaxWidth, height: imageMaxHeight })

    if (content && (content.contentType === 'CONTENT_IMAGE' || content.contentType === 'CONTENT_WEB')) {
      setIsFullPreview(width <= 1000)
    } else {
      setIsFullPreview(true)
    }
  }, [width, height, content])
  /* eslint-enable */

  const handleOnCloseEvent = async (event: Event) => {
    event && event.stopPropagation()
    /**
     * Add background scrolling
     */
    if (document.documentElement) document.documentElement.classList.remove('modal-open')
    trackContentSession({ ...content, contentLocation }, client)
    cookieClear()
    writeToCache(client, { isPreviewOpen: false })
    navigate(-1)
  }

  if (!contentId) return null

  return (
    <ContentPreviewContainer ref={containerRef} className="content-preview">
      { (width < 769)
        ? (
          <ContentPreviewMobile
            content={content}
            darkTheme={darkTheme}
            me={me}
            loading={loading}
            appBrandColor={primaryColor}
            likesEnabled={likesEnabled}
            commentsEnabled={commentsEnabled}
            contentLocation={contentLocation}
            dimensions={{ width, height }}
            onCloseClick={handleOnCloseEvent} />)
        : (
          <ContentPreviewWeb
            me={me}
            content={content}
            darkTheme={darkTheme}
            loading={loading}
            height={height}
            maxImageDimensions={maxImageDimensions}
            isFullPreview={isFullPreview}
            appBrandColor={primaryColor}
            likesEnabled={likesEnabled}
            commentsEnabled={commentsEnabled}
            contentLocation={contentLocation}
            onCloseClick={handleOnCloseEvent} />)}
    </ContentPreviewContainer>
  )
}

export default injectIntl(ContentPreview)
