import { gql } from '@apollo/client'

const GET_WIDGET_RECENT_FOLDERS_QUERY = gql`
  query GetWidgetRecentFoldersQuery ($cfpId: String!, $folderId: String!) {
    getWidgetRecentFoldersQuery(req: {cfpId: $cfpId, folderId: $folderId})
    {
      _id 
      title
    }
  }
`

export default GET_WIDGET_RECENT_FOLDERS_QUERY
