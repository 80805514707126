export const cacheDefaults = {
  activeCfp: {},
  analytics: {
    sessionsId: null,
    contentSessionId: null,
    socialSessionId: null,
    currentContent: null,
    content_sessions_id: null,
    appProfileIds: null,
    social_sessions_id: null,
    groupIds: [],
    userGroups: [],
    sessionsTime: null,
    createdGroupNames: [],
    contentLocation: null,
    audioSessionId: null,
    audioContent: null
  },
  alerts: [],
  darkTheme: false,
  unlockedContent: {},
  isPreviewOpen: false,
  folders: null
}
