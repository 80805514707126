// @flow

import React from 'react'
import styled from 'styled-components'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'
import color from 'michelangelo/dist/Components/styles/color'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Icon from 'michelangelo/dist/SharedComponents/DataDisplay/Icons/IconComponent'
import Row from 'michelangelo/dist/WebComponents/Layout/Row'
import Column from 'michelangelo/dist/WebComponents/Layout/Column'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../i18n/messages'
import logoutHelper from '../Helpers/logoutHelper'

const ConfirmationDialogWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;

  visibility: hidden;
  opacity: 0;

  transition: visibility .1s ease, opacity .3s ease;
  -webkit-transition: visibility .1s ease, opacity .3s ease;
  -moz-transition: visibility .1s ease, opacity .3s ease;
  -ms-transition: visibility .1s ease, opacity .3s ease;

  ${(props) => props.visibility && `
    visibility: visible;
    opacity: 1;
  `};
`
const Modal = styled.div`
  position: absolute;
  width: 500px;
  height: 302px;
  left: calc(50% - 500px/2);
  top: calc(50% - 302px/2 + 0.5px);
  background: ${color.white};
  box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.1);
  align-items: center;
`
const Header = styled.div`
  background: ${color.danger};
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 15px;
  justify-content: flex-end;
  height: 70px;
`
const ButtonParent = styled.div`
  display: flex;
  margin: 32px 32px 0px 32px;
`
const ButtonContainer = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`
const IconContainer = styled.div`

`
const CounterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 0px;
`
const ExpireContainer = styled.div`
  padding: 16px 24px;
`
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 24px;
`
const DescriptionText = styled.div`
  display: block;
  width: 100%;
  padding: 16px 24px;
`

type SessionTimeOutModalProps = {
  timerCount: string,
  showModal: boolean
} & InjectIntlProvidedProps

function SessionTimeOutModal ({ intl: { formatMessage }, timerCount, showModal }: SessionTimeOutModalProps) {
  /**
   * Leave for now just incase they want button after
   * review of this feature
   */
  const onLogoutClicked = async () => {
    await logoutHelper()
  }
  const onContinueClicked = () => {
    // keep seesion logice
  }

  const buttons = <Row classNames={['is-marginless']}>
    <Column classNames={['is-paddingless', 'is-12']}>
      <ButtonParent>
        <Column classNames={['is-paddingless', 'is-6']}>
          <ButtonContainer>
            <Button ariaLabel={formatMessage(messages.logout)} title={formatMessage(messages.logout)} onClick={onLogoutClicked} outline='true' themeColor={color.info}/>
          </ButtonContainer>
        </Column>
        <Column classNames={['is-paddingless', 'is-6']}>
          <ButtonContainer>
            <Button ariaLabel={formatMessage(messages.stayLoggedIn)} title={formatMessage(messages.stayLoggedIn)} onClick={onContinueClicked} fill='true' themeColor={color.info} />
          </ButtonContainer>
        </Column>
      </ButtonParent>
    </Column>
  </Row>

  const textDescription = <Row classNames={['is-marginless']}>
    <Column classNames={['is-paddingless', 'is-12']}>
      <DescriptionText>
        <Text text={formatMessage(messages.expireDescription)} fontColor={color.grey600} textSize='p'/>
      </DescriptionText>
    </Column>
  </Row>

  const showButtons = false
  const displayButtonsOrText = showButtons ? buttons : textDescription

  return (
    <ConfirmationDialogWrapper visibility={showModal}>
      <Row classNames={['is-marginless']}>
        <Column classNames={['is-paddingless', 'is-12']}>
          <Modal>
            <Header>
              <Column classNames={['is-paddingless', 'is-12']}>
                <Text text={formatMessage(messages.sessionTimout)} fontColor={color.white} textSize='h4'/>
              </Column>
            </Header>
            <Row classNames={['is-marginless', 'is-vcentered']}>
              <Column classNames={['is-paddingless', 'is-8', 'is-offset-2']}>
                <Container>
                  <Column classNames={['is-paddingless', 'is-1']}>
                    <IconContainer>
                      <Icon color={color.danger} iconName='clock' size={36} />
                    </IconContainer>
                  </Column>
                  <Column classNames={['is-paddingless', 'is-11']}>
                    <ExpireContainer>
                      <Text text={formatMessage(messages.expiredSession)} fontColor={color.grey600} textSize='p'/>
                    </ExpireContainer>
                  </Column>
                </Container>
              </Column>
            </Row>
            <Row classNames={['is-marginless']}>
              <Column classNames={['is-paddingless', 'is-12']}>
                <CounterContainer>
                  <Text text={`${timerCount} seconds`} fontColor={color.grey400} textSize='h1'/>
                </CounterContainer>
              </Column>
            </Row>
            {displayButtonsOrText}
          </Modal>
        </Column>
      </Row>
    </ConfirmationDialogWrapper>
  )
}
export default injectIntl(SessionTimeOutModal)
