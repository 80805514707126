// @flow

import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import Icon from 'michelangelo/dist/SharedComponents/DataDisplay/Icons/IconComponent'
import EmptyScreen from 'michelangelo/dist/Components/EmptyScreen'
import ContentTypePreview from './ContentTypePreview'
import color from 'michelangelo/dist/Components/styles/color'
import ContentCard from '../../NewsFeed/Components/ContentCard'
import { useApolloClient } from '@apollo/client'
import { messages } from '../../i18n/messages'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Comments from '../../NewsFeed/Components/Comments'
import { includes } from 'lodash'
import { useLocation, useNavigate, Route, Routes } from 'react-router-dom'

const ContentPreviewContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${color.black};
  z-index: 999;

`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const CommentsSection = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  background: ${color.white};

  top: ${(props) => props.height}px;
  visibility: hidden;

  transition: visibility .1s ease-out, opacity .1s ease-out, top .2s ease-out;
  -webkit-transition: visibility .1s ease-out, opacity .1s ease-out, top .2s ease-out;
  -moz-transition: visibility .1s ease-out, opacity .1s ease-out, top .2s ease-out;
  -ms-transition: visibility .1s ease-out, opacity .1s ease-out, top .2s ease-out;
  -ms-transition: visibility .1s ease-out, opacity .1s ease-out, top .2s ease-out;

  ${(props) => props.isVisible && `
    top: 0;
    visibility: visible;
  `};
`
const CommentsContainer = styled.div`
  display: flex;
  flex:1;
  flex-direction: column;
  overflow: hidden;
`
const CommentsHeader = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0px 0px 7px rgba(39, 43, 52, 0.08);
  z-index: 4;
`
const CommentsBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow:hidden;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  width: 100%;
  height: 40px;
  top: 0;
  background: rgba(0,0,0,0.65);


  visibility: visible;
  opacity: 1;

  transition: visibility .1s ease-out, opacity .3s ease-out;
  -webkit-transition: visibility .1s ease-out, opacity .3s ease-out;
  -moz-transition: visibility .1s ease-out, opacity .3s ease-out;
  -ms-transition: visibility .1s ease-out, opacity .3s ease-out;
  -ms-transition: visibility .1s ease-out, opacity .3s ease-out;

  ${(props) => props.isHidden && `
    visibility: hidden;
    opacity: 0;
  `};
`

const HeaderOptionContainer = styled.div`
  display: flex;
  padding: 4px;
`

const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
`

const ContentCardContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(0deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0.65) 90%, rgba(0,0,0,0.08) 100%);

  visibility: visible;
  opacity: 1;

  transition: visibility .1s ease-out, opacity .3s ease-out;
  -webkit-transition: visibility .1s ease-out, opacity .3s ease-out;
  -moz-transition: visibility .1s ease-out, opacity .3s ease-out;
  -ms-transition: visibility .1s ease-out, opacity .3s ease-out;
  -ms-transition: visibility .1s ease-out, opacity .3s ease-out;

  ${(props) => props.isHidden && `
    visibility: hidden;
    opacity: 0;
  `};
`

// added for testing purposes
ContentPreviewContainer.displayName = 'ContentPreviewContainer'

type ContentPreviewMobileProps = {
  me: Object,
  onClose?: Function,
  contentId: string,
  darkTheme?: boolean,
  appBrandColor?: string,
  commentsEnabled?: boolean,
  likesEnabled?: boolean,
  client?: Object,
  contentLocation: String
} & InjectIntlProvidedProps

const docType = ['CONTENT_PDF', 'CONTENT_DOCX', 'CONTENT_PPTX', 'CONTENT_XLSX', 'CONTENT_RICH_TEXT']

function ContentPreviewMobile ({ me, onCloseClick, loading, likesEnabled, content, dimensions, commentsEnabled, appBrandColor, darkTheme, contentLocation, intl: { formatMessage } }: ContentPreviewMobileProps) {
  const [areOptionsHidden, setAreOptionsHidden] = useState(false)
  const commentsRef = useRef(null)

  const { width, height } = dimensions

  const client = useApolloClient()
  const navigate = useNavigate()
  const location = useLocation()
  const path = location?.pathname
  const isWebContent = content && content.contentType === 'CONTENT_WEB'

  const onOpenDocumentClick = async () => {
    const urlToOpen = content.htmlSignedDownloadUrl ? content.htmlSignedDownloadUrl : content.signedDownloadUrl
    window.open(urlToOpen)
  }

  const toggleOptions = () => {
    setAreOptionsHidden(areOptionsHidden => !areOptionsHidden)
  }

  const onCommentClick = () => {
    navigate({
      pathname: `${path}/commentSection`,
      state: {
        background: location.state.background,
        contentId: content._id,
        contentLocation: location.state.contentLocation
      }
    })
  }

  const onCommentsClose = (e: Event) => {
    e && e.stopPropagation()
    navigate(-1)
  }

  useEffect(() => {
    if (location && commentsRef.current) {
      setTimeout(() => { commentsRef.current && commentsRef.current.setInputFocus() }, 500)
    }
  }, [location])

  const isDoc = content && includes(docType, content.contentType)

  let loadingView = null
  if (loading) {
    loadingView = (
      <EmptyScreen backgroundColor={'black'} loading text={`${formatMessage(messages.loading)}...`} iconColor={color.white} fontColor={color.white} iconSize={30} />
    )
  }
  return (
    <ContentPreviewContainer>
      <Container>
        <HeaderContainer isHidden={areOptionsHidden}>
          <HeaderOptionContainer onClick={onCloseClick}><Icon iconName='x' color={color.white} size={16}/></HeaderOptionContainer>
          {isDoc && <HeaderOptionContainer onClick={onOpenDocumentClick}><Text text={'Open Document'} textSize={'h5'} fontColor={appBrandColor}/></HeaderOptionContainer>}
        </HeaderContainer>

        {loadingView}

        {!loading && content &&
          <>
            <PreviewContainer onClick={toggleOptions}>
              <ContentTypePreview content={content} client={client} isMobilePreview={true} isFullPreview={true} maxImageDimensions={{ width, height }} me={me} formatMessage={formatMessage} contentLocation={contentLocation} />
            </PreviewContainer>

            <ContentCardContainer isHidden={areOptionsHidden}>
              <ContentCard
                content={content}
                isDarkTheme={darkTheme}
                me={me}
                appBrandColor={appBrandColor}
                likesEnabled={likesEnabled}
                commentsEnabled={commentsEnabled}
                isContentPreview={!isWebContent}
                client={client}
                onCommentButtonClick={onCommentClick}
                contentLocation={contentLocation}
                noCommentList
                isTransparent
              />
            </ContentCardContainer>
          </>
        }

        { (content && content.commentsEnabled && commentsEnabled) &&
            <Routes>
          <Route path={`${path}/commentSection`} render={() => (
            <CommentsSection height={height} isVisible={true}>
              <CommentsContainer height={height}>
                <CommentsHeader>
                  <Text text={'Comments'} textSize={'h4'} fontColor={color.black}/>
                  <Text text={'Done'} textSize={'h5'} fontColor={appBrandColor} onPress={onCommentsClose}/>
                </CommentsHeader>
                <CommentsBody>
                  <Comments testID={content._id} isMobilePreview ref={commentsRef} content={ content } client={client} />
                </CommentsBody>
              </CommentsContainer>
            </CommentsSection>)
          } /> </Routes>}
      </Container>
    </ContentPreviewContainer>
  )
}

export default injectIntl(ContentPreviewMobile)
