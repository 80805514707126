// @flow
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Avatar from 'michelangelo/dist/SharedComponents/DataDisplay/Avatar/Avatar'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import color from 'michelangelo/dist/Components/styles/color'
import { injectIntl } from 'react-intl'
import type { InjectIntlProvidedProps } from 'react-intl'
import { messages } from '../i18n/messages'

const DirectoriesList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  max-height: calc(100vh - 156px);
`
DirectoriesList.displayName = 'DirectoriesList'

const DirectoryItem = styled.li`
  display: flex;
  align-items: center;
  padding: 16px;
  background: ${props => props.active ? color.white : color.grey50};
  box-shadow: inset 0px -1px 0px ${color.lightestGrey};
  cursor: ${props => props.active ? 'auto' : 'pointer'};
  @media screen and (max-width: 767.98px) {
    background: ${color.white}
  }
`
DirectoryItem.displayName = 'DirectoryItem'

const AvatarContainer = styled.div`
  margin-right: 16px;
`
AvatarContainer.displayName = 'AvatarContainer'

type ListProps = {
  items: Array<Object>,
  appBrandColor: string,
  onItemClick?: Function,
  selectedItem?: Object,
  loading?: boolean,
  onLoadMore?: Function
} & InjectIntlProvidedProps

function List (props: ListProps) {
  const { items, appBrandColor, onItemClick, selectedItem, loading, intl: { formatMessage }, onLoadMore } = props
  const [selectedDirectory, setSelectedDirectory] = useState(selectedItem)

  useEffect(() => {
    const listElm = document.getElementById('directories-list')
    const onScrollCallback = (e: Object) => {
      if (loading) return
      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
        onLoadMore()
      }
    }
    if (listElm) {
      listElm.addEventListener('scroll', onScrollCallback)
    }
    return () => {
      if (listElm) {
        listElm.removeEventListener('scroll', onScrollCallback)
      }
    }
  }, [loading, onLoadMore])

  useEffect(() => {
    setSelectedDirectory(selectedItem)
  }, [selectedItem])

  const onDirectoryClick = (item) => {
    if (selectedDirectory && (item._id === selectedDirectory._id)) return
    if (onItemClick) onItemClick(item)
    setSelectedDirectory(item)
  }

  const isActive = (currentDirectory) => {
    if (!selectedDirectory) return false
    return currentDirectory._id === selectedDirectory._id
  }

  return (
    <DirectoriesList id="directories-list">
      {
        loading ? <Text text={`${formatMessage(messages.loading)}...`} align="center"/> : null
      }
      {
        !loading && items.length === 0 ? <Text text={`${formatMessage(messages.noResults)}!`} align="center"/> : null
      }
      {
        items.map((item, index) => (
          <DirectoryItem key={index} onClick={() => { onDirectoryClick(item) }} active={isActive(item)}>
            <AvatarContainer>
              <Avatar icon='folder' size={48} appBrandColor={appBrandColor} thumbnail={item.directoryContactPhoto && item.directoryContactPhoto._128px} />
            </AvatarContainer>
            <Text text={item.name} textSize="p" fontColor={color.lightBlack} />
          </DirectoryItem>
        ))
      }
    </DirectoriesList>
  )
}

export default injectIntl(List)
