export const contentLocations = {
  NEWSFEED: 'News Feed',
  EXPLORE: 'Explore',
  PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
  CAROUSEL: 'CAROUSEL',
  CAROUSEL_EXPLORE: 'CAROUSEL_EXPLORE',
  CAROUSEL_NEWSFEED: 'CAROUSEL_NEWSFEED',
  MOST_POPULAR: 'MOST_POPULAR',
  SEARCH_BAR: 'SEARCH_BAR',
  QUICK_LINKS: 'Quick Links',
  RECENT_CONTENT: 'Recent Content',
  CALENDAR: 'Calendar',
  UPCOMING_EVENTS: 'Upcoming Events'
}

export const contentShareType = {
  LINK_COPIED: 'LINK_COPIED',
  FACEBOOK: 'FACEBOOK',
  TWITTER: 'TWITTER',
  EMAIL: 'TWITTER'
}
