// @flow
import React from 'react'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import QuickLinksWidget from 'michelangelo/dist/WebComponents/NewsFeed/Widgets/QuickLinksWidget/QuickLinksWidget'
import type { WidgetProps } from '../WidgetProps'
import { messages } from '../../../i18n/messages'
import GET_QUICK_LINKS_QUERY from '../../../apollo/queries/widgets/getQuickLinksWidget'
import { contentLocations } from '../../../Helpers/contentHelper'
import { readFromCache } from '../../../apollo/cacheHelper'
import { trackClickQuickLinkEvent, trackContentOpened } from '../../../Helpers/segmentHelper'
import { checkIsReadAcknowledgement } from '../../../Helpers/ReadAcknowledgement'
import SEND_EMAIL_QUERY from '../../../apollo/queries/sendEmail'
import { goToContentPreview } from '../../../Helpers/contentPreviewHelper'

const QuickLinksWidgetComponent = ({ me, intl: { formatMessage } }: WidgetProps & InjectIntlProvidedProps) => {
  const client = useApolloClient()
  const navigate = useNavigate()
  const location = useLocation()
  const contentLocation = contentLocations.QUICK_LINKS
  const { activeCfp } = readFromCache(client, ['activeCfp'])
  const { data } = useQuery(GET_QUICK_LINKS_QUERY, {
    variables: { cfpId: activeCfp && activeCfp._id },
    fetchPolicy: 'cache-and-network'
  })
  const [sendEmail] = useMutation(SEND_EMAIL_QUERY)

  const quickLinks = data && data.getQuickLinks ? data.getQuickLinks : []

  const checkIsRead = async (content) => {
    return await checkIsReadAcknowledgement(client, content, formatMessage, me, sendEmail)
  }

  const handleItemClick = async (content) => {
    const isRead = await checkIsRead(content)
    if (!isRead) {
      return
    }
    trackContentOpened({ ...content, contentLocation }, client)
    trackClickQuickLinkEvent({ ...content, contentId: content._id, user: me._id }, client)
    if (content.contentType === 'CONTENT_WEB' && !content.likesEnabled && !content.commentsEnabled) {
      let urlToOpen
      if (content.webUrl) urlToOpen = content.webUrl
      else urlToOpen = content.htmlSignedDownloadUrl ? content.htmlSignedDownloadUrl : content.signedDownloadUrl
      window.open(urlToOpen)
    } else {
      // navigate({
      //   pathname: `${activeCfp._id}/newsfeed-preview/${content._id}`,
      //   state: {
      //     background: location,
      //     contentId: content._id,
      //     contentLocation: contentLocation
      //   }
      // })
      goToContentPreview(navigate, { content, cfpId: activeCfp._id, contents: [], location, contentLocation }, {})
    }
  }

  return (
    <div>
      <QuickLinksWidget emptyResultsLabel={formatMessage(messages.quickLinksWidgetEmptyResultsLabel)}
        data={quickLinks} headerTitle={formatMessage(messages.quickLinksWidgetHeaderLabel)}
        onItemClick={handleItemClick}/>
    </div>
  )
}

export default injectIntl(QuickLinksWidgetComponent)
