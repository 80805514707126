// @flow

import React from 'react'
import { useQuery } from '@apollo/client'
import GET_CAROUSEL_QUERY from '../apollo/queries/getCarouselByCfpId'
import CarouselComponent from 'michelangelo/dist/WebComponents/NewsFeed/Carousel/Carousel'

type CarouselType = {
  onCarouselClick: Function,
  activeCfp: Object,
  aspectRatio: number,
}

function Carousel ({ onCarouselClick, activeCfp, aspectRatio }: CarouselType) {
  const { data } = useQuery(GET_CAROUSEL_QUERY, {
    variables: { cfpId: activeCfp._id },
    fetchPolicy: 'cache-and-network'
  })

  if (!activeCfp.carousel || activeCfp.carousel.length === 0 || !data) {
    return null
  }

  const onItemClick = ({ content }) => { if (content) { return onCarouselClick(content) } }
  return <CarouselComponent data={data.getCarouselByCfpId} onItemClick={onItemClick} aspectRatio={aspectRatio} />
}

export default Carousel
