// @flow

import React, { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import color from 'michelangelo/dist/Components/styles/color'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'
import { isEqual } from 'lodash'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../i18n/messages'
import DateHelper from '../Helpers/DateHelper'
import ContentItem from './ContentItem'
import Row from 'michelangelo/dist/Components/Row'
import LoadingScreen from '../Components/LoadingScreen'
import { Waypoint } from 'react-waypoint'
import { trackSortContentsEvent } from '../Helpers/segmentHelper'

const TextContainer = styled.div`
  align-items: center;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 200px;
  height: 40vh;
`

const ItemContainer = styled.div`
  margin-bottom: 8px;
  width: 100%;
  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`

const ButtonContainer = styled.div`
  margin-top: 5px;
  margin-bottom: 20px;
  margin-right: 10px;
`

const ButtonInnerContainer = styled.div`
  border-style: solid;
  border-width: 2px;
  padding-left: 5px;
  padding-right: 5px;
`

ItemContainer.displayName = 'ItemContainer'

type ListColumnProps = {
  label: string,
  prop: string,
  hasIcon?: boolean,
  render?: Function,
  ratio?: number,
  isSortable?: boolean,
  sortFunction?: Function,
  type?: string,
  tooltipProp?: string,
  onSortClick?: Function
}

type defaultSortObj = {
  type?: string,
  field?: string
}

type ContentListProps = {
  me: Object,
  client: Object,
  title: string,
  itemsPerPage: Number,
  default: string,
  loading: boolean,
  loadingMore: boolean,
  hasMore: boolean,
  onLoadMore: Function,
  sort: string,
  sortField: string,
  onItemClick?: Function,
  onSeeAllClick?: Function,
  onLoadMoreClick?: Function,
  columns: Array<ListColumnProps>,
  defaultSort?: defaultSortObj,
  handleSortChange?: Function,
  changeSort: Function,
  updateContentsList?: Function,
  totalItems: Number,
  contentSortField?: String,
  contentSortType?: String,
  activeCfp: Object,
  listRef: Object
} & InjectIntlProvidedProps

export const exploreSortOption = {
  contentTitle: 'ContentTitle',
  contentPriority: 'ContentPriority',
  contentDate: 'ContentDate'
}

export const sortDirection = {
  asc: 'asc',
  desc: 'desc'
}

export const sortFields = {
  title: 'title',
  date: 'postDate',
  priority: 'priority'
}
const fieldToColumns = {
  [sortFields.title]: exploreSortOption.contentTitle,
  [sortFields.date]: exploreSortOption.contentDate,
  [sortFields.priority]: exploreSortOption.contentPriority
}
function ContentList (props: ContentListProps) {
  const {
    me,
    client,
    loading,
    onLoadMore,
    loadingMore,
    hasMore,
    sort,
    sortField,
    onItemClick,
    intl: { formatMessage },
    onLikeClick,
    onCommentClick,
    onShareClick,
    activeCfp,
    listItems,
    changeSort,
    itemsPerPage
  } = props
  const [loadingState, setLoadingState] = useState(loading || false)
  const [contentTitleSort, setContentTitleSort] = useState(sort || 'asc')
  const [contentDateSort, setContentDateSort] = useState(sortDirection.desc)
  const [localSortField, setLocalSortField] = useState(sortField)

  useEffect(() => {
    setLoadingState(loading)
  }, [loading])

  const changeDirection = (field) => {
    const prevDirection = field === sortFields.title ? contentTitleSort : contentDateSort
    return prevDirection === sortDirection.asc ? sortDirection.desc : sortDirection.asc
  }

  const handleSort = (field) => {
    let sort = sortDirection.asc
    if (field === sortFields.title) {
      sort = localSortField !== sortFields.title ? contentTitleSort : changeDirection(field)
      changeSort(sortFields.title, sort)
      if (sort !== contentTitleSort) setContentTitleSort(sort)
    } else if (field === sortFields.date) {
      sort = localSortField !== sortFields.date ? contentDateSort : changeDirection(field)
      changeSort(sortFields.date, sort)
      if (sort !== contentDateSort) setContentDateSort(sort)
    } else {
      changeSort(sortFields.priority, sort)
    }
    if (localSortField !== field) setLocalSortField(field)
    trackSortContentsEvent(fieldToColumns[field])
  }

  const getLoader = () => {
    return <LoadingScreen customHeight='40vh' />
  }

  const renderContentList = () => {
    return listItems.map(function (content, index) {
      const contentDate = new Date(content.modified || content.created)
      const dateUpdated = DateHelper.getDateFromNow(contentDate)
      const subtitle = `${dateUpdated}`

      return (
        <Row
          isFolderHover
          customBackgroundColor={color.grey50}
          columnRatios={[1]}
          key={`item_row_${content._id}`}
        >
          <ItemContainer>
            <MemoizedContentItem
              key={content._id}
              me={me}
              client={client}
              content={content}
              subtitle={subtitle}
              onLikeClick={onLikeClick}
              onCommentClick={onCommentClick}
              onShareClick={onShareClick}
              onItemClick={onItemClick}
              activeCfp={activeCfp}
            />
            {hasMore && listItems.length >= itemsPerPage && index === listItems.length - 2 && (
              <Waypoint
                onEnter={() => {
                  onLoadMore()
                }}
              />
            )}
          </ItemContainer>
        </Row>
      )
    })
  }

  if (!loadingState && listItems && listItems.length === 0) {
    return (
      <TextContainer>
        <Text
          text={formatMessage(messages.noContentToShow)}
          fontColor={color.grey500}
          textTransform="uppercase"
          textSize="th"
          align="center"
        />
      </TextContainer>
    )
  }

  return (
    <div style={{ width: '100%' }}>
      {/* buttons */}
      <Row>
        <ButtonContainer>
          <ButtonInnerContainer>
            <Button
              ariaLabel={formatMessage(messages.contentTitle)}
              title={formatMessage(messages.contentTitle)}
              textTransform="capitalize"
              onClick={() => handleSort(sortFields.title)}
              icon={
                contentTitleSort === sortDirection.asc
                  ? 'arrowUp'
                  : 'arrowDown'
              }
              themeColor={
                localSortField === sortFields.title
                  ? color.black
                  : color.grey400
              }
            />

          </ButtonInnerContainer>
        </ButtonContainer>
        <ButtonContainer>
          <ButtonInnerContainer>
            <Button
              ariaLabel={formatMessage(messages.contentDate)}
              title={formatMessage(messages.contentDate)}
              textTransform="capitalize"
              onClick={() => handleSort(sortFields.date)}
              icon={
                contentDateSort === sortDirection.asc
                  ? 'arrowUp'
                  : 'arrowDown'
              }
              themeColor={
                localSortField === sortFields.date
                  ? color.black
                  : color.grey400
              }
            />
          </ButtonInnerContainer>
        </ButtonContainer>
        <ButtonContainer>
          <ButtonInnerContainer>
            <Button
              ariaLabel={formatMessage(messages.contentPriority)}
              title={formatMessage(messages.contentPriority)}
              textTransform="capitalize"
              onClick={() => handleSort(sortFields.priority)}
              themeColor={
                localSortField === sortFields.priority
                  ? color.black
                  : color.grey400
              }
            />
          </ButtonInnerContainer>
        </ButtonContainer>
      </Row>
      {/* buttons */}
      {(loadingState && loadingMore !== 3 && <div>{getLoader()}</div>) || renderContentList()}
      {loadingMore === 3 && getLoader()}
    </div>
  )
}
// eslint-disable-next-line react/display-name
const MemoizedContentItem = memo(ContentItem, (prevPros, nextProps) => {
  return isEqual(prevPros.content, nextProps.content) &&
      isEqual(prevPros.onItemClick, nextProps.onItemClick)
})
export default injectIntl(ContentList)
