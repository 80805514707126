// @flow

import React from 'react'
import styled from 'styled-components'
import { Worker, Viewer } from '@react-pdf-viewer/core'
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'

import * as pdfjs from 'pdfjs-dist'

const WebViewContainer = styled.div`
  width: 100%;
  height: 100%;
`

const WebView = styled.div`
  width: 100%;
  height: 100%;
`

type ContentPreviewPdfProps = {
  downloadUrl: String,
  isMobilePreview: boolean
}

function ContentPreviewPdf (props: ContentPreviewPdfProps) {
  const { downloadUrl } = props
  const defaultLayoutPluginInstance = defaultLayoutPlugin()

  return (
    <WebViewContainer>
      <WebView>
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
          <Viewer
              fileUrl={downloadUrl}
              plugins={[
                defaultLayoutPluginInstance
              ]}
              theme="dark"
          />
        </Worker>
      </WebView>
    </WebViewContainer>
  )
}

export default ContentPreviewPdf
