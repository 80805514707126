// @flow

import ar from './ar.json'
import bg from './bg-BG.json'
import cs from './cs.json'
import de from './de.json'
import enUS from './en-US.json'
import es from './es.json'
import fr from './fr.json'
import ja from './ja.json'
import ms from './ms-MY.json'
import pl from './pl.json'
import pt from './pt-PT.json'
import ptBR from './pt-BR.json'
import ro from './ro.json'
import ru from './ru.json'
import tr from './tr.json'
import hu from './hu.json'
import zhCN from './zh-CN.json'
import zhTW from './zh-TW.json'

const translations = (locale: string) => {
  const mappedLocale = {
    ar,
    bg,
    'bg-BG': bg,
    cs,
    de,
    en: enUS,
    'en-US': enUS,
    es,
    fr,
    ja,
    ms,
    'ms-MY': ms,
    pl,
    pt,
    'pt-PT': pt,
    'pt-BR': ptBR,
    ro,
    ru,
    tr,
    hu,
    zh: zhCN,
    'zh-CN': zhCN,
    'zh-TW': zhTW
  }
  const remappedLocales = (locale) => {
    if (locale.startsWith('es')) return es
    return enUS // default to English - US
  }

  return mappedLocale[locale] || remappedLocales(locale)
}

export default translations
