// @flow

import React, { useEffect, useState, useMemo } from 'react'
import color from 'michelangelo/dist/Components/styles/color'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../i18n/messages'
import GET_SIGNED_DOWNLOAD_URL from '../apollo/queries/getSignedDownloadUrl'
import SEND_EMAIL_QUERY from '../apollo/queries/sendEmail'
import { useLazyQuery, useMutation } from '@apollo/client'
import { checkIsReadAcknowledgement } from '../Helpers/ReadAcknowledgement'
import {
  trackLikeEvent,
  trackContentShared,
  trackContentSaved
} from '../Helpers/segmentHelper'
import { contentLocations, contentShareType } from '../Helpers/contentHelper'
import axios from 'axios'
import is from 'is_js'
import VideoPlayer from '../Components/VideoPlayer/VideoPlayer'
import AudioPlayer from '../Components/AudioPlayer/AudioPlayer'
import OverlayPage from '../Components/OverlayPage'
import ContentListItem from 'michelangelo/dist/WebComponents/ListItem/ContentListItem'
import ADD_LIKE_QUERY from '../apollo/queries/addLike'
import REMOVE_LIKE_QUERY from '../apollo/queries/removeLike'
import * as copy from 'copy-to-clipboard'
import { hermesUrl } from '../config'
import { isHashtag } from '../Helpers/hashtagHelper'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const ImageContainer = styled.div`
  margin-right: 16px;
  height: 168px;
  width: 30%;
  overflow: hidden;
  cursor: pointer;
`

type ContentItemProps = {
  me: Object,
  content: Object,
  subtitle: String,
  onCommentClick: Function,
  onItemClick: Function,
  client: Object,
  activeCfp: Object
} & InjectIntlProvidedProps

const ContentItem = ({ me, content, subtitle, onCommentClick, onItemClick, client, activeCfp, intl: { formatMessage } }: ContentItemProps) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [caption, setCaption] = useState(content.caption || '')
  const contentLocation = contentLocations.EXPLORE
  const contentSubLocation = 'CONTENT_EXPLORE'
  const [sendEmail] = useMutation(SEND_EMAIL_QUERY)
  const [createLike] = useMutation(ADD_LIKE_QUERY)
  const [removeLike] = useMutation(REMOVE_LIKE_QUERY)
  const navigate = useNavigate()

  const [getSignedDownloadUrl, { loading: signedLoading, data: signedData }] = useLazyQuery(
    GET_SIGNED_DOWNLOAD_URL,
    { variables: { s3Key: content.s3Key, accountId: me.memberships[0].account._id } }
  )

  useEffect(() => {
    const handleFileDownload = async () => {
      if (signedLoading || !signedData || !isSaving) return
      const link = signedData.getSignedDownloadUrl.signedUrl
      const response = await axios.get(link, { responseType: 'blob' })
      const fileName = content.s3Key ? content.s3Key.replace('/', '_') : ''
      if (window.navigator.msSaveOrOpenBlob && (is.ie() || is.edge())) {
        const fileData = [response.data]
        const blobObject = new Blob(fileData)
        window.navigator.msSaveOrOpenBlob(blobObject, fileName)
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const a = document.createElement('a')
        a.href = url
        a.setAttribute('download', fileName)
        const body = document.body
        if (body) body.appendChild(a)
        a.click()
        a.remove()
      }
      setIsSaving(false)
    }
    handleFileDownload()
  }, [content, signedLoading, signedData, isSaving])

  useEffect(() => {
    async function formatCaption () {
      const matches = caption.match(/@[a-z\d]{24}/g) || []

      let newCaption = caption
      if (matches != null) {
        for (const match of matches) {
          const userId = match.substr(1)
          const user = content.mentions.find(u => u.id === userId)
          if (user) {
            newCaption = newCaption.replace(match, '[' + user.name + '](' + userId + ')')
          } else {
            newCaption = newCaption.replace(match, '[Deleted User]()')
          }
        }
      }
      setCaption(newCaption)
    }
    formatCaption()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const checkIsRead = async (content) => {
    setShowOverlay(true)
    const isRead = await checkIsReadAcknowledgement(client, content, formatMessage, me, sendEmail)
    setShowOverlay(false)
    return isRead
  }

  const onLikeClick = async () => {
    const isRead = await checkIsRead(content)
    // if was cancelled
    if (!isRead) return

    const likeRequest = {
      variables: {
        like: {
          account: me.memberships[0].account._id,
          item: content._id,
          itemType: 'CONTENT',
          owner: me._id,
          created: content.postDate
        }
      }
    }
    if (content.likedByUser) {
      await removeLike(likeRequest)
      await trackLikeEvent(false, { ...content, contentLocation, contentSubLocation }, client)
    } else {
      await createLike(likeRequest)
      await trackLikeEvent(true, { ...content, contentLocation, contentSubLocation }, client)
    }
  }

  const onShareClick = async () => {
    const isRead = await checkIsRead(content)
    // if was cancelled
    if (!isRead) {
      throw new Error('onShareClick Cancel Read Acknowledgement')
    }

    const shareUrl = `${hermesUrl}/content/${content._id}`
    try {
      copy(shareUrl)
      await trackContentShared({ ...content, contentLocation, contentSubLocation }, contentShareType.LINK_COPIED, client)
    } catch (e) {
      console.error(e, 'error')
      return false
    }
    return true
  }

  const onSaveClick = async () => {
    const isRead = await checkIsRead(content)
    // if was cancelled
    if (!isRead) {
      setIsSaving(false)
      throw new Error('onSaveClick Cancel Read Acknowledgement')
    }

    try {
      if (isSaving) return // prevent click
      setIsSaving(true) // show loading state on the save button
      getSignedDownloadUrl() // call query
      await trackContentSaved({ ...content, contentLocation, contentSubLocation }, client)
    } catch (e) {
      setIsSaving(false)
    }
  }

  const mediaPlayer = useMemo(() => {
    const getVideoPlayer = () => {
      return (
        <div>
          <VideoPlayer
            {...(content.mediumThumbnailUrl ? { poster: content.mediumThumbnailUrl } : null)}
            fluid={true}
            inactivityTimeout={100}
            sources={[
              {
                src: content.hlsVideoPlaylistUrl || content.signedDownloadUrl || ''
              }
            ]}
            content={content}
            client={client}
            me={me}
            formatMessage={formatMessage}
            contentLocation={contentLocation}
          />
        </div>
      )
    }

    const getAudioPlayer = () => {
      return <AudioPlayer
        src={content.signedDownloadUrl}
        thumbnail={content.mediumThumbnailUrl}
        content={content}
        client={client}
        me={me}
        formatMessage={formatMessage}
        contentLocation={contentLocation}
      />
    }

    return content.contentType === 'CONTENT_VIDEO' ? getVideoPlayer() : content.contentType === 'CONTENT_AUDIO' ? getAudioPlayer() : null
  }, [content, contentLocation, me, client, formatMessage])

  const shareNotification = {
    bannerText: formatMessage(messages.linkSuccess),
    icon: 'link',
    type: 'success'
  }

  const saveNotification = {
    bannerText: formatMessage(messages.contentDownloaded),
    icon: 'download',
    type: 'info'
  }

  const handleHashtagClick = (hashtag) => {
    let hashtagValue = hashtag
    if (isHashtag(hashtag)) {
      hashtagValue = hashtag.substring(1)
    }
    navigate({
      pathname: `/${activeCfp._id}/hashtags/${hashtagValue}`
    })
  }

  return (
    <div key={`item_${content._id}`}>
      <OverlayPage visible={showOverlay} />
      <ContentListItem
        folderHoverShadow
        customBackgroundColor={color.grey50}
        timeAgoText={subtitle}
        title={content.title}
        caption={caption}
        commentsTotal={content.totalComments}
        likesTotal={content.totalLikes}
        user={content.ownerName}
        commentsText={formatMessage(messages.comments)}
        onLikeClick={onLikeClick}
        onCommentClick={() => onCommentClick(content)}
        onShareClick={onShareClick}
        onSaveClick={onSaveClick}
        displayThumbnail={content.displayThumbnail}
        contentType={content.contentType}
        likesEnabled={content.likesEnabled}
        commentsEnabled={content.commentsEnabled}
        shareEnabled={content.share === 'SHARE_ENABLED' || content.share === 'SHARE_EMAIL_ONLY'}
        disableSaving={content.disableSaving}
        onItemClick={() => onItemClick(content)}
        content={content}
        mediumThumbnailUrl={content.mediumThumbnailUrl}
        largeThumbnailUrl={content.largeThumbnailUrl}
        xlargeThumbnailUrl={content.xlargeThumbnailUrl}
        signedThumbnailUrl={content.signedThumbnailUrl}
        mediaPlayer={mediaPlayer}
        onHashtagClick={handleHashtagClick}
        shareNotification={shareNotification}
        saveNotification={saveNotification}
        seeMoreText={formatMessage(messages.more)}
        likedByUser={content.likedByUser}
        ImageContainer={ImageContainer}
      />
    </div>
  )
}

export default injectIntl(ContentItem)
