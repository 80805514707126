// @flow
import React from 'react'
import styled from 'styled-components'
import Icon from 'michelangelo/dist/SharedComponents/DataDisplay/Icons/IconComponent'
import color from 'michelangelo/dist/Components/styles/color'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Row from 'michelangelo/dist/WebComponents/Layout/Row'
import Column from 'michelangelo/dist/WebComponents/Layout/Column'
import ContentCaption from 'michelangelo/dist/SharedComponents/ContentCard/ContentCaption'
import ReactAddToCalendar from './addCalendar'
import moment from 'moment'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../i18n/messages'

const CalendarDetailWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  background-color: ${(props) => props.fromNewsFeed ? 'rgba(0,0,0,.6)' : 'transparent'};
`
const Modal = styled.div`
  position: absolute;
  overflow: auto;
  width: 500px;
  height: 325px;
  left: calc(50% - 500px/2);
  top: calc(50% - 302px/2 + 0.5px);
  background: ${color.white};
  box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.1);
  align-items: center;
`
// TODO: find a way not to have this hardcoded
const AddToCalendarContainer = styled.div`
  margin-left: 91px;
`

const TextContainer = styled.div`
  padding: 16px 16px 8px 16px;
`
const TimeContainer = styled.div`
  padding: 0px 16px 16px 16px;
`
const CloseDiv = styled.div`
  display: flex;
  background-color: ${color.white};
  padding: 16px 16px 16px 16px;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  cursor: pointer;
`
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 8px;
`
const Image = styled.img`
  min-width: 100%;
`
type DetailsModalProps = {
  show: ?boolean,
  onClose: Function,
  event: ?Object,
  formatMessage: ?Function,
  fromNewsFeed?: boolean
} & InjectIntlProvidedProps
function DetailsModal (props: DetailsModalProps) {
  const { show, onClose, event, fromNewsFeed, intl: { formatMessage } } = props
  const showHideClassName = show ? 'block' : 'none'

  const handleOnCloseEvent = () => {
    /**
     * Allows to scroll through news feed/calendar
     */
    if (document.documentElement) document.documentElement.classList.remove('modal-open')
    onClose()
  }

  if (!event) return null

  const formattedEvent = {
    title: fromNewsFeed ? event.fullTitle : event.extendedProps.fullTitle,
    description: fromNewsFeed ? event.notes : event.extendedProps.notes,
    location: '',
    startTime: moment(event.start).toString(),
    endTime: moment(event.end).toString()
  }

  return (
    <CalendarDetailWrapper fromNewsFeed={fromNewsFeed} style={{ display: showHideClassName }}>
      <Row classNames={['is-marginless']}>
        <Column classNames={['is-paddingless', 'is-12']}>
          <Modal>
            <Row classNames={['is-marginless']}>
              <Column classNames={['is-paddingless', 'is-11']}>
                <TextContainer>
                  <Text text={fromNewsFeed ? event.fullTitle : event.extendedProps.fullTitle} size='p' fontColor={color.grey800} />
                </TextContainer>
              </Column>
              <Column classNames={['is-paddingless', 'is-1']}>
                <CloseDiv onClick={handleOnCloseEvent}>
                  <Icon iconName='x' size={24}/>
                </CloseDiv>
              </Column>
            </Row>
            <Row classNames={['is-marginless']}>
              <Column classNames={['is-paddingless', 'is-6']}>
                <TimeContainer>
                  <Text text={moment(event.start).format(event.allDay ? 'MMMM Do YYYY' : 'MMMM Do YYYY, h:mm:ss a')} fontColor={color.grey400} textSize='h6' />
                  <Text text={moment(event.allDay ? event.end.setDate(event.end.getDate() - 1) : event.end).format(event.allDay ? 'MMMM Do YYYY' : 'MMMM Do YYYY, h:mm:ss a')} fontColor={color.grey400} textSize='h6' />
                </TimeContainer>
              </Column>
              <Column classNames={['is-paddingless']}>
                <AddToCalendarContainer>
                  <ReactAddToCalendar formatMessage={props.formatMessage} event={formattedEvent}/>
                </AddToCalendarContainer>
              </Column>
            </Row>
            <Row classNames={['is-marginless']}>
              <Column classNames={['is-paddingless']}>
                <ImageContainer>
                  <Image src={fromNewsFeed ? event.signedThumbnailUrl : event.extendedProps.signedThumbnailUrl} />
                </ImageContainer>
              </Column>
            </Row>
            <Row classNames={['is-marginless']}>
              <Column classNames={['is-paddingless', 'is-12']}>
                <TextContainer>
                  <ContentCaption captionText={fromNewsFeed ? event.notes : event.extendedProps.notes} moreText={formatMessage(messages.more)} fontColor={color.grey800} />
                </TextContainer>
              </Column>
            </Row>
          </Modal>
        </Column>
      </Row>
    </CalendarDetailWrapper>
  )
}
export default injectIntl(DetailsModal)
