// @flow
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import TopBar from 'michelangelo/dist/WebComponents/Navigation/TopBar'
import NavigationBar from 'michelangelo/dist/WebComponents/Navigation/NavigationBar'
import useDimensions from 'michelangelo/dist/Components/helpers/WebDimension'
import color from 'michelangelo/dist/Components/styles/color'
import { useNavigate, useLocation } from 'react-router-dom'
import { readFromCache, getMeFromCache, writeToCache } from '../../apollo/cacheHelper'
import { useApolloClient, useQuery } from '@apollo/client'
import GridList from './GridList'
import AlertList from './Alerts'
import { useAlerts } from '../../Helpers/AlertHelper'
import { buildAlfredUrl } from '../../Helpers/AlfredHelper'
import UrlHelper from '../../Helpers/urlHelper'
import { decodedAccessToken } from '../../Helpers/DecodedAccessToken'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../../i18n/messages'
import { includes } from 'lodash'
import GET_LOGO_URL_QUERY from '../../apollo/queries/getLogoUrl'
import SearchBar from './SearchBar'
import SimpleList from 'michelangelo/dist/SharedComponents/Lists/SimpleList'
import { analyticsUrl, luciusUrl, cmsHost, luciusAccessAccountIds } from '../../config'
import { riffraff } from '../../apollo/client'
import { trackEventsAndUserActivity } from '../../Helpers/segmentHelper'
import { getProfilePhoto, isAccountAdmin, isAppProfileAdmin, isContentPublisher } from '../../Helpers/UserInfoUtil'
import GET_APPS_QUERY from '../../apollo/queries/apps'
import GET_GRID_QUERY from '../../apollo/queries/getGridByAccountId'
import { EXPLORE_FOLDERS } from '../../apollo/queries/getExplorer'

const Container = styled.div`
  flex: 1;
  flex-direction: column;
`

const PageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${(props) => props.isMobile
  ? `
    padding-top: 56px;
    padding-bottom: 54px`
  : `
    padding-top: ${props.gridEnabled ? '3.5rem' : '110px'}`}
`

const TopBarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 400;
`

const NavigationBarContainer = styled.div`
  position: fixed;
  ${(props) => props.isMobile ? 'bottom: 0;' : `top: ${props.isHidden ? 5 : 56}px; `}
  left: 0;
  right: 0;
  z-index: 0;
  transition: top .3s ease;
  -webkit-transition: top .3s ease;
  -moz-transition: top .2s ease;
  -ms-transition: top .2s ease;
`

TopBar.displayName = 'TopBar'
NavigationBar.displayName = 'NavigationBar'
PageContainer.displayName = 'PageContainer'

const userProfileKeys = {
  MY_PROFILE: 'myProfile',
  CHANGE_EMAIL: 'changeEmail',
  CHANGE_PASSWORD: 'changePassword',
  LOGOUT: 'logout'
}

const adminLinkKeys = {
  APP_MANAGEMENT: 'appManagement',
  ANALYTICS: 'analytics',
  CONTENT_MANAGEMENT: 'contentManagement',
  SUPPORT_SITE: 'supportSite'
}

const navigationRoutes = {
  NEWS_FEED: 'newsfeed',
  EXPLORE: 'explore',
  DIRECTORY: 'directory',
  CALENDAR: 'calendar'
}

type AppNavigatorProps = {
  showFullDisplay: boolean,
  me: Object,
  children: any
} & InjectIntlProvidedProps

const AppNavigator = ({ showFullDisplay, me, children, intl: { formatMessage } }: AppNavigatorProps) => {
  const [navigationBarHidden, setNavigationBarHidden] = useState(false)
  const [brandLogoUrl, setBrandLogoUrl] = useState(null)
  const [brandHomeUrl, setBrandHomeUrl] = useState('/')
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769)
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const subDomain = UrlHelper.getSubDomain()
  // get window width on Resize
  const [containerRef, { width }] = useDimensions()

  const client = useApolloClient()
  const navigate = useNavigate()
  const location = useLocation()
  const topBarRef = useRef(null)
  const lastScrollPosition = useRef(window.pageYOffset)
  const accountId = me.memberships[0].account._id

  const onNavigationTabClick = (tab) => navigate(`/${activeCfp._id}/${tab.webUrl}`)

  // $FlowFixMe[prop-missing]
  const { getMeRequest: { hasPassword } } = getMeFromCache(client)
  const { activeCfp, alerts } = readFromCache(client, ['activeCfp', 'alerts'])
  const [numberOfAlerts, setNumberOfAlerts] = useState(0)
  const [isShaking, setIsShaking] = useState(false)
  const { logoutUri, exp } = decodedAccessToken()

  const insertIf = (condition, ...elements: Object) => condition ? elements : []

  /**
   * Show or hide search bar
   */
  const [isShowing, setShowing] = useState(false)
  const [updateAlertData, { newAlerts }] = useAlerts(activeCfp._id, alerts)
  const [gridEnabled, setGridEnabled] = useState(false)
  const { contentFeedProfiles, account } = me.memberships[0]

  const { data } = useQuery(GET_LOGO_URL_QUERY, {
    variables: { cfpId: activeCfp._id },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })

  const { data: gridData } = useQuery(GET_GRID_QUERY, {
    variables: { accountId },
    fetchPolicy: 'cache-and-network'
  })

  const { data: foldersData, loading: loadingFolders } = useQuery(EXPLORE_FOLDERS(),
    {
      variables: {
        cfpId: activeCfp._id,
        sort: 'asc',
        sortField: 'priority'
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  )

  const navigationTabs = [
    { label: formatMessage(messages.newsFeed), icon: 'newsFeed', webUrl: navigationRoutes.NEWS_FEED, isSelected: false },
    { label: formatMessage(messages.explore), icon: 'folder', webUrl: navigationRoutes.EXPLORE, isSelected: false },
    ...insertIf(account.directoryEnabled, { label: formatMessage(messages.directory), icon: 'users', webUrl: navigationRoutes.DIRECTORY, isSelected: false }),
    { label: formatMessage(messages.calendar), icon: 'event', webUrl: navigationRoutes.CALENDAR, isSelected: false }
  ]

  const [allApps, setAllApps] = useState([])

  const { data: apps } = useQuery(GET_APPS_QUERY, {
    variables: {
      isMobile: false,
      os: 'web'
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })

  /** Update Apps */
  useEffect(() => {
    const allApps = apps?.getApps || []
    setAllApps(allApps)
  }, [apps])

  useEffect(() => {
    if (!loadingFolders && foldersData && foldersData.getExplorerFolders) {
      writeToCache(client, { folders: foldersData.getExplorerFolders })
    } else if (loadingFolders) {
      writeToCache(client, { folders: null })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foldersData, loadingFolders, activeCfp._id])
  /**  Check if grid flag is enabled to render the new UI */
  useEffect(() => {
    if (gridData && gridData.getGridByAccountId) {
      setGridEnabled(gridData.getGridByAccountId.enabled)
    }
  }, [gridData])

  const checkAccess = () => {
    const currentTime = new Date().getTime() / 1000
    if (exp < currentTime) {
      handleLogout()
    }
  }

  /** Get Current Route from url and set it to NavigationBar */
  useEffect(() => {
    checkAccess()
    const currentRouteName = location?.pathname.split('/')[2]
    const currentCfpId = location?.pathname.split('/')[1]
    const currentCfp = contentFeedProfiles && contentFeedProfiles.find((item) => item._id === currentCfpId)

    if (currentCfp) {
      (currentCfp._id !== activeCfp._id) && writeToCache(client, { activeCfp: currentCfp })
    } else {
      currentRouteName && navigate(`/${activeCfp._id}/${currentRouteName}`, { replace: true })
    }

    const currentIndex = navigationTabs.map(tab => tab.webUrl).indexOf(currentRouteName)
    setCurrentTabIndex(currentIndex)
    setBrandHomeUrl(`/${activeCfp._id}`)
    // eslint-disable-next-line
  }, [location])

  const handleChangeEmailOrPassword = (path: string) => {
    /** Support for mobile use */
    const w = window.innerWidth > 500 ? 500 : 375
    const h = 600
    const top = window.top.outerHeight / 2 + window.top.screenY - (h / 2)
    const left = window.top.outerWidth / 2 + window.top.screenX - (w / 2)
    const alfredUrl = buildAlfredUrl(path, subDomain, false, hasPassword)

    /** Opens up a new window with alfred in center */
    window.open(alfredUrl, '', `toolbar=no,location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=${w}, height=${h}, top=${top}, left=${left}`)
  }

  const handleLogout = async () => {
    if (logoutUri) {
      return handleSsoLogout()
    }

    try {
      await riffraff.logout() // call the riffraff logout function to kill the cookie for the users browser
    } catch (err) {
      console.error('riffraff logout failed') // this shouldn't ever fail, but in case it does, cookie is invalid anyway, so catch error and move on
    }
    await trackEventsAndUserActivity('Logout') // track the logout with segment
    localStorage.clear() // wipe local storage
    window.location.href = buildAlfredUrl(null, subDomain) // redirect to Alfred
  }

  const handleSsoLogout = async () => {
    if (!logoutUri) return
    try {
      await riffraff.logout() // call the riffraff logout function to kill the cookie for the users browser
    } catch (err) {
      console.error('riffraff logout failed') // this shouldn't ever fail, but in case it does, cookie is invalid anyway, so catch error and move on
    }
    await trackEventsAndUserActivity('Logout') // track the logout with segment
    localStorage.clear() // wipe local storage
    window.location.href = logoutUri
  }

  /** USER PROFILE OPTIONS CLICK */
  const onUserOptionClick = ({ label, item }) => {
    switch (item.key) {
      case userProfileKeys.MY_PROFILE : navigate(`/${activeCfp._id}/my-profile`)
        break
      case userProfileKeys.CHANGE_EMAIL : handleChangeEmailOrPassword('change-email')
        break
      case userProfileKeys.CHANGE_PASSWORD : handleChangeEmailOrPassword('change-password')
        break
      case userProfileKeys.LOGOUT : handleLogout()
        break
      default : alert(`clicked ${label}`)
    }
  }

  const onAdminLinksClick = (label, item) => {
    switch (item.key) {
      case adminLinkKeys.CONTENT_MANAGEMENT : {
        const hasDot = includes(subDomain, '.')
        let newSubDomain = subDomain
        if (hasDot) {
          newSubDomain = subDomain.split('.')[0]
        }
        window.open(`https://${newSubDomain}.${cmsHost}`, '_blank')
        break
      }
      case adminLinkKeys.APP_MANAGEMENT : {
        window.open(`${luciusUrl}/?subdomain=${subDomain}&accountId=${accountId}`, '_blank')
        break
      }
      case adminLinkKeys.ANALYTICS : {
        window.open(`${analyticsUrl}?access_token=${localStorage.getItem('accessToken') || ''}`, '_blank')
        break
      }
      case adminLinkKeys.SUPPORT_SITE : {
        window.open('https://support.theemployeeapp.com/')
        break
      }
      default : alert(`clicked ${label}`)
    }

    closeIconsDropdown()
  }

  const closeIconsDropdown = () => {
    // call closeDropdown function from TopBar
    topBarRef.current && topBarRef.current.closeDropdown()
  }

  const onScroll = () => {
    const currentScrollPosition = window.pageYOffset

    if (currentScrollPosition > lastScrollPosition.current) { // SCROLLING DOWN
      setNavigationBarHidden(currentScrollPosition > 54)
    } else if (currentScrollPosition < lastScrollPosition.current) { // SCROLLING UP
      setNavigationBarHidden(false)
    }

    lastScrollPosition.current = currentScrollPosition
  }

  /** Add Scroll Event Listener */
  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => { window.removeEventListener('scroll', onScroll) }
  }, [])

  /** Update Profile Logo */
  useEffect(() => {
    const logoUrl = data && data.getLogoUrl && data.getLogoUrl.logoUrl
    setBrandLogoUrl(logoUrl)
  }, [data])

  /** check for Mobile width when window is resizing */
  useEffect(() => {
    setIsMobile(width < 769)
  }, [width])

  /**
   * This will only run if we get a new number of alerts
   */
  const runShake = () => {
    if (numberOfAlerts > 0) {
      setIsShaking(true)
    } else {
      setIsShaking(false)
    }
  }
  useEffect(() => {
    if (newAlerts !== numberOfAlerts) {
      setNumberOfAlerts(newAlerts)
      runShake()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAlerts])

  const handleAlertClickIcon = async () => {
    /**
     * Update the cache since user clicked onn bell icon
     * Update state so the badge is back to zero
    */
    await updateAlertData({ variables: { _id: activeCfp._id, lastSyncTime: new Date().getTime(), newAlertsNumber: 0 } })
    setNumberOfAlerts(0)
  }

  const { user, readWriteAccess } = decodedAccessToken()
  const isSSOUser = user.provider !== 'local'
  const isContentPublisherUser = isContentPublisher()
  const isAppProfileAdminUser = isAppProfileAdmin()
  const isAdminUser = isAccountAdmin()
  let permissionsUser = false

  if (readWriteAccess) {
    if (readWriteAccess.CALENDAR.length > 0 || readWriteAccess.DIRECTORY.length > 0 || readWriteAccess.GROUP.length > 0) {
      permissionsUser = true
    }
  }
  /**
   * Permission for internal roles for debugging purpose/helping
   */
  const rolesArray = ['admin', 'developer', 'customercare']
  const isSuperAdmin = includes(rolesArray, me.role)

  const hasCpfs = contentFeedProfiles && contentFeedProfiles.length > 0

  const userOptions = [
    { key: userProfileKeys.MY_PROFILE, label: formatMessage(messages.myProfile), icon: 'userAlt' },
    ...insertIf(!isSSOUser, { key: userProfileKeys.CHANGE_EMAIL, label: formatMessage(messages.changeEmail), icon: 'envelope' }),
    { key: userProfileKeys.CHANGE_PASSWORD, label: formatMessage(messages.changePassword), icon: 'lock' },
    { key: userProfileKeys.LOGOUT, label: formatMessage(messages.logout), icon: 'signOut', iconColor: color.danger }
  ]

  const luciusAccountAccess = includes(luciusAccessAccountIds, accountId)
  const adminLinks = [
    ...insertIf(isAdminUser || isContentPublisherUser || isAppProfileAdminUser || isSuperAdmin || permissionsUser, { key: adminLinkKeys.CONTENT_MANAGEMENT, label: formatMessage(messages.contentManagement), icon: 'image', iconColor: color.grey400 }),
    ...insertIf(isSuperAdmin || (luciusAccountAccess && (isAppProfileAdminUser || isAdminUser)), { key: adminLinkKeys.APP_MANAGEMENT, label: formatMessage(messages.appManagement), icon: 'mobile', iconColor: color.grey400 }),
    ...insertIf(isAppProfileAdminUser || isAdminUser || isSuperAdmin, { key: adminLinkKeys.ANALYTICS, label: formatMessage(messages.analytics), icon: 'chartBar', iconColor: color.grey400 }),
    ...insertIf(isAppProfileAdminUser || isAdminUser || isSuperAdmin || isContentPublisherUser, { key: adminLinkKeys.SUPPORT_SITE, label: formatMessage(messages.supportSite), icon: 'questionCircle', iconColor: color.grey400 })

  ]

  const searchObject = { icon: isShowing ? 'x' : 'search', onClick: () => setShowing(!isShowing) }
  const topBarIcons = [
    ...insertIf(isMobile, searchObject),
    ...insertIf(isAdminUser || isAppProfileAdminUser || isContentPublisherUser || isSuperAdmin || permissionsUser, { icon: 'solidUserCrown', component: <SimpleList listItems={adminLinks} hasDividers onItemClick={onAdminLinksClick} /> }),
    ...insertIf(hasCpfs, { icon: 'gripHorizontal', component: <GridList apps={allApps} me={me} closeDropdown={closeIconsDropdown} isMobile={isMobile} /> }),
    { icon: 'solidBell', shake: isShaking, onClick: handleAlertClickIcon, badge: numberOfAlerts, component: <AlertList closeDropdown={closeIconsDropdown} /> }
  ]

  return (
    <Container ref={containerRef}>
      {
        !showFullDisplay && <>
          <TopBarContainer>
            <TopBar
              isSearchOpen={isShowing}
              ref={topBarRef}
              userName={me.name}
              brandLogoUrl={isShowing && isMobile ? null : brandLogoUrl}
              brandHomeUrl={brandHomeUrl}
              appBrandColor={activeCfp.primaryColor}
              SearchBarComponent={<SearchBar me={me} isShowing={!isMobile ? true : isShowing} width={width} />}
              isMobile={isMobile}
              icons={isShowing && isMobile ? [searchObject] : topBarIcons}
              userOptionList={userOptions}
              profilePhoto={getProfilePhoto(me.profilePhoto, 0)}
              onUserOptionClick={onUserOptionClick}
            />
            {!gridEnabled &&
              <NavigationBarContainer isHidden={navigationBarHidden} isMobile={isMobile}>
                <NavigationBar tabList={navigationTabs} isMobile={isMobile} currentSelectedIndex={currentTabIndex} activeColor={activeCfp.primaryColor} onTabClick={onNavigationTabClick} />
              </NavigationBarContainer>
            }
          </TopBarContainer>
        </>
      }
      <PageContainer isHidden={navigationBarHidden} isMobile={isMobile} gridEnabled={gridEnabled}>
        {children}
      </PageContainer>
    </Container>
  )
}

export default injectIntl(AppNavigator)
