import { GET_USERS_FOR_CONTENT } from '../getContentByUser'
import * as Environment from '../../../config'

export async function getUsersForContent (client, contentId, account, searchValue) {
  const result = await client.query({
    query: GET_USERS_FOR_CONTENT,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    context: { uri: `${Environment.theRingUrl}/data` },
    variables: {
      user: {
        contentId,
        account,
        search: searchValue
      }
    }
  })
  return result
}
