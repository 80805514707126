// @flow

import DirectoryWidget from 'michelangelo/dist/WebComponents/NewsFeed/Widgets/DirectoryWidget/DirectoryWidget'
import React, { useEffect, useState } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'
import { injectIntl } from 'react-intl'
import type { InjectIntlProvidedProps } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { readFromCache } from '../../../apollo/cacheHelper'
import directoriesQuery from '../../../apollo/queries/getDirectories'
import { messages } from '../../../i18n/messages'
import { trackDirectoryWidgetEvent } from '../../../Helpers/segmentHelper'

type DirectoryProps = {
} & InjectIntlProvidedProps

const DirectoryComponent = ({
  me,
  intl: { formatMessage }
}: DirectoryProps) => {
  const [directories, setDirectories] = useState([])
  // const [reachedDirectoryEnd, setReachedDirectoryEnd] = useState(false)
  const client = useApolloClient()
  const navigate = useNavigate()
  const { activeCfp } = readFromCache(client, ['activeCfp'])

  const { fetchMore: fetchMoreDirectory, loading: directoryLoading, data: directoryData } = useQuery(directoriesQuery, {
    variables: {
      contentFeedProfile: activeCfp._id,
      offset: 0,
      limit: 10 // setting batch size to 10-15 to ensure we always load a scrollview in the widget
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (directoryLoading) return
    if (!directoryData || !directoryData.directory) return
    setDirectories(directoryData.directory)
  }, [directoryLoading, directoryData, setDirectories])

  const onLoadMore = () => console.log(fetchMoreDirectory)

  // const onLoadMore = () => {
  //   const offset = directories.length
  //   if (reachedDirectoryEnd) return
  //   return fetchMoreDirectory({
  //     variables: {
  //       offset
  //     },
  //     updateQuery: (prev, { fetchMoreResult }) => {
  //       if ((fetchMoreResult.directory).length === 0) setReachedDirectoryEnd(true)
  //       if (!fetchMoreResult) return prev
  //       return Object.assign({}, prev, {
  //         directory: [...prev.directory, ...fetchMoreResult.directory]
  //       })
  //     }
  //   })
  // }

  const handleFooterClick = async () => {
    navigate(`/${activeCfp._id}/directory`)
  }

  const handleDirectoryClick = async (directory: Object) => {
    await trackDirectoryWidgetEvent(directory)
    if (directory && directory._id) {
      navigate(`/${activeCfp._id}/directory/${directory._id}`)
    }
  }

  return (
    me.memberships[0].account.directoryEnabled
      ? <div>
        <DirectoryWidget
          directoryTitle={formatMessage(messages.directory)}
          data={directories}
          onDirectoryClick={handleDirectoryClick}
          onFooterClick={handleFooterClick}
          footerLabel={formatMessage(messages.seeInDirectoryLabel)}
          noDirectoriesMessage={formatMessage(messages.noDirectoryLabel)}
          onLoadMore={onLoadMore}
          loading={directoryLoading}
        />
      </div>
      : null
  )
}
export default injectIntl(DirectoryComponent)
