// @flow
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { readFromCache, writeToCache } from '../../apollo/cacheHelper'
import { useApolloClient, useQuery } from '@apollo/client'
import { injectIntl } from 'react-intl'
import { messages } from '../../i18n/messages'
import type { InjectIntlProvidedProps } from 'react-intl'
import GridSectionList from 'michelangelo/dist/WebComponents/Lists/GridSectionList'
import { sortBy, find, findIndex } from 'lodash'
import { decodedAccessToken } from '../../Helpers/DecodedAccessToken'
import { trackUpcomingNavigationLinks, trackEventsAndUserActivity } from '../../Helpers/segmentHelper'
import { riffraff } from '../../apollo/client'
import { buildAlfredUrl } from '../../Helpers/AlfredHelper'
import UrlHelper from '../../Helpers/urlHelper'
import eventTypes from '../../Helpers/eventTypeHelper'
import { navigationRoutes } from '../../Helpers/NavigationHelper'
import GET_GRID_QUERY from '../../apollo/queries/getGridByAccountId'

type GridListProps = {
  me: Object,
  closeDropdown?: Function,
  isMobile: boolean,
  apps: Array<Object>
} & InjectIntlProvidedProps
const GridList = ({ me, closeDropdown, isMobile, intl: { formatMessage }, apps }: GridListProps) => {
  const insertIf = (condition, ...elements: Object) => condition ? elements : []
  const [gridListData, setGridListData] = useState([])
  const client = useApolloClient()
  const navigate = useNavigate()
  const location = useLocation()
  const [gridEnabled, setGridEnabled] = useState(false)

  const { activeCfp } = readFromCache(client, ['activeCfp'])
  const defaultRoute = (me && me.memberships[0].account.defaultHomeView === 'EXPLORE') ? 'explore' : 'newsfeed'
  const { contentFeedProfiles, account } = me.memberships[0]
  const { logoutUri, exp } = decodedAccessToken()
  const subDomain = UrlHelper.getSubDomain()
  const accountId = me.memberships[0].account._id

  const { data: gridData } = useQuery(GET_GRID_QUERY, {
    variables: { accountId },
    fetchPolicy: 'cache-and-network'
  })

  /**  Check if grid flag is enabled to render the new UI */
  useEffect(() => {
    if (gridData && gridData.getGridByAccountId) {
      setGridEnabled(gridData.getGridByAccountId.enabled)
    }
  }, [gridData])

  /**
   * Only display directory if it is enabled on the account
   */
  const navigationTabs = [
    { label: formatMessage(messages.newsFeed), icon: 'newsFeed', webUrl: navigationRoutes.NEWS_FEED, isSelected: false },
    { label: formatMessage(messages.explore), icon: 'folder', webUrl: navigationRoutes.EXPLORE, isSelected: false },
    ...insertIf(account.directoryEnabled, { label: formatMessage(messages.directory), icon: 'users', webUrl: navigationRoutes.DIRECTORY, isSelected: false }),
    { label: formatMessage(messages.calendar), icon: 'event', webUrl: navigationRoutes.CALENDAR, isSelected: false }
  ]

  const onNavigationTabClick = (linkUrl) => {
    navigate(`/${activeCfp._id}/${linkUrl}`)
    trackUpcomingNavigationLinks({ linkUrl, user: me._id }, eventTypes.UPCOMING_LINK_CLICKED)
    closeDropdown && closeDropdown()
  }

  const onAppProfileClick = (profile) => {
    navigate(`/${profile.cfp._id}/${defaultRoute}`)
    writeToCache(client, { activeCfp: profile.cfp })

    closeDropdown && closeDropdown()
  }

  const checkAccess = () => {
    const currentTime = new Date().getTime() / 1000
    if (exp < currentTime) {
      handleLogout()
    }
  }
  /** Get Current Route from url and set it to NavigationBar */

  useEffect(() => {
    checkAccess()
    const currentRouteName = location?.pathname.split('/')[2]
    const currentCfpId = location?.pathname.split('/')[1]
    const currentCfp = contentFeedProfiles && contentFeedProfiles.find((item) => item._id === currentCfpId)

    if (currentCfp) {
      (currentCfp._id !== activeCfp._id) && writeToCache(client, { activeCfp: currentCfp })
    } else {
      currentRouteName && navigate(`/${activeCfp._id}/${currentRouteName}`, { replace: true })
    }
    // eslint-disable-next-line
  }, [location])

  const handleSsoLogout = async () => {
    if (!logoutUri) return
    try {
      await riffraff.logout() // call the riffraff logout function to kill the cookie for the users browser
    } catch (err) {
      console.error('riffraff logout failed') // this shouldn't ever fail, but in case it does, cookie is invalid anyway, so catch error and move on
    }
    await trackEventsAndUserActivity('Logout') // track the logout with segment
    localStorage.clear() // wipe local storage
    window.location.href = logoutUri
  }

  const handleLogout = async () => {
    if (logoutUri) {
      return handleSsoLogout()
    }

    try {
      await riffraff.logout() // call the riffraff logout function to kill the cookie for the users browser
    } catch (err) {
      console.error('riffraff logout failed') // this shouldn't ever fail, but in case it does, cookie is invalid anyway, so catch error and move on
    }
    await trackEventsAndUserActivity('Logout') // track the logout with segment
    localStorage.clear() // wipe local storage
    window.location.href = buildAlfredUrl(null, subDomain) // redirect to Alfred
  }

  useEffect(() => {
    const currentRouteName = location?.pathname.split('/')[2]
    const navigationRoutesItems = []

    navigationTabs.forEach(({ label, icon, webUrl }) => {
      const newItem = {
        label,
        icon,
        isSelected: !!(webUrl === currentRouteName),
        webUrl
      }
      navigationRoutesItems.push(newItem)
    })

    const navigationSection = {
      title: '',
      onClick: ({ webUrl }) => onNavigationTabClick(webUrl),
      list: navigationRoutesItems
    }

    let appProfiles = []
    if (contentFeedProfiles && contentFeedProfiles.length > 0) {
      appProfiles = contentFeedProfiles.map((item) => ({
        cfp: item,
        label: item.name,
        appBrandColor: item.primaryColor,
        isSelected: activeCfp._id === item._id
      }))
    }

    let appProfilesSection
    /**
       * If we have more then one cfp then the Current CFP first
       * in the list and then alpha order the remainder cfps
       */
    if (appProfiles && appProfiles.length > 0) {
      const currentCfp = find(appProfiles, { isSelected: true })
      const currentCFPIndex = findIndex(appProfiles, currentCfp)
      appProfiles.splice(currentCFPIndex, 1)
      appProfiles = sortBy(appProfiles, 'label')
      appProfiles.unshift(currentCfp)

      appProfilesSection = {
        title: formatMessage(messages.appProfiles),
        list: appProfiles,
        onClick: onAppProfileClick
      }
    }

    let appsSection
    if (apps) {
      appsSection = {
        title: formatMessage(messages.myApps),
        onClick: (app) => window.open(app.webUrl, '_blank'),
        list: [...apps]
      }
    }
    if (gridEnabled) {
      setGridListData([navigationSection, appProfilesSection, appsSection])
    } else {
      setGridListData([appProfilesSection, appsSection])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, activeCfp, apps, location, gridEnabled])

  return (
    <GridSectionList dataList={gridListData} isMobile={isMobile}/>
  )
}

export default injectIntl(GridList)
