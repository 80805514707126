// @flow
import { gql } from '@apollo/client'

const ADD_LIKE_QUERY = gql`
  mutation likeCreation($like: likeInput!) {
    likeCreation(req: $like)
    {
      _id
      totalLikes
      likedByUser
    }
  }`

export const ADD_COMMENT_LIKE_QUERY = gql`
  mutation commentLikeCreation($like: likeInput!) {
  commentLikeCreation(req: $like) {
    _id
    totalLikes
    likedByUser
  }
}`

export default ADD_LIKE_QUERY
