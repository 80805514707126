// @flow

import { readFromCache, writeToCache } from '../apollo/cacheHelper'
import { includes, has } from 'lodash'
import customConfirm from '../utils/confirm'
import { messages } from '../i18n/messages'
import { ApolloClient } from '@apollo/client'

export const checkIsReadAcknowledgement = async (client: ApolloClient<any>, content: Object, formatMessage: Function, me: Object, sendEmail: Function) => {
  const { unlockedContent } = readFromCache(client)
  const unlockedContentEditable = Object.assign({}, unlockedContent)

  if (!content.secure) return true

  if (has(unlockedContentEditable, me._id) && includes(unlockedContentEditable[me._id], content._id)) return true

  try {
    await customConfirm({
      testID: `${content._id}_readack`,
      confirmBody: formatMessage(messages.acknowledge),
      cancelText: formatMessage(messages.cancel),
      agreeText: formatMessage(messages.agree)
    })
    await sendConfirmationEmail(me.memberships[0].account._id, me.email, content.title, sendEmail)
    unlockedContentEditable[me._id] = (has(unlockedContentEditable, me._id)) ? [...unlockedContentEditable[me._id], content._id] : unlockedContentEditable[me._id] = [content._id]
    writeToCache(client, { unlockedContent: unlockedContentEditable })
    return true
  } catch (e) {
    console.error('checkIsReadAcknowledgement error', e)
    return false
  }
}

const sendConfirmationEmail = async (accountId: string, email: string, title: string, sendEmail: Function) => {
  const emailRequest = { variables: { accountId, email, title } }
  return sendEmail(emailRequest)
}
