// @flow
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { readFromCache } from '../../apollo/cacheHelper'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../../i18n/messages'
import { useApolloClient, useQuery } from '@apollo/client'
import AlertListComponent from 'michelangelo/dist/WebComponents/Lists/AlertList'
import GET_ALERTS_QUERY from '../../apollo/queries/getAlerts/query'
import { slice, isEmpty } from 'lodash'
import moment from 'moment'
import AlertItemPopUp from '../Alert'
import { trackAlertOpenedEvent } from '../../Helpers/segmentHelper'

type AlertListProps = {
  closeDropdown?: Function
} & InjectIntlProvidedProps
const AlertList = ({ closeDropdown, intl: { formatMessage } }: AlertListProps) => {
  const [alertListData, setAlertListData] = useState([])
  const [alertClicked, setAlertClicked] = useState(null)

  const client = useApolloClient()
  const navigate = useNavigate()

  const { activeCfp } = readFromCache(client, ['activeCfp'])

  const { data } = useQuery(GET_ALERTS_QUERY, {
    variables: {
      cfpId: activeCfp._id
    },
    fetchPolicy: 'network-only'
  })

  const handleSeeAllClick = () => {
    closeDropdown && closeDropdown()
    navigate(`/${activeCfp._id}/alerts`)
  }

  const handleAlertItemClick = (i) => {
    trackAlertOpenedEvent(alertListData[i])
    if (document.documentElement) document.documentElement.classList.add('modal-open')
    setAlertClicked(alertListData[i])
  }

  /**
   * Reset if the pop up has been closed
   */
  const handleClosedPopUp = () => {
    setAlertClicked(null)
  }

  useEffect(() => {
    let didClose = false
    if (data && data.getAlerts && data.getAlerts.length > 0) {
      const alertList = data.getAlerts.slice().reverse()
      const firstThreeAlerts = slice(alertList, 0, 3)
      const alerts = firstThreeAlerts.map((item) => ({
        _id: item._id,
        sentBy: item.sentBy,
        text: item.message,
        created: `${moment(item.created).format('dddd')}, ${moment(item.created).format('LL')} ${formatMessage(messages.at)} ${moment(item.created).format('LT')}`,
        modified: item.modified ? `${moment(item.modified).format('dddd')}, ${moment(item.modified).format('LL')} ${formatMessage(messages.at)} ${moment(item.modified).format('LT')}` : null
      }))

      !didClose && setAlertListData(alerts)
    }

    return () => { didClose = true }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div>
      <AlertItemPopUp alertData={alertClicked} onClosePopUp={handleClosedPopUp} />
      <AlertListComponent nullMessage={formatMessage(messages.nothing)} buttonText={isEmpty(alertListData) ? formatMessage(messages.gotToAlerts) : formatMessage(messages.seeAll)} onClick={(i) => handleAlertItemClick(i)} buttonOnClick={handleSeeAllClick} title={formatMessage(messages.alerts)} alertObjectArray={alertListData} primaryColor={activeCfp.primaryColor} isTopBar />
    </div>
  )
}

export default injectIntl(AlertList)
