import { gql } from '@apollo/client'

const TOTAL_USER_LIKES = gql`query totalLikesByUserId ($userId: String!) {
  totalLikesByUserId(req: {userId: $userId}){
    count
    __typename
  }
}`

const TOTAL_USER_COMMENTS = gql`query totalCommentsByUserId ($userId: String!) {
  totalCommentsByUserId (req: {userId: $userId}) {
    count
    __typename
  }
}`

export { TOTAL_USER_LIKES, TOTAL_USER_COMMENTS }
