/**
 * Use this to help centralize status's on a track event
 * If a track event has no status default is success
 * See segmentHelper.js for details
 */
const status = {
  FAIL: 'FAIL',
  SUCCESS: 'SUCCESS',
  FAIL_IDENTICAL_NAME: 'FAIL_IDENTICAL_NAME',
  FAIL_NAME_FORMAT: 'FAIL_NAME_FORMAT',
  REQUEST_FAIL: 'REQUEST_FAIL'
}

export default status
