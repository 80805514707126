// @flow

import { alfredUrl, defaultDomain } from '../config'

export const buildAlfredUrl = (path: ?string, subDomain: string, withCallBackUrl: boolean = true, hasPassword: boolean = true): string => {
  let url = alfredUrl
  if (path) url = `${alfredUrl}/${path}`
  let callbackUrl = ''
  if (withCallBackUrl) callbackUrl = `&callbackUrl=https://${subDomain}.${defaultDomain}`
  let routeToAlfredUrl = `${url}?subdomain=${subDomain}${callbackUrl}`
  if (!hasPassword) routeToAlfredUrl += '&hasPassword=false'
  return routeToAlfredUrl
}
