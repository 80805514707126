// @flow

import Column from 'michelangelo/dist/WebComponents/Layout/Column'
import Row from 'michelangelo/dist/WebComponents/Layout/Row'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import GET_GRID_QUERY from '../../apollo/queries/getGridByAccountId'
import GridContainer from './GridContainer'

type GridLayoutType = {
  me: Object,
  children: any,
  content?: any,
}

const GridLayout = ({ me, children, content }: GridLayoutType) => {
  const [gridEnabled, setGridEnabled] = useState(false)
  const accountId = me.memberships[0].account._id
  const { data } = useQuery(GET_GRID_QUERY, {
    variables: { accountId },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (data && data.getGridByAccountId) {
      setGridEnabled(data.getGridByAccountId.enabled)
    }
  }, [data])

  let leftWidgets = data && data.getGridByAccountId && data.getGridByAccountId.widgets.filter(widget => widget.position[0] === 1)
  leftWidgets = Array.isArray(leftWidgets) ? leftWidgets.sort((a, b) => (a.position[1] > b.position[1]) ? 1 : -1) : []

  let rightWidgets = data && data.getGridByAccountId && data.getGridByAccountId.widgets.filter(widget => widget.position[0] === 2)
  rightWidgets = Array.isArray(rightWidgets) ? rightWidgets.sort((a, b) => (a.position[1] > b.position[1]) ? 1 : -1) : []

  return (
    <Row classNames={['is-marginless']}>
      <Column classNames={['is-marginless', 'is-paddingless', 'is-hidden-tablet-only', 'is-hidden-mobile', 'news-feed-side-column']}>
        { gridEnabled &&
        <GridContainer me={me} widgets={leftWidgets} />
        }
      </Column>

      <Column classNames={['is-paddingless', 'is-marginless-desktop', 'is-8-tablet', 'is-6-desktop', 'is-offset-0-desktop', 'is-offset-2-tablet']}>
        {children}
      </Column>

      <Column classNames={['is-marginless', 'is-paddingless', 'is-hidden-tablet-only', 'is-hidden-mobile', 'news-feed-side-column']}>
        { gridEnabled &&
        <GridContainer content={content} me={me} widgets={rightWidgets} />
        }
      </Column>
    </Row>
  )
}

export default GridLayout
