// @flow

import React, { useState } from 'react'
import styled from 'styled-components'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'
import color from 'michelangelo/dist/Components/styles/color'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'

const ConfirmationDialogWrapper = styled.div`
  display: ${props => props.visible ? 'block' : 'none'};
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
`

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: ${(props) => props.width ? props.width : '400'}px;
  height: ${(props) => props.height ? props.height : '150'}px;
  left: ${(props) => props.width ? 'calc(50% - ' + props.width + 'px/2)' : 'calc(50% - 400px/2)'};
  top: ${(props) => props.height ? 'calc(50% - ' + props.height + 'px/2 + 0.5px)' : 'calc(50% - 150px/2 + 0.5px)'};
  background: #FFFFFF;
  box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.1);
  align-items: center;
`

const Footer = styled.div`
  background: #EEF2F6;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 15px;
  justify-content: flex-end;
  height: 70px;
`

const Gap = styled.div`
  width: 15px;
  height: 15px;
`

const BoddyWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  max-width: 100%;
  text-align: center;
`

type ConfirmModalProps = {
  confirmBody: ?string,
  promise: Object,
  testID: string,
  cancelText: string,
  agreeText: string
}

function ConfirmDialog (props: ConfirmModalProps) {
  const { confirmBody, promise, testID, cancelText, agreeText } = props
  const [visible, setVisible] = useState(true)

  const onCancelClicked = () => {
    if (document.documentElement) document.documentElement.classList.remove('modal-open')
    promise.reject(new Error('cancelled'))
    setVisible(false)
  }

  const onConfirmClicked = () => {
    if (document.documentElement) document.documentElement.classList.remove('modal-open')
    promise.resolve(true)
    setVisible(false)
  }

  return (
    <ConfirmationDialogWrapper className='trashAlt' visible={visible}>
      <Modal>
        <BoddyWrapper>
          <Text text={confirmBody} fontWeight="bold" textSize="p"/>
        </BoddyWrapper>
        <Footer>
          <Button testID={`${testID}_cancel`} ariaLabel={ cancelText || 'Cancel'} title={cancelText || 'Cancel'} onClick={onCancelClicked} outline='true' themeColor={color.info}/>
          <Gap />
          <Button testID={`${testID}_confirm`} ariaLabel={ agreeText || 'Agree'} title={ agreeText || 'Agree'} onClick={onConfirmClicked} fill='true' themeColor={color.success} />
        </Footer>
      </Modal>
    </ConfirmationDialogWrapper>
  )
}
export default ConfirmDialog
