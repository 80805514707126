import { gql } from '@apollo/client'

/**
 * Don't add ownerName to the query if it's not enabled
 * Don't add translatedTitle and translatedCaption to the query if it's not enabled
 * @param {bool} displayContentAuthorEnabled
 * @param {bool} shouldTranslate
 */
const GET_RECENT_CONTENTS_BY_USER_ID_QUERY = gql`
  query GetRecentContentByUserId ($userId: String!) {
    getRecentContentsByUserId(userId: $userId)
    {
        _id
        thumbnailS3Key
        s3Key
        contentType
        signedDownloadUrl
        htmlSignedDownloadUrl
        thumbnailUrl
        displayThumbnail
        title
        caption
        secure
        webUrl
        likesEnabled
        commentsEnabled
        mentions {
          id: _id
          name
        }
    }
  }
`

export default GET_RECENT_CONTENTS_BY_USER_ID_QUERY
