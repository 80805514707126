// @flow

import React, { useState } from 'react'
import EventListItem from 'michelangelo/dist/SharedComponents/ListItem/EventListItem'
import { injectIntl } from 'react-intl'
import type { InjectIntlProvidedProps } from 'react-intl'
import DetailsModal from '../../Calendar/modal'
import color from 'michelangelo/dist/Components/styles/color'

type EventProps = {
  event: Object
} & InjectIntlProvidedProps

function Event (props: EventProps) {
  const { intl: { formatMessage }, event } = props
  const [showModal, setShowModal] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)

  const onEventClick = (event: Object) => {
    /**
     * Stops scrolling within news feed
     */
    if (document.documentElement) document.documentElement.classList.add('modal-open')
    const startDateObj = new Date(event.startDate)
    const endDateObj = new Date(event.endDate)
    let startDate = startDateObj.toISOString()
    let endDate = endDateObj.toISOString()
    // If it is allDay event, send to calendar dates without time. Add one day to end date, to display correctly the allDay event (including the last day)
    if (event.allDay) {
      startDate = startDate.slice(0, 10)
      endDateObj.setDate(endDateObj.getDate() + 1)
      endDate = endDateObj.toISOString()
      endDate = endDate.slice(0, 10)
    }

    const newEvent = {
      id: event._id,
      fullTitle: event.title,
      start: startDate,
      end: endDate,
      color: color.info,
      allDay: event.allDay,
      signedThumbnailUrl: event.signedThumbnailUrl || null,
      notes: event.notes
    }

    setSelectedEvent(newEvent)
    setShowModal(true)
  }

  return (
    <div>
      <EventListItem hidePlusButton fromText="From" toText="To" newsFeed eventObject={event} onClickEvent={() => onEventClick(event)} />
      <DetailsModal fromNewsFeed formatMessage={formatMessage} event={selectedEvent} show={showModal} onClose={ () => setShowModal(false) } />
    </div>
  )
}

export default injectIntl(Event)
