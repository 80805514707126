// @flow

import React, { useEffect, useState, useRef } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
// import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
// import bootstrapPlugin from '@fullcalendar/bootstrap'
import { injectIntl } from 'react-intl'
import GET_EVENTS_QUERY from '../apollo/queries/getEvents/getCalendarEvents'
import { useQuery } from '@apollo/client'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'
import color from 'michelangelo/dist/Components/styles/color'
import { getContentIconColor } from 'michelangelo/dist/Components/helpers/color-helper'
import Row from 'michelangelo/dist/WebComponents/Layout/Row'
import Column from 'michelangelo/dist/WebComponents/Layout/Column'
import Checkbox from 'michelangelo/dist/SharedComponents/Inputs/Checkbox'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import { messages } from '../i18n/messages'
import type { InjectIntlProvidedProps } from 'react-intl'
import { removeDuplicates } from '../Helpers/GeneralHelper'
// import '@fullcalendar/core/main.css'
// import '@fullcalendar/daygrid/main.css'
// import '@fullcalendar/bootstrap/main.css'
import DetailsModal from './modal'
import moment from 'moment'

const CalendarPageContainer = styled.div`
  background: ${color.white};
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.08);
  @media (min-width: 768px) {
    margin: 18px 32px;
   }
  height: 100%;
`
const CalendarList = styled.div`
    display: inline-block;
`
const AllCalendarsTitle = styled.div`
  padding-bottom: 14px;
`
const EmptyCalendar = styled.div`
  padding-bottom: 14px;
`
const CalendarListContainer = styled.div`
  padding-left: 35px;
  height: 100%;
  padding-top: 30px;
  @media (max-width: 768px) {
    text-align: center;
   }
`
const CalendarContainer = styled.div`
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.08);
  padding: 30px 16px 36px 16px;
`

// added for testing purposes
CalendarPageContainer.displayName = 'CalendarPageContainer'
CalendarListContainer.displayName = 'CalendarListContainer'
CalendarContainer.displayName = 'CalendarContainer'

type CalendarProps = {
    me: Object
} & InjectIntlProvidedProps

function CalendarPage (props: CalendarProps) {
  const { cfpId } = useParams()
  const [events, setEvents] = useState([])
  const [windowWidth, setWindowWidth] = useState(window.innerWidth) // we use this for displaying long or short day names
  const minimalScreenWidth = 1024
  const { intl: { formatMessage, locale } } = props
  const [showModal, setShowModal] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [calendars, setCalendars] = useState([])
  const colorArray = getContentIconColor('ARRAY')

  const location = useLocation()

  const calendarComponentRef = useRef(null)

  const { loading, data } = useQuery(GET_EVENTS_QUERY, {
    variables: {
      cfpId
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  window.onresize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    const { eventDate, eventId } = location
    if (calendarComponentRef.current && eventDate && eventId) {
      const calendarApi = calendarComponentRef.current.getApi()
      calendarApi.gotoDate(eventDate)

      const selectedEvent = calendarApi.getEventById(eventId)

      if (selectedEvent) {
        onEventDetailClick({ event: selectedEvent })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, calendarComponentRef.current])

  useEffect(() => {
    if (!data || !data.getEvents || !data.getEvents.length) return
    setEvents(data.getEvents) // events list from backend

    const calendarObjectList = data.getEvents.map((event) => { // get calendars from events list
      return ({ id: event.calendar._id, name: event.calendar.title, notes: event.calendar.notes })
    })
    // remove duplicates and set color for each calendar
    const uniqueCalendarList = removeDuplicates(calendarObjectList, 'id').map((calendar, index) => {
      const calendarColor = colorArray[index % colorArray.length]
      return {
        id: calendar.id,
        name: calendar.name,
        color: calendarColor,
        checked: true
      }
    })
    setCalendars(uniqueCalendarList)
    // eslint-disable-next-line
    }, [data])

  if (loading || !data || !data.getEvents) return null

  // build event properties as needed for the calendar
  const eventsForCalendar = events.filter((event) => {
    const calendar = calendars.find(calendar => calendar.id === event.calendar._id)
    if (calendar && !calendar.checked) { return false }
    return true
  }).map((event) => {
    const calendar = calendars.find(calendar => calendar.id === event.calendar._id)
    if (calendar) {
      const startDateObj = new Date(event.startDate)
      const endDateObj = new Date(event.endDate)
      let startDate = startDateObj.toISOString()
      let endDate = endDateObj.toISOString()
      // If it is allDay event, send to calendar dates without time. Add one day to end date, to display correctly the allDay event (including the last day)
      if (event.allDay) {
        startDateObj.setHours(startDateObj.getHours())
        startDate = startDateObj.toISOString().slice(0, 10)
        endDateObj.setHours(endDateObj.getHours())
        endDateObj.setDate(endDateObj.getDate() + 1)
        endDate = endDateObj.toISOString().slice(0, 10)
      }
      let eventTitleToShow = event.title
      if (!event.allDay && event.title.length > 12) { eventTitleToShow = event.title.substring(0, 12).concat('...') }
      if (event.allDay && event.title.length > 17) { eventTitleToShow = event.title.substring(0, 17).concat('...') }

      return {
        id: event._id,
        title: eventTitleToShow,
        fullTitle: event.title,
        start: startDate,
        end: endDate,
        color: calendar.color,
        allDay: event.allDay,
        signedThumbnailUrl: event.signedThumbnailUrl,
        notes: event.notes
      }
    }
    return false
  })

  const onEventDetailClick = ({ event }) => {
    /**
     * Prevent from double scroll when modal is opened
     */
    if (document.documentElement) document.documentElement.classList.add('modal-open')
    setSelectedEvent(event)
    setShowModal(true)
  }

  const changeCalendar = (calendar) => {
    setCalendars(calendars.map(c =>
      c.id === calendar.id
        ? { ...c, checked: !calendar.checked }
        : c)
    )
  }

  const getAllCalendarsCheckBox = () => {
    if (calendars.length > 0) {
      return calendars.map((calendar, index) => {
        if (calendar) {
          return (<Checkbox key={calendar.id} testID={calendar.id} disabled={false} isChecked={calendar.checked} label={calendar.name} customLabelColor={calendar.color} onChange={() => changeCalendar(calendar)}/>)
        }
        return null
      })
    }
    return null
  }

  const allCalendarsCheckBoxList = getAllCalendarsCheckBox()
  const getWeekNames = (date) => {
    let dayNames = moment.weekdays(true)
    if (windowWidth <= minimalScreenWidth) dayNames = moment.weekdaysShort(true)
    return '<div class="calendar-days">' + dayNames[date.getUTCDay()] + '</div>'
  }

  return (
    <CalendarPageContainer>
      <Row classNames={['is-marginless']}>
        <Column classNames={['is-paddingless', 'is-3-desktop', 'is-3-tablet', 'is-12-mobile']}>
          <CalendarListContainer>
            {
              calendars.length > 0
                ? <CalendarList>
                  <AllCalendarsTitle>
                    <Text text={formatMessage(messages.allCalendars)} fontWeight="bold" textSize="h4" />
                  </AllCalendarsTitle>
                  { allCalendarsCheckBoxList }
                </CalendarList>
                : <EmptyCalendar>
                  <Text text={formatMessage(messages.emptyCalendars)} fontWeight="bold" textSize="h4" />
                </EmptyCalendar>
            }
          </CalendarListContainer>
        </Column>
        <Column classNames={['is-paddingless', 'is-9-desktop', 'is-9-tablet', 'is-12-mobile']}>
          <CalendarContainer>
            <FullCalendar
              timeZone='local'
              ref={calendarComponentRef}
              defaultView="dayGridMonth"
              // plugins={[ dayGridPlugin, interactionPlugin, bootstrapPlugin ]}
              plugins={[dayGridPlugin]}
              events={eventsForCalendar}
              header={{
                left: 'prev',
                center: 'title',
                right: 'next'
              }}
              eventLimit={3}
              displayEventTime={false}
              columnHeaderHtml={getWeekNames}
              locale={locale}
              fixedWeekCount={false}
              // eventRender={eventAfterRender}
              // columnHeaderFormat={{ weekday: 'long' }} //it will display always log day names
              // eventTimeFormat={{ hour: '2-digit', minute: '2-digit', meridiem: false, hour12: false }} // like '14:30'
              // eventLimitClick={onMoreEventsClick} // we can use this to build our own events list display
              eventClick={onEventDetailClick}
              // themeSystem='bootstrap'
            />
          </CalendarContainer>
        </Column>
      </Row>
      <DetailsModal formatMessage={formatMessage} event={selectedEvent} show={showModal} onClose={ () => setShowModal(false) } />
    </CalendarPageContainer>
  )
}
export default injectIntl(CalendarPage)
