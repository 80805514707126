import { gql } from '@apollo/client'

const GET_ALERTS_QUERY = gql`query alertsQuery ($cfpId: String!) {
  getAlerts(req:{cfpId: $cfpId}){
    _id
    account
    contentFeedProfile
    created
    message
    owner
    accessGroups
    notifySMS
    notifyPush
    modified
    sentBy
  }
}`

export const GET_TRANSLATED_ALERT_QUERY = gql`query alertQuery ($alertId: String!, $lang: String) {
  getAlertById(req:{ id: $alertId, lang: $lang })
  {
    _id
    message
    translatedMessage
  }
}`

export default GET_ALERTS_QUERY
