import GET_ME_QUERY from '../queries/getMe'

const updateGetMeNameCache = (firstName, lastName) => {
  return async (proxy, { data: { userNameUpdate } }) => {
    const { getMeRequest } = proxy.readQuery({
      query: GET_ME_QUERY
    })

    const updatedUser = { ...getMeRequest, firstName, lastName, name: `${firstName} ${lastName}` }
    proxy.writeQuery({
      query: GET_ME_QUERY,
      data: { getMeRequest: updatedUser }
    })
  }
}

export { updateGetMeNameCache }
