// @flow

import React from 'react'
import CalendarWidget from 'michelangelo/dist/WebComponents/NewsFeed/Widgets/CalendarWidget/CalendarWidget'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import GET_EVENTS_QUERY from '../../../apollo/queries/getEvents/getCalendarEvents'
import { trackCalendarEvents } from '../../../Helpers/segmentHelper'
import { contentLocations } from '../../../Helpers/contentHelper'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

type CalendarWidgetComponentProps = {
    me: Object,
    widget?: Object,
}

const CalendarWidgetComponent = ({ me, widget }: CalendarWidgetComponentProps) => {
  const { cfpId } = useParams()
  const { loading, data } = useQuery(GET_EVENTS_QUERY, {
    variables: { cfpId },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })

  const events = data && data.getEvents ? data.getEvents : []

  const onDateClicked = (data, events) => {
    trackCalendarEvents({ events, user: me._id }, contentLocations.CALENDAR)
  }
  return (
    <div style={{ width: '100%' }}>
      {
        !loading && <Container>
          <CalendarWidget widget={widget} data={events} onDateClicked={onDateClicked} />
        </Container>
      }
    </div>
  )
}

export default CalendarWidgetComponent
