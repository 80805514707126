// @flow

import React, { useRef } from 'react'
import styled from 'styled-components'
import Column from 'michelangelo/dist/WebComponents/Layout/Column'
import Icon from 'michelangelo/dist/SharedComponents/DataDisplay/Icons/IconComponent'
import EmptyScreen from 'michelangelo/dist/Components/EmptyScreen'
import ContentTypePreview from './ContentTypePreview'
import color from 'michelangelo/dist/Components/styles/color'
import ContentCard from '../../NewsFeed/Components/ContentCard'
import { useApolloClient } from '@apollo/client'
import { messages } from '../../i18n/messages'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'

const ContentPreviewContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
`

const Modal = styled.div`
  display: flex;
  height: 100%;
  min-height: 600px;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  ${(props) => props.isFullPreview && `
      flex: 1;
      height: 100%;
  `};
`

const ClosePreviewDiv = styled.div`
  display: flex;
  background-color: ${color.white};
  border-radius: 50%;
  padding: 4px;
  margin-right: 16px;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  cursor: pointer;

  @media (max-width: 1000px) {
    margin-right: 0px;
    margin-bottom: 8px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  max-height: ${(props) => props.maxHeight + 32}px;

  ${(props) => props.isFullPreview && `
    flex: 1;
    height: 100%;
  `};

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    overflow-y: auto;
  }
`

const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;

  ${(props) => props.isFullPreview
? `
    flex: 1;
    height: 100%;`
    : `
    min-height: 500px;
    min-width: 500px;`};
`

const ContentCardContainer = styled.div`
  background: ${color.white};
  border-left: 1px solid ${(props) => props.darkTheme ? color.darkSecondary : color.grey200};
  border-top: 1px solid ${(props) => props.darkTheme ? color.darkSecondary : color.grey200};
  overflow-y: auto;

  ${(props) => props.isFullPreview
? `
      height: 100%;`
    : `
      width: 424px;
      max-height: ${props.maxHeight}px;
  `};
`
const LoadingContainer = styled.div`
  display: flex;
  position: absolute;
  top: ${(props) => props.height ? `${props.height / 2 - 100}px` : '50%'};
  width: 300px;
  height: 200px;
  align-self: center;
  margin: auto;
  border-radius: 4px;
  overflow: auto;
`

// added for testing purposes
ContentPreviewContainer.displayName = 'ContentPreviewContainer'

type ContentPreviewWebProps = {
    me: Object,
    onClose?: Function,
    contentId: string,
    darkTheme?: boolean,
    appBrandColor?: string,
    commentsEnabled?: boolean,
    likesEnabled?: boolean,
    client?: Object,
    contentLocation: String
} & InjectIntlProvidedProps

function ContentPreviewWeb (props: ContentPreviewWebProps) {
  const { me, onCloseClick, loading, height, maxImageDimensions, isFullPreview, likesEnabled, content, commentsEnabled, appBrandColor, darkTheme, intl: { formatMessage }, contentLocation } = props

  const contentRef = useRef(null)

  const client = useApolloClient()
  const isWebContent = content && content.contentType === 'CONTENT_WEB'

  const onClose = async (e: Event) => {
    e.stopPropagation()
    if (e && contentRef.current && contentRef.current.contains(e.target)) return
    onCloseClick && await onCloseClick(e)
  }

  const getContentPreview = () => {
    if (isWebContent && (likesEnabled || commentsEnabled)) return
    const preview = (
      <PreviewContainer isFullPreview={isFullPreview}>
        <ContentTypePreview content={content} client={client} isFullPreview={isFullPreview} maxImageDimensions={maxImageDimensions} me={me} formatMessage={formatMessage} contentLocation={contentLocation} />
      </PreviewContainer>)

    if (isFullPreview) {
      return (
        <Column classNames={['is-paddingless', 'is-8-fullhd', 'is-7-widescreen', 'is-7-desktop', 'is-10-tablet', 'is-12-mobile']}>
          {preview}
        </Column>
      )
    }

    return preview
  }

  const getContentCard = () => {
    const contentCard = (
      <ContentCardContainer isFullPreview={isFullPreview} maxHeight={maxImageDimensions.height}>
        <ContentCard
          content={content}
          isDarkTheme={darkTheme}
          me={me}
          appBrandColor={appBrandColor}
          likesEnabled={likesEnabled}
          commentsEnabled={commentsEnabled}
          isContentPreview={!isWebContent}
          client={client}
          contentLocation={contentLocation}
        />
      </ContentCardContainer>)
    if (isFullPreview) {
      return (
        <Column classNames={['is-paddingless', 'is-4-fullhd', 'is-5-widescreen', 'is-5-desktop', 'is-10-tablet', 'is-12-mobile']}>
          {contentCard}
        </Column>
      )
    }

    return contentCard
  }
  let loadingView = null
  if (loading) {
    loadingView = (
      <LoadingContainer height={height}><EmptyScreen backgroundColor={null} loading text={`${formatMessage(messages.loading)}...`} iconColor={color.disabled} fontColor={color.darkGray} iconSize={33} /></LoadingContainer>
    )
  }
  return (
    <Column classNames={['is-12', 'is-paddingless']}>
      <Modal onClick={onClose}>

        <Container isFullPreview={isFullPreview}>
          <ClosePreviewDiv onClick={onClose} >
            <Icon iconName='x' size={12}/>
          </ClosePreviewDiv>
          {loadingView}
          <Content ref={contentRef} isFullPreview={isFullPreview} maxHeight={maxImageDimensions.height}>
            {/* display content thumbnail, image, video, etc depending on content_type */}
            {!loading && content && getContentPreview()}

            {!loading && content && getContentCard()}
          </Content>

        </Container>
      </Modal>
    </Column>
  )
}

export default injectIntl(ContentPreviewWeb)
