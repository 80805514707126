import { gql } from '@apollo/client'

const EXPLORE_QUERY = (displayContentAuthorEnabled, webV3 = false) => {
  let ownerName = ''
  if (displayContentAuthorEnabled) {
    ownerName = 'ownerName'
  }
  if (webV3) {
    return gql`
  query getExplorer ($cfpId: String!, $folderId: String) {
    getExplorer (req: { cfpId: $cfpId, folderId: $folderId, officeDocSupport: true, displayContentAuthorEnabled: ${displayContentAuthorEnabled}}) {
      ... on Content {
        _id
        owner
        ${ownerName}
        account
        title
        created
        modified
        includeInFeed
        accessGroups
        thumbnailS3Key
        mediumThumbnailUrl
        largeThumbnailUrl
        xlargeThumbnailUrl
        displayThumbnail
        publishDate
        webUrl
        s3Key
        share
        postDate
        postTimezone
        posted
        notesEnabled
        contentType
        totalLikes
        totalComments
        totalViews
        secure
        contentFolder
        hlsVideoPlaylistUrl
        likesEnabled
        commentsEnabled
        caption
        published
        likedByUser
        signedDownloadUrl
        htmlSignedDownloadUrl
        disableSaving
        mentionsEnabled
        usersMentioned
        mentions {
          id: _id
          name
        }
        hashtags
      }
      ... on ContentFolder {
        _id
        title
        parentId
        priority
      }
    }
  }
`
  }
  return gql`
  query getExplorer ($cfpId: String!, $folderId: String) {
    getExplorer (req: { cfpId: $cfpId, folderId: $folderId, officeDocSupport: true, displayContentAuthorEnabled: ${displayContentAuthorEnabled}}) {
      ... on Content {
        _id
        owner
        ${ownerName}
        ownerPhoto {
            _32px
            _64px
            _128px
          }
        account
        title
        created
        modified
        modifiedBy
        notify
        includeInFeed
        accessGroups
        integrationId
        integrationType
        thumbnailS3Key
        smallThumbnailUrl
        mediumThumbnailUrl
        largeThumbnailUrl
        xlargeThumbnailUrl
        thumbnailJobId
        thumbnailJobStatus
        displayThumbnail
        publishDate
        webUrl
        s3Key
        tags
        share
        postDate
        unpostDate
        postTimezone
        posted
        notesEnabled
        cacheable
        contentType
        totalLikes
        totalComments
        totalViews
        secure
        contentFolder
        info
        hlsVideoPlaylistUrl
        transcodeVideoStatus
        likesEnabled
        commentsEnabled
        caption
        published
        likedByUser
        signedDownloadUrl
        htmlSignedDownloadUrl
        disableSaving
        mentionsEnabled
        usersMentioned
        mentions {
          id: _id
          name
        }
        hashtags
        priority
      }
      ... on ContentFolder {
        _id
        title
        parentId
        priority
      }
    }
  }
`
}

export const EXPLORE_CONTENTS = (displayContentAuthorEnabled = true) => {
  let ownerName = ''
  if (displayContentAuthorEnabled) {
    ownerName = 'ownerName'
  }
  return gql`
  query getExplorerContents($cfpId: String!, $folderId: String, $limit: Int, $offset: Int, $search: String, $sort: String, $sortField: String) {
    getExplorerContents(cfpId: $cfpId, folderId: $folderId, limit: $limit, offset: $offset, search: $search, sort: $sort, sortField: $sortField) {
        _id
        owner
        ${ownerName}
        account
        title
        created
        modified
        includeInFeed
        accessGroups
        thumbnailS3Key
        mediumThumbnailUrl
        largeThumbnailUrl
        xlargeThumbnailUrl
        displayThumbnail
        publishDate
        webUrl
        s3Key
        share
        postDate
        postTimezone
        posted
        notesEnabled
        contentType
        totalLikes
        totalComments
        totalViews
        secure
        contentFolder
        hlsVideoPlaylistUrl
        likesEnabled
        commentsEnabled
        caption
        published
        likedByUser
        signedDownloadUrl
        htmlSignedDownloadUrl
        disableSaving
        mentionsEnabled
        usersMentioned
        mentions {
          id: _id
          name
        }
        hashtags
    }
  }
`
}

export const EXPLORE_FOLDERS = () => {
  return gql`
  query getExplorerFolders($cfpId: String!, $folderId: String, $limit: Int, $offset: Int, $search: String, $sort: String, $sortField: String) {
    getExplorerFolders(cfpId: $cfpId, folderId: $folderId, limit: $limit, offset: $offset, search: $search, sort: $sort, sortField: $sortField) {
        _id
        title
        parentId
        priority
        isHomeFolder
        totalContents
        folderIcon
    }
  }
`
}
export default EXPLORE_QUERY
