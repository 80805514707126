export const removeDuplicates = (arr, prop) => {
  const obj = {}
  return Object.keys(arr.reduce((prev, next) => {
    if (!obj[next[prop]]) obj[next[prop]] = next
    return obj
  }, obj)).map((i) => obj[i])
}

export const isIE = () => {
  const ua = navigator.userAgent
  /* MSIE used to detect old browsers and Trident used to newer ones */
  const isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1

  return isIE
}
