// @flow

import React from 'react'
import styled from 'styled-components'

const OverlayPageWrapper = styled.div`
  display: ${props => props.visible ? 'block' : 'none'};
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
`
// add for testing purpose
OverlayPageWrapper.displayName = 'OverlayPageWrapper'

type OverlayPageProps = {
  visible: boolean
}

function OverlayPage (props: OverlayPageProps) {
  const { visible } = props
  return (
    <OverlayPageWrapper visible={visible}/>
  )
}

export default OverlayPage
