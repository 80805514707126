import React, { useEffect, useState } from 'react'
import SessionTimeOutModal from './SessionTimoutWarning'
import handleLogout from '../Helpers/logoutHelper'

export const LogoutPopup = () => {
  const foursHours = 4 * 60 * 60 * 1000
  const [signoutTime, setSignoutTime] = useState(foursHours) // higgins has 20 min
  const [warningTime, setWarningTime] = useState(foursHours - 1500) // 4 hours - 15 sec
  const [showModal, setShowModal] = useState(false)
  const [counter, setCounter] = useState(15)

  let warnTimeout
  let logoutTimeout

  useEffect(() => {
    counter > 0 && showModal && setTimeout(() => setCounter(counter - 1), 1000)
  }, [counter, showModal])

  /**
   * Modal to show time almost ending
   */
  const warn = () => {
    // add timer countdown
    setShowModal(true)
  }

  /**
   * Run this if they don't do anything
   */
  const logout = async () => {
    await handleLogout()
  }

  const setTimeouts = () => {
    warnTimeout = setTimeout(warn, warningTime)
    logoutTimeout = setTimeout(logout, signoutTime)
  }

  const clearTimeouts = () => {
    setShowModal(false)
    setWarningTime(foursHours - 1500)
    setSignoutTime(foursHours)
    setCounter(15)
    if (warnTimeout) clearTimeout(warnTimeout)
    if (logoutTimeout) clearTimeout(logoutTimeout)
  }

  useEffect(() => {
    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ]

    const resetTimeout = () => {
      clearTimeouts()
      setTimeouts()
    }

    for (const i in events) {
      window.addEventListener(events[i], resetTimeout)
    }

    setTimeouts()

    return () => {
      for (const i in events) {
        window.removeEventListener(events[i], resetTimeout)
        clearTimeouts()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <SessionTimeOutModal showModal={showModal} timerCount={counter} />
}
