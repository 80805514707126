import { gql } from '@apollo/client'

const GET_UPCOMING_EVENTS_WIDGET_QUERY = gql`query GetWidgetUpcomingEventsQuery ($cfpId: String!, $isUpcomingEvent: Boolean) {
  getWidgetUpcomingEvents(req:{cfpId: $cfpId, isUpcomingEvent: $isUpcomingEvent}){
    _id
    account
    created
    title
    eventTimeZone
    startDate
    endDate
    allDay
    notes
    notify
    calendar {
      _id
      title
    }
    thumbnailS3Key
    smallThumbnailUrl
    mediumThumbnailUrl
    largeThumbnailUrl
    xlargeThumbnailUrl
    displayThumbnail
    signedThumbnailUrl
  }
}
`

export default GET_UPCOMING_EVENTS_WIDGET_QUERY
