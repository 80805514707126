// @flow

import RecentFoldersWidgets from 'michelangelo/dist/WebComponents/NewsFeed/Widgets/RecentFoldersWidget/RecentFoldersWidget'
import React from 'react'
import { useApolloClient, useQuery } from '@apollo/client'
import { injectIntl } from 'react-intl'
import type { InjectIntlProvidedProps } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { readFromCache } from '../../../apollo/cacheHelper'
import GET_WIDGET_RECENT_FOLDERS_QUERY from '../../../apollo/queries/widgets/getWidgetRecentFoldersQuery'
import type { WidgetProps } from '../WidgetProps'
import { messages } from '../../../i18n/messages'
import { trackFolderOpenEvent } from '../../../Helpers/segmentHelper'
import UrlHelper from '../../../Helpers/urlHelper'

const RecentFoldersWidgetComponent = ({
  me,
  widget,
  intl: { formatMessage, locale }
}: WidgetProps & InjectIntlProvidedProps) => {
  const client = useApolloClient()
  const navigate = useNavigate()
  const { activeCfp } = readFromCache(client, ['activeCfp'])

  const { data } = useQuery(GET_WIDGET_RECENT_FOLDERS_QUERY, {
    variables: {
      cfpId: activeCfp && activeCfp._id,
      folderId: activeCfp.homeFolder._id
    },
    fetchPolicy: 'cache-and-network'
  })
  const contentFolders = data && data.getWidgetRecentFoldersQuery ? data.getWidgetRecentFoldersQuery : []

  const handleFooterClick = () => {
    navigate(`/${activeCfp._id}/explore`)
  }

  const handleFolderClick = (folder: Object) => {
    trackFolderOpenEvent({ ...folder, location: 'Recent Folders' })
    const params = UrlHelper.buildExploreUrlParams(folder._id)
    navigate({ pathname: `/${activeCfp._id}/explore`, search: params }, { folder: folder._id })
  }
  return (
    <div>
      <RecentFoldersWidgets
        emptyResultsLabel={formatMessage(
          messages.folderWidgetEmptyResultsLabel
        )}
        data={contentFolders}
        headerTitle={formatMessage(messages.folderWidgetHeaderTitle)}
        footerLabel={formatMessage(messages.folderWidgetFooterLabel)}
        onFooterClick={handleFooterClick}
        onFolderClick={handleFolderClick}
      />
    </div>
  )
}

export default injectIntl(RecentFoldersWidgetComponent)
