// @flow
import { gql } from '@apollo/client'

const GET_ALERT_DATA = gql` 
 query GetAlertData {
    alerts @client
 }`

const updateAlertData = (_: Object, { _id, lastSyncTime, newAlertsNumber }: {_id: string, lastSyncTime: Number, newAlertsNumber: number}, { client }: Object) => {
  const queryResult = client.readQuery({ query: GET_ALERT_DATA })

  if (queryResult) {
    const { alerts } = queryResult

    const foundAlertData = alerts.find(item => item._id === _id)

    if (foundAlertData) {
      if (foundAlertData.newAlertsNumber !== newAlertsNumber || foundAlertData.lastSyncTime < lastSyncTime) {
        const newAlertsDataArray = alerts.map((item) => (item._id === _id) ? { ...item, lastSyncTime, newAlertsNumber } : item)

        client.writeQuery({ query: GET_ALERT_DATA, data: { alerts: newAlertsDataArray } })
      }
    } else {
      const data = {
        alerts: [
          ...alerts,
          { _id, lastSyncTime, newAlertsNumber, __typename: 'AlertData' }
        ]
      }

      client.writeQuery({ query: GET_ALERT_DATA, data })
    }
    return queryResult.alerts
  }
  return []
}

export default { updateAlertData }
