// @flow
import React, { useEffect, useState } from 'react'
import directoryEntriesQuery from '../apollo/queries/getDirectoryEntries'
import { useApolloClient, useQuery } from '@apollo/client'
import type { InjectIntlProvidedProps } from 'react-intl'
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { readFromCache } from '../apollo/cacheHelper'
import { onLoad } from '../Helpers/segmentHelper'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import { messages } from '../i18n/messages'
// import { isEmpty, debounce } from 'lodash'
import { debounce } from 'lodash'

import color from 'michelangelo/dist/Components/styles/color'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import SearchInput from './Components/SearchInput'
import Contacts from './Contacts'
import { usePrevious, useWindowSize } from '../utils/hooks'
import Icon from 'michelangelo/dist/SharedComponents/DataDisplay/Icons/IconComponent'
import { getLanguageTagFromLocale } from '../Helpers/TranslationHelper'

const getWidthByNrOfColumns = (cols: number) => {
  return ((cols / 12) * 100).toFixed(6)
}

const ContactsContainer = styled.div`
  background: ${color.grey50};
  position: fixed;
  width: inherit;
  @media screen and (max-width: 767.98px) {
    width: 100%;
    background: ${color.white};
    position: relative;
    display: ${props => props.visible ? 'block' : 'none'};
  }
  @media screen and (min-width: 768px) {
    width: ${getWidthByNrOfColumns(5)}%;
  }
  @media screen and (min-width: 992px) {
    width: ${getWidthByNrOfColumns(4)}%;
  }
`
ContactsContainer.displayName = 'ContactsContainer'

const SearchContainer = styled.div`
  padding: 16px;
  box-shadow: inset 0px -1px 0px ${color.lightestGrey};
`
SearchContainer.displayName = 'SearchContainer'

const ContactDetailsContainer = styled.div`
  background: ${color.white};
  min-height: 100vh;
  @media screen and (max-width: 767.98px) {
    width: 100%;
    display: ${props => props.visible ? 'block' : 'none'};
    margin-left: 0;
  }
  @media screen and (min-width: 768px) {
    width: ${getWidthByNrOfColumns(7)}%;
    margin-left: ${getWidthByNrOfColumns(5)}%;
  }
  @media screen and (min-width: 992px) {
    width: ${getWidthByNrOfColumns(8)}%;
    margin-left: ${getWidthByNrOfColumns(4)}%;
  }
`
ContactDetailsContainer.displayName = 'ContactDetailsContainer'

const DirectoryLink = styled.a`
  display: flex;
  align-items: center;
`
DirectoryLink.displayName = 'DirectoryLink'

const DirectoryLinkContainer = styled.div`
  display: flex;
`

const TextContainer = styled.div`
  padding-top: 1px;
`

type DirectoryEntryProps = {
  me: Object
} & InjectIntlProvidedProps

function DirectoryEntry (props: DirectoryEntryProps) {
  const client = useApolloClient()
  // const location = useLocation()
  // const path = location.pathname
  const url = window.location.href
  const { directoryId, entryId } = useParams()
  const navigate = useNavigate()
  const size = useWindowSize()

  const { me, intl: { formatMessage, locale } } = props

  const [directoryEntries, setDirectoryEntries] = useState([])
  // const [reachedDirectoryEnd, setReachedDirectoryEnd] = useState(false)
  const [searchText, setSearchText] = useState('')
  const prevSearchText = usePrevious(searchText)
  const [selectedContact, setSelectedContact] = useState(null)

  const { activeCfp } = readFromCache(client, ['activeCfp'])

  // query for the directory entries
  const { fetchMore, loading, data } = useQuery(directoryEntriesQuery, {
    variables: {
      directoryId,
      offset: 0,
      limit: 30, // increasing batch size to 30 to ensure we always load a scrollview on smaller devices
      search: '',
      locale: getLanguageTagFromLocale(locale)
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (loading) return
    if (!data || !data.directoryEntry) return
    setDirectoryEntries(data.directoryEntry)
  }, [loading, data, setDirectoryEntries])

  useEffect(() => {
    // set first contact as selected only for tablet and above screen sizes
    if (directoryEntries.length && !entryId && size.width >= 768) {
      navigate(`entry/${directoryEntries[0]._id}`)
    }
  }, [directoryEntries, entryId, size, navigate, url])

  // tracks the directoryView to segment
  useEffect(() => {
    onLoad(client, me, 'DirectoryEntry')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onLoadMore = () => []
  // const onLoadMore = () => {
  //   const offset = directoryEntries.length
  //   if (reachedDirectoryEnd) return
  //   return fetchMore({
  //     variables: {
  //       offset,
  //       search: searchText,
  //       locale: getLanguageTagFromLocale(locale)
  //     },
  //     updateQuery: (prev, { fetchMoreResult }) => {
  //       if (isEmpty(fetchMoreResult.directoryEntry)) setReachedDirectoryEnd(true)
  //       if (!fetchMoreResult) return prev
  //       return Object.assign({}, prev, {
  //         directoryEntry: [...prev.directoryEntry, ...fetchMoreResult.directoryEntry]
  //       })
  //     }
  //   })
  // }

  const searchDirectoryEntries = async (searchText) => {
    await fetchMore({
      variables: {
        offset: 0,
        search: searchText,
        locale: getLanguageTagFromLocale(locale)
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return []
        const directoryEntry = fetchMoreResult.directoryEntry
        setDirectoryEntries(directoryEntry)
        if (size.width >= 768 && directoryEntry.length > 0) {
          navigate(`entry/${directoryEntry[0]._id}`)
        }
        return {
          directoryEntry: [...fetchMoreResult.directoryEntry]
        }
      }
    })
  }

  const delayedSetSearchText = debounce(text => {
    if (prevSearchText === undefined) return
    searchDirectoryEntries(text)
    return setSearchText(text)
  }, 500)
  const handleSearchInputSearch = (text) => {
    delayedSetSearchText(text)
  }

  const handleContactsOnItemClick = (item) => {
    setSelectedContact(item)
    navigate(`entry/${item._id}`)
  }

  return (
    <div>
      <ContactsContainer visible={!selectedContact}>
        <SearchContainer>
          <DirectoryLinkContainer>
            <DirectoryLink href={`${activeCfp._id}/directory`} onClick={(e) => {
              e.preventDefault()
              navigate(`/${activeCfp._id}/directory`)
            }}>
              <Text text={formatMessage(messages.directory)} fontColor={color.grey300} textTransform="uppercase" textSize="th"/>
            </DirectoryLink>
            <Icon iconName="rightArrow" size={16} color={activeCfp.primaryColor}/>
            <TextContainer>
              <Text text={formatMessage(messages.contacts)} fontColor={color.grey500} textTransform="uppercase" textSize="th"/>
            </TextContainer>
          </DirectoryLinkContainer>
          <SearchInput loading={loading} onChange={handleSearchInputSearch} placeholder={formatMessage(messages.search)} />
        </SearchContainer>
        <Contacts onLoadMore={onLoadMore} loading={loading} items={directoryEntries} appBrandColor={activeCfp.primaryColor} selectedItem={selectedContact} onItemClick={handleContactsOnItemClick}/>
      </ContactsContainer>
      <ContactDetailsContainer visible={selectedContact}>
        <Outlet context={{ directoryEntries, setSelectedContact, item: selectedContact }} />
      </ContactDetailsContainer>
    </div>
  )
}

export default injectIntl(DirectoryEntry)
