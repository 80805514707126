// @flow
import { includes, toLower } from 'lodash'
import { trackOpenAddressEvent } from './segmentHelper'

const excludedKeys = ['company', 'title']
const addressKeysOrdered = ['address1', 'address2', 'address3', 'city', 'state', 'zip', 'country']

const getLists = (directoryEntry: Object) => {
  const phoneList = []
  const emailList = []
  const moreList = []
  const addressList = []
  if (directoryEntry.contactInfo) {
    for (const [key, value] of Object.entries(directoryEntry.contactInfo)) {
      if (!value || typeof value !== 'object') {
        continue
      }
      if (!value.key) {
        (value: any).key = toLower(key)
      }
      if (value.tag === 'phone') {
        phoneList.push({ ...value })
      } else if (value.tag === 'email') {
        emailList.push({ ...value })
      } else if (value.tag === 'other' && includes(addressKeysOrdered, toLower(key))) {
        addressList.push({ ...value })
      } else if (value.tag === 'other' && !includes(excludedKeys, toLower(key))) {
        moreList.push({ ...value })
      }
    }
  }

  return { phoneList, emailList, moreList, addressList }
}

const generateAddressString = (addressList: Array<Object>) => {
  let address = ''
  const sortedAddressList = addressList.slice().sort(function (a, b) {
    return addressKeysOrdered.indexOf(toLower(a.key)) - addressKeysOrdered.indexOf(toLower(b.key))
  })
  for (const { value } of sortedAddressList) {
    address += value.replace(/ /g, '+') + '+'
  }
  return address
}

const openAddress = async (addressList: Array<Object>, directoryEntry: Object) => {
  const address = generateAddressString(addressList)
  await openGoogleMaps(address, directoryEntry)
}

const openGoogleMaps = async (address: string, directoryEntry: Object) => {
  const directoryEntryId = directoryEntry._id ? directoryEntry._id : ''
  const directoryEntryName = directoryEntry.name ? directoryEntry.name : ''
  const addressOpenedProperties = {
    address,
    directoryEntryId,
    directoryEntryName
  }
  await trackOpenAddressEvent(addressOpenedProperties)
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${address}`
  window.open(googleMapsUrl, '_blank')
}

export default { openAddress, openGoogleMaps, getLists }
