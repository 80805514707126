// @flow
import { gql } from '@apollo/client'

const GET_APPS_QUERY = gql`
  query getApps($isMobile: Boolean!, $os: String) {
    getApps(req:{isMobile:$isMobile, os: $os}) {
      label: appName
      webUrl
      imageUrl: logoUrl
      openInMobileApp
      openInWebBrowser
      isEnabled
    }
  }
`

export default GET_APPS_QUERY
