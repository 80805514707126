import jwtDecode from 'jwt-decode'
import { messages } from '../i18n/messages'

const getAdminType = () => {
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    const decodedToken = jwtDecode(accessToken)
    const adminType = decodedToken ? decodedToken.adminType : 'NONE'
    if (adminType) {
      return adminType
    }
  }
  return 'NONE'
}

export const isAccountAdmin = () => {
  const adminType = getAdminType()
  return adminType === 'ACCOUNT_ADMIN'
}

export const isAppProfileAdmin = () => {
  const adminType = getAdminType()
  return adminType === 'APP_PROFILE_ADMIN'
}

export const isContentPublisher = () => {
  const adminType = getAdminType()
  return adminType === 'CONTENT_PUBLISHER'
}

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
export const getRoleType = (formatMessage: Function) => {
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    const decodedToken = jwtDecode(accessToken)
    const adminType = decodedToken.adminType
    let role = formatMessage(messages.user)
    switch (adminType) {
      case 'ACCOUNT_ADMIN' : role = formatMessage(messages.accountAdmin)
        break
      case 'APP_PROFILE_ADMIN' : role = formatMessage(messages.appProfileAdmin)
        break
      case 'CONTENT_PUBLISHER' : role = formatMessage(messages.contentPublisher)
        break
      case 'NONE' : role = null
        break

      default : role = formatMessage(messages.accountAdmin)
    }
    return role
  }
  return formatMessage(messages.user)
}

/**
 * @param photo
 * @param {Number} quality      Accept value 0,1,2. 0 => lowest quality; 1 => medium; 2 => high
 */
export const getProfilePhoto = (photo, quality = 0) => {
  try {
    const profilePhoto = Object.assign({}, photo)
    if (!profilePhoto) return null
    if (profilePhoto.__typename) delete profilePhoto.__typename
    const imagesArray = Object.values(profilePhoto).filter(n => n)
    const length = imagesArray.length
    if (length === 1) {
      return imagesArray[0]
    } else if (length > 1) {
      switch (quality) {
        case 0:
          break
        case 1:
          return imagesArray[Math.round(length / 2)]
        case 2:
          return imagesArray[length - 1]
        default:
          break
      }
    }
    return imagesArray[0]
  } catch (e) {
    return null
  }
}
