// @flow
import React, { useEffect, useState } from 'react'
import directoriesQuery from '../apollo/queries/getDirectories'
import directoryEntriesAllQuery from '../apollo/queries/getDirectoryEntriesAll'
import { useApolloClient, useQuery } from '@apollo/client'
import type { InjectIntlProvidedProps } from 'react-intl'
import { Outlet, useNavigate } from 'react-router-dom'
import { readFromCache } from '../apollo/cacheHelper'
import { onLoad } from '../Helpers/segmentHelper'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import { messages } from '../i18n/messages'
import { debounce } from 'lodash'
// import { isEmpty, debounce } from 'lodash'

import color from 'michelangelo/dist/Components/styles/color'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import SearchInput from './Components/SearchInput'
import List from './List'
import { usePrevious, useWindowSize } from '../utils/hooks'
import Icon from 'michelangelo/dist/Components/Icon'
import Contacts from '../DirectoryEntry/Contacts'
import { getLanguageTagFromLocale } from '../Helpers/TranslationHelper'

const getWidthByNrOfColumns = (cols: number) => {
  return ((cols / 12) * 100).toFixed(6)
}

const DirectoryContainer = styled.div`
  background: ${color.grey50};
  position: fixed;
  width: inherit;
  @media screen and (max-width: 767.98px) {
    width: 100%;
    background: ${color.white};
    position: relative;
    display: ${props => props.visible ? 'block' : 'none'};
  }
  @media screen and (min-width: 768px) {
    width: ${getWidthByNrOfColumns(5)}%;
  }
  @media screen and (min-width: 992px) {
    width: ${getWidthByNrOfColumns(4)}%;
  }
`
DirectoryContainer.displayName = 'DirectoryContainer'

const SearchContainer = styled.div`
  padding: 16px;
  box-shadow: inset 0px -1px 0px ${color.lightestGrey};
`
SearchContainer.displayName = 'SearchContainer'

const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  background: ${color.white};
  min-height: 100vh;
  @media screen and (max-width: 767.98px) {
    width: 100%;
    display: ${props => props.visible ? 'block' : 'none'};
    margin-left: 0;
  }
  @media screen and (min-width: 768px) {
    width: ${getWidthByNrOfColumns(7)}%;
    margin-left: ${getWidthByNrOfColumns(5)}%;
  }
  @media screen and (min-width: 992px) {
    width: ${getWidthByNrOfColumns(8)}%;
    margin-left: ${getWidthByNrOfColumns(4)}%;
  }
`
Empty.displayName = 'Empty'

const IconContainer = styled.div`
  margin: 16px 0px;
`
IconContainer.displayName = 'IconContainer'

const TitleContainer = styled.div`
  margin: 8px 0px;
`
TitleContainer.displayName = 'TitleContainer'

const ContactDetailsContainer = styled.div`
  background: ${color.white};
  min-height: 100vh;
  @media screen and (max-width: 767.98px) {
    width: 100%;
    display: ${props => props.visible ? 'block' : 'none'};
    margin-left: 0;
  }
  @media screen and (min-width: 768px) {
    width: ${getWidthByNrOfColumns(7)}%;
    margin-left: ${getWidthByNrOfColumns(5)}%;
  }
  @media screen and (min-width: 992px) {
    width: ${getWidthByNrOfColumns(8)}%;
    margin-left: ${getWidthByNrOfColumns(4)}%;
  }
`
ContactDetailsContainer.displayName = 'ContactDetailsContainer'

type DirectoryProps = {
  me: Object
} & InjectIntlProvidedProps

function Directory (props: DirectoryProps) {
  const client = useApolloClient()
  const url = window.location.href
  const navigate = useNavigate()
  const size = useWindowSize()

  const { me, intl: { formatMessage, locale } } = props
  const [directories, setDirectories] = useState([])
  const [directoryEntries, setDirectoryEntries] = useState([])
  // const [reachedDirectoryEnd, setReachedDirectoryEnd] = useState(false)
  // const [reachedDirectoryEntriesEnd, setReachedDirectoryEntriesEnd] = useState(false)
  const [searchText, setSearchText] = useState('')
  const prevSearchText = usePrevious(searchText)
  const [selectedDirectory, setSelectedDirectory] = useState(null)
  const [selectedContact, setSelectedContact] = useState(null)

  const { activeCfp } = readFromCache(client, ['activeCfp'])

  // eslint-disable-next-line
  const { fetchMore: fetchMoreDirectory, loading: directoryLoading, data: directoryData } = useQuery(directoriesQuery, {
    variables: {
      contentFeedProfile: activeCfp._id,
      offset: 0,
      limit: 30, // increasing batch size to 30 to ensure we always load a scrollview on smaller devices
      locale: getLanguageTagFromLocale(locale)
    },
    fetchPolicy: 'cache-and-network'
  })
  // query for the directory entries all
  const { fetchMore: fetchMoreDirectoryEntries, loading: directoryEntryAllLoading, data: directoryEntryAllData } = useQuery(directoryEntriesAllQuery, {
    variables: {
      contentFeedProfile: activeCfp._id,
      offset: 0,
      limit: 30, // increasing batch size to 30 to ensure we always load a scrollview on smaller devices
      search: '',
      locale: getLanguageTagFromLocale(locale)
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (directoryLoading) return
    if (!directoryData || !directoryData.directory) return
    setDirectories(directoryData.directory)
  }, [directoryLoading, directoryData, setDirectories])

  useEffect(() => {
    if (directoryEntryAllLoading) return
    if (!directoryEntryAllData || !directoryEntryAllData.directoryEntryAll) return
    setDirectoryEntries(directoryEntryAllData.directoryEntryAll)
  }, [directoryEntryAllLoading, directoryEntryAllData, setDirectoryEntries])

  // tracks the directoryView to segment
  useEffect(() => {
    onLoad(client, me, 'Directory')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onLoadMore = () => console.log('loading more')

  // const onLoadMore = () => {
  //   const offset = searchText === '' ? directories.length : directoryEntries.length
  //   if (searchText === '' && reachedDirectoryEnd) return
  //   if (searchText !== '' && reachedDirectoryEntriesEnd) return
  //   return searchText === '' ? fetchMoreDirectory({
  //     variables: {
  //       offset
  //     },
  //     updateQuery: (prev, { fetchMoreResult }) => {
  //       if (isEmpty(fetchMoreResult.directory)) setReachedDirectoryEnd(true)
  //       if (!fetchMoreResult) return prev
  //       return Object.assign({}, prev, {
  //         directory: [...prev.directory, ...fetchMoreResult.directory]
  //       })
  //     }
  //   }) : fetchMoreDirectoryEntries({
  //     variables: {
  //       offset,
  //       search: searchText
  //     },
  //     updateQuery: (prev, { fetchMoreResult }) => {
  //       if (isEmpty(fetchMoreResult.directoryEntryAll)) setReachedDirectoryEntriesEnd(true)
  //       if (!fetchMoreResult) return prev
  //       return Object.assign({}, prev, {
  //         directoryEntryAll: [...prev.directoryEntryAll, ...fetchMoreResult.directoryEntryAll]
  //       })
  //     }
  //   })
  // }

  const searchDirectoryEntries = async (searchText) => {
    await fetchMoreDirectoryEntries({
      variables: {
        offset: 0,
        search: searchText,
        locale: getLanguageTagFromLocale(locale)
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return []
        const directoryEntryAll = fetchMoreResult.directoryEntryAll
        setDirectoryEntries(directoryEntryAll)
        if (directoryEntryAll.length > 0) {
          const regex = /search/g
          if (size.width >= 768) {
            if (!url.match(regex)) {
              navigate(`search/entry/${directoryEntryAll[0]._id}`)
            } else {
              navigate(`entry/${directoryEntryAll[0]._id}`)
            }
          } else {
            if (!url.match(regex)) {
              navigate('search')
            }
          }
        }
        return {
          directoryEntryAll: [...directoryEntryAll]
        }
      }
    })
  }

  const delayedSetSearchText = debounce(text => {
    if (prevSearchText === undefined) return
    searchDirectoryEntries(text)
    return setSearchText(text)
  }, 500)
  const handleSearchInputSearch = (text) => {
    delayedSetSearchText(text)
  }

  const handleDirectoriesOnItemClick = (item) => {
    setSelectedDirectory(item)
    navigate(`${item._id}`)
  }

  const handleContactsOnItemClick = (item) => {
    setSelectedContact(item)
    navigate(`entry/${item._id}`)
  }

  return (
    <div>
      <DirectoryContainer visible={!selectedContact && !selectedDirectory}>
        <SearchContainer>
          <Text text={formatMessage(messages.directory)} textSize="th" textTransform="uppercase" fontColor={color.grey500}/>
          <SearchInput loading={directoryEntryAllLoading} onChange={handleSearchInputSearch} placeholder={formatMessage(messages.searchAllUsers)} cfpId={activeCfp._id} />
        </SearchContainer>
        {searchText === '' && (
          <List onLoadMore={onLoadMore} loading={directoryLoading} items={directories} appBrandColor={activeCfp.primaryColor} selectedItem={selectedDirectory} onItemClick={handleDirectoriesOnItemClick}/>
        )}

        {searchText !== '' && (
          <Contacts onLoadMore={onLoadMore} loading={directoryEntryAllLoading} items={directoryEntries} appBrandColor={activeCfp.primaryColor} selectedItem={selectedContact} onItemClick={handleContactsOnItemClick} />
        )}
      </DirectoryContainer>

      {searchText === '' && (
        <Empty>
          <IconContainer>
            <Icon color={color.lightGrey} iconName='info' size="3x"/>
          </IconContainer>
          <TitleContainer>
            <Text text={formatMessage(messages.chooseDirectory)} fontWeight="bold" textSize="h5" fontColor={color.darkGray}/>
          </TitleContainer>
        </Empty>
      )}

      {searchText !== '' && (
        <ContactDetailsContainer visible={selectedContact}>
          <Outlet context={{ directoryEntries, setSelectedContact }} />
        </ContactDetailsContainer>
      )}
    </div>
  )
}

export default injectIntl(Directory)
