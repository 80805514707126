import { defaultDomain, environment, alfredUrl } from '../config'
export default {
  getCallbackUrl: function (subdomainWithDot) {
    if (!subdomainWithDot) {
      subdomainWithDot = window.location.hostname.split(new RegExp(defaultDomain))[0]
    }
    // Developer hacks for subdomain and callbackUrl
    if (subdomainWithDot === 'localhost') {
      return null
    } else {
      return 'https://' + subdomainWithDot.substring(0, subdomainWithDot.length - 1) + '.' + defaultDomain
    }
  },
  getSubDomainWithDot: function () {
    return window.location.hostname.split(new RegExp(defaultDomain))[0]
  },
  getSubDomain: function () {
    const subDomainWithDot = this.getSubDomainWithDot()
    if (subDomainWithDot === 'localhost' || environment === 'development') { // for localhost and serenity.beta we should add subdomain as a query param in url
      const queryParams = window.location.search
      return queryParams.split('?subdomain=')[1]
    } else {
      return subDomainWithDot.substring(0, subDomainWithDot.length - 1)
    }
  },
  buildAlfredUrl: function () {
    const subDomainWithDot = this.getSubDomainWithDot()
    const subdomain = this.getSubDomain(subDomainWithDot)
    const callbackUrl = this.getCallbackUrl(subDomainWithDot)
    if (callbackUrl && subdomain) {
      return alfredUrl + '/?subdomain=' + subdomain + '&callbackUrl=' + callbackUrl
    }
    return null
  },
  buildExploreUrlParams: (folder = null, folderSortField = 'priority', folderSortType = 'asc', contentSortField = 'priority', contentSortType = 'asc') => {
    const data = { }
    if (folder) data.activeFolder = folder
    if (folderSortField && folderSortField !== 'priority') {
      data.folderSortField = folderSortField
      data.folderSortType = folderSortType
    }
    if (contentSortField && contentSortField !== 'priority') {
      data.contentSortField = contentSortField
      data.contentSortType = contentSortType
    }
    const res = []
    for (const d in data) {
      res.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
    }
    return '?' + res.join('&')
  },
  useQueryParams: (search) => new Proxy(new URLSearchParams(search), {
    get: (searchParams, prop) => searchParams.get(prop)
  })
}
