import { gql } from '@apollo/client'

/**
 * Don't add ownerName to the query if it's not enabled
 * @param {bool} displayContentAuthorEnabled
 */
const getMostPopularQuery = (displayContentAuthorEnabled) => {
  let ownerName = ''
  if (displayContentAuthorEnabled) {
    ownerName = 'ownerName'
  }
  return gql`
    query getMostPopular ($cfpId: String!, $displayContentAuthorEnabled: Boolean) {
      getMostPopular (req: { cfpId: $cfpId, displayContentAuthorEnabled: $displayContentAuthorEnabled }) {
        _id
        owner
        ${ownerName}
        account
        title
        created
        modified
        modifiedBy
        notify
        includeInFeed
        accessGroups
        integrationId
        integrationType
        thumbnailS3Key
        smallThumbnailUrl
        mediumThumbnailUrl
        largeThumbnailUrl
        xlargeThumbnailUrl
        thumbnailJobId
        thumbnailJobStatus
        displayThumbnail
        publishDate
        webUrl
        s3Key
        tags
        share
        postDate
        unpostDate
        postTimezone
        posted
        notesEnabled
        cacheable
        contentType
        totalLikes
        totalComments
        totalViews
        secure
        contentFolder
        info
        hlsVideoPlaylistUrl
        transcodeVideoStatus
        likesEnabled
        commentsEnabled
        caption
        published
        likedByUser
        signedDownloadUrl
        htmlSignedDownloadUrl
      }
    }
    `
}

export default getMostPopularQuery
