import { gql } from '@apollo/client'

const directoryEntriesAllQuery = gql`
query getDirectoryEntriesAll ($contentFeedProfile: String!, $limit: Int!, $offset: Int!, $search: String!, $locale: String) {
  directoryEntryAll: getDirectoryEntriesAll(req: {contentFeedProfile: $contentFeedProfile, limit: $limit, offset: $offset, search: $search, locale: $locale} ) {
    _id,
    name,
    contactInfo
    directoryContactPhoto {
      _32px
      _64px
      _128px
      _256px
      _512px
    }
    originalDirectoryContactPhoto
  }
}`

export default directoryEntriesAllQuery
