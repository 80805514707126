// @flow
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import RecentContentWidget from 'michelangelo/dist/WebComponents/NewsFeed/Widgets/RecentContentWidget/RecentContentWidget'
import color from 'michelangelo/dist/Components/styles/color'
import { contentLocations } from '../../../Helpers/contentHelper'
import { trackContentOpened, trackRecentContentWidget } from '../../../Helpers/segmentHelper'
import { checkIsReadAcknowledgement } from '../../../Helpers/ReadAcknowledgement'
import { goToContentPreview, goToOldContentPreview } from '../../../Helpers/contentPreviewHelper'
import SEND_EMAIL_QUERY from '../../../apollo/queries/sendEmail'
import GET_RECENT_CONTENTS_BY_USER_ID_QUERY from '../../../apollo/queries/widgets/getRecentContentsByUserId'
import eventTypes from '../../../Helpers/eventTypeHelper'
import { messages } from '../../../i18n/messages'
import { readFromCache } from '../../../apollo/cacheHelper'
import GET_GRID_QUERY from '../../../apollo/queries/getGridByAccountId'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TitleContainer = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: ${color.grey500};
`

type RecentContentWidgetComponentProps = {
    me: Object
}

const SLIDE_DELAY_TIME = 10000

const RecentContentWidgetComponent = ({ me }: RecentContentWidgetComponentProps) => {
  const { formatMessage } = useIntl()
  const client = useApolloClient()
  const { activeCfp } = readFromCache(client)
  const accountId = me.memberships[0].account._id

  const [gridEnabled, setGridEnabled] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  const { data, loading } = useQuery(GET_RECENT_CONTENTS_BY_USER_ID_QUERY, {
    variables: { userId: me._id },
    fetchPolicy: 'cache-and-network'
  })

  const { data: gridData } = useQuery(GET_GRID_QUERY, {
    variables: { accountId },
    fetchPolicy: 'cache-and-network'
  })

  /**  Check if grid flag is enabled to render the new UI */
  useEffect(() => {
    if (gridData && gridData.getGridByAccountId) {
      setGridEnabled(gridData.getGridByAccountId.enabled)
    }
  }, [gridData])

  const formatInputMentions = (content) => {
    const caption = content && content.caption
    if (!caption) return
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    context.font = 'bold 12px sans-serif'

    const matches = caption.match(/@[a-z\d]{24}/g) || []

    let newCaption = caption
    if (matches != null) {
      for (const match of matches) {
        const userId = match.substr(1)
        const user = content.mentions.find(u => u.id === userId)
        if (user) {
          newCaption = newCaption.replace(match, '[' + user.name + '](' + userId + ')')
        } else {
          newCaption = newCaption.replace(match, '[Deleted User]()')
        }
      }
    }
    return newCaption
  }

  const contents = data && data.getRecentContentsByUserId ? data.getRecentContentsByUserId.map(c => { return { ...c, caption: formatInputMentions(c) } }) : []

  const [, setShowOverlay] = useState(false)

  const contentLocation = contentLocations.NEWSFEED

  const [sendEmail] = useMutation(SEND_EMAIL_QUERY)

  const checkIsRead = async (content) => {
    setShowOverlay(true)
    const isRead = await checkIsReadAcknowledgement(client, content, formatMessage, me, sendEmail)
    setShowOverlay(false)
    return isRead
  }

  const handleDotClick = async (content) => {
    await trackRecentContentWidget({ ...content, contentId: content._id, user: me._id }, client, eventTypes.MY_RECENT_CONTENT_WIDGET_DOT_CLICKED)
  }

  const handleHashtagClick = (hashtag) => {
    const formatted = hashtag && hashtag.slice(1)
    navigate({
      pathname: `/${activeCfp._id}/hashtags/${formatted}`
    })
  }

  const handleItemClick = async (content) => {
    const isRead = await checkIsRead(content)
    // if was cancelled
    if (!isRead) return
    await trackRecentContentWidget({ ...content, contentId: content._id, user: me._id }, client, eventTypes.MY_RECENT_CONTENT_WIDGET)
    await trackContentOpened({ ...content, contentLocation: contentLocations.RECENT_CONTENT }, client)
    if (content.contentType === 'CONTENT_WEB' && !content.likesEnabled && !content.commentsEnabled) {
      let urlToOpen
      if (content.webUrl) urlToOpen = content.webUrl
      else urlToOpen = content.htmlSignedDownloadUrl ? content.htmlSignedDownloadUrl : content.signedDownloadUrl
      window.open(urlToOpen)
    } else {
      if (gridEnabled) {
        const contentFilters = { type: 'desc', field: 'modified' }
        const folderFilters = { type: 'asc', field: 'title' }
        goToContentPreview(navigate, { cfpId: activeCfp._id, content, location, contentLocation, contents: [] },
          { contentFilters, folderFilters })
      } else {
        goToOldContentPreview(navigate, client, { content, location, contentLocation })
      }
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <TitleContainer>
        <label>{formatMessage(
          messages.recentContentsWidgetHeaderLabel
        )}</label>
      </TitleContainer>
      {!loading &&
            <Container>
              <RecentContentWidget
                onHashtagClick={handleHashtagClick}
                onItemClick={handleItemClick}
                onDotClicked={handleDotClick}
                data={contents}
                imageWidth={230}
                imageHeight={126}
                carouselWidth={230}
                carouselHeight={270}
                textWidth={230}
                textHeight={80}
                iconColor={color.info}
                aspectRatio={15 / 9}
                slideDelayTime={SLIDE_DELAY_TIME}
                moreText='More'
                emptyText={formatMessage(
                  messages.recentContentsWidgetEmptyResultsLabel
                )}
              />
            </Container>
      }
    </div>
  )
}

export default RecentContentWidgetComponent
