import URL from 'url'
/**
 * This file is where we should be defining and exporting all environment variable.  process.env should NOT be called anywhere else in the code base.
 * If you need to access an environment variable, declare and export it here.
 *
 * If you are developing locally, set these variables in your .env file.
 * example:
 *
 * .env
 *
 * REACT_APP_RIFFRAFF_PROTOCOL=http
 * REACT_APP_RIFFRAFF_PORT=3030
 * REACT_APP_RIFFRAFF_HOST=192.168.95.163
 */

export const riffraffProtocol = process.env.REACT_APP_RIFFRAFF_PROTOCOL
export const riffraffPort = process.env.REACT_APP_RIFFRAFF_PORT
export const riffraffHost = process.env.REACT_APP_RIFFRAFF_HOST
export const riffraffUrl = URL.format({ protocol: riffraffProtocol, hostname: riffraffHost, port: riffraffPort })
export const globalSearchLimit = process.env.REACT_APP_GLOBAL_SEARCH_LIMIT || 10
export const theRingProtocol = process.env.REACT_APP_THE_RING_PROTOCOL
export const theRingPort = process.env.REACT_APP_THE_RING_PORT
export const theRingHost = process.env.REACT_APP_THE_RING_HOST
export const theRingUrl = URL.format({ protocol: theRingProtocol, hostname: theRingHost, port: theRingPort }) // 'https://gateway.beta.theemployeeapp.com'
export const hermesUrl = process.env.REACT_APP_HERMES_URL

export const cmsHost = process.env.REACT_APP_CMS_HOST || 'admin.beta.theemployeeapp.com'

export const _accessToken = process.env.REACT_APP_ACCESS_TOKEN
export const environment = process.env.NODE_ENV || 'development'
export const alfredUrl = process.env.REACT_APP_ALFRED_URL || 'https://login.beta.theemployeeapp.com'
export const luciusUrl = process.env.REACT_APP_LUCIUS_URL || 'https://admin.beta.theemployeeapp.com'
export const analyticsUrl = process.env.REACT_APP_ANALYTICS_URL || 'https://analytics.beta.theemployeeapp.com/access'
export const defaultDomain = process.env.REACT_APP_DEFAULT_DOMAIN || 'theemployeeapp.com'
export const publicUrl = process.env.PUBLIC_URL || 'http://localhost:3000'
export const luciusAccessAccountIds = process.env.REACT_APP_LUCIUS_ACCESS_ACCOUNT_IDS ? process.env.REACT_APP_LUCIUS_ACCESS_ACCOUNT_IDS.split(',') : []
export const pdfLicenseKey = process.env.REACT_APP_PDF_LICENSE_KEY
