// @flow
import { riffraff } from '../apollo/client'
import { trackEventsAndUserActivity } from './segmentHelper'
import { buildAlfredUrl } from './AlfredHelper'
import UrlHelper from './urlHelper'

const handleLogout = async () => {
  const subDomain = UrlHelper.getSubDomain()
  try {
    await riffraff.logout() // call the riffraff logout function to kill the cookie for the users browser
  } catch (err) {
    console.error('riffraff logout failed') // this shouldn't ever fail, but in case it does, cookie is invalid anyway, so catch error and move on
  }
  await trackEventsAndUserActivity('Logout') // track the logout with segment
  localStorage.clear() // wipe local storage
  window.location.href = buildAlfredUrl(null, subDomain) // redirect to Alfred
}

export default handleLogout
