// @flow
import { gql } from '@apollo/client'

const GET_EXPLORE_BANNER_OPTION = gql`
  query getExploreBanner ($subdomain: String!) {
    getApplicationBySubdomain(req: { subdomain: $subdomain }) {
      title
      exploreBannerOption
    }
  }`

export default GET_EXPLORE_BANNER_OPTION
