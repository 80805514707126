import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import helpersClass from './helper'
import { injectIntl } from 'react-intl'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'
import color from 'michelangelo/dist/Components/styles/color'
import styled from 'styled-components'
import { messages } from '../i18n/messages'

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
type ReactAddToCalendarProps = {
  optionsOpen: ?boolean,
  listItems: ?Array,
  rootClass: ?String,
  formatMessage: ?Function
}

const LinkButton = styled.a`
  font: bold 11px Arial;
  text-decoration: none;
  color: #333333;
  padding: 2px 6px 2px 6px;
`

// eslint-disable-next-line new-cap
const helpers = new helpersClass()

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
function ReactAddToCalendar (props: ReactAddToCalendarProps) {
  const [isIE, setisIE] = useState(false)
  const [optionsOpen, setOptionsOpen] = useState(props.optionsOpen)
  const { formatMessage } = props

  useEffect(() => {
    if (!String.prototype.startsWith) {
      // eslint-disable-next-line no-extend-native
      String.prototype.startsWith = (searchString, position) => {
        position = position || 0
        return this.indexOf(searchString, position) === position
      }
    }

    if (
      typeof window !== 'undefined' &&
      window.navigator.msSaveOrOpenBlob &&
      window.Blob
    ) {
      setisIE(true)
    }
  }, [])

  const toggleCalendarDropdown = () => {
    const showOptions = !optionsOpen

    if (showOptions) {
      document.addEventListener('click', toggleCalendarDropdown, false)
    } else {
      document.removeEventListener('click', toggleCalendarDropdown)
    }

    setOptionsOpen(showOptions)
  }

  const handleDropdownLinkClick = (e) => {
    e.preventDefault()
    const url = e.currentTarget.getAttribute('href')

    if (
      !helpers.isMobile() &&
      (url.startsWith('data') || url.startsWith('BEGIN'))
    ) {
      const filename = 'download.ics'
      const blob = new Blob([url], { type: 'text/calendarcharset=utf-8' })

      if (isIE) {
        window.navigator.msSaveOrOpenBlob(blob, filename)
      } else {
        /****************************************************************
        // many browsers do not properly support downloading data URIs
        // (even with 'download' attribute in use) so this solution
        // ensures the event will download cross-browser
        ****************************************************************/
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } else {
      window.open(url, '_blank')
    }

    toggleCalendarDropdown()
  }

  const renderDropdown = () => {
    const items = props.listItems.map(listItem => {
      const currentItem = Object.keys(listItem)[0]
      const currentLabel = listItem[currentItem]

      let icon = null
      if (props.displayItemIcons) {
        const currentIcon =
          currentItem === 'outlook' || currentItem === 'outlookcom'
            ? 'windows'
            : currentItem
        icon = <i className={'fa fa-' + currentIcon} />
      }

      return (
        <li key={helpers.getRandomKey()}>
          <LinkButton
            className={currentItem + '-link'}
            onClick={handleDropdownLinkClick}
            href={helpers.buildUrl(
              props.event,
              currentItem,
              isIE
            )}
            target='_blank'
          >
            {icon}
            {currentLabel}
          </LinkButton>
        </li>
      )
    })

    return (
      <div className={props.dropdownClass}>
        <ul>
          {items}
        </ul>
      </div>
    )
  }

  const renderButton = () => {
    return (
      <Button ariaLabel={formatMessage(messages.addToCalendar)} title={formatMessage(messages.addToCalendar)} textTransform='capitalize' icon='add' iconSize={18} onClick={toggleCalendarDropdown} themeColor={color.blue} />
    )
  }

  let options = null
  if (optionsOpen) {
    options = renderDropdown()
  }

  let addToCalendarBtn = null
  if (props.event) {
    addToCalendarBtn = renderButton()
  }

  return (
    <div className={props.rootClass}>
      {addToCalendarBtn}
      {options}
    </div>
  )
}

export default injectIntl(ReactAddToCalendar)

ReactAddToCalendar.propTypes = {
  buttonClassClosed: PropTypes.string,
  buttonClassOpen: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonTemplate: PropTypes.object,
  buttonIconClass: PropTypes.string,
  useFontAwesomeIcons: PropTypes.bool,
  buttonWrapperClass: PropTypes.string,
  displayItemIcons: PropTypes.bool,
  optionsOpen: PropTypes.bool,
  dropdownClass: PropTypes.string,
  event: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    location: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string
  }).isRequired,
  listItems: PropTypes.arrayOf(PropTypes.object),
  rootClass: PropTypes.string
}

ReactAddToCalendar.defaultProps = {
  buttonClassClosed: 'react-add-to-calendar__button',
  buttonClassOpen: 'react-add-to-calendar__button--light',
  buttonLabel: 'Add to My Calendar',
  buttonTemplate: { caret: 'right' },
  buttonIconClass: 'react-add-to-calendar__icon--',
  useFontAwesomeIcons: true,
  buttonWrapperClass: 'react-add-to-calendar__wrapper',
  displayItemIcons: true,
  optionsOpen: false,
  dropdownClass: 'react-add-to-calendar__dropdown',
  event: {
    title: 'Sample Event',
    description: 'This is the sample event provided as an example only',
    location: 'Portland, OR',
    startTime: '2016-09-16T20:15:00-04:00',
    endTime: '2016-09-16T21:45:00-04:00'
  },
  listItems: [
    { apple: 'Apple Calendar' },
    { google: 'Google' },
    { outlook: 'Outlook' },
    { outlookcom: 'Outlook.com' },
    { office365: 'Office365' },
    { yahoo: 'Yahoo' }
  ],
  rootClass: 'react-add-to-calendar'
}
