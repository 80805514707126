import React from 'react'
import ReactDOM from 'react-dom'
import ConfirmDialog from '../Components/modals/ConfirmDialog'

const customConfirm = (props) => {
  return new Promise((resolve, reject) => {
    const div = document.createElement('div')
    document.body.appendChild(div)
    if (document.documentElement) document.documentElement.classList.add('modal-open')
    const component = React.createElement(ConfirmDialog, Object.assign({}, props, {
      promise: { resolve, reject },
      willUnmount: () => {
        // eslint-disable-next-line react/no-deprecated
        ReactDOM.unmountComponentAtNode(div)
        document.body.removeChild(div)
        document.body.style.removeProperty('overflow')
      }
    }))

    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(component, div)
  })
}

export default customConfirm
