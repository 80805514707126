// @flow
import React, { useState, useEffect, useRef } from 'react'
import { readFromCache } from '../../apollo/cacheHelper'
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client'
import SearchBarComponent from 'michelangelo/dist/WebComponents/Inputs/SearchBar'
import SEARCH_ITEMS_QUERY from '../../apollo/queries/searchItems'
import ListItem from 'michelangelo/dist/WebComponents/ListItem/ListItem'
import styled from 'styled-components'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import color from 'michelangelo/dist/Components/styles/color'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../../i18n/messages'
import { contentLocations } from '../../Helpers/contentHelper'
import { useNavigate, useLocation } from 'react-router-dom'
import debounce from 'lodash/debounce'
import { globalSearchLimit } from '../../config'
import { trackSearchEvent, trackSearchItemClickedEvent } from '../../Helpers/segmentHelper'
import SEARCH_HASHTAGS_QUERY from '../../apollo/queries/hashtags/searchHashtags'
import { isHashtag } from '../../Helpers/hashtagHelper'
import { goToOldContentPreview, goToContentPreview } from '../../Helpers/contentPreviewHelper'
import GET_GRID_QUERY from '../../apollo/queries/getGridByAccountId'

const Empty = styled.div`
  display: flex;
  height: 54px;
  width: 100%;
  align-items: center;
  justify-content: center;
`

SearchBarComponent.displayName = 'SearchBarComponent'
ListItem.displayName = 'ListItem'
Empty.displayName = 'Empty'

const searchFilterKeys = {
  ALL: 'all',
  CONTENT: 'content',
  FOLDER: 'folder',
  EVENT: 'event',
  HASHTAG: 'hashtag'
}
type SearchBarProps = {
    me: Object,
    isShowing: boolean,
    width: number
} & InjectIntlProvidedProps

const MIN_CHARACTER_COUNT = 2

const SearchBar = ({ me, isShowing, intl: { formatMessage }, width }: SearchBarProps) => {
  const [searchValue, setSearchValue] = useState('')
  const [selectedFilterKey, setSelectedFilterKey] = useState(searchFilterKeys.ALL)
  const [searchResults, setSearchResults] = useState([])
  const [allContentLoaded, setAllContentLoaded] = useState(false)
  const [onLoadMore, setOnLoadMore] = useState(false)
  const [offset, setOffset] = useState(0)
  const [gridEnabled, setGridEnabled] = useState(false)

  const searchRef = useRef(null)

  const client = useApolloClient()
  const navigate = useNavigate()
  const location = useLocation()
  const displayContentAuthorEnabled = me.memberships[0].account.displayContentAuthorEnabled

  const { activeCfp } = readFromCache(client, ['activeCfp'])
  const accountId = me.memberships[0].account._id

  const [getSearchItems, searchItemsResult] = useLazyQuery(SEARCH_ITEMS_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  const [getHashtagItems, searchHashtagsResult] = useLazyQuery(SEARCH_HASHTAGS_QUERY(true), {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  const { data: gridData } = useQuery(GET_GRID_QUERY, {
    variables: { accountId },
    fetchPolicy: 'cache-and-network'
  })

  /**  Check if grid flag is enabled to render the new UI */
  useEffect(() => {
    if (gridData && gridData.getGridByAccountId) {
      setGridEnabled(gridData.getGridByAccountId.enabled)
    }
  }, [gridData])

  const delayedSetSearchValue = debounce(value => {
    const searchValueIsHashtag = isHashtag(value)
    const hashtagDetected = searchValueIsHashtag || selectedFilterKey === searchFilterKeys.HASHTAG
    const requiredHashtagLength = searchValueIsHashtag ? 2 : 1
    setOnLoadMore(false)
    setOffset(0)
    setAllContentLoaded(false)
    if (value && hashtagDetected && value.length >= requiredHashtagLength) {
      trackSearchEvent({ searchTerm: searchValue, searchKey: searchFilterKeys.HASHTAG }, client)
      getHashtagItems({
        variables: {
          cfpId: activeCfp._id,
          searchValue: searchValueIsHashtag ? value.substring(1) : value
        }
      })
      return setSearchValue(value)
    } else if (!hashtagDetected && value.length >= MIN_CHARACTER_COUNT) {
      trackSearchEvent({ searchTerm: searchValue, searchKey: selectedFilterKey }, client)
      getSearchItems({
        variables: {
          limit: globalSearchLimit,
          offset: 0,
          cfpId: activeCfp._id,
          searchTerm: value,
          displayContentAuthorEnabled,
          officeDocSupport: true
        }
      })
      return setSearchValue(value)
    } else {
      setSearchValue('')
    }
  }, 500)

  const onSearchChange = (value) => { delayedSetSearchValue(value) }

  const onSearchFilterChange = (label, item) => {
    setSelectedFilterKey(item.key)
    setSearchValue('')
  }

  const onEndReached = function () {
    if (allContentLoaded) return
    if (!searchItemsResult.loading && searchItemsResult.data.getSearchItems.result) {
      const { content, folders, events } = searchItemsResult.data.getSearchItems.result
      const maxLength = Math.max((content || []).length, (folders || []).length, (events || []).length)
      const newOffset = offset + maxLength
      setOffset(newOffset)
      setOnLoadMore(true)
      return searchItemsResult.fetchMore({
        variables: {
          offset: newOffset
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const { content, folders, events } = fetchMoreResult.getSearchItems.result
          const newResultsMaxLength = Math.max((content || []).length, (folders || []).length, (events || []).length)
          if (newResultsMaxLength < globalSearchLimit) {
            return setAllContentLoaded(true)
          }
          const newSearchResults = createSearchResultsList(content, folders, events)
          setSearchResults(searchResults.concat(newSearchResults))
          return fetchMoreResult
        }
      })
    }
  }
  const onSearchItemClick = (selectedItem) => {
    const itemIsHashtag = isHashtag(selectedItem.label) || selectedFilterKey === searchFilterKeys.HASHTAG
    if (!itemIsHashtag) {
      trackSearchItemClickedEvent({ ...selectedItem, contentId: selectedItem._id, user: me._id }, client)
    }
    if (selectedItem.contentType === 'CONTENT_FOLDER') {
      navigate(`/${activeCfp._id}/explore/folders/${selectedItem._id}`)
    } else if (selectedItem.contentType === 'CONTENT_EVENT') {
      navigate({
        pathname: `/${activeCfp._id}/calendar`,
        eventDate: selectedItem.startDate,
        eventId: selectedItem._id
      })
    } else if (selectedItem.contentType === 'CONTENT_WEB' && !selectedItem.likesEnabled && !selectedItem.commentsEnabled) {
      window.open(selectedItem.webUrl, '_blank')
    } else if (itemIsHashtag) {
      navigate({
        pathname: `/${activeCfp._id}/hashtags/${selectedItem.label.substring(1)}`
      })
    } else {
      if (gridEnabled && width >= 769) {
        const contentFilters = { type: 'desc', field: 'modified' }
        const folderFilters = { type: 'asc', field: 'title' }
        goToContentPreview(navigate, { cfpId: activeCfp._id, content: selectedItem, contents: [], location, contentLocation: contentLocations.SEARCH_BAR },
          { contentFilters, folderFilters })
      } else {
        goToOldContentPreview(navigate, client, { content: selectedItem, location, contentLocation: contentLocations.SEARCH_BAR })
      }
    }

    searchRef.current && searchRef.current.closeDropdown()
  }

  const renderSearchedItem = (item, index) => {
    if (item.empty) {
      return <Empty key={'emptyText'}><Text text={formatMessage(messages.nothing)} textSize={'h5'} fontColor={color.grey400}/></Empty>
    }
    return <ListItem key={`${item.label}-${item._id}`} payload={item} onClick={onSearchItemClick} label={item.label} icon={item.icon} iconColor={item.iconColor} contentType={item.contentType} />
  }

  const searchFilters = [
    { key: searchFilterKeys.ALL, label: formatMessage(messages.all), icon: 'list' },
    { key: searchFilterKeys.CONTENT, label: formatMessage(messages.content), icon: 'file' },
    { key: searchFilterKeys.FOLDER, label: formatMessage(messages.folder), icon: 'folder' },
    { key: searchFilterKeys.EVENT, label: formatMessage(messages.event), icon: 'event' },
    { key: searchFilterKeys.HASHTAG, label: 'Hashtag', icon: 'hashtag' }
    // { label: 'Directory', icon: 'addressBook' }
  ]
  const createSearchResultsList = function (content, folders, events, hashtags) {
    const searchItemsList = []
    let contentList = []
    let folderList = []
    let eventList = []

    if (content) { contentList = content }
    if (folders) { folderList = folders.map((folder) => ({ ...folder, contentType: 'CONTENT_FOLDER' })) }
    if (events) { eventList = events.map((event) => ({ ...event, contentType: 'CONTENT_EVENT' })) }

    switch (selectedFilterKey) {
      case searchFilterKeys.ALL : {
        searchItemsList.push(...contentList, ...folderList, ...eventList)
        break
      }
      case searchFilterKeys.CONTENT : {
        searchItemsList.push(...contentList)
        break
      }
      case searchFilterKeys.FOLDER : {
        searchItemsList.push(...folderList)
        break
      }
      case searchFilterKeys.EVENT : {
        searchItemsList.push(...eventList)
        break
      }
      default : { /* empty */ }
    }
    const results = searchItemsList.length > 0 ? searchItemsList : [{ empty: true }]
    return results
  }

  useEffect(() => {
    const getSearchResults = () => {
      if (!onLoadMore) {
        const { data } = searchItemsResult
        if (searchValue && !isHashtag(searchValue) && searchValue.toString().length >= MIN_CHARACTER_COUNT && (data && data.getSearchItems.result)) {
          const { content, folders, events } = data.getSearchItems.result
          const searchItemsList = []
          let contentList = []
          let folderList = []
          let eventList = []

          if (content) { contentList = content }
          if (folders) { folderList = folders.map((folder) => ({ ...folder, contentType: 'CONTENT_FOLDER' })) }
          if (events) { eventList = events.map((event) => ({ ...event, contentType: 'CONTENT_EVENT' })) }

          switch (selectedFilterKey) {
            case searchFilterKeys.ALL : {
              searchItemsList.concat(contentList || []).concat(folderList || []).concat(eventList || [])
              break
            }
            case searchFilterKeys.CONTENT : {
              searchItemsList.push(...contentList)
              break
            }
            case searchFilterKeys.FOLDER : {
              searchItemsList.push(...folderList)
              break
            }
            case searchFilterKeys.EVENT : {
              searchItemsList.push(...eventList)
              break
            }
            default: { /* empty */ }
          }

          const results = createSearchResultsList(content, folders, events)
          setSearchResults(results)
        } else {
          setSearchResults([])
        }
      }
    }

    getSearchResults()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, searchItemsResult, selectedFilterKey, onLoadMore])

  useEffect(() => {
    if (!onLoadMore) {
      const { data } = searchHashtagsResult
      const searchValueIsHashtag = isHashtag(searchValue)
      const requiredHashtagLength = searchValueIsHashtag ? 2 : 1
      if (searchValue.toString().length >= requiredHashtagLength && (isHashtag(searchValue) || selectedFilterKey === searchFilterKeys.HASHTAG) && data && data.searchHashtags) {
        const foundHashtags = data.searchHashtags

        if (foundHashtags.length <= 0) {
          setSearchResults([{ empty: true }])
          return
        }

        setSearchResults(foundHashtags.map(item => ({ label: `#${item.value}`, icon: 'hashtag' })))
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, searchHashtagsResult, selectedFilterKey, onLoadMore])

  return (
    <SearchBarComponent
      isShowing={isShowing}
      customMaxWidth={700}
      ref={searchRef}
      searchFilters={searchFilters}
      searchResults={searchResults}
      isLoading={searchItemsResult.loading}
      renderSearchedItem={renderSearchedItem}
      handleSearchChange={onSearchChange}
      handleSearchFilterChange={onSearchFilterChange}
      placeholder={formatMessage(messages.searchHere)}
      onEndReached={onEndReached} />
  )
}

export default injectIntl(SearchBar)
