// @flow

import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useApolloClient, useQuery, useMutation } from '@apollo/client'
import type { InjectIntlProvidedProps } from 'react-intl'
import axios from 'axios'
import { injectIntl } from 'react-intl'
import { messages } from '../i18n/messages'
import Row from 'michelangelo/dist/WebComponents/Layout/Row'
import Column from 'michelangelo/dist/WebComponents/Layout/Column'
import color from 'michelangelo/dist/Components/styles/color'
import styled from 'styled-components'
import Avatar from 'michelangelo/dist/SharedComponents/DataDisplay/Avatar/Avatar'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Checkbox from 'michelangelo/dist/SharedComponents/Inputs/Checkbox'
import Icon from 'michelangelo/dist/SharedComponents/DataDisplay/Icons/IconComponent'
import Tooltip, { TooltipPosition } from 'michelangelo/dist/WebComponents/Feedback/Tooltip'
import { statuses, statusStrings } from '../Helpers/SmsHelper'
import { readFromCache } from '../apollo/cacheHelper'
import { getContentIconColor } from 'michelangelo/dist/Components/helpers/color-helper'
import { SEND_OPT_IN } from '../apollo/queries/sendOptIn'
import { TOTAL_USER_COMMENTS, TOTAL_USER_LIKES } from '../apollo/queries/TotalData/getTotalData'
import { UPDATE_USER_NAME_MUTATION } from '../apollo/queries/UpdateUserData/updateUserData'
import GET_GRID_QUERY from '../apollo/queries/getGridByAccountId'
import { updateGetMeNameCache } from '../apollo/cacheUpdaters/userName'
import Notification from 'michelangelo/dist/Components/Notification'
import { trackUserNameUpdate } from '../Helpers/segmentHelper'
import statusHelper from '../Helpers/statusHelper'
import UrlHelper from '../Helpers/urlHelper'
import { buildAlfredUrl } from '../Helpers/AlfredHelper'
import { decodedAccessToken } from '../Helpers/DecodedAccessToken'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { useNavigate, useLocation } from 'react-router-dom'
import { getRoleType } from '../Helpers/UserInfoUtil'
import ProfilePhotoUploadModal from '../Components/modals/ProfilePhotoUploadModal'

const REACT_APP_ATREIDES_BASE_URL = process.env.REACT_APP_ATREIDES_BASE_URL || 'https://api.beta.theemployeeapp.com'

const Modal = styled.div` 
  display: ${(props) => props.show ? 'block' : 'none'};
  position: absolute;
  padding: 30px 55px 30px 30px;
  overflow: auto;
  z-index: 100000;
  width: 664px;
  height: 285px;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background: ${color.white};
  box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.1);
  align-items: center;
`

const ModalTitleSection = styled.div`
  margin-bottom: 24px;
`

const ModalTitle = styled.h2`
  font-size: 36px;
  font-weight: 400;
  margin-bottom: 0;
  color: #202020;
  line-height: 1;
`

const ModalSendToSection = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 21px;
`

const ModalSendToText = styled.h3`
  font-size: 18px;
  font-weight: 400;
  color: ${color.black};
  margin-bottom: 0;
  line-height: 1;
`

const ModalFormFullWidth = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`

const ModalFormLeft = styled.div`
  width: 20px;
  margin-right: 10px;
`

const ModalFormRight = styled.div``

const ModalFormRightText = styled.p`
  font-size: 12px;
  line-height:14px;
  color: ${color.grey600};
`

const ModalButtons = styled.div`
  width: 240px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`

const ModalButton = styled.button`
  padding: 8px 32px;
  color: white;
  font-size: 16px;
  border-radius: 6px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: ${(props) => props.cancel ? '#AEB5BC' : props.disabled ? '#AEB5BC' : '#69BA6E'};

  &[disabled] {
    pointer-events: none;
  }
`

const NameContainer = styled.div`
  display: flex;
  align-items: center;
`

const MyProfilePageContainer = styled.div`
  background: ${color.white};
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.08);
  height: calc(100vh - 32px);
  margin: 16px;
  min-height: 600px;
  height: fit-content;
  padding-bottom: 16px;
  @media (max-width: 1140px) {
    height: 100%;
    min-height: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
  }
`
const UsernameContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0px 0px 48px 48px;
  margin-top: 48px;
  @media (max-width: 1024px) {
    margin-right: 16px;
    margin-left: 16px;
    padding: 0px;
  }
  @media (max-width: 768px) {
    margin-right: 24px;
    margin-left: 24px;
    margin-top: 24px;
  }
`
const UserTextContainer = styled.div`
  height:122px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-width: 0px;
  width: 100%;
  border-radius: 6px;
  overflow-wrap: break-word;
  word-break: break-word;
  margin: 8px 8px 8px 32px;
  padding: ${(props) => props.editing ? '0px' : '8px'};
  background-color: ${color.white};
  :focus {
    background-color: ${color.white};
  }
  @media (max-width: 1024px) {
    width: 50%;
  }
`
const HiddenInput = styled.input`
  display: none;
`
const UserNameInput = styled.input`
  width: ${(props) => props.width || '100%'};
  outline: none;
  border-radius: 6px;
  background-color: transparent;
  border: none;
  padding: 8px;
  height: ${(props) => props.height || 'auto'};
  border: 1px solid ${(props) => props.borderColor} !important;
  :focus {
    border: 1px solid ${color.info};
    background-color: ${color.white};
  }
  margin-bottom: 8px;
  ::-ms-clear {
    display: none;
  }
`

const UserNameInputContainer = styled.div`
  display:flex;
`

const UserNameInputDescription = styled.p`
  margin-bottom: 8px;
  font-size:12px;

  white-space: nowrap;
    display: flex;
    align-items: center;
    margin-right: 8px;
`

const SaveButton = styled.button`
  padding: 4px 24px;
  border-radius:16px;
  outline: none;
  pointer-events: ${(props) => props.disabled ? 'none' : 'auto'};
  border: 1px solid ${(props) => props.disabled ? '#AEB5BC' : '#3fa8f0'};
  cursor: pointer;
  background-color: ${(props) => props.inverted ? '#fff' : props.disabled ? '#AEB5BC' : '#3FA8F0'};
  color: ${(props) => props.inverted ? '#3FA8F0' : '#fff'};

  margin-left: 8px;
`

const ErrorText = styled.p`
  color: red;
  font-size:10px;
  width: ${(props) => props.width || 'calc(100% - 68px)'};
  margin-left: ${(props) => props.width ? 'unset' : 'auto'};
  text-align: center;
`

const OptInButton = styled.button`
  width: auto;
  padding: 8px 18px;
  margin: 0px;
  text-transform: uppercase;
  background-color: #4EB364;
  outline: 0px;
  border: none;
  color:white;
  cursor: pointer !important;
`

const CustomButton = styled.button`
  width: 100%;
  padding: 0px;
  margin: 0px;
  display: inline-block;
  line-height: 1;
  background: transparent;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  cursor: pointer !important;
  :hover {
    cursor: text;
  }
`
const UserInfoContainer = styled.div`
  padding: 24px;
  margin-left: 48px;
  margin-top: 16px;
  border: 1px solid ${color.grey200};
  border-radius: 6px;
  @media (max-width: 1024px) {
    margin-right: 16px;
    margin-left: 16px;
  }
  @media (max-width: 768px) {
    margin-right: 24px;
    margin-left: 24px;
  }
`
const UserInfoItem = styled.div`
  display: flex;
  flex: 1;
  align-items: ${(props) => props.isEditing ? 'flex-start' : 'center'};
  width: 100%;
`
const UserInfoText = styled.div`
  border-radius: 6px;
  width: 100%;
  padding: 8px;
  padding-top: ${(props) => props.isEditing ? '11px' : '8px'};
  ${(props) => !props.noHover
    ? `:hover {
      background-color: ${color.grey100};
    }`
    : ''
  }
`
const IconContainer = styled.div`
  padding: 16px;
`
const IconInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
  padding-top: 24px;
`
const RegCodeIconContainer = styled.div`
  transform: rotate(-130deg);
  padding: 16px;
`
const TitleContainer = styled.div`
  padding-bottom: 24px;
`
const InteractionsContainer = styled.div`
  margin-top: 48px;
  margin-left: 64px;
  margin-right: 24px;
  padding: 24px;
  border: 1px solid ${color.grey200};
  border-radius: 6px;
  @media (max-width: 1024px) {
    margin-right: 16px;
    margin-left: 16px;
  }
  @media (max-width: 768px) {
    margin-right: 24px;
    margin-left: 24px;
    margin-top: 24px;
  }
`
const GroupsContainer = styled.div`
  margin-top: 16px;
  margin-left: 64px;
  margin-right: 24px;
  padding: 16px;
  border: 1px solid ${color.grey200};
  border-radius: 6px;
  @media (max-width: 1024px) {
    margin-right: 16px;
    margin-left: 16px;
  }
  @media (max-width: 768px) {
    margin-right: 24px;
    margin-left: 24px;
    padding: 8px;
  }
  height: 264px;
`
const Groups = styled.div`
  display: flex;
  flex: 1;
  align-items: baseline;
  justify-content: center;
`
const AvatarContainer = styled.div`
  padding: 24px 32px;
  display: flex;
  flex: 1;
  justify-content: center;
`
const GroupTextContainer = styled.div`
  padding: 0px 16px;
  min-width: 100px;
  word-wrap: break-word;
`
const GroupParent = styled.div`
  display: flex;
  flex: 1;
  max-width: 180px;
  padding: 8px;
  min-width: 180px;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`
const ArrowControls = styled.div`
  display: flex;
  justify-content: center;
`
const ArrowIcon = styled.div`
  padding: 8px;
`
const InteractionList = styled.div`
  padding-top: 24px;
  display: flex;
  flex: 1;
  @media (max-width: 1030px) {
    display: block;
  }
`
const Interactions = styled.div`
  display: flex;
  flex: 1;
  padding: 0px 16px;
  align-items: center;
  @media (max-width: 1100px) {
    padding: 0px 8px;
  }
  @media (max-width: 1030px) {
    padding: 8px 0px;
  }
`
const SpacerContainer = styled.div`
  padding: 0px 8px;
`

MyProfilePageContainer.displayName = 'MyProfilePageContainer'
UserInfoContainer.displayName = 'UserInfoContainer'

type MyProfileProps = {
  me: Object
} & InjectIntlProvidedProps

function MyProfilePage ({ me, intl: { formatMessage } }: MyProfileProps) {
  const client = useApolloClient()
  const { activeCfp } = readFromCache(client)
  const darkTheme = readFromCache(client, ['darkTheme'])
  const location = useLocation()
  const primaryColor = activeCfp.primaryColor
  const memberships = me.memberships[0]
  const groups = memberships.groupObjects
  const colorArray = getContentIconColor('ARRAY')
  const userId = me._id
  const accountId = me.memberships[0].account._id
  const enableUserProfilePhotos = !!me.memberships[0].account.enableUserProfilePhotos
  const [changeProfilePhoto, setChangeProfilePhoto] = useState((location.state && location.state.editModal) ? location.state.editModal : false)
  const [totalLikes, setTotalLikes] = useState({ count: 0 })
  const [mobileNumberError, setMobileNumberError] = useState(false)
  const [errorInFirstName, setErrorInFirstName] = useState(false)
  const [errorInLastName, setErrorInLastName] = useState(false)
  const [totalComments, setTotalComments] = useState({ count: 0 })
  const [updateUserName] = useMutation(UPDATE_USER_NAME_MUTATION)
  const [sendOptIn] = useMutation(SEND_OPT_IN)
  const [accountMobileNumber, setAccountMobileNumber] = useState(memberships.mobileNumber || 'N/A')
  const [initialMobileNumber, setInitialMobileNumber] = useState(memberships.mobileNumber || 'N/A')
  const [firstName, setFirstName] = useState(me.firstName)
  const [lastName, setLastName] = useState(me.lastName)
  const [isEditing, setIsEditing] = useState(false)
  const [showOptInModal, setShowOptInModal] = useState(false)
  const [isTermsAndPrivacyChecked, setIsTermsAndPrivacyChecked] = useState(false)
  const [isEditingMobileNumber, setIsEditingMobileNumber] = useState(false)
  const [canEditProfilePhoto, setCanEditProfilePhoto] = useState(false)
  const navigate = useNavigate()
  const [windowWidth, setWidth] = useState(window.innerWidth)
  const subDomain = UrlHelper.getSubDomain()
  const { user } = decodedAccessToken()
  const profilePhoto = me && me.profilePhoto ? me.profilePhoto : null

  const modalRef = useRef(null)
  const optInButtonRef = useRef(null)

  const { data } = useQuery(GET_GRID_QUERY, {
    variables: { accountId },
    fetchPolicy: 'cache-and-network'
  })

  const handleOutsideClick = (e: Event) => {
    if (modalRef.current && !modalRef.current.contains(e.target) && optInButtonRef.current !== e.target) {
      toggleOptInModal(false)
    }
  }

  useEffect(() => {
    setIsTermsAndPrivacyChecked(false)
    handleTermsCheckboxChange(false)

    if (showOptInModal) {
      document.addEventListener('click', handleOutsideClick, false)
    } else {
      document.removeEventListener('click', handleOutsideClick, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptInModal])

  useEffect(() => {
    if (data && data.getGridByAccountId) {
      setCanEditProfilePhoto(data.getGridByAccountId.enabled)
    }
  }, [data])

  useEffect(() => {
    resetNotificationsAndMessages()
  }, [location])

  useEffect(() => {
    if (memberships.mobileNumber) {
      let num = splitNumber(memberships.mobileNumber)
      num = num.replace('+1', '')
      setInitialMobileNumber(num)
      setAccountMobileNumber(num)
    }
  }, [memberships.mobileNumber])

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  /**
   * Handeling hard reload since the activeCfp gets wiped out and we can no longer show proper color
   */
  if (!activeCfp || isEmpty(activeCfp)) navigate('/')
  // Notifications
  const [successNotification, setSuccessNotification] = useState(false)
  const [warningNotification, setWarningNotification] = useState(false)
  const [errorNotofication, setErrorNotification] = useState(false)
  const [nameWarningNotification, setNameWarningNotification] = useState(false)

  /**
   * Find time membership
   */
  const registrationDate = moment(memberships.registrationDate).fromNow(true)
  const account = memberships.account

  /**
   * Skip if we likes aren't enabled
   */
  const totalLikesData = useQuery(TOTAL_USER_LIKES, {
    variables: { userId },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !account.likesEnabled
  })
  /**
   * Skip if comments aren't enabled
   */
  const totalCommentsData = useQuery(TOTAL_USER_COMMENTS, {
    variables: { userId },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !account.commentsEnabled
  })

  /**
   * Update the query cache for likes
   */
  const updateLikeCountCache = (data) => {
    const totalLikesByUserId = client.readQuery({
      query: TOTAL_USER_LIKES,
      variables: { userId }
    })
    /**
     * If the data is NOt the same then write to query cache
     */
    if (!totalLikesByUserId || isEmpty(totalLikesByUserId) || !totalLikesByUserId.totalLikesByUserId) {
      client.writeQuery({
        query: TOTAL_USER_LIKES,
        variables: { userId },
        data: { totalLikesByUserId: data }
      })
    } else if (totalLikesByUserId.totalLikesByUserId.count !== data) {
      client.writeQuery({
        query: TOTAL_USER_LIKES,
        variables: { userId },
        data: { totalLikesByUserId: data }
      })
    }
  }

  /**
   * Update the query cache for comments
   */
  const updateCommentCountCache = (data) => {
    const totalCommentsByUserId = client.readQuery({
      query: TOTAL_USER_COMMENTS,
      variables: { userId }
    })
    /**
     * If the data is NOt the same then write to query cache
     */
    if (!totalCommentsByUserId || isEmpty(totalCommentsByUserId) || !totalCommentsByUserId.totalCommentsByUserId) {
      client.writeQuery({
        query: TOTAL_USER_COMMENTS,
        variables: { userId },
        data: { totalCommentsByUserId: data }
      })
    } else if (totalCommentsByUserId.totalCommentsByUserId.count !== data) {
      client.writeQuery({
        query: TOTAL_USER_COMMENTS,
        variables: { userId },
        data: { totalCommentsByUserId: data }
      })
    }
  }

  useEffect(() => {
    if (!totalLikesData || !totalLikesData.data) return
    setTotalLikes(totalLikesData.data.totalLikesByUserId)
    updateLikeCountCache(totalLikesData.data.totalLikesByUserId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalLikesData])

  useEffect(() => {
    if (!totalCommentsData || !totalCommentsData.data) return
    setTotalComments(totalCommentsData.data.totalCommentsByUserId)
    updateCommentCountCache(totalCommentsData.data.totalCommentsByUserId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCommentsData])

  const handleUpdateUserName = async (e) => {
    resetNotificationsAndMessages()

    try {
      const { data } = await updateUserName({
        variables: { userId, firstName, lastName },
        update: updateGetMeNameCache(firstName, lastName)
      })
      const isSuccessful = data.userNameUpdate.success
      if (isSuccessful) {
        setSuccessNotification(true)
        // Get some track events here for this new feature
        await trackUserNameUpdate({ status: statusHelper.SUCCESS }, me)
      }
    } catch (error) {
      setErrorNotification(false)
      await trackUserNameUpdate({ status: statusHelper.REQUEST_FAIL }, me)
      console.error('error on handleUpdateUserName', error)
    }
  }

  let displaySocialData = true
  if (!account.userAnalyticsEnabled) {
    displaySocialData = false
  }

  /**
   * If tracking is disabled then only show how long user has been member for
   */
  const dataArray = []
  dataArray.push({ name: 'registrationDate', data: registrationDate })
  /**
   * Add total comments and likes if this is enabled for the account
   */
  if (displaySocialData) {
    if (account.commentsEnabled) {
      dataArray.push({ name: 'totalCommentsByUserId', data: totalComments ? totalComments.count : 0 })
    }
    if (account.likesEnabled) {
      dataArray.push({ name: 'totalLikesByUserId', data: totalLikes ? totalLikes.count : 0 })
    }
  }

  const validateOptInStatus = (status) => {
    let modifiedStatus = status
    if (status.includes('-')) {
      modifiedStatus = status.replace('-', '_')
    } else if (status.includes(' ')) {
      modifiedStatus = status.replace(' ', '_')
    }

    return statusStrings[statuses[modifiedStatus]]
  }

  const handlePhotoUploadError = () => {
    setErrorNotification(true)
  }

  const capitalizeFirstLetter = (string, name) => {
    if (name) {
      const wholeName = string.split(' ')
      let fullName = ''

      for (const letter of wholeName) {
        fullName += `${letter.charAt(0).toUpperCase() + letter.slice(1)} `
      }
      return fullName
    }

    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  /**
   * Resets all the notifications
   */
  const resetNotificationsAndMessages = () => {
    setWarningNotification(false)
    setErrorNotification(false)
    setSuccessNotification(false)
    setNameWarningNotification(false)
  }

  const handleMobileNumberClick = () => {
    setIsEditingMobileNumber(true)

    if (accountMobileNumber === 'N/A') {
      setAccountMobileNumber('')
    }

    resetNotificationsAndMessages()
  }

  const handleTermsCheckboxChange = (checked) => {
    setIsTermsAndPrivacyChecked(checked)
  }

  const handleOnClick = () => {
    setIsEditing(true)
    resetNotificationsAndMessages()
  }

  const splitNumber = (num) => {
    return num.slice(0, 5) + '-' + num.slice(5, 8) + '-' + num.slice(8)
  }

  const handleMobileNumberSeparation = (e) => {
    const key = e.keyCode || e.charCode
    const val = e.target.value
    const isBackSpaceKey = key === 8 || key === 46
    const phoneNumberTrim = accountMobileNumber.trim()
    if (key !== 189) {
      if (isBackSpaceKey) {
        setAccountMobileNumber(val.trim())
      } else if (phoneNumberTrim.length === 3) {
        setAccountMobileNumber(phoneNumberTrim + '-')
      } else if (phoneNumberTrim.length === 7) {
        setAccountMobileNumber(phoneNumberTrim + '-')
      } else {
        setAccountMobileNumber(val)
      }
    }
  }

  const handleMobileNumberOnChange = (e) => {
    const val = e.target.value
    const pureNumber = val.split('-').join('')
    setAccountMobileNumber(val)

    if (isEmpty(val) || !e164(pureNumber)) {
      setMobileNumberError(true)
    } else {
      setMobileNumberError(false)
    }
  }

  const handleOnChange = (e, type) => {
    const val = e.currentTarget.value

    if (type === 'firstName') {
      setFirstName(val)
      if (isEmpty(val)) {
        setErrorInFirstName(true)
      } else {
        setErrorInFirstName(false)
      }
    } else {
      setLastName(val)
      if (isEmpty(val)) {
        setErrorInLastName(true)
      } else {
        setErrorInLastName(false)
      }
    }
  }

  const handleMobileNumberCancel = () => {
    setAccountMobileNumber(initialMobileNumber)

    if (accountMobileNumber === 'N/A') {
      setAccountMobileNumber(accountMobileNumber)
    }

    setIsEditingMobileNumber(false)
  }

  const toggleOptInModal = (bool) => {
    resetNotificationsAndMessages()
    setShowOptInModal(bool)
  }

  const handleSendOptIn = async () => {
    toggleOptInModal(false)

    try {
      const { data } = await sendOptIn({
        variables: { memberId: me.memberships[0]._id }
      })

      const isSuccessful = data.sendSMSToMember
      if (isSuccessful) {
        setSuccessNotification(true)
      }
    } catch (error) {
      setErrorNotification(true)
      console.error('error on sending opt in', error)
    }
  }

  const handleMobileNumberSave = async () => {
    if (accountMobileNumber.length) {
      setIsEditingMobileNumber(false)
      const accessToken = localStorage.getItem('accessToken') || ''
      // TODO: remove query param after V3 update to handle accessToken in request headers
      const userUpdateEndpoint = `${REACT_APP_ATREIDES_BASE_URL}/v3/user/update?accessToken=${accessToken}`
      try {
        await axios.put(userUpdateEndpoint, { regCode: me.memberships[0].registrationCode, mobileNumber: `+1${accountMobileNumber.split('-').join('')}` }, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        setSuccessNotification(true)
      } catch (e) {
        setErrorNotification(true)
        console.log(e)
      }
    }
  }

  const handleOnCancel = () => {
    setFirstName(me.firstName)
    setLastName(me.lastName)
    setIsEditing(false)
  }

  const handleOnSave = () => {
    if (firstName?.length && lastName?.length) {
      setIsEditing(false)
      handleUpdateUserName()
    }
  }

  const getWarningNotification = () => {
    if (warningNotification) {
      return <Notification show message={formatMessage(messages.nothingChanged)} autoClose={5} type='warning' />
    }
    return null
  }
  const getSuccessNotification = () => {
    if (successNotification) {
      return <Notification show message={formatMessage(messages.success)} autoClose={5} type='success' />
    }
    return null
  }
  const getErrorNotification = () => {
    if (errorNotofication) {
      return <Notification show message={formatMessage(messages.tryAgain)} autoClose={5} type='error' />
    }
    return null
  }
  const getNameWarningNotification = () => {
    if (nameWarningNotification) {
      return <Notification show message={formatMessage(messages.improperNameFormat)} autoClose={0} type='warning' />
    }
  }

  const handleChangeEmail = () => {
    /**
     * Support for mobile use
     */
    const w = window.innerWidth > 500 ? 500 : 375
    const h = 600
    const top = window.top.outerHeight / 2 + window.top.screenY - (h / 2)
    const left = window.top.outerWidth / 2 + window.top.screenX - (w / 2)
    const alfredUrl = buildAlfredUrl('change-email', subDomain)

    /**
     * Opens up a new window with alfred in center
     */
    window.open(alfredUrl, '', `toolbar=no,location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=${w}, height=${h}, top=${top}, left=${left}`)
  }

  const editProfilePhoto = () => {
    setChangeProfilePhoto(true)
  }

  /**
   * Galina think about adding a cancel and save button just incase hitting enter is not best
   * user experience
   * Galina: sure I'll do that.... :)
   */
  const e164 = (num) => {
    const phoneRegex = /^\d{10,10}$/
    return phoneRegex.test(num)
  }

  const updateMobileNumberForm = (
    <div>
      <UserNameInputContainer>
        <UserNameInput height="32px" width="207px" autoFocus type='text' value={accountMobileNumber} borderColor={mobileNumberError ? 'red' : 'gray'} onChange={handleMobileNumberOnChange} onKeyDown={handleMobileNumberSeparation} />
      </UserNameInputContainer>

      {mobileNumberError && (!isEmpty(accountMobileNumber) ? <ErrorText width="207px">{formatMessage(messages.e164Validation)}</ErrorText> : <ErrorText width="207px">{formatMessage(messages.mobileNumberValidation)}</ErrorText>)}

      <div style={{ display: 'flex', justifyContent: 'center', width: '207px' }}>
        <SaveButton disabled={mobileNumberError} type='submit' onClick={handleMobileNumberSave}>{formatMessage(messages.saveMobileNumber)}</SaveButton>
        <SaveButton inverted type='submit' onClick={handleMobileNumberCancel}>{formatMessage(messages.cancelMobileNumber)}</SaveButton>
      </div>

      <HiddenInput type='submit' value='Submit' />
    </div>
  )
  const mobileNumber = (
    <NameContainer>
      <div style={{ marginRight: '8px' }}>
        <Text text={accountMobileNumber || 'N/A'} textSize='p' fontColor={color.grey800} align='left' />
      </div>
      <Icon onClick={handleMobileNumberClick} iconName='pen' color={color.black} size={16} />
    </NameContainer>
  )
  const mobileNumberSection = isEditingMobileNumber ? updateMobileNumberForm : mobileNumber

  const form = (
    <div>
      <UserNameInputContainer>
        <UserNameInputDescription>
          {formatMessage(messages.firstNameInput)}
        </UserNameInputDescription>
        <UserNameInput autoFocus type='text' value={firstName} borderColor={errorInFirstName ? 'red' : 'gray'} onKeyDown={(e) => handleOnChange(e, 'firstName')} onChange={(e) => handleOnChange(e, 'firstName')} />
      </UserNameInputContainer>

      <UserNameInputContainer>
        <UserNameInputDescription>
          {formatMessage(messages.lastNameInput)}
        </UserNameInputDescription>
        <UserNameInput type='text' value={lastName} borderColor={errorInLastName ? 'red' : 'gray'} onKeyDown={(e) => handleOnChange(e, 'lastName')} onChange={(e) => handleOnChange(e, 'lastName')} />
      </UserNameInputContainer>

      {(errorInFirstName || errorInLastName) && <ErrorText>{formatMessage(messages.saveNameValidation)}</ErrorText>}

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <SaveButton disabled={!firstName?.length || !lastName?.length} type='submit' onClick={handleOnSave}>{formatMessage(messages.saveName)}</SaveButton>
        <SaveButton inverted type='submit' onClick={handleOnCancel}>{formatMessage(messages.cancelSaveName)}</SaveButton>
      </div>

      <HiddenInput type='submit' value='Submit' />
    </div>
  )
  const text = (
    <NameContainer>
      <div style={{ marginRight: '12px' }}>
        <Text text={`${firstName} ${lastName}`} textSize='p' fontColor={color.grey800} marginLeft="12px" textTransform='capitalize' align='left' />
      </div>
      <Icon onClick={handleOnClick} iconName='pen' color={color.black} size={16}/>
    </NameContainer>
  )
  const userNameComponent = isEditing ? form : text

  /**
   * Only display regcode for non open reg accounts
   */
  let isOpenRegistrationGroup = false
  if (account.openRegistrationGroup) {
    isOpenRegistrationGroup = true
  }
  const displayRegCodeData = isOpenRegistrationGroup
    ? <UserInfoItem>
      <RegCodeIconContainer>
        <Icon size={24} iconName='key' color={color.grey400} />
      </RegCodeIconContainer>
      <UserInfoText>
        <Tooltip label={formatMessage(messages.notEditableAccountId)} position={windowWidth <= 1023 ? TooltipPosition.TOP_LEFT : TooltipPosition.RIGHT}>
          <Row classNames={['is-marginless']}>
            <Column classNames={['is-12', 'is-paddingless']}>
              <Text text={memberships.registrationCode} textSize='p' fontColor={color.grey800} align='left' />
            </Column>
          </Row>
        </Tooltip>
      </UserInfoText>
    </UserInfoItem>
    : null

  /**
   * SSO users can't change out their email
   */
  const ssoUser = user.provider === 'local'
  const roleType = getRoleType(formatMessage)

  /**
   * All scrollable groups list
   */
  const [currentIndex, setCurrentIndex] = useState(0)
  const WIDE_DESKTOP_SCREEN = 1180
  const DESKTOP_SCREEN = 1024
  const TABLET_SCREEN = 768
  const PHONE_SCREEN = 568
  const SMALL_PHONE_SCREEN = 500
  let itemNumber = 1
  if (windowWidth >= WIDE_DESKTOP_SCREEN) itemNumber = 4
  else if (windowWidth >= DESKTOP_SCREEN) itemNumber = 3
  else if (windowWidth <= TABLET_SCREEN && windowWidth >= 744) itemNumber = 4
  else if (windowWidth >= PHONE_SCREEN) itemNumber = 3
  else if (windowWidth >= SMALL_PHONE_SCREEN) itemNumber = 2

  const [myGroups, setMyGroups] = useState(groups.slice(currentIndex, currentIndex + itemNumber))

  useEffect(() => {
    setMyGroups(groups.slice(currentIndex, currentIndex + itemNumber))
  }, [currentIndex, itemNumber, groups])

  /**
   * Creating UI avatar of groups
   */
  const myGroupsData = myGroups.map(function (item, index) {
    const groupColor = colorArray[index % colorArray.length]
    return (
      // eslint-disable-next-line react/jsx-key
      <GroupParent key={index}>
        <Row classNames={['is-marginless']}>
          <Column classNames={['is-paddingless', 'is-12']}>
            <AvatarContainer>
              <Avatar textTransform='uppercase' darkTheme={darkTheme} customCircleColor={groupColor} userName={item.name} size={40} fontSize='p' />
            </AvatarContainer>
            <GroupTextContainer>
              <Text text={item.name} textSize='h5' fontColor={color.grey800} textTransform='capitalize' align='center' />
            </GroupTextContainer>
          </Column>
        </Row>
      </GroupParent>
    )
  })

  const d = new Date() // now, or the specific date in question
  const timezone = d.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop()

  /**
   * This slides us through the array of groups and determines what
   * should be shown depending on true/false going forward or back
   */
  const updateCarouselData = (goForward: boolean) => {
    // find the index of the last image in the array
    const lastIndex = groups.slice().length
    // check if we need to start over from the last index again
    const resetIndex = (currentIndex + 1) === (lastIndex - 1)
    if (goForward) {
      const x = currentIndex + itemNumber
      const newIndex = resetIndex ? 0 : x
      setCurrentIndex(newIndex)
    } else {
      const newIndex = currentIndex - itemNumber
      setCurrentIndex(newIndex)
    }
  }

  // Are we at the start
  const isAtStart = currentIndex === 0
  /**
   * Keep this a onClick in order to have the not allowed cursor on web
   */
  const handleBackClick = isAtStart ? () => null : () => updateCarouselData(false)
  /**
   * Empty Group UI
   */
  const emptyGroup = (
    <Row classNames={['is-marginless', 'is-multiline', 'is-centered']}>
      <Column classNames={['is-paddingless', 'is-12']}>
        <IconInfoContainer>
          <Icon size={32} iconName='info' color={color.grey500} />
        </IconInfoContainer>
      </Column>
      <Column classNames={['is-paddingless', 'is-12']}>
        <Text text={formatMessage(messages.nothing)} textSize='p' fontColor={color.grey500} textTransform='inherit' align='left' />
      </Column>
    </Row>
  )
  /**
   * Arrows html
   */
  const groupArrowControls = (
    <Row classNames={['is-marginless', 'is-centered']}>
      <Column classNames={['is-paddingless', 'is-12-mobile']}>
        <ArrowControls>
          <ArrowIcon>
            <Icon onClick={handleBackClick} cursor={isAtStart ? 'not-allowed' : 'pointer'} color={isAtStart ? color.grey100 : color.grey400} iconName='leftArrow' size={24} />
          </ArrowIcon>
          <ArrowIcon>
            <Icon color={color.grey400} onClick={() => updateCarouselData(true)} iconName='rightArrow' size={24} />
          </ArrowIcon>
        </ArrowControls>
      </Column>
    </Row>
  )

  const renderModal = (
    <>
      { /* $FlowFixMe[incompatible-type] */ }
      <div ref={modalRef}>
        <Modal className='opt-in__modal' show={showOptInModal}>
          <ModalTitleSection>
            <ModalTitle>{formatMessage(messages.smsOptIn)}</ModalTitle>
          </ModalTitleSection>

          <ModalSendToSection>
            <ModalSendToText>{formatMessage(messages.sendTo)} {me.name && capitalizeFirstLetter(me.name, true)} {initialMobileNumber}</ModalSendToText>
          </ModalSendToSection>

          <ModalFormFullWidth>
            <ModalFormLeft>
              <Checkbox key="isTermsAndPrivacyChecked" testID={'SMSModalTermsCheckbox'} isChecked={isTermsAndPrivacyChecked} onChange={handleTermsCheckboxChange} />
            </ModalFormLeft>

            <ModalFormRight>
              <ModalFormRightText>
                {formatMessage(messages.smsTerms)}<a rel="noopener noreferrer" target="_blank" href='https://app.theemployeeapp.com/signup/privacy.html'>{formatMessage(messages.termsAndPrivacyPolicy)}</a>
              </ModalFormRightText>
            </ModalFormRight>
          </ModalFormFullWidth>

          <ModalButtons>
            <ModalButton onClick={() => toggleOptInModal(false)} cancel>{formatMessage(messages.smsOptInCancel)}</ModalButton>
            <ModalButton onClick={handleSendOptIn} disabled={!isTermsAndPrivacyChecked}>{formatMessage(messages.smsOptInSend)}</ModalButton>
          </ModalButtons>
        </Modal>
      </div>
    </>
  )

  /**
   * Only show arrows if the list is longer then the allowed itemNumber shown
   */
  const displayControls = groups.length > itemNumber ? groupArrowControls : null
  /**
   * Show empty screen if the user is not in any groups
   */
  const displayMyGroupsData = groups.length > 0 ? myGroupsData : emptyGroup

  /**
   * Remove offset when on larger screens
   */
  const firstColumnOptions = windowWidth >= 1024 ? ['is-paddingless', 'is-4-desktop', 'is-10-tablet', 'is-12-mobile'] : ['is-paddingless', 'is-4-desktop', 'is-10-tablet', 'is-offset-1-tablet', 'is-12-mobile']
  const secondColumnOptions = windowWidth >= 1024 ? ['is-paddingless', 'is-8-desktop', 'is-10-tablet', 'is-12-mobile'] : ['is-paddingless', 'is-8-desktop', 'is-10-tablet', 'is-offset-1-tablet', 'is-12-mobile']
  const date = me.mobileNumberOptInDate ? `${moment(me.mobileNumberOptInDate).format('MM/DD/yyyy hh:mm:ss A z')} ${timezone}` : 'N/A'
  const portalContainer = document.getElementById('modal-root')

  /* $FlowFixMe[incompatible-type] */
  showOptInModal ? portalContainer?.classList.add('modal-backdrop') : portalContainer?.classList.remove('modal-backdrop')

  return (
    <Row classNames={['is-marginless']}>

      { /* $FlowFixMe[incompatible-type] */ }
      {portalContainer && ReactDOM.createPortal(renderModal, portalContainer)}

      <Column classNames={['is-12-desktop', 'is-paddingless']}>
        <MyProfilePageContainer>
          <Row classNames={['is-marginless', 'is-multiline']}>
            <Column classNames={firstColumnOptions}>
              <UsernameContainer>
                <Avatar textTransform='uppercase' darkTheme={darkTheme} customCircleColor={primaryColor} userName={`${firstName} ${lastName}`} size={96} fontSize='h3' canEdit={canEditProfilePhoto && enableUserProfilePhotos} onEditClick={(canEditProfilePhoto && enableUserProfilePhotos) ? editProfilePhoto : null} thumbnail={profilePhoto ? profilePhoto._128px : null} editText={formatMessage(messages.edit)} />
                <UserTextContainer editing={isEditing}>
                  {userNameComponent}
                </UserTextContainer>
              </UsernameContainer>
            </Column>
            <Column classNames={secondColumnOptions}>
              <InteractionsContainer>
                <TitleContainer>
                  <Text text={formatMessage(messages.yourInteractions)} textSize='h4' fontColor={color.grey800} textTransform='capitalize' align='left' />
                  <InteractionList>
                    {
                      dataArray.reverse().map(function (item, index) {
                        let icon
                        let text
                        let toolTipText
                        const itemName = item.name
                        switch (itemName) {
                          case 'totalLikesByUserId':
                            icon = 'thumbsUp'
                            text = item.data > 1 ? formatMessage(messages.likes) : formatMessage(messages.like)
                            toolTipText = formatMessage(messages.totalLikedContent)
                            break
                          case 'totalCommentsByUserId':
                            text = item.data > 1 ? formatMessage(messages.comments) : formatMessage(messages.comment)
                            toolTipText = formatMessage(messages.totalCommentsMade)
                            icon = 'comment'
                            break
                          case 'registrationDate':
                            text = formatMessage(messages.timeMember)
                            toolTipText = formatMessage(messages.totalTimeMember)
                            icon = 'event'
                            break
                          default:
                            text = formatMessage(messages.timeMember)
                            toolTipText = formatMessage(messages.totalTimeMember)
                            icon = 'event'
                            break
                        }
                        return (
                          <Interactions key={index}>
                            <Tooltip label={toolTipText} position={TooltipPosition.TOP_LEFT} hidden={windowWidth <= 1023}>
                              <Icon size={24} iconName={icon} color={color.grey400} />
                              <SpacerContainer><Text text={item.data} textSize='h4' fontColor={color.grey700} textTransform='capitalize' align='left' /></SpacerContainer>
                              {windowWidth <= 1023 ? <Text text={text} textSize='p' fontColor={color.grey700} textTransform='lowercase' align='left' /> : null}
                            </Tooltip>
                          </Interactions>
                        )
                      })
                    }
                  </InteractionList>
                </TitleContainer>
              </InteractionsContainer>
            </Column>
          </Row>
          <Row classNames={['is-marginless', 'is-multiline']}>
            <Column classNames={firstColumnOptions}>
              <UserInfoContainer>
                <TitleContainer>
                  <Text text={formatMessage(messages.about)} textSize='h4' fontColor={color.grey800} textTransform='capitalize' align='left' />
                </TitleContainer>
                <UserInfoItem>
                  <IconContainer>
                    <Icon size={24} iconName='envelope' color={color.grey400} />
                  </IconContainer>
                  <UserInfoText>
                    {/** Once the change email is set up update this to allow for email as long as user is not sso type */}
                    <Tooltip label={ssoUser ? formatMessage(messages.emailAddress) : formatMessage(messages.notEditableEmail)} position={windowWidth <= 1023 ? TooltipPosition.TOP_LEFT : TooltipPosition.RIGHT}>
                      <CustomButton onClick={handleChangeEmail} disabled={!ssoUser}>
                        <Row classNames={['is-marginless']}>
                          <Column classNames={['is-12', 'is-paddingless']}>
                            <Text text={me.email || 'N/A'} textSize='p' fontColor={color.grey800} align='left' />
                          </Column>
                        </Row>
                      </CustomButton>
                    </Tooltip>
                  </UserInfoText>
                </UserInfoItem>
                {displayRegCodeData}
                { roleType && <UserInfoItem>
                  <IconContainer>
                    <Icon size={24} iconName='userTag' color={color.grey400} />
                  </IconContainer>
                  <UserInfoText>
                    <Tooltip label={formatMessage(messages.role)} position={windowWidth <= 1023 ? TooltipPosition.TOP_LEFT : TooltipPosition.RIGHT}>
                      <Row classNames={['is-marginless']}>
                        <Column classNames={['is-12', 'is-paddingless']}>
                          { roleType && <Text text={roleType} textSize='p' fontColor={color.grey800} align='left' />}
                        </Column>
                      </Row>
                    </Tooltip>
                  </UserInfoText>
                </UserInfoItem>}
              </UserInfoContainer>
            </Column>
            <Column classNames={secondColumnOptions}>
              <GroupsContainer>
                <TitleContainer>
                  <Text text={formatMessage(messages.yourGroups)} textSize='h4' fontColor={color.grey800} textTransform='capitalize' align='left' />
                </TitleContainer>
                <Groups>
                  {displayMyGroupsData}
                </Groups>
                {displayControls}
              </GroupsContainer>
            </Column>
          </Row>

          {account.smsEnabled && account.enableFeatureSmsAlerts && <Row classNames={['is-marginless', 'is-multiline']}>
            <Column classNames={firstColumnOptions}>
              <UserInfoContainer>
                <TitleContainer>
                  <Text text={formatMessage(messages.smsAlerts)} textSize='h4' fontColor={color.grey800} textTransform='capitalize' align='left' />
                </TitleContainer>
                <UserInfoItem isEditing={isEditingMobileNumber}>
                  <IconContainer isEditing={isEditingMobileNumber}>
                    <Icon size={24} iconName='mobile' color={color.grey400} />
                  </IconContainer>
                  <UserInfoText isEditing noHover={isEditingMobileNumber}>
                    {/** Once the change email is set up update this to allow for email as long as user is not sso type */}
                    {isEditingMobileNumber
                      ? <Row classNames={['is-marginless']}>
                      <Column classNames={['is-12', 'is-paddingless']}>
                        {mobileNumberSection}
                      </Column>
                    </Row>
                      : <Tooltip label={formatMessage(messages.mobileNumber)} position={windowWidth <= 1023 ? TooltipPosition.TOP_LEFT : TooltipPosition.RIGHT}>
                      <Row classNames={['is-marginless']}>
                        <Column classNames={['is-12', 'is-paddingless']}>
                          {mobileNumberSection}
                        </Column>
                      </Row>
                    </Tooltip>
                    }
                  </UserInfoText>
                </UserInfoItem>
                <UserInfoItem>
                  <IconContainer>
                    <div className='message-icon'></div>
                  </IconContainer>
                  <UserInfoText>
                    <Tooltip label={formatMessage(messages.smsSubscriptionStatus)} position={windowWidth <= 1023 ? TooltipPosition.TOP_LEFT : TooltipPosition.RIGHT}>
                      <Row classNames={['is-marginless']}>
                        <Column classNames={['is-12', 'is-paddingless']}>
                          <Text text={memberships.mobileNumberOptInStatus ? validateOptInStatus(memberships.mobileNumberOptInStatus) : 'N/A'} textSize='p' fontColor={color.grey800} align='left' />
                        </Column>
                      </Row>
                    </Tooltip>
                  </UserInfoText>
                </UserInfoItem>
                <UserInfoItem>
                  <IconContainer>
                    <Icon size={24} iconName='calendar' color={color.grey400} />
                  </IconContainer>
                  <UserInfoText>
                    <Tooltip label={formatMessage(messages.smsStatusUpdateDate)} position={windowWidth <= 1023 ? TooltipPosition.TOP_LEFT : TooltipPosition.RIGHT}>
                      <Row classNames={['is-marginless']}>
                        <Column classNames={['is-12', 'is-paddingless']}>
                          <Text text={date} textSize='p' fontColor={color.grey800} align='left' />
                        </Column>
                      </Row>
                    </Tooltip>
                  </UserInfoText>
                </UserInfoItem>

                <div style={{ padding: '0px 16px', margin: '12px 0 10px 0' }}>
                  <OptInButton ref={optInButtonRef} className='send-opt__in' onClick={() => toggleOptInModal(true)}>{formatMessage(messages.sendOptIn)}</OptInButton>
                </div>
              </UserInfoContainer>
            </Column>
          </Row>}

        </MyProfilePageContainer>
        {getWarningNotification()}
        {getSuccessNotification()}
        {getErrorNotification()}
        {getNameWarningNotification()}
      </Column>
      <ProfilePhotoUploadModal onError={handlePhotoUploadError} hideModal={setChangeProfilePhoto} active={changeProfilePhoto}/>
    </Row>
  )
}

export default injectIntl(MyProfilePage)
