import { gql } from '@apollo/client'

const GET_SIGNED_DOWNLOAD_URL = gql`
query getSignedDownloadUrl ($s3Key: String!, $accountId: String!) {
  getSignedDownloadUrl (req: { s3Key: $s3Key, accountId: $accountId }) {
    signedUrl
  }
}
`

export default GET_SIGNED_DOWNLOAD_URL
