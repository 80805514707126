// @flow
import React, { useState, useEffect } from 'react'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../../i18n/messages'
import color from 'michelangelo/dist/Components/styles/color'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import styled from 'styled-components'
import { isEmpty } from 'lodash'
import TranslateToggle from '../TranslationComponents/TranslateToggle'
import { useApolloClient } from '@apollo/client'
import { GET_TRANSLATED_ALERT_QUERY } from '../../apollo/queries/getAlerts/query'
import { getLanguageTagFromLocale, shouldShowTranslate } from '../../Helpers/TranslationHelper'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  display: ${(props) => props.display ? 'block' : 'none'};
  width: 100%;
  height: 100%;
`
const PopUp = styled.div`
  position: absolute;
  overflow: auto;
  background-color: ${color.white};
  width: 70%;
  max-width: 640px;
  height: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
const IconContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 56px;
`
const TimeContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0px 16px 24px 16px;
`
const TextContainer = styled.div`
  display: block;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  margin-bottom: 24px;
  overflow-wrap: break-word;
  word-wrap: break-word;
`
const CloseButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  width: 100%;
  padding: 24px 24px 16px 0px;
`
const TranslateToggleContainer = styled.div`
  margin: auto 0;
  padding-right: 50px;
  right: 0px;
`

type AlertItemPopUpProps = {
  alertData: Object,
  onClosePopUp: Function
} & InjectIntlProvidedProps
const AlertItemPopUp = ({ alertData, onClosePopUp, intl: { formatMessage, locale } }: AlertItemPopUpProps) => {
  const client = useApolloClient()
  const hasData = isEmpty(alertData)
  const [closePopUp, setClosePopUp] = useState(hasData)
  const [translatedText, setTranslatedText] = useState(null)
  const [toggleTranslate, setToggleTranslate] = useState(false)
  const [loading, setLoading] = useState(false)

  /**
   * Listen for data to change
   */
  useEffect(() => {
    if (isEmpty(alertData)) setClosePopUp(true)
    setTranslatedText(null)
    setToggleTranslate(false)
  }, [alertData])

  const renderIcon = ({ sentBy }) => {
    if (!sentBy) return 'alert-broadcast alert-icon__default alert-large__icon'

    if (sentBy.includes('SMS') && sentBy.includes('Broadcast')) {
      return 'alert-sms alert-icon__default alert-large__icon'
    }

    if (sentBy.includes('Broadcast')) {
      return 'alert-broadcast alert-icon__default alert-large__icon'
    } else if (sentBy.includes('SMS')) {
      return 'alert-sms alert-icon__default alert-large__icon'
    }
  }

  const close = () => {
    if (document.documentElement) document.documentElement.classList.remove('modal-open')
    setClosePopUp(false)
    onClosePopUp()
  }
  const renderTranslate = async (id) => {
    try {
      setLoading(true)
      const { data } = await client.query({
        query: GET_TRANSLATED_ALERT_QUERY,
        variables: {
          alertId: id,
          lang: getLanguageTagFromLocale(locale)
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      })
      setTranslatedText(data.getAlertById.translatedMessage)
      setLoading(false)
      setToggleTranslate(!toggleTranslate)
    } catch (e) {
      console.error(e)
    }
  }
  const showTranslateButton = shouldShowTranslate(locale)

  /**
   * Don't render this component unless we have a alert item object
   */
  if (isEmpty(alertData)) return null
  return (
    <Container display={closePopUp}>
      <PopUp>
        <CloseButtonContainer>
          { showTranslateButton &&
          <TranslateToggleContainer>
            <TranslateToggle alertId={alertData._id} testID={`${alertData._id}_translate_toggle`} renderTranslate={renderTranslate} translateTitle={toggleTranslate ? formatMessage(messages.seeOriginal) : formatMessage(messages.seeTranslation)} />
          </TranslateToggleContainer>}
          <Button smallButton icon='x' onClick={close} iconSize={32} appBrandColor={color.grey400} />
        </CloseButtonContainer>
        <IconContainer>
          <div className={renderIcon(alertData)}></div>
          {(alertData && alertData.sentBy && alertData.sentBy.includes('SMS') && alertData.sentBy.includes('Broadcast')) && <div className='alert-broadcast alert-icon__default alert-large__icon alert-two__icons' />}
        </IconContainer>
        <TimeContainer>
          <Text text={alertData.created} textSize='h5' fontColor={color.grey400} textTransform='capitalize' align='center' />
        </TimeContainer>
        <TextContainer style={loading ? { opacity: 0.5 } : {}}>
          <Text linkify text={(toggleTranslate && translatedText && !loading) ? translatedText : alertData.text} textSize='p' fontColor={color.grey700} align='left' />
        </TextContainer>
      </PopUp>
    </Container>
  )
}

export default injectIntl(AlertItemPopUp)
