import { UPDATE_USER_RECENT_CONTENT_MUTATION } from '../apollo/queries/UpdateUserData/updateUserData'
import * as Environment from '../config'

export const updateUserContentIds = ({ user, contentId }, client) => {
  const userId = user
  return client.mutate({
    mutation: UPDATE_USER_RECENT_CONTENT_MUTATION,
    variables: { userId, contentId },
    context: {
      uri: `${Environment.theRingUrl}/data`
    }
  })
}
