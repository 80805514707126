import { gql } from '@apollo/client'

const GET_COMMENTS_AND_REPLY_QUERY = gql`
  query GetCommentsAndReplies($comment: getCommentsAndRepliesInput!) {
  getCommentsAndReplies(req: $comment) {
    _id
    accountId
    comment
    created
    discussionId
    discussionType
    childrenComments
    usersMentioned
    mentions {
      id: _id
      name
    }
    children {
      _id
      parentComment
      comment
      childrenComments
      likedByUser
      created
      modified
      totalLikes
      usersMentioned
      mentions {
        id: _id
        name
      }
      children {
        _id
        parentComment
        firstParent
        comment
        likedByUser
        created
        modified
        totalLikes
        usersMentioned
        mentions {
          id: _id
          name
        }
        owner {
          id
          name
          email
          profilePhoto {
            _32px
            _64px
          }
        }
      }
      owner {
        id
        name
        email
        profilePhoto {
          _32px
          _64px
        }
      }
    }
    modified
    totalLikes
    owner {
      id
      name
      email
      profilePhoto {
          _32px
          _64px
        }
    }
    likedByUser
  }
}`

const CREATE_COMMENT_MUTATION = gql`
  mutation CreateComment ($comment: commentInput!) {
    commentCreation(req: $comment){
      _id
      accountId
      comment
      created
      discussionId
      discussionType
      parentComment
      firstParent
      childrenComments
      modified
      totalLikes
      usersMentioned
      mentions {
        id: _id
        name
      }
      owner {
        id
        name
        email
        profilePhoto {
          _32px
          _64px
        }
      }
      likedByUser
      discussionItem {
        _id
        totalComments
        totalFirstComments
      }
    }
  }`

const UPDATE_COMMENT_MUTATION = gql`
  mutation UpdateComment ($comment: commentUpdateInput!) {
    commentUpdate(req: $comment){
      _id,
       created,
       comment,
       discussionId,
       discussionType,
       modified,
       removingUser
       usersMentioned
       mentions {
         id: _id
         name
       }
    }
  }`

const DELETE_COMMENT_MUTATION = gql`
  mutation DeleteComment ($comment: String!, $content: String!) {
    commentDelete(comment: $comment, content: $content) {
      _id
      accountId
      comment
      created
      discussionId
      discussionType
      parentComment
      firstParent
      childrenComments
      modified
      totalLikes
      owner {
        id
        name
        email
      }
      likedByUser
      discussionItem {
        _id
        totalComments
        totalFirstComments
      }
    }
  }`

const GET_COMMENT_BY_ID = gql`
  query GetCommentById ($comment: getCommentByIdInput!) {
    getCommentById (req: $comment) {
      _id,
      comment,
      translatedComment,
      parentComment,
      firstParent
      usersMentioned
      mentions {
        id: _id
        name
      }
    }
  }`

const GET_REPLIES = gql`
  query GetReplies($comment: getRepliesInput!) {
  getReplies(req: $comment) {
    _id
    accountId
    comment
    created
    discussionId
    discussionType
    childrenComments
    parentComment
    firstParent
    modified
    totalLikes
    usersMentioned
    mentions {
      id: _id
      name
    }
    owner {
      id
      name
      email
      profilePhoto {
            _32px
            _64px
          }
    }
    likedByUser
    children {
      _id
      accountId
      comment
      created
      discussionId
      discussionType
      childrenComments
      parentComment
      firstParent
      modified
      totalLikes
      usersMentioned
      mentions {
        id: _id
        name
      }
      owner {
        id
        name
        email
        profilePhoto {
            _32px
            _64px
          }
      }
      likedByUser
    }

  }
}`

export {
  CREATE_COMMENT_MUTATION,
  UPDATE_COMMENT_MUTATION,
  DELETE_COMMENT_MUTATION,
  GET_COMMENTS_AND_REPLY_QUERY,
  GET_COMMENT_BY_ID,
  GET_REPLIES
}
