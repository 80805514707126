// @flow

import React, { useEffect, useState, memo, useRef } from 'react'
import Row from 'michelangelo/dist/WebComponents/Layout/Row'
import Column from 'michelangelo/dist/WebComponents/Layout/Column'
import styled from 'styled-components'
import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import EXPLORE_QUERY from '../apollo/queries/getExplorer'
import SEND_EMAIL_QUERY from '../apollo/queries/sendEmail'
import GET_EXPLORE_BANNER_OPTION from '../apollo/queries/application'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import color from 'michelangelo/dist/Components/styles/color'
import Carousel from '../Components/Carousel'
import {
  cookieTimer,
  onLoad,
  trackFolderOpenEvent,
  trackContentOpened,
  trackBannerOpened,
  trackLoadMoreFoldersEvent,
  trackSortFoldersEvent,
  trackSeeAllContentsEvent,
  trackLoadMoreContentsEvent,
  trackSortContentsEvent,
  trackSeeAllFoldersEvent
} from '../Helpers/segmentHelper'
import ExplorerBanner from '../Components/ExplorerBanner'
import List from './List'
import moment from 'moment'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { readFromCache } from '../apollo/cacheHelper'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../i18n/messages'
import LoadingScreen from '../Components/LoadingScreen'
import { contentLocations } from '../Helpers/contentHelper'
import { checkIsReadAcknowledgement } from '../Helpers/ReadAcknowledgement'
import { goToOldContentPreview, goToContentPreview } from '../Helpers/contentPreviewHelper'
import UrlHelper from '../Helpers/urlHelper'
import OverlayPage from '../Components/OverlayPage'
import GET_GRID_QUERY from '../apollo/queries/getGridByAccountId'
import useDimensions from 'michelangelo/dist/Components/helpers/WebDimension'

const ExploreContainer = styled.div``

const CarouselContainerExplore = styled.div`
  margin-bottom: 0;
`
const TitleContainer = styled.div`
  display: flex;
  padding: 8px;
  padding-bottom: 16px;
  background-color: ${color.white};
  justify-content: flex-start;
`

const Container = styled.div`
  flex-grow: 1;
  margin: 16px auto 30px auto;
  position: relative;
  background-color: ${color.white};
  padding: 24px 16px;
  max-width: 1152px;
  @media (max-width: 960px) {
    margin: 16px 32px 22px 32px;
  }
  @media (max-width: 767px) {
    margin: 16px 0 0 0;
    padding: 0;
  }
`

const MostPopularHeaderContainer = styled.div`
  padding: 8px 16px;
`
const MostPopularContainer = styled.div`
  max-width: 1152px;
  margin: 0 auto;
`

ExploreContainer.displayName = 'ExploreContainer'
// $FlowFixMe
ExplorerBanner.displayName = 'ExploreBanner' // typo on explorER
CarouselContainerExplore.displayName = 'CarouselContainerExplore'
// $FlowFixMe
Carousel.displayName = 'CarouselExplore'
TitleContainer.displayName = 'TitleContainer'
Container.displayName = 'Container'

type ExploreProps = {
  me: Object
} & InjectIntlProvidedProps

function Explore ({ me, intl: { formatMessage } }: ExploreProps) {
  const [folders, setFolders] = useState([])
  const [contents, setContents] = useState([])
  const [bannerType, setBannerType] = useState('NONE')
  const [showOverlay, setShowOverlay] = useState(false)
  const [gridEnabled, setGridEnabled] = useState(false)
  const [containerRef, { width }] = useDimensions()

  const client = useApolloClient()
  const navigate = useNavigate()
  const location = useLocation()
  const { cfpId, folderId } = useParams()
  const { activeCfp } = readFromCache(client, ['activeCfp'])

  const homeFolderId = activeCfp.homeFolder._id
  const displayContentAuthorEnabled = me.memberships[0].account.displayContentAuthorEnabled
  const contentLocation = contentLocations.EXPLORE

  const accountId = me.memberships[0].account._id
  const [getExplore, { loading, data }] = useLazyQuery(EXPLORE_QUERY(displayContentAuthorEnabled),
    {
      variables: {
        cfpId,
        folderId: folderId || homeFolderId
      },
      fetchPolicy: 'network-only', // TODO check later if useLazyQuery is getting most recent data with other fetch policies. Actually is showing most up up date content only with network-only or no-cache policy.
      notifyOnNetworkStatusChange: true
    }
  )

  const { data: gridData } = useQuery(GET_GRID_QUERY, {
    variables: { accountId },
    fetchPolicy: 'cache-and-network'
  })

  /**  Check if grid flag is enabled to render the new UI */
  useEffect(() => {
    if (gridData && gridData.getGridByAccountId) {
      setGridEnabled(gridData.getGridByAccountId.enabled)
    }
  }, [gridData])

  const [getExploreBanner, getExploreBannerResult] = useLazyQuery(GET_EXPLORE_BANNER_OPTION, { fetchPolicy: 'network-only' })
  const [sendEmail] = useMutation(SEND_EMAIL_QUERY)

  // get explore query hook
  useEffect(() => {
    getExplore({
      variables: {
        cfpId,
        folderId: folderId || homeFolderId
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    })
  }, [getExplore, folderId, cfpId, homeFolderId])

  // hook to set explore data result to local state variables
  useEffect(() => {
    const { isPreviewOpen } = readFromCache(client, ['isPreviewOpen'])
    if (!get(data, 'getExplorer') || isPreviewOpen) return
    setFolders(data.getExplorer.length && data.getExplorer.filter(item => !item.postDate && item._id !== homeFolderId))
    setContents(data.getExplorer.length && data.getExplorer.filter(item => item.postDate))
  }, [data, homeFolderId, client])

  // get application by subdomain if subdomain provided in the url
  useEffect(() => {
    const subdomain = UrlHelper.getSubDomain()
    if (!subdomain) setBannerType('MOST_POPULAR')
    else {
      getExploreBanner({
        variables: {
          subdomain
        }
      })
    }
  }, [getExploreBanner])

  useEffect(() => {
    const exploreBannerOption = get(getExploreBannerResult, 'data.getApplicationBySubdomain.exploreBannerOption')
    if (!exploreBannerOption) return
    setBannerType(exploreBannerOption)
  }, [getExploreBannerResult])

  useEffect(() => {
    onLoad(client, me, 'Explorer View')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      cookieTimer()
    }, 10000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const handleCarouselClick = async (content) => {
    const isRead = await checkIsRead(content)
    // if was cancelled
    if (!isRead) return
    await trackContentOpened({ ...content, contentLocation: contentLocations.CAROUSEL_EXPLORE }, client)
    await trackBannerOpened(content, contentLocation)
    if (content.contentType === 'CONTENT_WEB' && !content.likesEnabled && !content.commentsEnabled) {
      return window.open(content.webUrl, '_target')
    }

    if (gridEnabled && width >= 769) {
      goToContentPreview(navigate, { cfpId, content, contents, contentLocation, location },
        {
          folderFilters: sortFolderFiltersRef.current,
          contentFilters: sortContentFiltersRef.current,
          contentPage: contentPageIterationRef.current,
          folderPage: folderPageIterationRef.current
        })
    } else {
      goToOldContentPreview(navigate, client, { content, location, contentLocation })
    }
  }

  const updateContentsListRef = useRef([])
  const sortContentFiltersRef = useRef(location.state && location.state.contentFilters ? location.state.contentFilters : { type: 'desc', field: 'modified' })
  const sortFolderFiltersRef = useRef(location.state && location.state.folderFilters ? location.state.folderFilters : { type: 'asc', field: 'title' })
  const contentPageIterationRef = useRef(location.state && location.state.contentPage ? location.state.contentPage : 1)
  const folderPageIterationRef = useRef(location.state && location.state.folderPage ? location.state.folderPage : 1)

  const handleFolderClickEvent = (folder) => {
    trackFolderOpenEvent(folder)
    navigate(`/${cfpId}/explore/folders/${folder._id}`)
  }

  const handleSortContentChange = (sort) => {
    sortContentFiltersRef.current = sort
  }

  const handleSortFolderChange = (sort) => {
    sortFolderFiltersRef.current = sort
  }

  const updatePage = (page) => {
    contentPageIterationRef.current = page
  }

  const updateFolderPage = (page) => {
    folderPageIterationRef.current = page
  }

  const updateContentsList = (sortedData) => {
    updateContentsListRef.current = sortedData
  }

  const checkIsRead = async (content) => {
    setShowOverlay(true)
    const isRead = await checkIsReadAcknowledgement(client, content, formatMessage, me, sendEmail)
    setShowOverlay(false)
    return isRead
  }
  const handleContentClickEvent = (isContentBanner = false) => async (content) => {
    const isRead = await checkIsRead(content)
    // if was cancelled
    if (!isRead) return
    const newContentlocation = isContentBanner ? contentLocations.MOST_POPULAR : contentLocation
    trackContentOpened({ ...content, contentLocation: newContentlocation }, client)
    if (content.contentType === 'CONTENT_WEB' && !content.likesEnabled && !content.commentsEnabled) {
      return window.open(content.webUrl, '_target')
    }

    if (gridEnabled && width >= 769) {
      const dataDev = updateContentsListRef.current

      goToContentPreview(navigate,
        { cfpId, content, contents: dataDev.reverse(), contentLocation: newContentlocation, location, rootPath: location?.pathname },
        {
          folderFilters: sortFolderFiltersRef.current,
          contentFilters: sortContentFiltersRef.current,
          contentPage: contentPageIterationRef.current,
          folderPage: folderPageIterationRef.current
        })
    } else {
      goToOldContentPreview(navigate, client, { content, location, contentLocation: newContentlocation })
    }
  }

  const handleSeeAllFolderClick = (totalItems) => {
    trackSeeAllFoldersEvent(totalItems)
  }

  const handleLoadMoreFoldersClick = (totalItems) => {
    trackLoadMoreFoldersEvent(totalItems)
  }

  const handleSortingFoldersClick = (columnName) => {
    trackSortFoldersEvent(columnName)
  }

  const handleSeeAllContentsClick = (totalItems) => {
    trackSeeAllContentsEvent(totalItems)
  }

  const handleLoadMoreContentsClick = (totalItems) => {
    trackLoadMoreContentsEvent(totalItems)
  }

  const handleSortingContentsClick = (columnName) => {
    trackSortContentsEvent(columnName)
  }

  const getLoader = () => {
    return <LoadingScreen />
  }

  const renderTopContent = () => {
    if (getExploreBannerResult.loading) return <Container>{ getLoader() }</Container>

    switch (bannerType) {
      case 'MOST_POPULAR':
        return (
          <Column classNames={['is-paddingless']}>
            <MostPopularContainer>
              <MostPopularHeaderContainer>
                <Text text={formatMessage(messages.mostPopular)} textSize='h4' fontColor={color.grey500} textTransform='capitalize' align='left' />
              </MostPopularHeaderContainer>
              <ExplorerBanner itemClick={handleContentClickEvent(true)} />
            </MostPopularContainer>
          </Column>
        )
      case 'CAROUSEL':
        return (
          <Column classNames={['is-paddingless', 'is-6-desktop', 'is-8-tablet', 'is-12-mobile']}>
            <CarouselContainerExplore>
              <Carousel onCarouselClick={handleCarouselClick} activeCfp={activeCfp} aspectRatio={4} displayContentAuthorEnabled={displayContentAuthorEnabled}/>
            </CarouselContainerExplore>
          </Column>
        )
      default:
        return (null)
    }
  }

  return (
    <ExploreContainer ref={containerRef}>
      <OverlayPage visible={showOverlay}/>
      <Row classNames={['is-marginless', 'is-centered']}>
        {renderTopContent()}
      </Row>
      <Container>
        <Row>
          <Column classNames={['is-12']}>
            <TitleContainer>
              <Text text={formatMessage(messages.explore)} fontWeight="bold" textSize="h3" fontColor={color.lightTextDefault} textTransform="capitalize"/>
            </TitleContainer>
            { loading ? getLoader() : null }
            <MemoizedList
              title={formatMessage(messages.folders)}
              columns={[
                {
                  label: formatMessage(messages.name),
                  prop: 'title',
                  ratio: 1,
                  isSortable: true,
                  type: 'CONTENT_FOLDER',
                  hasIcon: true,
                  onSortClick: handleSortingFoldersClick
                }
              ]}
              listItems={folders}
              loading={loading}
              handleSortChange={handleSortFolderChange}
              onItemClick={handleFolderClickEvent}
              onSeeAllClick={handleSeeAllFolderClick}
              onLoadMoreClick={handleLoadMoreFoldersClick}
              defaultSort={sortFolderFiltersRef.current}
              pageOpened={folderPageIterationRef.current}
              updatePage={updateFolderPage}
            />
            <MemoizedList
              title={formatMessage(messages.content)}
              columns={[
                {
                  label: formatMessage(messages.name),
                  prop: 'title',
                  ratio: 1,
                  isSortable: true,
                  hasIcon: true,
                  onSortClick: handleSortingContentsClick
                },
                {
                  label: formatMessage(messages.lastModified),
                  prop: 'modified',
                  render: item => (moment(item.modified || item.postDate).format('LLL')),
                  ratio: 1,
                  isSortable: true,
                  sortFunction: item => (moment(item.modified || item.postDate)),
                  hasIcon: false,
                  onSortClick: handleSortingContentsClick
                }
              ]}
              listItems={contents}
              loading={loading}
              updateContentsList={updateContentsList}
              handleSortChange={handleSortContentChange}
              onItemClick={handleContentClickEvent(false)}
              onSeeAllClick={handleSeeAllContentsClick}
              onLoadMoreClick={handleLoadMoreContentsClick}
              defaultSort={sortContentFiltersRef.current}
              pageOpened={contentPageIterationRef.current}
              updatePage={updatePage}
            />
          </Column>
        </Row>
      </Container>
    </ExploreContainer>
  )
}

// eslint-disable-next-line react/display-name
const MemoizedList = memo(List, (prevProps, nextProps) => {
  return isEqual(prevProps.listItems, nextProps.listItems)
})
export default injectIntl(Explore)
