// @flow

import React, { useEffect, useState } from 'react'
import GET_ALERTS_QUERY, { GET_TRANSLATED_ALERT_QUERY } from '../apollo/queries/getAlerts/query'
import { useQuery, useApolloClient, useMutation, gql } from '@apollo/client'
import moment from 'moment'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../i18n/messages'
import Row from 'michelangelo/dist/WebComponents/Layout/Row'
import Column from 'michelangelo/dist/WebComponents/Layout/Column'
import color from 'michelangelo/dist/Components/styles/color'
import styled from 'styled-components'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Alert from 'michelangelo/dist/WebComponents/ListItem/Alert'
import { readFromCache } from '../apollo/cacheHelper'
import { trackAlertOpenedEvent } from '../Helpers/segmentHelper'
import LoadingScreen from '../Components/LoadingScreen'
import { isEmpty, findIndex } from 'lodash'
import { getLanguageTagFromLocale, shouldShowTranslate } from '../Helpers/TranslationHelper'

const AlertPageContainer = styled.div`
  background: ${color.white};
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.08);
  max-width: 1152px;
  margin: 0 auto;
`
const AlertList = styled.div`
  height: calc(100vh - 230px);
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  overflow: scroll;
`
const Underline = styled.div`
  width: auto;
  height: 4px;
  background: ${props => props.activeColor};
  display: ${(props) => props.isHighlighted ? 'block' : 'none'};
  position: relative;
  margin-top: 8px;
`
const TextContainer = styled.div`
  width: auto;
  padding: 8px 24px;
`
const NullContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const SliderList = styled.div`
  width: 100%;
  display: inline-block;
  padding: 24px;
`
const Slider = styled.div`
  width: auto;
  height: 2px;
  background: ${color.grey100};
  margin-left: 24px;
  margin-right: 24px;
`
const ActiveTextContainer = styled.div`
  width: auto;
`
const SliderContainer = styled.div`
  margin-top: -11px;
`
AlertPageContainer.displayName = 'AlertPageContainer'
AlertList.displayName = 'AlertList'
SliderList.displayName = 'SliderList'

const UPDATE_ALERT_DATA = gql`
  mutation updateAlertData($_id: String!, $lastSyncTime: Number!, $newAlertsNumber: Number!, ) {
    updateAlertData(_id: $_id, lastSyncTime: $lastSyncTime, newAlertsNumber: $newAlertsNumber) @client
  }
`

type AlertsProps = {
  me: Object
} & InjectIntlProvidedProps

function AlertsPage ({ me, intl: { formatMessage, locale } }: AlertsProps) {
  const client = useApolloClient()
  const [activeAlert, setActiveAlert] = useState(null)
  const [alertList, setAlertList] = useState([])
  const { activeCfp } = readFromCache(client)
  const primaryColor = activeCfp.primaryColor

  const { loading, data } = useQuery(GET_ALERTS_QUERY, {
    variables: {
      cfpId: activeCfp._id
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  const [updateAlertData] = useMutation(UPDATE_ALERT_DATA)

  const handleClick = (index: number, alert: Object) => {
    setActiveAlert(index)
    if (index === activeAlert) {
      setActiveAlert(null)
    } else {
      /**
       * Track the event if the user drills down to see entire alert
       */
      trackAlertOpenedEvent(alert)
    }
  }

  /**
   * If we are on this page update cache to 0 since we are here
   * Also when menu is in onclick of alert icon will take place of this
   */

  useEffect(() => {
    const updateAlerts = async () => {
      await updateAlertData({ variables: { _id: activeCfp._id, lastSyncTime: new Date().getTime(), newAlertsNumber: 0 } })
    }
    updateAlerts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data && data.getAlerts && isEmpty(alertList)) setAlertList(data.getAlerts.slice().reverse())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (loading) return <LoadingScreen />

  const showTranslateButton = shouldShowTranslate(locale)
  const renderTranslate = async (id) => {
    try {
      const index = findIndex(alertList, (item) => item._id === id)
      const { data } = await client.query({
        query: GET_TRANSLATED_ALERT_QUERY,
        variables: {
          alertId: id,
          lang: getLanguageTagFromLocale(locale)
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      })
      alertList[index].translatedMessage = data.getAlertById.translatedMessage
      setAlertList(alertList.slice())
    } catch (e) {
      console.error(e.message)
    }
  }

  return (
    <Row classNames={['is-marginless']}>
      <Column classNames={['is-12']}>
        <AlertPageContainer>
          <SliderList>
            <Row classNames={['is-marginless', 'is-mobile']}>
              <Column classNames={['is-paddingless', 'is-narrow']}>
                <TextContainer>
                  <ActiveTextContainer>
                    <Text text={formatMessage(messages.alerts)} textSize='h4' fontColor={primaryColor} textTransform='capitalize' align='center' />
                    <Underline isHighlighted activeColor={primaryColor} />
                  </ActiveTextContainer>
                </TextContainer>
              </Column>
            </Row>
            <SliderContainer>
              <Column classNames={['is-paddingless']}>
                <Slider />
              </Column>
            </SliderContainer>
          </SliderList>
          <AlertList>
            {
              !isEmpty(alertList)
                ? alertList.map((alert, index) => {
                  const date = alert.created
                  return (
                    <Alert
                      id={alert._id}
                      key={index}
                      sentBy={alert.sentBy}
                      onClick={() => { handleClick(index, alert) }}
                      isActive={activeAlert === index} text={alert.message}
                      createdDate={`${moment(date).format('dddd')}, ${moment(date).format('LL')} ${formatMessage(messages.at)} ${moment(date).format('LT')}`}
                      translatedText={alert.translatedMessage || null}
                      showTranslateButton={showTranslateButton}
                      translateTitle={formatMessage(messages.seeTranslation)}
                      translateOriginal={formatMessage(messages.seeOriginal)}
                      renderTranslate={renderTranslate}
                    />
                  )
                })
                : <NullContainer><Text text={formatMessage(messages.nothing)} textSize='p' fontColor={color.grey400} textTransform='inherit' align='left' /></NullContainer>
            }
          </AlertList>
        </AlertPageContainer>
      </Column>
    </Row>
  )
}

export default injectIntl(AlertsPage)
