// @flow

import { messages } from '../i18n/messages'
import { replace } from 'lodash'

const getSocialCountData = (content: Object, likesEnabled?: boolean, commentsEnabled?: boolean, formatMessage: Function) => {
  /**
   * We will need to come back and add logic for shares
   * Only show like and comments totals if they are enabled
   * likesEnabled && commentsEnabled are account level
   */
  if (content) {
    const socialLabels = {
      like: content.totalLikes && content.likesEnabled && likesEnabled ? (content.totalLikes > 1 ? replace(formatMessage(messages.numberOfLikes), '#NUMBER#', content.totalLikes) : replace(formatMessage(messages.numberOfLike), '#NUMBER#', content.totalLikes)) : '',
      comment: content.totalComments && content.commentsEnabled && commentsEnabled ? (content.totalComments > 1 ? replace(formatMessage(messages.numberOfComments), '#NUMBER#', content.totalComments) : replace(formatMessage(messages.numberOfComment), '#NUMBER#', content.totalComments)) : '',
      share: content.totalShares ? (content.totalShares > 1 ? formatMessage(messages.shares) : formatMessage(messages.share)) : ''
    }
    const socialData = {
      socialLabels,
      totalLikes: content.likesEnabled && likesEnabled ? (content.totalLikes || null) : null,
      totalComments: content.commentsEnabled && commentsEnabled ? (content.totalComments || null) : null,
      totalShares: content.totalShares || null
    }
    return socialData
  } else return {}
}

export { getSocialCountData }
