// @flow

import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import { useApolloClient, useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import UpcomingEventsWidget from 'michelangelo/dist/WebComponents/NewsFeed/Widgets/UpcomingEventsWidget/UpcomingEventsWidget'
import color from 'michelangelo/dist/Components/styles/color'
import { messages } from '../../../i18n/messages'
import { readFromCache } from '../../../apollo/cacheHelper'
import { trackUpcomingEvents } from '../../../Helpers/segmentHelper'
import eventTypes from '../../../Helpers/eventTypeHelper'
import type { WidgetProps } from '../WidgetProps'
import type { InjectIntlProvidedProps } from 'react-intl'
import GET_UPCOMING_EVENTS_WIDGET_QUERY from '../../../apollo/queries/widgets/getUpcomingEventsWidgetQuery'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px 8px;
`
const TitleContainer = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: ${color.grey500};
`

const UpcomingEventsWidgetComponent = ({ me, widget, intl: { formatMessage } }: WidgetProps & InjectIntlProvidedProps) => {
  const client = useApolloClient()
  const navigate = useNavigate()
  const { cfpId } = useParams()
  const { activeCfp } = readFromCache(client, ['activeCfp'])

  const { loading, data } = useQuery(GET_UPCOMING_EVENTS_WIDGET_QUERY, {
    variables: { cfpId, isUpcomingEvent: true },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })

  const events = data && data.getWidgetUpcomingEvents ? data.getWidgetUpcomingEvents : []
  const handleFooterClick = () => {
    trackUpcomingEvents({ user: me._id }, eventTypes.SEE_ALL_CALENDAR)
    navigate(`/${activeCfp._id}/calendar`)
  }

  const handleDateClick = (data) => {
    trackUpcomingEvents({ event: data, user: me._id }, eventTypes.UPCOMING_EVENT_CLICKED)
  }

  return (
    <div>
      <TitleContainer>
        <label>{formatMessage(
          messages.upcomingEventsWidgetHeaderLabel
        )}</label>
      </TitleContainer>
      {
        !loading &&
                <Container>
                  <UpcomingEventsWidget
                    width='100%'
                    data={events}
                    onUpcomingEventClick={handleDateClick}
                    emptyText={formatMessage(messages.upcomingEventsWidgetEmptyResultsLabel)}
                    footerLabel={formatMessage(messages.upcomingEventsWidgetFooterLabel)}
                    onFooterClick={handleFooterClick}
                    widget={widget}
                  />
                </Container>
      }
    </div>
  )
}
export default injectIntl(UpcomingEventsWidgetComponent)
