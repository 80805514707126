// @flow

import { ApolloClient, gql } from '@apollo/client'
import { cacheDefaults } from './cacheDefaults'
import GET_ME_QUERY from './queries/getMe'

export const defaultCacheKeys: Array<string> = Object.keys(cacheDefaults)

export const getCacheKeysWithClient = (keys: Array<string>) => {
  let tempKeys = ''
  if (keys.length === 1) {
    tempKeys = `${keys[0]} @client`
  } else {
    for (let i = 0; i < keys.length; i++) {
      tempKeys += `${keys[i]} @client \n`
    }
  }
  return tempKeys
}

export const generateCacheQueryFromKeys = (keys?: Array<string>) => {
  let data = ''
  if (keys) {
    data = getCacheKeysWithClient(keys)
  }
  return gql`query GetData {
    ${data}
  }`
}

export const readFromCache = (client: ApolloClient<any>, keys?: Array<string>): any => {
  const cacheQuery = generateCacheQueryFromKeys(keys || defaultCacheKeys)
  try {
    return client.readQuery({ query: cacheQuery })
  } catch (error) {
    // Known issue in apollo v2 doesn't return null if object is not found in cache https://github.com/apollographql/apollo-feature-requests/issues/1
    if (error.message.includes('Invariant Violation: 8') || error.message.includes("Can't find field")) {
      return null
    }
    console.error('readFromCache', { error, query: JSON.stringify(cacheQuery), keys: JSON.stringify(keys) })
  }
}

export const writeToCache = (client: ApolloClient<any>, data: any, keys?: Array<string>) => {
  const cacheQuery = generateCacheQueryFromKeys(keys || defaultCacheKeys)
  try {
    client.writeQuery({ query: cacheQuery, data })
  } catch (e) {
    console.error('writeToCache', e)
  }
}

export function getMeFromCache (client: ApolloClient<any>) {
  let result = null
  try {
    result = client.readQuery({ query: GET_ME_QUERY })
  } catch (e) {
    console.error('getMeFromCache', e)
  }

  return result
}
