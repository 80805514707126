// @flow

import { useState, useEffect } from 'react'
import type { ApolloClient } from '@apollo/client'
import GET_ALERTS_QUERY from '../apollo/queries/getAlerts/query'
import { useLazyQuery, useMutation, useQuery, gql } from '@apollo/client'
import { readFromCache, writeToCache } from '../apollo/cacheHelper'

const UPDATE_ALERT_DATA = gql`
  mutation updateAlertData($_id: String!, $lastSyncTime: Number!, $newAlertsNumber: Number!, ) {
    updateAlertData(_id: $_id, lastSyncTime: $lastSyncTime, newAlertsNumber: $newAlertsNumber) @client
  }
`

export const useAlerts = (cfpId: String, alerts: Array<Object>) => {
  const [newAlerts, setNewAlerts] = useState(0)

  const [updateAlertData] = useMutation(UPDATE_ALERT_DATA)

  const [getAlerts, { data }] = useLazyQuery(GET_ALERTS_QUERY, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    cfpId && getAlerts({ variables: { cfpId } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cfpId])

  const updateAlerts = (data) => {
    const currentAlertData = Array.isArray(alerts) && alerts.find(item => item._id === cfpId)
    const alertList = data ? data.getAlerts : null

    if (Array.isArray(alertList)) {
      let newAlerts = 0
      let lastSync = 0

      const reversedAlerts = alertList.slice().reverse()
      const lastAlertTime = (alertList.length > 0) ? new Date(reversedAlerts[0].created).getTime() : new Date().getTime()

      if (currentAlertData) {
        newAlerts = currentAlertData.newAlertsNumber
        lastSync = currentAlertData.lastSyncTime
      } else {
        // if no alert data is saved for current CFP, initialize lastSyncTime as last alert creation date
        lastSync = lastAlertTime
      }

      for (let i = 0; i < reversedAlerts.length; i++) {
        const alertDate = new Date(reversedAlerts[i].created).getTime()
        if (alertDate > lastSync) {
          newAlerts++
        } else {
          break
        }
      }

      setNewAlerts(newAlerts)

      if (!currentAlertData || currentAlertData.lastSyncTime < lastAlertTime) {
        updateAlertData({ variables: { _id: cfpId, lastSyncTime: lastAlertTime, newAlertsNumber: newAlerts } })
      }
    }
  }

  useEffect(() => {
    if (data) { updateAlerts(data) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return [updateAlertData, { newAlerts }]
}

/**
 * This helps get the number of new alerts from what was sync previously
 */
export const getBadgeAlertCount = (client: ApolloClient<any>) => {
  try {
    const { activeCfp, alerts } = readFromCache(client)
    const lastAlertSyncTime = alerts.lastAlertSyncTime
    let newAlertsNumber = alerts.newAlertsNumber
    /**
     * Leverage for better comparing
     * https://www.epochconverter.com/programming/
     * Initialize so it's not empty
     */
    let formattedDate = new Date().getTime()

    if (activeCfp) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { data } = useQuery(GET_ALERTS_QUERY, {
        variables: {
          cfpId: activeCfp._id.toString()
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      })

      /**
       * Check to make sure we have data befor running anything
       */
      const list = data ? data.getAlerts : null
      /**
       * Cache default is 0 for lastAlertSyncTime so this should always run
       */
      if (lastAlertSyncTime) {
        /**
         * Wait for that list of data
         */
        if (list) {
          /**
           * Let's loop through find each item and compare epoch
           */
          list.forEach((item) => {
            /**
             * Leverage for better comparing
             * https://www.epochconverter.com/programming/
             */
            formattedDate = new Date(item.created).getTime()
            if (formattedDate > lastAlertSyncTime) {
              newAlertsNumber++
            }
          })
          /**
           * Only write to cache if we have something worthy like new alerts :)
           */
          writeToCache(client, { alerts: { lastAlertSyncTime: formattedDate, newAlertsNumber } })
        }
      }
    }
    return newAlertsNumber
  } catch (error) {
    console.log(error, 'ERROR')
    return 0
  }
}
