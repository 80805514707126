// @flow
import { gql } from '@apollo/client'

export type GetMeRequestType = {
  me: {
    name: String,
    firstName: String,
    lastName: String,
    email: String,
    hasPassword: Boolean,
    memberships: [{
      _id: String,
      account: {
        name: String,
        likesEnabled: Boolean,
      },
      contentFeedProfiles: [{
        _id: String
      }]
    }]
  }
}

const GET_ME_QUERY = gql`
  query me {
    getMeRequest {
      _id
      name
      firstName
      lastName
      email
      mobileNumber
      mobileNumberOptInStatus
      mobileNumberOptInDate
      profilePhoto {
        _32px
        _64px
        _128px
        _256px
        _512px
      }
      originalProfilePhoto
      role
      hasPassword
      memberships {
        _id
        groups
        groupObjects {
          _id
          name
        }
        registrationCode
        registrationDate
        mobileNumber
        mobileNumberOptInStatus
        mobileNumberOptInDate
        account {
          _id
          name
          likesEnabled
          mentionsEnabled
          directoryEnabled
          commentsEnabled
          userAnalyticsEnabled
          displayContentAuthorEnabled
          contentFeedProfiles
          openRegistrationGroup
          defaultHomeView
          commentThreadingEnabled
          enableUserProfilePhotos
          enableFeatureSmsAlerts
          smsEnabled
        }
        contentFeedProfiles {
          _id
          primaryColor
          name
          admins
          everyoneGroup
          homeFolder {
            _id
          }
          carousel {
            s3Key
            carouselId
            title
            content
            smallCarouselKey
            mediumCarouselKey
            largeCarouselKey
            xlargeCarouselKey
            signedDownloadUrl
          }
          groups
          googleTranslationEnabled
        }
      }
    }
  }`
export default GET_ME_QUERY
