// @flow

import React from 'react'
import styled from 'styled-components'
import Icon from 'michelangelo/dist/SharedComponents/DataDisplay/Icons/IconComponent'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'
import color from 'michelangelo/dist/Components/styles/color'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import { useIntl } from 'react-intl'
import { messages } from '../../i18n/messages'

const DeleteCommentConfirmationWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
`

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: 500px;
  height: 302px;
  left: calc(50% - 500px/2);
  top: calc(50% - 302px/2 + 0.5px);
  background: #FFFFFF;
  box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.1);
  align-items: center;
`

const Footer = styled.div`
  background: #EEF2F6;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 15px;
  justify-content: flex-end;
  height: 70px;
`

const Gap = styled.div`
  width: 15px;
  height: 15px;
`

const IconWrapper = styled.div`
  padding-top: 40px;
`

const BoddyWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

type ConfirmModalProps = {
  show: ?boolean,
  onCancel: Function,
  onConfirm: Function,
  comment: ?string,
  testID: string,
  disableSubmitButton: boolean
}

function ConfirmModal (props: ConfirmModalProps) {
  const { show, onCancel, onConfirm, comment, testID, disableSubmitButton } = props
  const showHideClassName = show ? 'block' : 'none'
  const intl = useIntl()

  const onCancelClicked = () => {
    onCancel()
  }

  const onConfirmClicked = () => {
    onConfirm(comment)
  }

  return (
    <DeleteCommentConfirmationWrapper className='trashAlt' style={{ display: showHideClassName }}>
      <Modal data-cy={`${testID}_confirmModal`}>
        <IconWrapper>
          <Icon testID={`${testID}_confirmModal_delete_icon`} iconName='trashAlt' size={48} color={color.danger}/>
        </IconWrapper>
        <BoddyWrapper>
          <Text testID={`${testID}_confirmModal_delete_title_text`} text={intl.formatMessage(messages.deleteComment)} fontWeight="bold" textSize="h4" fontColor={color.danger} align='center'/>
          <Gap />
          <Text testID={`${testID}_confirmModal_delete_confirmation_text`} text={intl.formatMessage(messages.deleteCommentText)} fontWeight="bold" textSize="p" align='center'/>
        </BoddyWrapper>
        <Footer>
          <Button testID={`${testID}_confirmModal_cancel_button`} ariaLabel={intl.formatMessage(messages.cancel)} title={intl.formatMessage(messages.cancel)} onClick={onCancelClicked} outline='true' themeColor={color.info}/>
          <Gap />
          <Button disabled={disableSubmitButton} testID={`${testID}_confirmModal_delete_button`} ariaLabel={intl.formatMessage(messages.delete)} title={intl.formatMessage(messages.delete)} onClick={onConfirmClicked} fill='true' themeColor={color.danger} />
        </Footer>
      </Modal>
    </DeleteCommentConfirmationWrapper>
  )
}

export default ConfirmModal
