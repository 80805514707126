import { gql } from '@apollo/client'

const GET_QUICK_LINKS_QUERY = gql`
    query getQuickLinks($cfpId: String!) {
        getQuickLinks(cfpId: $cfpId,) {
            _id
            title
            thumbnailUrl
            isQuickLink
            displayThumbnail
            contentType
            likesEnabled
            commentsEnabled
            htmlSignedDownloadUrl
            signedDownloadUrl
            webUrl
            secure
        }
    }
`

export default GET_QUICK_LINKS_QUERY
