// @flow

import React from 'react'
import styled from 'styled-components'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Icon from 'michelangelo/dist/SharedComponents/DataDisplay/Icons/IconComponent'
import color from 'michelangelo/dist/Components/styles/color'

const TabLi = styled.li`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  cursor: pointer;
  background: ${props => props.active ? props.appBrandColor : props.appBrandColor + '1a'};
`
TabLi.displayName = 'TabLi'

const IconContainer = styled.div`
  margin-right: 10px;
`
IconContainer.displayName = 'IconContainer'

const TextContainer = styled.div`
`
TextContainer.displayName = 'TextContainer'

type TabItemProps = {
  icon: string,
  label: string,
  tag?: string,
  onClick?: Function
}

type TabsProps = {
  item: TabItemProps,
  appBrandColor: string,
  active: boolean,
  onClick?: Function
}
function TabItem (props: TabsProps) {
  const { item, appBrandColor, active, onClick } = props

  const handleItemClick = () => {
    if (onClick) onClick()
  }

  return (
    <TabLi appBrandColor={appBrandColor} active={active} onClick={handleItemClick}>
      <IconContainer>
        <Icon iconName={item.icon} color={active ? color.white : appBrandColor} size={22}/>
      </IconContainer>
      <TextContainer>
        <Text text={item.label} fontColor={active ? color.white : appBrandColor} fontSize="p" fontWeight={active ? 'bold' : 'normal'}/>
      </TextContainer>
    </TabLi>
  )
}

export default TabItem
