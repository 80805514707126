// @flow

import React from 'react'

type InfoItemTypeProps = {
  type: string,
  value: string,
  onClick?: Function
}

function InfoItemType (props: InfoItemTypeProps) {
  const { type, value, onClick } = props
  let Tag = 'a'
  const TagProps = {}

  switch (type) {
    case 'email':
      TagProps.href = `mailto:${value}`
      break
    case 'phone':
      TagProps.href = `tel:${value}`
      break
    case 'url':
      TagProps.href = value
      TagProps.target = '_blank'
      break
    case 'coordinates':
      TagProps.href = '#'
      if (onClick) TagProps.onClick = onClick
      break
    default:
      Tag = 'span'
      break
  }

  return (
    <Tag {...TagProps}>{ value }</Tag>
  )
}

export default InfoItemType
