// @flow

import React, { useState } from 'react'
import styled from 'styled-components'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import Icon from 'michelangelo/dist/SharedComponents/DataDisplay/Icons/IconComponent'
import color from 'michelangelo/dist/Components/styles/color'
import useClipboard from 'react-use-clipboard'
import InfoItemType from './InfoItemType'
import NotificationBanner from 'michelangelo/dist/Components/NotificationBanner'
import InfoItemTypeActions from './InfoItemTypeActions'

const Container = styled.div`
  display: flex;
  padding: 16px 0;
  border-bottom: solid 1px ${color.grey200};
  position: relative;
  align-items: center;
`
Container.displayName = 'Container'

const IconContainer = styled.div`
  padding-right: 16px;
`
IconContainer.displayName = 'IconContainer'

const CopyIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-bottom: -9px;
`
CopyIconContainer.displayName = 'CopyIconContainer'

const InfoContainer = styled.div`
`
InfoContainer.displayName = 'InfoContainer'

const LabelContainer = styled.div`
`
LabelContainer.displayName = 'LabelContainer'

const ValueContainer = styled.div`
`
ValueContainer.displayName = 'ValueContainer'

const NotificationBannerContainer = styled.div`
  display: flex;
  position: relative;
  left: -70px;
  bottom: 48px;
`
NotificationBannerContainer.displayName = 'NotificationBannerContainer'

const CopyButtonContainer = styled.div`
  position: relative;
  display: ${props => props.visible ? 'flex' : 'none'};
`
CopyButtonContainer.displayName = 'CopyButtonContainer'

type InfoItemProps = {
  icon?: string,
  label: string,
  value: string,
  type: string,
  appBrandColor: string,
  onClick?: Function
}

function InfoItem (props: InfoItemProps) {
  const { icon, label, value, type, appBrandColor, onClick } = props
  const [isCopied, setCopied] = useClipboard(value, {
    successDuration: 1000
  })
  const [showCopy, setShowCopy] = useState(false)

  const getIcon = () => {
    return (icon
      ? <IconContainer>
          <Icon iconName={icon} color="#AEB5BC" size={22}/>
        </IconContainer>
      : null)
  }

  const renderNotificationBanner = (bannerText: string, icon: string, type: string) => {
    return (
      <NotificationBannerContainer><NotificationBanner fullWidth autoClose bannerText={bannerText} icon={icon} type={type} /></NotificationBannerContainer>
    )
  }

  const getCopyToClipboardIcon = () => (
    <CopyButtonContainer visible={showCopy}>
      {isCopied ? renderNotificationBanner('Copied to clipboard', 'clone', 'info') : null}
      <CopyIconContainer>
        <Icon iconName='clone' color={color.lightGrey} size={16} onClick={() => { setCopied(true) }}/>
      </CopyIconContainer>
    </CopyButtonContainer>
  )

  return (
    <Container onMouseOver={() => { setShowCopy(true) }} onMouseLeave={() => { setShowCopy(false) }}>
      { getIcon() }
      <InfoContainer>
        <LabelContainer>
          <Text text={label} textSize="h5" fontColor="#AEB5BC" textTransform="capitalize"/>
        </LabelContainer>
        <ValueContainer>
          <InfoItemType type={type} value={value} onClick={onClick}/>
        </ValueContainer>
      </InfoContainer>
      { getCopyToClipboardIcon() }
      <InfoItemTypeActions value={value} type={type} appBrandColor={appBrandColor} />
    </Container>
  )
}

export default InfoItem
