// @flow
import { gql } from '@apollo/client'

const SEND_EMAIL_QUERY = gql`
  mutation sendEmail($accountId: String!, $email: String!, $title: String!) {
    sendEmail(req: { accountId: $accountId, email: $email, title: $title }) { 
    msg
    }
    
  }`

export default SEND_EMAIL_QUERY
