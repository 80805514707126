// @flow

import React from 'react'
import styled from 'styled-components'
import Icon from 'michelangelo/dist/SharedComponents/DataDisplay/Icons/IconComponent'

const Container = styled.div`
  position: absolute;
  right: 0;
  display: none;
  @media screen and (max-width: 1023px) {
    display: flex;
  }
`
Container.displayName = 'Container'

const ActionContainer = styled.div`
  width: 40px;
  height: 40px;
  background: ${props => props.appBrandColor + '1a'};
  border-radius: 9999px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`
ActionContainer.displayName = 'ActionContainer'

const Action = styled.a`
  width: 100%;
  height: 100%;
  padding: 12px;
`
Action.displayName = 'Action'

type InfoItemTypeProps = {
  type: string,
  value: string,
  appBrandColor: string,
  onClick?: Function
}

function InfoItemTypeActions (props: InfoItemTypeProps) {
  const { type, value, appBrandColor } = props
  const actions = []
  switch (type) {
    case 'email':
      actions.push({
        icon: 'envelope',
        href: `mailto:${value}`
      })
      break
    case 'phone':
      actions.push(
        {
          icon: 'phone',
          href: `tel:${value}`
        },
        {
          icon: 'comment',
          href: `sms:${value}`
        }
      )
      break
    default:
      break
  }

  return (
    <Container>
      {
        actions.map((action, index) => {
          return (
            <ActionContainer appBrandColor={appBrandColor} key={index}>
              <Action href={action.href}>
                <Icon color={appBrandColor} iconName={action.icon} size={15}/>
              </Action>
            </ActionContainer>
          )
        })
      }
    </Container>
  )
}

export default InfoItemTypeActions
