// @flow

import React from 'react'
import styled from 'styled-components'

import { messages } from '../../i18n/messages'
import { injectIntl } from 'react-intl'

import type { ReactComponentStyled } from 'styled-components'
import type { InjectIntlProvidedProps } from 'react-intl'
import { useNavigate } from 'react-router-dom'

/** Michelangelo Components */
import color from 'michelangelo/dist/Components/styles/color'
import Row from 'michelangelo/dist/Components/Row'
import Column from 'michelangelo/dist/Components/Column'
import Text from 'michelangelo/dist/Components/Text'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'

const LinkContainer: ReactComponentStyled<{}> = styled.div`
  padding: 8px 16px;
  margin-top: 50%;
`

const Spacer: ReactComponentStyled<{}> = styled.div`
  padding-bottom: 8px;
`

type RefreshProps = {
} & InjectIntlProvidedProps

const RefreshPage = ({ intl: { formatMessage } }: RefreshProps) => {
  const navigate = useNavigate()
  const handleRefresh = (e: any) => {
    e.preventDefault()
    navigate('/')
  }
  return (
    <Row classNames={['is-marginless', 'is-centered', 'is-multiline']}>
      <Column classNames={['is-paddingless', 'is-12']}>
        <Column classNames={['is-paddingless', 'is-4-desktop', 'is-offset-4-desktop', 'is-6-tablet', 'is-offset-3-tablet', 'is-10-mobile', 'is-offset-1-mobile']}>
          <LinkContainer>
            <Text align='left' textSize='p' fontColor={color.grey600} text={formatMessage(messages.refresh)}>
              <Spacer />
              <Button width='150px' textTransform='capitalize' fill themeColor={color.info} title='Go Home' fontSize='p' onClick={handleRefresh} />
            </Text>
          </LinkContainer>
        </Column>
      </Column>
    </Row>
  )
}

export default injectIntl(RefreshPage)
