import { gql } from '@apollo/client'

const directoryEntriesQuery = gql`
query getDirectoryEntries ($directoryId: String!, $limit: Int!, $offset: Int!, $search: String!, $locale: String) {
  directoryEntry: getDirectoryEntries(req: {directoryId: $directoryId, limit: $limit, offset: $offset, search: $search, locale: $locale} ) {
    _id,
    name,
    contactInfo,
    directoryContactPhoto {
      _32px
      _64px
      _128px
      _256px
      _512px
    }
    originalDirectoryContactPhoto
  }
}`

export default directoryEntriesQuery
