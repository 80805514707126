import { gql } from '@apollo/client'

const GET_EVENTS_QUERY = gql`query getEventsQuery ($cfpId: String!, $isUpcomingEvent: Boolean) {
  getEvents(req:{cfpId: $cfpId, isUpcomingEvent: $isUpcomingEvent}){
    _id
    account
    created
    title
    eventTimeZone
    startDate
    endDate
    allDay
    notes
    notify
    calendar {
      _id
      title
    }
    thumbnailS3Key
    smallThumbnailUrl
    mediumThumbnailUrl
    largeThumbnailUrl
    xlargeThumbnailUrl
    displayThumbnail
    signedThumbnailUrl
  }
}
`

export default GET_EVENTS_QUERY
