import { gql } from '@apollo/client'

export const GET_CAROUSEL_BY_CFP_ID_QUERY = gql`
  query GetCarouselByCfpId ($cfpId: String!) {
    getCarouselByCfpId(cfpId: $cfpId)
    {
      s3Key
      _id
      imageUrl
      content: contentObj {
        _id
        owner
        ownerName
        account
        title
        created
        modified
        modifiedBy
        notify
        includeInFeed
        accessGroups
        integrationId
        integrationType
        thumbnailS3Key
        smallThumbnailUrl
        mediumThumbnailUrl
        largeThumbnailUrl
        xlargeThumbnailUrl
        thumbnailJobId
        thumbnailJobStatus
        displayThumbnail
        publishDate
        webUrl
        s3Key
        tags
        share: shareType
        postDate
        unpostDate
        postTimezone
        posted
        notesEnabled
        cacheable
        contentType
        totalLikes
        totalComments
        totalViews
        secure
        contentFolder
        info
        hlsVideoPlaylistUrl
        transcodeVideoStatus
        likesEnabled
        commentsEnabled
        caption
        published
        likedByUser
        signedDownloadUrl
        htmlSignedDownloadUrl
      }
    }
  }
`
export default GET_CAROUSEL_BY_CFP_ID_QUERY
