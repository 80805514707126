// @flow

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { values } from 'lodash'
import Avatar from 'michelangelo/dist/Components/NativeAvatar'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import color from 'michelangelo/dist/Components/styles/color'
import Company from 'michelangelo/dist/Components/DirectoryEntryHeader/Company'
import Location from 'michelangelo/dist/Components/DirectoryEntryHeader/Location'
import Row from 'michelangelo/dist/WebComponents/Layout/Row'
import Column from 'michelangelo/dist/WebComponents/Layout/Column'
import Tabs from './Components/Tabs'
import Icon from 'michelangelo/dist/SharedComponents/DataDisplay/Icons/IconComponent'
import { useParams, useNavigate, useOutletContext } from 'react-router-dom'
import { checkIfCoordinates, checkIfEmail, checkIfPhone, checkIfUrl } from '../utils/validations'
import { injectIntl } from 'react-intl'
import type { InjectIntlProvidedProps } from 'react-intl'
import { messages } from '../i18n/messages'
import ProfileHelper from '../Helpers/ProfileHelper'

const ContactDetailsContainer = styled.div`
  padding: 57px 0px;
  @media screen and (max-width: 767.98px) {
    padding: 0;
  }
`
ContactDetailsContainer.displayName = 'ContactDetailsContainer'

const BodyContainer = styled.div`
  padding-top: 33px;
`
BodyContainer.displayName = 'BodyContainer'

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 767.98px) {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 28px;
    justify-content: center;
  }
`
HeaderContainer.displayName = 'HeaderContainer'

const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  @media screen and (max-width: 767.98px) {
    align-items: flex-start;
    margin-left: 30px;
    width: auto;
  }
`
HeaderInfo.displayName = 'HeaderInfo'

const AvatarContainer = styled.div`
`
AvatarContainer.displayName = 'AvatarContainer'

const NameContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 3px;
  @media screen and (max-width: 767.98px) {
    margin: 0;
  }
`
NameContainer.displayName = 'NameContainer'

const TitleContainer = styled.div`
`
TitleContainer.displayName = 'TitleContainer'

const AddressDivider = styled.div`
  position: absolute;
  border: 1px solid ${color.grey200};
  transform: rotate(90deg);
  margin: 32px auto;
  width: 37px;
  height: 0;
  left: 0;
  right: 0;
  @media screen and (max-width: 767.98px) {
    display: none;
  }
`
AddressDivider.displayName = 'AddressDivider'

const AddressContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 63px;
  @media screen and (max-width: 767.98px) {
    flex-direction: column;
  }
`
AddressContainer.displayName = 'AddressContainer'

const CompanyContainer = styled.div`
  @media screen and (min-width: 768px) {
    position: absolute;
    right: calc(50% + 32px);
    top: 16px;
  }
`
CompanyContainer.displayName = 'CompanyContainer'

const LocationContainer = styled.div`
  @media screen and (min-width: 768px) {
    position: absolute;
    left: calc(50% + 32px);
    top: 16px;
  }
`
LocationContainer.displayName = 'LocationContainer'

const ContactLink = styled.a`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 22px;
  @media screen and (min-width: 768px) {
    display: none;
  }
`
ContactLink.displayName = 'ContactLink'

type DirectoryEntryProps = {
  item: Object,
  appBrandColor: string,
  onBackButtonClick?: Function,
  emptyInfoText?: string,
  linkText: string
} & InjectIntlProvidedProps

function ContactDetails (props: DirectoryEntryProps) {
  const { appBrandColor, linkText, item, intl: { formatMessage } } = props
  const { cfpId, directoryId, entryId } = useParams()
  const navigate = useNavigate()
  const { directoryEntries, setSelectedContact } = useOutletContext()
  const [selectedItem, setSelectedItem] = useState(item)

  useEffect(() => {
    const foundItem = directoryEntries.find(e => e._id === entryId)

    if (!foundItem) {
      if (directoryEntries.length) {
        setSelectedContact(directoryEntries[0])
      } else {
        return
      }
    }
    if (foundItem) {
      setSelectedContact(foundItem)
      setSelectedItem(foundItem)
    }
  }, [entryId, directoryEntries, setSelectedContact])

  const getLocation = (city?: string, state?: string) => {
    if (city && state) {
      return `${city}, ${state}`
    } else if (city && !state) {
      return city
    } else if (!city && state) {
      return state
    } else {
      return null
    }
  }

  const getValue = (value: mixed) => {
    if (!value) {
      return null
    }
    if (typeof value === 'string') {
      return value
    }
    if (typeof value === 'object') {
      return getValue(value.value)
    }
    return null
  }

  const getHeaderInfo = (contactInfo: Object) => {
    let title
    let company
    let city
    let state
    if (contactInfo) {
      for (const [key, value] of Object.entries(contactInfo)) {
        if (key.toLowerCase().trim() === 'title') {
          title = getValue(value)
        } else if (key.toLowerCase().trim() === 'company') {
          company = getValue(value)
        } else if (key.toLowerCase().trim() === 'city') {
          city = getValue(value)
        } else if (key.toLowerCase().trim() === 'state') {
          state = getValue(value)
        }
      }
    }
    return { title, company, city, state }
  }

  const getIcon = (tag, label) => {
    let icon
    switch (tag) {
      case 'phone':
        switch (label && label.toLowerCase().trim()) {
          case 'home':
            icon = 'home'
            break
          case 'work':
          case 'office':
            icon = 'phoneOffice'
            break
          case 'mobile':
            icon = 'mobile'
            break
          default:
            icon = 'mobile'
            break
        }
        break
      case 'email':
        switch (label && label.toLowerCase().trim()) {
          case 'personal':
            icon = 'user'
            break
          case 'work':
            icon = 'building'
            break
          default:
            icon = 'building'
            break
        }
        break
      default:
        break
    }
    return icon
  }

  const getType = (value) => {
    if (checkIfEmail(value)) return 'email'
    if (checkIfPhone(value)) return 'phone'
    if (checkIfUrl(value)) return 'url'
    if (checkIfCoordinates(value)) return 'coordinates'
    return 'text'
  }

  const handleOnLocationClick = async () => {
    const { addressList } = ProfileHelper.getLists(selectedItem)
    await ProfileHelper.openAddress(addressList, selectedItem)
  }

  const handleOnBackButtonClick = () => {
    setSelectedContact(null)
    setSelectedItem(null)
  }

  const generateTabs = () => {
    const excludedKeys = ['company', 'title']
    // const addressKeysOrdered = ['address1', 'address2', 'address3', 'city', 'state', 'zip', 'country']

    const tabs = [
      {
        icon: 'phone',
        label: formatMessage(messages.phone),
        tag: 'phone',
        contents: []
      },
      {
        icon: 'envelope',
        label: formatMessage(messages.email),
        tag: 'email',
        contents: []
      },
      {
        icon: 'bars',
        label: formatMessage(messages.more)[0].toLocaleUpperCase() + formatMessage(messages.more).substring(1, formatMessage(messages.more).length),
        tag: 'other',
        contents: []
      }
    ]

    if (selectedItem?.contactInfo) {
      for (let i = 0; i < tabs.length; i++) {
        const tab = tabs[i]
        const storage = {}
        for (const [key, val] of Object.entries(selectedItem?.contactInfo)) {
          // $FlowFixMe
          storage[key] = val.value
        }
        const contents = values(selectedItem?.contactInfo).filter(i => i.tag === tab.tag)
        for (let j = 0; j < contents.length; j++) {
          const content = contents[j]
          // $FlowFixMe
          const key: string = content.key ? content.key : Object.keys(storage).find(key => storage[key] === content.value)
          if (tab.tag === 'other' && (excludedKeys.includes(key.toLowerCase()))) continue
          const tabContent = {
            icon: getIcon(tab.tag, content.key),
            label: key,
            value: content.value,
            type: getType(content.value),
            onClick: null
          }
          if (tabContent.type === 'coordinates') {
            tabContent.onClick = handleOnLocationClick
          }
          tab.contents.push(tabContent)
        }
      }
    }

    return tabs
  }

  const { title, company, city, state } = getHeaderInfo(selectedItem?.contactInfo)
  const location = getLocation(city || undefined, state || undefined)
  return (
    <ContactDetailsContainer>
      <ContactLink href="#" onClick={(e) => {
        e.preventDefault()
        if (handleOnBackButtonClick) handleOnBackButtonClick()
        const historyLink = linkText === 'Contacts' ? `directory/${directoryId}` : 'directory/search'
        navigate(`/${cfpId}/${historyLink}`)
      }}>
        <Icon iconName="leftArrow" size={20} color={appBrandColor}/> <Text text={linkText} fontColor={color.grey500} textSize="th"/>
      </ContactLink>
      <HeaderContainer>
        <AvatarContainer>
          <Avatar size={94} userName={selectedItem?.name} fontSize='h3' appBrandColor={appBrandColor} thumbnail={selectedItem?.directoryContactPhoto && selectedItem?.directoryContactPhoto._128px}/>
        </AvatarContainer>
        <HeaderInfo>
          <NameContainer>
            <Text text={selectedItem?.name} fontColor={appBrandColor} textSize='h3' />
          </NameContainer>
          <TitleContainer>
            <Text text={title || ''} fontColor={color.disabled} textSize='h6' />
          </TitleContainer>
          <AddressContainer>
            <CompanyContainer>
              <Company companyName={company} />
            </CompanyContainer>
            { (company && location) ? <AddressDivider/> : null }
            <LocationContainer>
              <Location location={location} onLocationClick={handleOnLocationClick} />
            </LocationContainer>
          </AddressContainer>
        </HeaderInfo>
      </HeaderContainer>
      <BodyContainer>
        <Row classNames={['is-marginless', 'is-centered']}>
          <Column classNames={['is-paddingless', 'is-three-quarters', 'is-12-mobile']}>
            <Tabs items={generateTabs()} appBrandColor={appBrandColor} emptyInfoText={''}/>
          </Column>
        </Row>
      </BodyContainer>
    </ContactDetailsContainer>
  )
}

export default injectIntl(ContactDetails)
