// @flow

import React from 'react'
import styled from 'styled-components'
import Icon from 'michelangelo/dist/Components/Icon'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'
import color from 'michelangelo/dist/Components/styles/color'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import type { InjectIntlProvidedProps } from 'react-intl'
import { useIntl } from 'react-intl'
import { messages } from '../../i18n/messages'

const EmptyNewsFeedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  background: ${color.white};
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.08);
  padding: 120px 0px;
`

const IconContainer = styled.div`
  margin: 16px 0px;
`
const TitleContainer = styled.div`
  margin: 8px 0px;
`
const DescriptionContainer = styled.div`
  margin-bottom: 24px;
`

// added for testing purposes
EmptyNewsFeedContainer.displayName = 'EmptyNewsFeedContainer'
IconContainer.displayName = 'IconContainer'
TitleContainer.displayName = 'TitleContainer'
DescriptionContainer.displayName = 'DescriptionContainer'

type InfoCardProps = {
  icon: string,
  title: string,
  description: string,
  refetch?: Function
} & InjectIntlProvidedProps

function EmptyNewsFeed (props: InfoCardProps) {
  const { icon, title, description, refetch } = props
  const intl = useIntl()

  return (
    <EmptyNewsFeedContainer>
      <IconContainer>
        <Icon color={color.lightGrey} iconName={icon} size="3x"/>
      </IconContainer>
      <TitleContainer>
        <Text text={title} fontWeight="bold" textSize="h4" fontColor={color.darkestGray}/>
      </TitleContainer>
      <DescriptionContainer>
        <Text text={description} textSize="h5" fontColor={color.lightGrey}/>
      </DescriptionContainer>
      { refetch
        ? <Button ariaLabel={intl.formatMessage(messages.reload)} icon='circle' themeColor={color.info} iconSize={16} title={intl.formatMessage(messages.reload)} onClick={() => refetch()} textThemeColor={color.info} textTransform="capitalize" disabled={false} />
        : null
      }
    </EmptyNewsFeedContainer>
  )
}

export default EmptyNewsFeed
