import { gql } from '@apollo/client'

/**
 * Don't add ownerName to the query if it's not enabled
 * @param {bool} displayContentAuthorEnabled
 */
const getNewsFeedQuery = (displayContentAuthorEnabled) => {
  let ownerName = ''
  if (displayContentAuthorEnabled) {
    ownerName = 'ownerName'
  }
  return gql`
    query getNewsFeed ($cfpId: String!, $limit: Int, $offset: Int) {
      getNewsFeed (req: { cfpId: $cfpId, limit: $limit, offset: $offset, displayContentAuthorEnabled: ${displayContentAuthorEnabled}, officeDocSupport: true, commentLimit: 10, commentOffset: 0 }) {
        ... on Content {
          _id
          owner
          ${ownerName}
          ownerPhoto {
            _32px
            _64px
            _128px
            _256px
            _512px
          }
          account
          title
          created
          modified
          modifiedBy
          notify
          includeInFeed
          accessGroups
          integrationId
          integrationType
          thumbnailS3Key
          smallThumbnailUrl
          mediumThumbnailUrl
          largeThumbnailUrl
          xlargeThumbnailUrl
          mentionsEnabled
          thumbnailJobId
          thumbnailJobStatus
          displayThumbnail
          publishDate
          webUrl
          s3Key
          tags
          share
          postDate
          unpostDate
          postTimezone
          posted
          notesEnabled
          cacheable
          contentType
          totalLikes
          totalComments
          totalFirstComments
          totalViews
          secure
          contentFolder
          info
          hlsVideoPlaylistUrl
          transcodeVideoStatus
          likesEnabled
          commentsEnabled
          caption
          pdfS3Key
          published
          likedByUser
          signedDownloadUrl
          htmlSignedDownloadUrl
          disableSaving
          hashtags
          usersMentioned
          mentions {
            id: _id
            name
          }
        }
        ... on Event {
          _id
          owner
          ownerName
          ownerPhoto {
            _32px
            _64px
            _128px
            _256px
            _512px
          }
          account
          title
          created
          modified
          modifiedBy
          notify
          includeInFeed
          accessGroups
          integrationId
          integrationType
          thumbnailS3Key
          smallThumbnailUrl
          mediumThumbnailUrl
          largeThumbnailUrl
          xlargeThumbnailUrl
          thumbnailJobId
          thumbnailJobStatus
          signedThumbnailUrl
          displayThumbnail
          eventTimeZone
          startDate
          endDate
          allDay
          notes
          calendar {
            _id
            title
          }
        }
      }
    }
  `
}

export default getNewsFeedQuery
