// @flow

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ListItem from 'michelangelo/dist/WebComponents/ListItem/ListItem'
import { cloneDeep, orderBy } from 'lodash'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { color } from 'michelangelo/dist/Components'
import LoadingScreen from '../Components/LoadingScreen'
import { useApolloClient, useLazyQuery } from '@apollo/client'
import { EXPLORE_FOLDERS } from '../apollo/queries/getExplorer'
import { readFromCache, writeToCache } from '../apollo/cacheHelper'

const ListContainer = styled.div`
  width: 100%;
  overflow: hidden;
`
const ChildrenContainer = styled.div`
  margin-left: 20px;
  width: 100%;
`

ListContainer.displayName = 'ListContainer'

type ListProps = {
  title: string,
  cfpId: string,
  homeFolder: string,
  selectedFolder: string,
  listItems: Array<Object>,
  activeFolders: Array<string>,
  loading: boolean,
  sort: string,
  sortField: string,
  onItemClick?: Function
} & InjectIntlProvidedProps

function FolderList (props: ListProps) {
  const client = useApolloClient()
  const { title, cfpId, homeFolder, selectedFolder, listItems, loading, sort, sortField, onItemClick, activeFolders } = props
  const [items, setItems] = useState(listItems)
  const [folderId, setFolderId] = useState(homeFolder)
  const [openedFolders, setOpenedFolders] = useState([...activeFolders])
  const [load, setLoad] = useState(false)
  const [initLoad, setInitLoad] = useState(loading)
  const [getFolders, { loading: loadingFolders, data: foldersData }] = useLazyQuery(EXPLORE_FOLDERS(),
    {
      variables: {
        cfpId,
        folderId
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  )

  useEffect(() => {
    if (selectedFolder === homeFolder) {
      getFolders({
        variables: {
          cfpId,
          folderId: selectedFolder
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolder, cfpId])

  useEffect(() => {
    setOpenedFolders([...activeFolders])
  }, [activeFolders])

  useEffect(() => {
    if (listItems && listItems.length && selectedFolder === folderId) setItems(sortFolders(listItems, sortField, sort))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listItems, selectedFolder])

  useEffect(() => {
    setItems(sortFolders(items, sortField, sort))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortField, sort])

  useEffect(() => {
    const itemsMutable = cloneDeep(items)
    if (!loadingFolders && foldersData && foldersData.getExplorerFolders) {
      const result = foldersData.getExplorerFolders.length ? foldersData.getExplorerFolders : []
      const parentId = result.length ? result[0].parentId : selectedFolder
      if (selectedFolder === homeFolder || parentId === homeFolder) {
        if (initLoad) setInitLoad(false)
        setItems(result)
      } else {
        const folder = findFolderById(itemsMutable || [], parentId)
        if (folder) {
          folder.children = result
          if (itemsMutable && itemsMutable.length) {
            const sortedFolderList = sortFolders(itemsMutable, sortField, sort)
            setItems(sortedFolderList)
            updateCache(parentId, result)
          }
        }
      }
    }
    if (!loadingFolders) setLoad(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingFolders, foldersData])

  // return folder immutable object and easy update children
  const findFolderById = (folders, id) => {
    for (const folder of folders) {
      if (folder._id === id) return folder
      if (folder.children && folder.children.length > 0) {
        const child = findFolderById(folder.children, id)
        if (child) return child
      }
    }
  }

  const sortFolders = (itemsToSort, sortField, sort) => {
    const sortList = sortField === 'priority' ? sortField : [item => item[sortField].toLowerCase()]
    return orderBy(itemsToSort, sortList, sort)
  }

  const updateCache = (parentId, children) => {
    const { folders: cacheFolders } = readFromCache(client, ['folders'])
    const cacheFoldersMutable = JSON.parse(JSON.stringify(cacheFolders))
    const parent = findFolderById(cacheFoldersMutable, parentId)
    if (parent) parent.children = children
    writeToCache(client, { folders: cacheFoldersMutable })
  }

  const onFolderClick = (item) => {
    if (onItemClick) {
      onItemClick(item)
    }
    if (!item.children) {
      if (!openedFolders.includes(item._id)) setLoad(true)
      getFolders({
        variables: {
          cfpId,
          folderId: item._id
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      })
    }
    setFolderId(item._id)
    if (!openedFolders.includes(item._id)) {
      setOpenedFolders([...openedFolders, item._id])
    } else {
      setOpenedFolders(openedFolders.filter(folder => folder !== item._id))
    }
  }

  const renderListItem = (item) => {
    const spin = (selectedFolder === item._id && loadingFolders && load)
    let icon = openedFolders.includes(item._id) ? 'chevronDown' : 'rightArrow'

    if (spin) {
      icon = 'spinner'
    }
    return <ListItem
      folderHoverShadow
      payload={item}
      spin={spin}
      customBackgroundColor={(selectedFolder === item._id) ? color.lightPrimaryActive : 'transparent'}
      icon={icon}
      label={item.title}
      onClick={onFolderClick}
    />
  }

  const renderList = () => {
    return items.length > 0
      ? (
      <ListContainer>
        { items.map(item => {
          const showChildren = item.children && !!item.children.length && openedFolders.includes(item._id)
          return (<div key={`folder_item_${item._id}`}>
            {renderListItem(item)}
            {showChildren && renderChildren(item)}
          </div>)
        }) }
      </ListContainer>
        )
      : null
  }

  const renderChildren = (item) => {
    const childrenList = item.children
    return (childrenList && childrenList.length > 0)
      ? <ChildrenContainer key={`folder_children_${item._id}`}>
      <FolderList
        title={title}
        cfpId={cfpId}
        homeFolder={homeFolder}
        selectedFolder={selectedFolder}
        activeFolders={activeFolders}
        listItems={childrenList}
        loading={false}
        sort={sort}
        sortField={sortField}
        onItemClick={(folder) => onItemClick(folder)}
      />
    </ChildrenContainer>
      : null
  }

  const getLoader = () => {
    return <LoadingScreen customHeight='40vh' />
  }

  return (
    <div>
      {(initLoad)
        ? getLoader()
        : renderList()}
    </div>
  )
}

export default injectIntl(FolderList)
