// @flow
import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { injectIntl } from 'react-intl'
import { messages } from '../../../i18n/messages'
import type { InjectIntlProvidedProps } from 'react-intl'
import IntegratedApps from 'michelangelo/dist/WebComponents/NewsFeed/Widgets/IntegratedApps/IntegratedApps'
import GET_APPS_QUERY from '../../../apollo/queries/apps'
import { trackAppOpenEvent } from '../../../Helpers/segmentHelper'
import styled from 'styled-components'

const WidgetWrapper = styled.div`
`

type AppsWidgetProps = {
  me: Object,
} & InjectIntlProvidedProps
const AppsWidget = ({ me, intl: { formatMessage } }: AppsWidgetProps) => {
  const [gridListData, setGridListData] = useState([])
  const { data: allApps } = useQuery(GET_APPS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      isMobile: false,
      os: 'web'
    },
    notifyOnNetworkStatusChange: true
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apps = allApps?.getApps || []

  const onAppClick = async (appItem) => {
    await trackAppOpenEvent(appItem)
    window.open(appItem.webUrl, '_blank')
  }
  useEffect(() => {
    let appsSection
    if (apps) {
      appsSection = {
        onClick: (appItem) => onAppClick(appItem),
        list: [...apps]
      }
    }

    if ([...apps].length > 0) {
      setGridListData(gridListData => [...gridListData, appsSection])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apps])
  return (
    <WidgetWrapper>
      <IntegratedApps dataList={gridListData} noAppsMessage={formatMessage(messages.noAppIntegrations)} appTitle={formatMessage(messages.myApps)}/>
    </WidgetWrapper>
  )
}

export default injectIntl(AppsWidget)
