import { gql } from '@apollo/client'

const directoriesQuery = gql`
query getDirectories ($contentFeedProfile: String!, $limit: Int!, $offset: Int!, $locale: String) {
  directory: getDirectories(req: {contentFeedProfile: $contentFeedProfile, limit: $limit, offset: $offset, locale: $locale }) {
    _id,
    name
  }
}`

export default directoriesQuery
