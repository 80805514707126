import { gql } from '@apollo/client'

/**
 * File to hold updating user information
 */

const UPDATE_USER_NAME_MUTATION = gql`
  mutation userNameUpdate ($userId: String!, $firstName: String!, $lastName: String!) {
    userNameUpdate(req: {userId: $userId, firstName: $firstName, lastName: $lastName}){
      success
    }
  }
`

const UPDATE_USER_RECENT_CONTENT_MUTATION = gql`
  mutation userRecentContentUpdate ($userId: String!, $contentId: String!) {
    userRecentContentUpdate(req: {userId: $userId, contentId: $contentId}){
      success
    }
  }
`

const UPDATE_USER_PROFILE_PHOTO_MUTATION = gql`
  mutation profilePhotoCreation ($file: Upload!) {
    profilePhotoCreation(file: $file)
  }
`

const DELETE_USER_PROFILE_PHOTO_MUTATION = gql`
  mutation profilePhotoDelete {
    profilePhotoDelete
  }
`

export { UPDATE_USER_NAME_MUTATION, UPDATE_USER_RECENT_CONTENT_MUTATION, UPDATE_USER_PROFILE_PHOTO_MUTATION, DELETE_USER_PROFILE_PHOTO_MUTATION }
