// @flow

import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import Icon from 'michelangelo/dist/Components/Icon'
import color from 'michelangelo/dist/Components/styles/color'
import { useNavigate } from 'react-router-dom'

const InputContainer = styled.div`
  display: flex;
  border: 1px solid ${color.grey200};
  background: ${color.white};
  border-radius: 16px;
  align-items: center;
  padding: 5px 10px;
  margin-top: 18px;
  position: relative;
`
InputContainer.displayName = 'InputContainer'

const Input = styled.input`
  appearance: none;
  border: 0;
  outline: none;
  width: 100%;
  color: ${color.grey800};
  margin-left: 10px;
  margin-right: 10px;
  ::placeholder {
    color: ${color.grey400};
  }
  ::-ms-clear { 
    display:none; 
  }
`
Input.displayName = 'Input'

const Anchor = styled.a`
  display: flex;
`
Anchor.displayName = 'Anchor'

type SearchInputProps = {
  onChange: Function,
  placeholder?: string,
  loading?: boolean,
  cfpId: string
}

function SearchInput (props: SearchInputProps) {
  const { onChange, placeholder, loading, cfpId } = props
  const inputRef = useRef('')
  const [value, setValue] = useState('')
  const navigate = useNavigate()

  const handleOnChangeEvent = (e) => {
    setValue(e.target.value)
    if (e.target.value === '') navigate(`/${cfpId}/directory`)
    if (onChange) onChange(e.target.value)
  }
  const clearInput = (e) => {
    e.preventDefault()
    if (inputRef && inputRef.current) {
      inputRef.current.value = ''
      navigate(`/${cfpId}/directory`)
    }
    setValue('')
    if (onChange) onChange('')
  }
  return (
    <InputContainer>
      <Icon iconName="search" size="sm" color={color.grey400}/>
      <Input ref={inputRef} type="text" onChange={handleOnChangeEvent} placeholder={placeholder} />
      { (value || loading)
        ? <Anchor href="#" onClick={(e) => { clearInput(e) }}>
          <Icon spin={loading} iconName={loading ? 'spinner' : 'x'} size="sm" color={color.grey400} />
          </Anchor>
        : null }
    </InputContainer>
  )
}

export default SearchInput
