// @flow
import React, { useState, useEffect } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'
import { readFromCache } from '../../../apollo/cacheHelper'
import { injectIntl } from 'react-intl'
import { messages } from '../../../i18n/messages'
import { useNavigate } from 'react-router-dom'
import type { InjectIntlProvidedProps } from 'react-intl'
import Avatar from 'michelangelo/dist/SharedComponents/DataDisplay/Avatar/Avatar'
import { trackProfileWidget } from '../../../Helpers/segmentHelper'
import eventTypes from '../../../Helpers/eventTypeHelper'
import GET_GRID_QUERY from '../../../apollo/queries/getGridByAccountId'
import styled from 'styled-components'
import { getProfilePhoto } from '../../../Helpers/UserInfoUtil'

const WidgetWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ProfileWidgetLeft = styled.div``
const ProfileWidgetRight = styled.div`
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ProfileWidgetName = styled.p`
  font-size: 16px;
  margin-bottom: 8px;
`

const ProfileWidgetViewProfile = styled.p`
  font-size: 12px;
  color: #3fa8f0;
  cursor: pointer;
  padding-bottom: 4px;
  width: fit-content;
  border-bottom: 1px solid #3fa8f0;
  margin-bottom: 0;
`

ProfileWidgetViewProfile.displayName = 'ProfileWidgetViewProfile'

type ProfileWidgetProps = {
  me: Object,
} & InjectIntlProvidedProps;
const ProfileWidget = ({ me, intl: { formatMessage } }: ProfileWidgetProps) => {
  const [gridEnabled, setGridEnabled] = useState(false)
  const enableUserProfilePhotos = !!me.memberships[0].account.enableUserProfilePhotos

  const client = useApolloClient()
  const navigate = useNavigate()

  const { activeCfp } = readFromCache(client, ['activeCfp'])

  const accountId = me.memberships[0].account._id
  const { data } = useQuery(GET_GRID_QUERY, {
    variables: { accountId },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (data && data.getGridByAccountId) {
      setGridEnabled(data.getGridByAccountId.enabled)
    }
  }, [data])

  function renderProfilePhoto () {
    return (
      <Avatar userName={me.name} darkTheme={true} appBrandColor={activeCfp.primaryColor} size={64} editText={formatMessage(messages.editImage)} onClick={enableUserProfilePhotos ? handleEditImage : null} canEdit={enableUserProfilePhotos} thumbnail={getProfilePhoto(me.profilePhoto, 1)} />
    )
  }

  function handleProfileClick () {
    trackProfileWidget(client, eventTypes.PROFILE_WIDGET_VIEW_PROFILE)

    navigate(`/${activeCfp._id}/my-profile`)
  }

  function handleEditImage () {
    trackProfileWidget(client, eventTypes.PROFILE_WIDGET_EDIT)

    navigate(`/${me._id}/my-profile`, { editModal: true })
  }

  return (
    <>
      {gridEnabled && (
        <WidgetWrapper>
          <ProfileWidgetLeft class="profile-widget__left">
            {renderProfilePhoto()}
          </ProfileWidgetLeft>
          <ProfileWidgetRight class="profile-widget__right">
            <ProfileWidgetName>{me.name}</ProfileWidgetName>

            <ProfileWidgetViewProfile onClick={handleProfileClick}>
              {formatMessage(messages.viewProfile)}
            </ProfileWidgetViewProfile>
          </ProfileWidgetRight>
        </WidgetWrapper>
      )}
    </>
  )
}

export default injectIntl(ProfileWidget)
