import { gql } from '@apollo/client'

const SEARCH_ITEMS_QUERY = gql`
   query getSearchItems($offset: Int!, $limit: Int!, $displayContentAuthorEnabled: Boolean!, $officeDocSupport: Boolean!, $cfpId: String!, $searchTerm: String!) {
     getSearchItems(req: {limit: $limit, offset: $offset, displayContentAuthorEnabled: $displayContentAuthorEnabled, cfpId: $cfpId, searchTerm: $searchTerm, officeDocSupport: $officeDocSupport}) {
       result {
         content {
           _id
           owner
           ownerName
           account
           label: title
           webUrl
           s3Key
           created
           modified
           postDate
           unpostDate
           contentType
           includeInFeed
           accessGroups
           contentFolder
           hlsVideoPlaylistUrl
           thumbnailS3Key
           smallThumbnailUrl
           mediumThumbnailUrl
           largeThumbnailUrl
           xlargeThumbnailUrl
           displayThumbnail
           likesEnabled
           commentsEnabled
           caption
           published
           signedDownloadUrl
           htmlSignedDownloadUrl
         }
         folders {
           _id
           cfpIds
           owner
           accountId
           account {
             _id
           }
           created
           modified
           label: title
           parentId
           integrationId
           integrationType
           priority
           isHomeFolder
         }
         events {
           _id
           account
           created
           label: title
           eventTimeZone
           startDate
           endDate
           allDay
           notes
           notify
           thumbnailS3Key
           smallThumbnailUrl
           mediumThumbnailUrl
           largeThumbnailUrl
           xlargeThumbnailUrl
           displayThumbnail
           signedThumbnailUrl
         }
       }
       totalCount
     }
   }
`
export default SEARCH_ITEMS_QUERY
