// @flow

import moment from 'moment'

const DAYS_BEFORE_A_MONTH_AGO = 25 // moment js outputs 'a month ago' starting from 26 days instead of 30/31 https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/02-fromnow/

const getDaysBetween = (beginningDate: Date, endingDate: Date) => {
  const oneDay = 86400000 // time in ms 1000 * 60 * 60 * 24
  const beginningDateInMs = beginningDate.getTime()
  const endingDateInMs = endingDate.getTime()
  return Math.round((endingDateInMs - beginningDateInMs) / oneDay)
}

const getDateFromNow = (date: Date) => {
  if (isDateBeforeOneMonth(date)) {
    return moment(date).fromNow()
  } else {
    return moment(date).format('LL')
  }
}

const getDateFromNowSingleFormat = (date: Date) => {
  return moment(date).fromNow()
}

const isDateBeforeOneMonth = (date: Date) => {
  const currentDate = new Date()
  const days = getDaysBetween(date, currentDate)
  return days <= DAYS_BEFORE_A_MONTH_AGO
}

export default { getDateFromNow, getDateFromNowSingleFormat, isDateBeforeOneMonth }
