import { gql } from '@apollo/client'

export const GET_GRID_BY_ACCOUNT_ID_QUERY = gql`
  query GetGridByAccountId ($accountId: String!) {
    getGridByAccountId(accountId: $accountId) {
      enabled
      widgets {
        _id
        enabled
        title
        type
        widgetParams
        position
      }
    }
  }
`
export default GET_GRID_BY_ACCOUNT_ID_QUERY
