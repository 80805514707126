// @flow

import axios from 'axios'
import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'

const WebView = styled.iframe`
  width: 100%;
  background-color: white;
  padding: 8px 24px;
  height: calc(100% - 80px);
  ${(props) => props.isMobilePreview && `
    height: 100%;
    padding: 0;
    border-radius: 4px;
  `}
`

type ContentPreviewIFrameProps = {
  downloadUrl: String,
  isMobilePreview: boolean
}

function ContentPreviewIFrame (props: ContentPreviewIFrameProps) {
  const { downloadUrl, isMobilePreview } = props

  const iframeRef = useRef<any>(null)

  const resizePages = (pages) => {
    for (let i = 0; i < pages.length; i++) {
      const small = window.outerWidth * 0.00116
      const medium = window.outerWidth * 0.0008
      const large = window.outerWidth * 0.00053
      if (window.outerWidth > 1408) {
        pages[i].style.transform = 'scale(1, 1)'
        pages[i].style.transformOrigin = 'center'
        pages[i].style.margin = '0 auto'
      } else if (window.outerWidth > 1024 && window.outerWidth < 1408) {
        pages[i].style.transform = `scale(${large}, 1)`
        pages[i].style.transformOrigin = 'left'
        pages[i].style.marginLeft = '35px'
      } else if (window.outerWidth > 1001 && window.outerWidth < 1024) {
        pages[i].style.transform = 'scale(0.789, 1)'
        pages[i].style.transformOrigin = 'left'
        pages[i].style.marginLeft = '35px'
      } else if (window.outerWidth > 768 && window.outerWidth < 1001) {
        pages[i].style.transform = `scale(${medium}, 1)`
        pages[i].style.transformOrigin = 'left'
        pages[i].style.marginLeft = '35px'
      } else {
        pages[i].style.transform = `scale(${small}, 1)`
        pages[i].style.transformOrigin = 'left'
      }
    }
  }

  useEffect(() => {
    const init = async () => {
      await axios.get(downloadUrl)
        .then((res) => {
          // parse html string into DOM Document
          const parser = new DOMParser()
          const DOMdocument: any = parser.parseFromString(res.data, 'text/html')

          // get all links of document
          const links = DOMdocument.getElementsByTagName('a')

          // for each link, add target blank attribute
          for (let i = 0; i < links.length; i++) {
            links[i].setAttribute('target', '_blank')
          }

          // get each page of a processed pdf
          const singlePage = DOMdocument.getElementById('page1-div')
          const multiPage = DOMdocument.getElementsByClassName('pf')
          const pages = singlePage ? [singlePage] : multiPage
          // resize pages on initial render
          resizePages(pages)

          // write innerHTML of document inside iframe
          const doc = iframeRef.current && iframeRef.current.contentDocument
          if (doc) {
            doc.open()
            doc.write(DOMdocument.documentElement.innerHTML)
            doc.close()
          }
        })
    }
    init()
  }, [downloadUrl])
  useEffect(() => {
    const scale = () => {
      // check if iframe exists
      const doc = iframeRef.current && iframeRef.current.contentDocument
      if (doc) {
        // get each page of a processed pdf
        const singlePage = doc.getElementById('page1-div')
        const multiPage = doc.getElementsByClassName('pf')
        const pages = singlePage ? [singlePage] : multiPage
        // resize pages after initial render
        resizePages(pages)
      }
    }
    scale()
  })
  return (
    <WebView ref={iframeRef} isMobilePreview={isMobilePreview} />
  )
}

export default ContentPreviewIFrame
