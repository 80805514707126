import { gql } from '@apollo/client'

const SEARCH_HASHTAGS_QUERY = (includePostCount) => {
  let totalPosts = ''
  if (includePostCount) {
    totalPosts = 'totalPosts'
  }

  return gql`
    query searchHashtags($cfpId: String!, $searchValue: String!) {
      searchHashtags(req: { cfpId: $cfpId, searchValue: $searchValue }) {
        _id
        cfpId
        value
        ${totalPosts}
      }
    }
  `
}

export default SEARCH_HASHTAGS_QUERY
