import { gql } from '@apollo/client'

const GET_LOGO_URL_QUERY = gql`
  query getLogoUrl ($cfpId: String!) {
      getLogoUrl (req: { cfpId: $cfpId }) {
        logoUrl
      }
}`

export default GET_LOGO_URL_QUERY
