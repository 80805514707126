// @flow
import React from 'react'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import color from 'michelangelo/dist/Components/styles/color'

type TranslateToggleProps = {
    alertId: string,
    testID: string,
    translateTitle?: string,
    renderTranslate: Function
}
const TranslateToggle = ({ alertId, testID, translateTitle = 'See Translation', renderTranslate }: TranslateToggleProps) => {
  return <Text
    testID={testID || 'TranslateToggle'}
    fontColor={color.grey500}
    textTransform='capitalize'
    textSize='h6' // maybe move in props for other implementation
    themeColor={color.grey500}
    ariaLabel={translateTitle}
    text={translateTitle}
    // $FlowFixMe
    onPress={async () => await renderTranslate(alertId)}
  />
}

export default TranslateToggle
