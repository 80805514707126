// @flow

import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import color from 'michelangelo/dist/Components/styles/color'
import ListHeader from 'michelangelo/dist/WebComponents/ListItem/ListHeader'
import ListItem from 'michelangelo/dist/WebComponents/ListItem/ListItem'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'
import { clone, orderBy, isEmpty, every, isEqual } from 'lodash'
import ListRow from 'michelangelo/dist/WebComponents/ListItem/ListRow'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../i18n/messages'
import { usePrevious } from '../utils/hooks'

const ListContainer = styled.div`
  margin-bottom: 30px;
`
const TextsContainer = styled.div`
  display: flex;
  align-listItems: center;
  justify-content: flex-start;
  @media (max-width: 767px) {
    display: none;
  }
`
const TitleContainer = styled.div`
  padding: 8px;
`
const SeeAllContainer = styled.div`
  padding: 8px;
  margin-left: auto;
`

ListContainer.displayName = 'ListContainer'
TextsContainer.displayName = 'TextsContainer'
TitleContainer.displayName = 'TitleContainer'
SeeAllContainer.displayName = 'SeeAllContainer'

type ListColumnProps = {
  label: string,
  prop: string,
  hasIcon?: boolean,
  render?: Function,
  ratio?: number,
  isSortable?: boolean,
  sortFunction?: Function,
  type?: string,
  tooltipProp?: string,
  onSortClick?: Function
}

type defaultSortObj = {
  type?: string,
  field?: string
}

type ListProps = {
  title: string,
  listItems: Array<Object>,
  loading: boolean,
  onItemClick?: Function,
  onSeeAllClick?: Function,
  onLoadMoreClick?: Function,
  columns: Array<ListColumnProps>,
  defaultSort?: defaultSortObj,
  handleSortChange?: Function,
  updateContentsList?: Function,
  pageOpened?: number,
  updatePage?: Function
} & InjectIntlProvidedProps

function List (props: ListProps) {
  const { listItems, loading, onItemClick, columns, title, intl: { formatMessage }, onSeeAllClick, onLoadMoreClick, defaultSort, handleSortChange, updateContentsList, pageOpened, updatePage } = props
  const perPage = 5
  const [currentPage, setCurrentPage] = useState(pageOpened > 0 ? pageOpened : 1)
  const [selectedColumn, setSelectedColumn] = useState(null)
  const [lastPage, setLastPage] = useState(1)
  const [items, setItems] = useState([])
  const [sort, setSort] = useState(defaultSort)
  const [isDefaultSort, setIsDefaultSort] = useState(true)

  useEffect(() => {
    handleSortChange && handleSortChange(sort)
  }, [sort, handleSortChange])

  useEffect(() => {
    updatePage && updatePage(currentPage)
  }, [currentPage, updatePage])

  const prevSort = usePrevious(sort)
  const prevCurrentPage = usePrevious(currentPage)
  let testID = 'testID'
  const sortItems = useCallback((_items, _sort, _sortFunction) => {
    let sortFunction = (item) => (item[_sort.field] ? item[_sort.field].toLowerCase() : '')
    if (_sortFunction) sortFunction = _sortFunction
    return orderBy(_items, [item => sortFunction(item)], [_sort.type])
  }, [])

  useEffect(() => {
    const cloneItems = clone(listItems) || []
    setLastPage(listItems.length ? Math.ceil(listItems.length / perPage) : 0)
    setItems(cloneItems.splice(0, perPage))
    setCurrentPage(pageOpened)
    setSort(defaultSort)
    setIsDefaultSort(true)
    setSelectedColumn(columns.find(column => column.prop === defaultSort.field))
  }, [listItems, defaultSort, columns, pageOpened])
  useEffect(() => {
    if (isEmpty(listItems)) return
    const pageChanged = currentPage !== prevCurrentPage // check if page has changed
    let sortChange = !isEqual(sort, prevSort) // check if sort has changed
    if (isDefaultSort) sortChange = true // if is default sort, do the sort
    const emptySort = every(sort, isEmpty) // check if sort obj is not empty
    if (!pageChanged && !sortChange) return // prevent rerender if not page or sort change has occurred
    let cloneItems = clone(listItems)
    let myData
    let _perPage = perPage
    let startIndex = currentPage === 1 ? 0 : (currentPage - 1) * _perPage
    if (sortChange || pageChanged) {
      if (!emptySort) cloneItems = sortItems(cloneItems, sort, selectedColumn ? selectedColumn.sortFunction : null)
      if (!emptySort) myData = sortItems(cloneItems, sort, selectedColumn ? selectedColumn.sortFunction : null)
      updateContentsList && updateContentsList(myData)
      startIndex = 0
      _perPage *= currentPage
    }
    const nextItems = cloneItems.splice(startIndex, _perPage)
    setItems(prev => {
      if (sortChange || pageChanged) return nextItems
      return [...prev, ...nextItems]
    })
  }, [sort, prevSort, listItems, currentPage, prevCurrentPage, lastPage, sortItems, selectedColumn, isDefaultSort, updateContentsList])

  const handleListHeaderClickEvent = (column, sortType, sortField) => {
    let s
    switch (sortType) {
      case 'arrowUp':
        s = 'asc'
        break
      case 'arrowDown':
        s = 'desc'
        break
      default:
        s = null
        break
    }
    setSort({
      type: s,
      field: s ? sortField : null
    })
    setIsDefaultSort(false)
    setSelectedColumn(column)
    if (column.onSortClick) column.onSortClick(column.label)
  }

  const renderListHeaders = () => {
    const columnRatios = columns.map(c => c.ratio || 1)
    return (
      <ListRow columnRatios={columnRatios}>
        {
          columns.map((column, index) => {
            const isActive = selectedColumn ? selectedColumn === column : false
            const isAsc = defaultSort.type === 'asc'
            return <ListHeader isActive={isActive} canSort={column.isSortable} label={column.label} onClick={(sorting) => handleListHeaderClickEvent(column, sorting, column.prop)} key={index} isAsc={isAsc} hasDisabledState={false}/>
          })
        }
      </ListRow>
    )
  }

  const handleItemClickEvent = useCallback((item) => {
    return onItemClick ? onItemClick(item) : null
  }, [onItemClick])

  const renderListItem = (item, index) => {
    const columnRatios = columns.map(c => c.ratio || 1)
    if (process.env.NODE_ENV === 'development') {
      testID = item.title.split(' ').join('')
    }
    return <ListRow testID={testID} columnRatios={columnRatios} key={index} onClick={() => handleItemClickEvent(item)}>
      {
        columns.map((column, index) => {
          return <ListItem
            key={index}
            label={column.render ? column.render(item) : item[column.prop]}
            contentType={column.hasIcon ? column.type ? column.type : item.contentType : null}
            tooltipLabel={item[column.tooltipProp || column.prop]}
          />
        })
      }
    </ListRow>
  }

  const renderListItems = () => {
    return items.map((item, index) => (
      renderListItem(item, index)
    ))
  }

  const renderList = () => {
    return (
      <ListContainer>
        { renderListHeaders() }
        { renderListItems() }
      </ListContainer>
    )
  }

  const onLoadMoreButtonClick = () => {
    setCurrentPage(currentPage + 1)
    const totalItems = listItems.length < (items.length + perPage) ? listItems.length : items.length + perPage
    if (onLoadMoreClick) onLoadMoreClick(totalItems)
  }
  const onSeeAllButtonClick = () => {
    setItems(listItems)
    setCurrentPage(lastPage)
    if (onSeeAllClick) onSeeAllClick(listItems.length)
  }

  const getLoadMoreButton = () => {
    return <Button ariaLabel={`${formatMessage(messages.loadMore)}...`} themeColor={color.info} iconSize={16} title={`${formatMessage(messages.loadMore)}...`} onClick={onLoadMoreButtonClick} textThemeColor={color.info} textTransform="none" disabled={false} />
  }

  return (
    <div>
      { !loading && listItems.length
        ? (
        <ListContainer>
          <TextsContainer>
            <TitleContainer>
              <Text text={title} fontWeight="bold" textSize="th" fontColor={color.grey400} textTransform="uppercase"/>
            </TitleContainer>
            {currentPage < lastPage
              ? <SeeAllContainer>
                <Text text={formatMessage(messages.seeAll)} textSize="h5" fontWeight="regular" fontColor={color.info} onPress={onSeeAllButtonClick}/>
              </SeeAllContainer>
              : null}
          </TextsContainer>
          { renderList() }
          { currentPage < lastPage ? getLoadMoreButton() : null }
        </ListContainer>
          )
        : null }
    </div>
  )
}

export default injectIntl(List)
