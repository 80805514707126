/* eslint-disable react/display-name */
// @flow

import React, { useEffect, useState, memo } from 'react'
import Row from 'michelangelo/dist/WebComponents/Layout/Row'
import Column from 'michelangelo/dist/WebComponents/Layout/Column'
import styled from 'styled-components'
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client'
import { EXPLORE_CONTENTS } from '../apollo/queries/getExplorer'
import SEND_EMAIL_QUERY from '../apollo/queries/sendEmail'
import GET_EXPLORE_BANNER_OPTION from '../apollo/queries/application'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import Text from 'michelangelo/dist/SharedComponents/Typography/Text'
import color from 'michelangelo/dist/Components/styles/color'
import Carousel from '../Components/Carousel'
import {
  cookieTimer,
  onLoad,
  trackContentOpened,
  trackBannerOpened,
  trackFolderOpenEvent,
  trackContentShared
} from '../Helpers/segmentHelper'
import ExplorerBanner from '../Components/ExplorerBanner'
import ContentList from './ContentList'
import FolderList from './FolderList'
import { get, isEqual } from 'lodash'
import { readFromCache } from '../apollo/cacheHelper'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../i18n/messages'
import LoadingScreen from '../Components/LoadingScreen'
import { contentLocations, contentShareType } from '../Helpers/contentHelper'
import { checkIsReadAcknowledgement } from '../Helpers/ReadAcknowledgement'
import { goToOldContentPreview, goToContentPreview } from '../Helpers/contentPreviewHelper'
import UrlHelper from '../Helpers/urlHelper'
import OverlayPage from '../Components/OverlayPage'
import useDimensions from 'michelangelo/dist/Components/helpers/useDimensions'
import Dropdown from 'michelangelo/dist/WebComponents/Inputs/Dropdown'
import ListItem from 'michelangelo/dist/WebComponents/ListItem/ListItem'
import * as copy from 'copy-to-clipboard'
import { hermesUrl } from '../config'
import { cloneDeep } from 'lodash/lang'

const ExploreContainer = styled.div`
`

const CarouselContainerExplore = styled.div`
  margin-bottom: 0;
`

const DropdownContainer = styled.div`
//  margin-left: 15px;
//  border: 1px solid;
`

const TitleContainer = styled.div`
  display: flex;
  padding: 8px;
  padding-bottom: 16px;
  background-color: ${color.white};
  justify-content: flex-start;
`

const FolderTitleContainer = styled.div`
  display: flex;
  padding: 8px;
  padding-bottom: 16px;
  justify-content: flex-start;
  margin-left: 8px;
`

const Container = styled.div`
  flex-grow: 1;
  margin: 16px auto 30px auto;
  position: relative;
  padding: 24px 16px;
  max-width: 1152px;
  @media (max-width: 960px) {
    margin: 16px 32px 22px 32px;
  }
  @media (max-width: 767px) {
    margin: 16px 0 0 0;
    padding: 0;
  }
`

const MostPopularHeaderContainer = styled.div`
  padding: 8px 16px;
`
const MostPopularContainer = styled.div`
  max-width: 1152px;
  margin: 0 auto;
`

ExploreContainer.displayName = 'ExploreContainer'
// $FlowFixMe
ExplorerBanner.displayName = 'ExploreBanner' // typo on explorER
CarouselContainerExplore.displayName = 'CarouselContainerExplore'
// $FlowFixMe
Carousel.displayName = 'CarouselExplore'
TitleContainer.displayName = 'TitleContainer'
Container.displayName = 'Container'

const ITEMS_PER_PAGE = 10

type ExploreProps = {
  me: Object,
  grid: boolean
} & InjectIntlProvidedProps

function Explore ({ me, grid: gridEnabled, intl: { formatMessage } }: ExploreProps) {
  const client = useApolloClient()
  const navigate = useNavigate()
  const location = useLocation()

  const { state, search } = location
  const { cfpId } = useParams()
  const { activeCfp, folders: cacheFolders } = readFromCache(client, ['activeCfp', 'folders'])
  const { width } = useDimensions()

  const homeFolderId = activeCfp.homeFolder._id
  const { activeFolder, folderSortField: folderField, folderSortType, contentSortField: contentField, contentSortType } = UrlHelper.useQueryParams(search)

  const initialFolder = activeFolder || homeFolderId
  const initialContents = state && state.contents ? state.contents : []
  const initFolderFilters = folderField && folderSortType ? { type: folderSortType, field: folderField } : { type: 'asc', field: 'priority' }
  const initContentFilters = contentField && contentSortType ? { type: contentSortType, field: contentField } : { type: 'asc', field: 'priority' }

  const [folderId, setFolderId] = useState(initialFolder)
  const [folders, setFolders] = useState(cacheFolders)
  const [contents, setContents] = useState(initialContents)
  const [hasNeedForMore, setHasNeedForMoreFetch] = useState(true)
  const [bannerType, setBannerType] = useState('NONE')
  const [showOverlay, setShowOverlay] = useState(false)
  const [folderSortDirection, setFolderSortDirection] = useState(initFolderFilters.type)
  const [folderSortField, setFolderSortField] = useState(initFolderFilters.field)
  const [contentSortDirection, setContentSortDirection] = useState(initContentFilters.type)
  const [contentSortField, setContentSortField] = useState(initContentFilters.field)
  const [activeFolders, setActiveFolders] = useState([])
  const [canLoadMore, setCanLoadMore] = useState(true)
  const folderSortLabels = [formatMessage(messages.folderNameAscending), formatMessage(messages.folderNameDescending), formatMessage(messages.folderDefault)]
  const displayContentAuthorEnabled = me.memberships[0].account.displayContentAuthorEnabled
  const contentLocation = contentLocations.EXPLORE
  const contentSubLocation = 'CONTENT_EXPLORE'

  const [getContents, { loading: loadingContents, data: contentsData, fetchMore, networkStatus }] = useLazyQuery(EXPLORE_CONTENTS(displayContentAuthorEnabled),
    {
      variables: {
        cfpId,
        folderId: folderId || homeFolderId,
        search: '',
        sort: contentSortDirection,
        sortField: contentSortField,
        limit: ITEMS_PER_PAGE,
        offset: contents.length
      },
      context: {
        queryDeduplication: false
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  )

  const [getExploreBanner, getExploreBannerResult] = useLazyQuery(GET_EXPLORE_BANNER_OPTION, { fetchPolicy: 'network-only' })
  const [sendEmail] = useMutation(SEND_EMAIL_QUERY)

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { activeFolder } = UrlHelper.useQueryParams(search)
    if (folders && folders.length > 0 && activeFolder) {
      handleBackFromPreview(activeFolder)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, folders])

  useEffect(() => {
    const newAc = new AbortController()
    getContents({
      variables: {
        cfpId,
        folderId,
        search: '',
        limit: ITEMS_PER_PAGE,
        offset: 0,
        sort: contentSortDirection,
        sortField: contentSortField
      },
      context: {
        fetchOptions: {
          signal: newAc.signal
        }
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    })

    setHasNeedForMoreFetch(true)

    return () => newAc.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId, contentSortDirection, contentSortField])

  useEffect(() => {
    // Dont show the root folder/which does not have parentId on the explore page
    const foldersWithoutRoot = cacheFolders?.filter(f => f.parentId) || []
    if (foldersWithoutRoot.length) setFolders(foldersWithoutRoot)
  }, [cacheFolders])

  useEffect(() => {
    if (!loadingContents && contentsData && contentsData.getExplorerContents) {
      determineLoadMore(contentsData.getExplorerContents)
      setContents(contentsData.getExplorerContents)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingContents, contentsData])

  // get application by subdomain if subdomain provided in the url
  useEffect(() => {
    const subdomain = UrlHelper.getSubDomain()
    if (!subdomain) setBannerType('MOST_POPULAR')
    else {
      getExploreBanner({
        variables: {
          subdomain
        }
      })
    }
  }, [getExploreBanner])

  useEffect(() => {
    const exploreBannerOption = get(getExploreBannerResult, 'data.getApplicationBySubdomain.exploreBannerOption')
    if (!exploreBannerOption) return
    setBannerType(exploreBannerOption)
  }, [getExploreBannerResult])

  useEffect(() => {
    onLoad(client, me, 'Explorer View')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      cookieTimer()
    }, 10000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const findActiveBranch = (folders, id, result = []) => {
    let found = false
    for (const folder of folders) {
      if (folder._id === id) {
        result.push(folder._id)
        found = true
        break
      }
      if (folder.children) {
        const res = findActiveBranch(folder.children, id, result)
        if (res) {
          result.push(folder._id)
          found = true
          break
        }
      }
    }
    return found
  }

  const determineLoadMore = (list) => {
    if (list.length && (list.length % ITEMS_PER_PAGE) === 0) {
      setCanLoadMore(true)
    } else if (canLoadMore) {
      setCanLoadMore(false)
    }
  }

  const handleBackFromPreview = (folderId) => {
    const openedFolders = []
    findActiveBranch(folders, folderId, openedFolders)
    setActiveFolders(openedFolders)
  }

  const onLoadMoreContents = () => hasNeedForMore && fetchMore({
    variables: {
      cfpId,
      folderId: folderId || homeFolderId,
      search: '',
      limit: ITEMS_PER_PAGE,
      offset: contents.length,
      sort: contentSortDirection,
      sortField: contentSortField
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (!fetchMoreResult.getExplorerContents.length) {
        setHasNeedForMoreFetch(false)
        return prev
      }

      setHasNeedForMoreFetch(true)
      determineLoadMore(fetchMoreResult.getExplorerContents)
      return {
        getExplorerContents: [
          ...prev.getExplorerContents,
          ...fetchMoreResult.getExplorerContents
        ]
      }
    }
  })
  const handleCarouselClick = async (content) => {
    const isRead = await checkIsRead(content)
    const newContentLocation = contentLocations.CAROUSEL_EXPLORE
    // if was cancelled
    if (!isRead) return
    await trackContentOpened({ ...content, contentLocation: contentLocations.CAROUSEL_EXPLORE }, client)
    await trackBannerOpened(content, contentLocation)
    if (content.contentType === 'CONTENT_WEB' && !content.likesEnabled && !content.commentsEnabled) {
      return window.open(content.webUrl, '_target')
    }
    const data = cloneDeep(contents).reverse()

    if (gridEnabled && width >= 769) {
      goToContentPreview(navigate, { cfpId, content, contents: data, contentLocation: newContentLocation, location },
        {
          folderFilters: { type: folderSortDirection, field: folderSortField },
          contentFilters: { type: contentSortDirection, field: contentSortField }
        })
    } else {
      goToOldContentPreview(navigate, client, { content, location, contentLocation })
    }
  }

  const handleContentClickEvent = (isContentBanner = false) => async (content) => {
    const isRead = await checkIsRead(content)
    // if was cancelled
    if (!isRead) return
    const newContentlocation = isContentBanner ? contentLocations.MOST_POPULAR : contentLocation
    trackContentOpened({ ...content, contentLocation: newContentlocation }, client)
    if (content.contentType === 'CONTENT_WEB' && !content.likesEnabled && !content.commentsEnabled) {
      return window.open(content.webUrl, '_target')
    }
    const data = cloneDeep(contents).reverse()

    if (gridEnabled && width >= 769) {
      goToContentPreview(navigate,
        { cfpId, content, contents: data, contentLocation: newContentlocation, location, rootPath: location?.pathname },
        {
          folderFilters: { type: folderSortDirection, field: folderSortField },
          contentFilters: { type: contentSortDirection, field: contentSortField }
        })
    } else {
      goToOldContentPreview(navigate, client, { content, location, contentLocation: newContentlocation })
    }
  }

  const handleFoldersStack = (folder) => {
    trackFolderOpenEvent(folder)
    navigate({ ...location, search: '', state: {}, replace: true })
    if (folder._id !== folderId) {
      setFolderId(folder._id)
    }
  }

  const onShareClick = async (content) => {
    const isRead = await checkIsRead(content)
    // if was cancelled
    if (!isRead) {
      throw new Error('onShareClick Cancel Read Acknowledgement')
    }

    const shareUrl = `${hermesUrl}/content/${content._id}`
    try {
      copy(shareUrl)
      await trackContentShared({ ...content, contentLocation, contentSubLocation }, contentShareType.LINK_COPIED, client)
    } catch (e) {
      console.error(e, 'error')
      return false
    }
    return true
  }

  const checkIsRead = async (content) => {
    setShowOverlay(true)
    const isRead = await checkIsReadAcknowledgement(client, content, formatMessage, me, sendEmail)
    setShowOverlay(false)
    return isRead
  }

  const handleHomeClick = () => {
    // navigate(`/${cfpId}/explore/folders/${homeFolderId}`)
    if (folderId !== homeFolderId) {
      handleFoldersStack(homeFolderId)
    }
  }

  const getLoader = () => {
    return <LoadingScreen />
  }

  const renderTopContent = () => {
    if (getExploreBannerResult.loading) return <Container>{ getLoader() }</Container>

    switch (bannerType) {
      case 'MOST_POPULAR':
        return (
          <Column classNames={['is-paddingless']}>
            <MostPopularContainer>
              <MostPopularHeaderContainer>
                <Text text={formatMessage(messages.mostPopular)} textSize='h4' fontColor={color.grey500} textTransform='capitalize' align='left' />
              </MostPopularHeaderContainer>
              <ExplorerBanner itemClick={handleContentClickEvent(true)} />
            </MostPopularContainer>
          </Column>
        )
      case 'CAROUSEL':
        return (
          <Column classNames={['is-paddingless', 'is-6-desktop', 'is-8-tablet', 'is-12-mobile']}>
            <CarouselContainerExplore>
              <Carousel onCarouselClick={handleCarouselClick} activeCfp={activeCfp} aspectRatio={4} displayContentAuthorEnabled={displayContentAuthorEnabled}/>
            </CarouselContainerExplore>
          </Column>
        )
      default:
        return (null)
    }
  }

  const renderHomeItem = () => {
    return (
      <ListItem
        folderHoverShadow
        customBackgroundColor='transparent'
        icon='home'
        label='Home'
        onClick={handleHomeClick}
      />)
  }

  const sortFolders = (itemClicked) => {
    if (itemClicked === folderSortLabels[0]) {
      setFolderSortDirection('asc')
      setFolderSortField('title')
    } else if (itemClicked === folderSortLabels[1]) {
      setFolderSortDirection('desc')
      setFolderSortField('title')
    } else {
      setFolderSortDirection('asc')
      setFolderSortField('priority')
    }
  }

  const changeContentSort = (field = 'priority', direction = 'asc') => {
    if (field !== contentSortField) {
      setContentSortField(field)
    }
    if (direction !== contentSortDirection) {
      setContentSortDirection(direction)
    }
  }

  const renderFolderList = () => {
    return <MemoizedFolderList
      style={{ padding: '100px' }}
      title={formatMessage(messages.folders)}
      cfpId={cfpId}
      homeFolder={homeFolderId}
      selectedFolder={folderId}
      listItems={folders}
      activeFolders={activeFolders}
      loading={!cacheFolders}
      sort={folderSortDirection}
      sortField={folderSortField}
      onItemClick={(folder) => handleFoldersStack(folder)}
    />
  }

  const ExplorePage = () => {
    return (
      <Row classNames={['is-marginless', 'is-centered']}>
        <Column classNames={['is-full-mobile is-one-third-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd']}>
          <FolderTitleContainer>
            <Text text={activeCfp.name + ' ' + formatMessage(messages.folders)}
              fontWeight="700"
              textSize="h4"
              fontColor={color.grey500}
              textTransform="capitalize"
            />
          </FolderTitleContainer>
          <DropdownContainer>
            <Dropdown
              label={formatMessage(messages.sortFolderBy)}
              list={folderSortLabels}
              onListItemClick={(item) => {
                sortFolders(item)
              }}
            />
          </DropdownContainer>
          {renderHomeItem()}
          {renderFolderList()}
        </Column>
        <Column classNames={['is-full-mobile is-two-thirds-tablet is-half-desktop is-two-third-widescreen is-half-fullhd']}>
          <MemoizedContentList
            me={me}
            client={client}
            activeCfp={activeCfp}
            title={formatMessage(messages.content)}
            listItems={contents}
            loading={loadingContents}
            hasMore={canLoadMore && hasNeedForMore}
            itemsPerPage={ITEMS_PER_PAGE}
            loadingMore={networkStatus}
            onLoadMore={() => onLoadMoreContents()}
            onItemClick={handleContentClickEvent(false)}
            onShareClick={(content) => onShareClick(content)}
            onCommentClick={handleContentClickEvent(false)}
            sort={contentSortDirection}
            sortField={contentSortField}
            changeSort={changeContentSort}
          />
        </Column>
      </Row>)
  }

  return (
    <ExploreContainer key={'explore_page'}>
      <OverlayPage visible={showOverlay}/>
      <Row classNames={['is-marginless', 'is-centered']}>
        {renderTopContent()}
      </Row>
      <Column classNames={['is-marginless', 'is-centered']}>
        {ExplorePage()}
      </Column>
    </ExploreContainer>
  )
}

const MemoizedContentList = memo(ContentList, (prevProps, nextProps) => {
  return isEqual(prevProps.listItems, nextProps.listItems) &&
      isEqual(prevProps.loading, nextProps.loading) &&
      isEqual(prevProps.loadingMore, nextProps.loadingMore) &&
      isEqual(prevProps.onItemClick, nextProps.onItemClick)
})

const MemoizedFolderList = memo(FolderList, (prevProps, nextProps) => {
  return isEqual(prevProps.listItems, nextProps.listItems) &&
      JSON.stringify(prevProps.listItems) === JSON.stringify(nextProps.listItems) &&
      isEqual(prevProps.loading, nextProps.loading) &&
      isEqual(prevProps.onItemClick, nextProps.onItemClick)
})
export default injectIntl(Explore)
