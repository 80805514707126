// @flow
import React, { useEffect, useRef, useState } from 'react'
import 'video.js/dist/video-js.min.css'
import './theme.css'
import { clone } from 'lodash'
import { trackVideoEvent, trackContentOpened, trackContentSession, cookieClear } from '../../Helpers/segmentHelper'
import { v4 as uuidv4 } from 'uuid'
import { checkIsReadAcknowledgement } from '../../Helpers/ReadAcknowledgement'
import { useMutation } from '@apollo/client'
import SEND_EMAIL_QUERY from '../../apollo/queries/sendEmail'
import type { ContentType } from '../../NewsFeed/Components/ContentCard'
import OverlayPage from '../OverlayPage'

let videojs
const importModule = async () => {
  try {
    // eslint-disable-next-line import/no-webpack-loader-syntax
    const moduleExport = await import('!video.js')
    videojs = moduleExport.default
  } catch (error) {
    const moduleExport = await import('video.js')
    videojs = moduleExport.default
  }
}
importModule()

type VideoPlayerSourceType = {
  src: string,
  type?: string
}

type HotKeysObjectType = {
  fullscreenKey: Function,
  muteKey: Function,
  playPauseKey: Function
}

type VideoPlayerUserActionsType = {
  doubleClick?: boolean | Function,
  hotkeys?: boolean | Function | HotKeysObjectType
}

type VideoPlayerFullscreenOptionsType = {
  navigationUI: 'hide' | 'show' | 'auto'
}

type VideoPlayerFullscreenType = {
  options: VideoPlayerFullscreenOptionsType
}

type VideoPlayerProps = {
  // Standard <video> Element Options
  autoplay?: true | false | 'muted' | 'play' | 'any',
  controls?: boolean,
  height?: string | number,
  loop?: boolean,
  muted?: boolean,
  poster?: string,
  preload?: 'auto' | 'metadata' | 'none',
  src?: string,
  width?: string | number,
  // Video.js-specific options
  aspectRatio?: string,
  autoSetup?: boolean,
  breakpoints?: Object,
  children?: Array<any> | Object,
  fluid?: boolean,
  inactivityTimeout?: number,
  language?: string,
  languages?: Object,
  liveui?: boolean,
  nativeControlsForTouch?: boolean,
  notSupportedMessage?: string,
  fullscreen?: VideoPlayerFullscreenType,
  playbackRates?: Array<number>,
  plugins?: Object,
  responsive?: boolean,
  sources?: Array<VideoPlayerSourceType>,
  suppressNotSupportedError?: boolean,
  techCanOverridePoster?: boolean,
  techOrder?: boolean,
  userActions?: VideoPlayerUserActionsType,
  // Custom Props
  content: ContentType,
  client?: Object,
  me?: Object,
  formatMessage?: Function,
  contentLocation: String
}

const VideoPlayer = (props: VideoPlayerProps) => {
  const { content, client, me, formatMessage, contentLocation } = props
  const videoRef = useRef()
  const [sessionId, setsessionId] = useState(null)
  const [showOverlay, setShowOverlay] = useState(false)
  const [sendEmail] = useMutation(SEND_EMAIL_QUERY)
  const checkIsRead = async () => {
    setShowOverlay(true)
    const isRead = await checkIsReadAcknowledgement(client, content, formatMessage, me, sendEmail)
    setShowOverlay(false)
    return isRead
  }
  const handleVideoPauseOnScroll = () => {
    const player = videojs.getPlayer(videoRef.current)
    const callback = (entries) => {
      let prevRatio = 0.0
      entries.forEach(entry => {
        if (entry.intersectionRatio !== prevRatio) {
          player.pause()
        }
        prevRatio = entry.intersectionRatio
      })
    }
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: [0.0, 0.5]
    }
    const observer = new IntersectionObserver(callback, options)
    const target = videoRef.current
    if (target) observer.observe(target)
  }

  const initPlayerEventListeners = () => {
    const player = videojs.getPlayer(videoRef.current)
    if (content.secure) player.bigPlayButton.off('click')
    player.on('loadedmetadata', (data) => {
      const width = player.videoWidth()
      const height = player.videoHeight()
      // TODO implement a better tailored way to fix the aspect ratio
      if (width !== 0 && height !== 0 && height > width) {
        player.aspectRatio('4:3')
      }
    })
    player.on('ended', () => {
      const playerAttr = {
        currentTime: player.currentTime(),
        duration: player.duration(),
        getVideoPlaybackQuality: player.getVideoPlaybackQuality().totalVideoFrames,
        remainingTime: player.remainingTime()
      }
      trackContentSession({ ...content, contentLocation }, client)
      trackVideoEvent('VIDEO_ENDED', { ...content, ...playerAttr, sessionId, contentLocation }, client)

      cookieClear()
      setsessionId(null)
    })
    player.on('pause', () => {
      if (!player.ended()) {
        const playerAttr = {
          currentTime: player.currentTime(),
          duration: player.duration(),
          getVideoPlaybackQuality: player.getVideoPlaybackQuality().totalVideoFrames,
          remainingTime: player.remainingTime()
        }
        trackContentSession({ ...content, contentLocation }, client)
        trackVideoEvent('VIDEO_PAUSED', { ...content, ...playerAttr, sessionId, contentLocation }, client)
        cookieClear()
        setsessionId(null)
      }
    })
    player.on('play', (e) => {
      const playerAttr = {
        currentTime: player.currentTime(),
        duration: player.duration(),
        getVideoPlaybackQuality: player.getVideoPlaybackQuality().totalVideoFrames,
        remainingTime: player.remainingTime()
      }
      const videoSessionId = sessionId || uuidv4()
      setsessionId(videoSessionId)
      trackVideoEvent('VIDEO_PLAYED', { ...content, ...playerAttr, videoSessionId, contentLocation }, client)
      trackContentOpened({ ...content, contentLocation }, client)
      if (e.target.tagName.toLowerCase() === 'video') {
        player.pause()
      }
    })
    player.on('click', async (e) => {
      try {
        if (content.secure) {
          const isRead = await checkIsRead()
          if (!isRead) return false
        }
        if (e.target.classList.contains('vjs-big-play-button')) {
          player.play()
        }
      } catch (e) {
        console.error('player on click', e)
      }
    })
  }

  useEffect(() => {
    const videoProps = clone(props)
    videoProps.controlBar = {
      children: [
        'progressControl',
        'currentTimeDisplay',
        'durationDisplay',
        'fullscreenToggle',
        'playToggle',
        'volumeControl'
      ]
    }
    const player = videojs(videoRef.current, videoProps, () => {
      initPlayerEventListeners()
      handleVideoPauseOnScroll()
    })
    return () => {
      player.off('loadedmetadata')
      player.off('ended')
      player.off('pause')
      player.off('play')
      player.off('click')
      player.dispose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div data-vjs-player={true}>
      <OverlayPage visible={showOverlay}/>
      <video ref={videoRef} className='video-js vjs-theme-apprise'/>
    </div>
  )
}

export default VideoPlayer
